import React, { useEffect, useState } from 'react';
import { Alert, Card, CardTitle, Spinner } from 'reactstrap';
import { COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_API, CREATE_PHYSICAL_DELIVERY_NOTE_PRODUCT_API, RETREIVE_SPECIFIC_DELIVERY_LOCATION_LOADING_ORDER_API } from '../../../../../../../../api/api_data';
import { useDispatch } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../../../../../../actions/actions_helper';
import { fail_specific_delivery_note_details, loading_specific_delivery_note_details, reset_specific_delivery_note_details, success_specific_delivery_note_details } from '../../../../../../../../reducers/dashboard_reducers/delivery_notes/specific_delivery_note_details_Slice';
import { COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_ACCESS_DENIED_MSG, COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_ERR_MSG, COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_SUCCESS_MSG, CREATE_DELIVERY_NOTE_PRODUCT_ACCESS_DENIED_MSG, CREATE_DELIVERY_NOTE_PRODUCT_ERR_MSG, CREATE_DELIVERY_NOTE_PRODUCT_SUCCESS_MSG, RETRIEVE_SPECIFIC_DELIVERY_NOTE_ACCESS_DENIED_MSG, RETRIEVE_SPECIFIC_DELIVERY_NOTE_ERR_MSG, RETRIEVE_SPECIFIC_DELIVERY_NOTE_SUCCESS_MSG } from '../../../../../../../../constants/success_error_messages_consts';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { useSelector } from 'react-redux';
import { messageToast } from '../../../../../../../../utils/Utils';
import { Button, Col, Icon } from '../../../../../../../../components/Component';
import ReadOnlyTable from '../../../../../table/ReadOnlyTable';
import ModalConfirmCancel from '../../../../../modal/modal_confirm_cancel/ModalConfirmCancel';
import { fail_create_delivery_note_product, loading_create_delivery_note_product, reset_create_delivery_note_product, success_create_delivery_note_product } from '../../../../../../../../reducers/dashboard_reducers/delivery_notes/create_delivery_note_product_Slice';
import { fail_complete_adding_delivery_note_products, loading_complete_adding_delivery_note_products, reset_complete_adding_delivery_note_products, success_complete_adding_delivery_note_products } from '../../../../../../../../reducers/dashboard_reducers/delivery_notes/complete_adding_delivery_note_products_Slice';

const AddProductsStep = ({ wizardData, setWizardData, nextStep, setContinueProcessingData }) => {

  const dispatch = useDispatch();
  const [page_data, setPageData] = useState(wizardData);
  const [loadingOrderData, setLoadingOrderData] = useState([]);
  const [total_mt_capacity, setTotalMTCapacity] = useState(0);
  const [product_data, setProductData] = useState([]);
  const [loadedProducts, setLoadedProducts] = useState([]);
  const [not_loaded_products, setNotLoadedProducts] = useState([]);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const [errAmendAccStat, setErrAmendAccStat] = useState("");
  const [loading, setLoading] = useState();
  const [errorVal, setErrorVal] = useState("");

  // Get Sum of all Goods Received Products
  const totalCapacitySum = product_data?.reduce((sum, item) => {
    // Convert total_capacity_in_mt to a number and add to the sum
    return sum + parseFloat(item?.total_capacity_in_mt);
  }, 0);

  //   Listen to Product Data Changes
  useEffect(() => {
    setTotalMTCapacity(totalCapacitySum);
    const load_prod = product_data?.filter((prod) => prod?.product_status === "loaded");
    const notload_prod = product_data?.filter((prod) => prod?.product_status === "not loaded");

    setLoadedProducts(load_prod);
    setNotLoadedProducts(notload_prod);
  }, [product_data]);

  useEffect(() => {
    if (wizardData)
    {
      console.log(wizardData);
      setProductData(wizardData?.products);
      // setProductData(wizardData?.loading_order_product_info?.products_list);
      setPageData(wizardData);
      dispatch(
        employee_dashboard_actions_helper(
          loading_specific_delivery_note_details,
          success_specific_delivery_note_details,
          fail_specific_delivery_note_details,
          RETRIEVE_SPECIFIC_DELIVERY_NOTE_ERR_MSG,
          RETRIEVE_SPECIFIC_DELIVERY_NOTE_SUCCESS_MSG,
          app_consts.POST,
          RETREIVE_SPECIFIC_DELIVERY_LOCATION_LOADING_ORDER_API,
          { loading_order_ref_no: page_data?.loading_order_ref_no, req_delivery_location_id: page_data?.branch_location_id },
          RETRIEVE_SPECIFIC_DELIVERY_NOTE_ACCESS_DENIED_MSG
        )
      );
    }
  }, [wizardData]);

  // Listen to Reducer
  const delivery_note_details = useSelector((state) => state.specific_delivery_note_details.specific_delivery_note_details);
  // 
  useEffect(() => {
    if (delivery_note_details.status !== app_consts.IDLE)
    {
      if (delivery_note_details.status === app_consts.LOADING)
      {
      } else
      {
        if (delivery_note_details.status === app_consts.FAIL)
        {
          messageToast(delivery_note_details.message, app_consts.FAIL);
        } else if (delivery_note_details.status === app_consts.SUCCESS)
        {
        }
        dispatch(reset_specific_delivery_note_details());
      }
    }
  }, [delivery_note_details]);

  useEffect(() => {
    if (loadingOrderData)
    {
      // Filter Out Loading Orders for this Branch Location
      handleFilterProducts(loadingOrderData);
    }
  }, [loadingOrderData]);

  const handleFilterProducts = (product_data) => {
  };

  const handleRowButtonClick = (data) => {
    if (data?.action === app_consts.LOAD)
    {
      setOpenConfirmCancelModal(true);
      setDetail(data?.data);
    }
  };

  const offload_from_warehouse = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_create_delivery_note_product,
        success_create_delivery_note_product,
        fail_create_delivery_note_product,
        CREATE_DELIVERY_NOTE_PRODUCT_ERR_MSG,
        CREATE_DELIVERY_NOTE_PRODUCT_SUCCESS_MSG,
        app_consts.POST,
        CREATE_PHYSICAL_DELIVERY_NOTE_PRODUCT_API,
        { delivery_note_id: page_data?.delivery_note_id, product_id: detail?.product_id, warehouse_storage_id: detail?.warehouse_storage_id },
        CREATE_DELIVERY_NOTE_PRODUCT_ACCESS_DENIED_MSG
      )
    );
  };
  //Listen to State of Product List
  const create_delivery_note_product = useSelector((state) => state.create_delivery_note_product.create_delivery_note_product);

  useEffect(() => {
    if (create_delivery_note_product.status !== app_consts.IDLE)
    {
      if (create_delivery_note_product.status === app_consts.LOADING)
      {
      } else
      {
        if (create_delivery_note_product.status === app_consts.FAIL)
        {
          messageToast(create_delivery_note_product.message, app_consts.FAIL);
          setErrAmendAccStat(create_delivery_note_product.message);
        } else if (create_delivery_note_product.status === app_consts.SUCCESS)
        {
          messageToast(CREATE_DELIVERY_NOTE_PRODUCT_SUCCESS_MSG, app_consts.SUCCESS);
          setProductData(create_delivery_note_product?.data?.products);
          setOpenConfirmCancelModal(false);
          // 

          setTotalMTCapacity(totalCapacitySum);
          const load_prod = create_delivery_note_product?.data?.products?.filter((prod) => prod?.product_status === "loaded");
          const notload_prod = create_delivery_note_product?.data?.products?.filter((prod) => prod?.product_status === "not loaded");

          setLoadedProducts(load_prod);
          setNotLoadedProducts(notload_prod);
        }
        dispatch(reset_create_delivery_note_product());
      }
    }
  }, [create_delivery_note_product]);

  const finish_adding_products = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_complete_adding_delivery_note_products,
        success_complete_adding_delivery_note_products,
        fail_complete_adding_delivery_note_products,
        COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_ERR_MSG,
        COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_SUCCESS_MSG,
        app_consts.POST,
        COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_API,
        { delivery_note_id: page_data?.delivery_note_id },
        COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to inish Adding Products Reducer
  const complete_adding_delivery_note_products = useSelector((state) => state.complete_adding_delivery_note_products.complete_adding_delivery_note_products);

  useEffect(() => {
    if (complete_adding_delivery_note_products.status !== app_consts.IDLE)
    {
      if (complete_adding_delivery_note_products.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (complete_adding_delivery_note_products.status === app_consts.FAIL)
        {
          setErrorVal(complete_adding_delivery_note_products.message);
          messageToast(complete_adding_delivery_note_products.message, app_consts.FAIL);
        } else if (complete_adding_delivery_note_products.status === app_consts.SUCCESS)
        {
          messageToast(COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_SUCCESS_MSG, app_consts.SUCCESS);
          setContinueProcessingData(complete_adding_delivery_note_products.data);
          nextStep();
        }
        dispatch(reset_complete_adding_delivery_note_products());
      }
    }
  }, [complete_adding_delivery_note_products]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Select Loaded Products</CardTitle>
        <Col lg="12">
          <div className="form-group d-flex justify-between">
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label text-soft">User Account :</label>
              </div>
              <div className="form-control-wrap">
                <label className="form-label text-primary">{page_data?.owner ? `${page_data?.owner} - ${page_data?.unique_identification_number}` : "--"}</label>
              </div>
            </div>
            <div>
              <div className="form-label-group">
                <label className="form-label text-soft">Total Capacity In MT :</label>
              </div>
              <div className="form-control-wrap">
                <label className="form-label text-primary">{total_mt_capacity ? parseFloat(total_mt_capacity)?.toFixed(2) : "--"}</label>
              </div>
            </div>
          </div>
        </Col>
        <div className="form-label-group">
          <label className="form-label text-soft fs-16px">Not Loaded Products</label>
        </div>
        {/* Table */}
        <div className="my-3">
          <ReadOnlyTable data={not_loaded_products} tableType={app_consts.NOT_LOADED_DN_EDIT_MAP} setSelectedData={handleRowButtonClick} />
        </div>
        <div className="form-label-group">
          <label className="form-label text-soft fs-16px">Loaded Products</label>
        </div>
        {/* Table */}
        <div className="my-3">
          <ReadOnlyTable data={loadedProducts} tableType={app_consts.ADD_LOADING_ORDER_PRODUCT_TABLE} />
        </div>
        {errorVal && (
          <div className="mt-4">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <Col xl="12">
          <div className="form-group mt-2">
            <div className="form-control-wrap-flex-wrap justify-end items-center">
              <Button
                onClick={finish_adding_products}
                disabled={
                  loading
                    ? loading
                    : !(loadedProducts?.length > 0 && not_loaded_products?.length === 0)
                }
                color="primary"
                size="md"
              >
                {loading ? <Spinner size="sm" color="light" /> : "Finish Loading Products "}
              </Button>
            </div>
          </div>
        </Col>
      </Card>
      {/* Yes or No Modal */}
      <ModalConfirmCancel
        openModal={openConfirmCancelModal}
        setOpenModal={setOpenConfirmCancelModal}
        modalTitle={`OffLoad ${detail && `${detail?.product_name} of ${detail.total_mt_capacity} MTs from Warehouse Inventory`}?`}
        modalDescription={`Warehouse Inventory will be Updated after this Action`}
        buttonConfirm="Confirm"
        buttonCancel="Cancel"
        succFunc={offload_from_warehouse}
        errAmend={errAmendAccStat}
        setErrAmend={setErrAmendAccStat}
      />
    </>
  );
};

export default AddProductsStep;