import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    submit_feedback: { status: "idle" },
};

export const submit_feedback_Slice = createSlice({
    name: "submit_feedback",
    initialState,
    reducers: {
        success_submit_feedback: (state, action) => {
            state.submit_feedback = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_submit_feedback: (state, action) => {
            state.submit_feedback = { status: app_consts.FAIL, message: action.payload };
        },
        reset_submit_feedback: (state) => {
            state.submit_feedback = { status: app_consts.IDLE };
        },
        loading_submit_feedback: (state) => {
            state.submit_feedback = { status: app_consts.LOADING };
        },
    }
});

export const { success_submit_feedback, fail_submit_feedback, reset_submit_feedback, loading_submit_feedback } = submit_feedback_Slice.actions;

export default submit_feedback_Slice.reducer;