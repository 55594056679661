import React, { useEffect, useState } from 'react';
import { Alert, Card, CardTitle, Label } from 'reactstrap';
import { UNDERGOING_PROCESSING } from '../../../../../../../../constants/react_app_consts';
import { CREATE_DELIVERY_NOTE_API, RETRIEVE_INITAL_DELIVERY_NOTE_LOADING_ORDERS, RETRIEVE_LOADING_ORDERS_LIST_BY_STATUS_API } from '../../../../../../../../api/api_data';
import { useDispatch } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../../../../../../actions/actions_helper';
import { fail_retrieve_delivery_notes_ticket_list, loading_retrieve_delivery_notes_ticket_list, reset_retrieve_delivery_notes_ticket_list, success_retrieve_delivery_notes_ticket_list } from '../../../../../../../../reducers/dashboard_reducers/delivery_notes/retrieve_delivery_notes_ticket_list_Slice';
import { CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_ACCESS_DENIED_MSG, CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_ERR_MSG, CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_SUCCESS_MSG, RETRIEVE_DELIVERY_NOTES_TICKETS_ACCESS_DENIED_MSG, RETRIEVE_DELIVERY_NOTES_TICKETS_ERR_MSG, RETRIEVE_DELIVERY_NOTES_TICKETS_SUCCESS_MSG, RETRIEVE_LOADING_ORDER_LIST_ACCESS_DENIED_MSG, RETRIEVE_LOADING_ORDER_LIST_ERR_MSG, RETRIEVE_LOADING_ORDER_LIST_SUCCESS_MSG } from '../../../../../../../../constants/success_error_messages_consts';
import { useSelector } from 'react-redux';
import { messageToast, rSelectMapFun } from '../../../../../../../../utils/Utils';
import { fail_retrieve_loading_order_list, loading_retrieve_loading_order_list, reset_retrieve_loading_order_list, success_retrieve_loading_order_list } from '../../../../../../../../reducers/dashboard_reducers/loading_orders/retrieve_loading_order_list_Slice';
import { BlockHead, BlockTitle, Button, Col, Icon, Row, RSelect } from '../../../../../../../../components/Component';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { retreive_rSelectVariables } from '../../../../../../../../utils/functionalUtils';
import { BiErrorCircle } from 'react-icons/bi';
import ProfileDetailText from '../../../../../text/ProfileDetailText';
import TextInput from '../../../../../../../components/inputs/TextInput';
import { fail_create_delivery_note_ticket, loading_create_delivery_note_ticket, reset_create_delivery_note_ticket, success_create_delivery_note_ticket } from '../../../../../../../../reducers/dashboard_reducers/delivery_notes/create_delivery_note_ticket_Slice';

const initialState = { loading_order_ref_no: "", first_weight: "", comments: "", delivery_location_id: "" };

const SelectLoadingOrdersStep = ({ nextStep, setWizardData }) => {

  const [loadingOrderList, setLoadingOrdersList] = useState(true);
  const [loadingOrderError, setLoadingOrderError] = useState("");
  const [loadingOrderData, setLoadingOrderData] = useState([]);
  const [loadingOrderDetails, setLoadingOrderDetails] = useState([]);
  const [loadingOrderSelect, setLoadingOrderSelect] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [delivery_location_list, setDeliveryLocationList] = useState([]);

  useEffect(() => {
    retrieve_loading_order_list_func();
  }, []);

  const dispatch = useDispatch();

  // 

  // Retrieve Delivery Notes Tickets
  const retrieve_loading_order_list_func = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_retrieve_loading_order_list,
        success_retrieve_loading_order_list,
        fail_retrieve_loading_order_list,
        RETRIEVE_LOADING_ORDER_LIST_ERR_MSG,
        RETRIEVE_LOADING_ORDER_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_INITAL_DELIVERY_NOTE_LOADING_ORDERS,
        null,
        RETRIEVE_LOADING_ORDER_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Loading Order Changes
  const retrieve_loading_order_list = useSelector((state) => state.retrieve_loading_order_list.retrieve_loading_order_list);
  // 
  useEffect(() => {
    if (retrieve_loading_order_list.status !== app_consts.IDLE)
    {
      if (retrieve_loading_order_list.status === app_consts.LOADING)
      {
        setLoadingOrdersList(true);
      } else
      {
        setLoadingOrdersList(false);
        if (retrieve_loading_order_list.status === app_consts.FAIL)
        {
          setLoadingOrderError(retrieve_loading_order_list.message);
          messageToast(RETRIEVE_LOADING_ORDER_LIST_ERR_MSG, app_consts.FAIL);
        } else if (retrieve_loading_order_list.status === app_consts.SUCCESS)
        {
          setLoadingOrderData(retrieve_loading_order_list.data);
        }
        dispatch(reset_retrieve_loading_order_list());
      }
    }
  }, [retrieve_loading_order_list]);

  useEffect(() => {
    if (loadingOrderData)
    {
      handleLoadingOrderList(loadingOrderData);
    }
  }, [loadingOrderData]);

  const handleLoadingOrderList = async () => {

    let mappedData = [];

    await Promise.all(
      loadingOrderData?.map(async (data_to_map) => {
        // Map Branch List
        const { label, value } = retreive_rSelectVariables(app_consts.LOADING_ORDERS_LIST, data_to_map);
        const result = await rSelectMapFun(label, value);
        mappedData.push(result);
      }));
    setLoadingOrderSelect(mappedData);
  };

  const handleDeliveryLocations = async (loadingOrderData) => {
    let mappedData = [];

    await Promise.all(
      loadingOrderData?.delivery_locations?.map(async (data_to_map) => {
        // Map Branch List
        const { label, value } = retreive_rSelectVariables(app_consts.DELIVERY_LOCATION_MAP, data_to_map);
        const result = await rSelectMapFun(label, value);

        // Check if the result already exists in mappedData based on label and value
        const isDuplicate = mappedData.some(item => item.label === result.label && item.value === result.value);

        if (!isDuplicate)
        {
          mappedData.push(result); // Add only if it's not a duplicate
        }
      })
    );

    setDeliveryLocationList(mappedData);
  };

  const handleLoadingOrderChange = (value, label) => {
    // Filter Data
    const filteredData = loadingOrderData?.filter((data) => data?.loading_order_ref_no === value);
    setLoadingOrderDetails(filteredData[0]);
    handleDeliveryLocations(filteredData[0]);
    setFormData({ ...formData, ["loading_order_ref_no"]: value, ["first_weight"]: "" });
  };

  // Handle First Weight Blur
  const handleFirstWeightBlur = () => {
    if (formData?.first_weight)
    {
      setFormData({ ...formData, ["first_weight"]: parseFloat(formData.first_weight).toFixed(2) });
    }
  };

  // Handle Change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCreateDN = () => {
    setError("");
    // Create Good Received Ticket
    dispatch(
      employee_dashboard_actions_helper(
        loading_create_delivery_note_ticket,
        success_create_delivery_note_ticket,
        fail_create_delivery_note_ticket,
        CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_ERR_MSG,
        CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_SUCCESS_MSG,
        app_consts.POST,
        CREATE_DELIVERY_NOTE_API,
        formData,
        CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Create Delivery Note Reducer
  const create_delivery_note_ticket = useSelector((state) => state.create_delivery_note_ticket.create_delivery_note_ticket);

  useEffect(() => {
    if (create_delivery_note_ticket.status !== app_consts.IDLE)
    {
      if (create_delivery_note_ticket.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (create_delivery_note_ticket.status === app_consts.FAIL)
        {
          setError(create_delivery_note_ticket.message);
          messageToast(create_delivery_note_ticket.message, app_consts.FAIL);
        } else if (create_delivery_note_ticket.status === app_consts.SUCCESS)
        {
          setWizardData(create_delivery_note_ticket.data);
          messageToast(CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_SUCCESS_MSG, app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_create_delivery_note_ticket());
      }
    }
  }, [create_delivery_note_ticket]);


  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Select Loading Order</CardTitle>
        <Row className="g-4">
          <div className="mt-4 mb-4">
          </div>
          <Col size={6} className="my-2">
            <div className="form-group">
              <label className="form-label" htmlFor="uom">
                Loading Order
              </label>
              <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                <div className="w-100">
                  <RSelect
                    options={loadingOrderSelect}
                    className="w-100"
                    placeholder="Search"
                    id="loading_order_ref_no"
                    onChange={(e) => { handleLoadingOrderChange(e.value, e.label); }}
                    isDisabled={loadingOrderList}
                  />
                </div>
              </div>
            </div>
          </Col>
          <hr />
          <BlockHead>
            <BlockTitle tag="h6">Loading Order Details</BlockTitle>
          </BlockHead>
          {
            formData.loading_order_ref_no ?
              <>
                <div className="profile-ud-list">
                  <ProfileDetailText label="Owner" text={loadingOrderDetails?.owner || "---"} />
                  <ProfileDetailText label="Contact person" text={loadingOrderDetails?.contact_person || "---"} />
                  <ProfileDetailText label="Contact Mobile" text={loadingOrderDetails?.contact_mobile || "---"} />
                  <ProfileDetailText label="Transporter" text={loadingOrderDetails?.transporter_name || "---"} />
                  <ProfileDetailText label="Truck Driver Name" text={loadingOrderDetails?.truck_driver_name || "---"} />
                  <ProfileDetailText label="Truck License Plate" text={loadingOrderDetails?.truck_license_plate || "---"} />
                  <ProfileDetailText label="Trailer One" text={loadingOrderDetails?.trailer_one_no || "---"} />
                  <ProfileDetailText label="Trailer Two" text={loadingOrderDetails?.trailer_two_no || "---"} />
                </div>
                <hr />
                <BlockHead>
                  <BlockTitle tag="h6">Branch Location | Weight Bridge Readings</BlockTitle>
                </BlockHead>
                <Col size={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="uom">
                      Delivery Location
                    </label>
                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                      <div className="w-100">
                        <RSelect
                          options={delivery_location_list}
                          className="w-100"
                          placeholder="Search"
                          id="loading_order_ref_no"
                          onChange={(e) => { setFormData({ ...formData, ["delivery_location_id"]: e.value }); }}
                          isDisabled={loadingOrderList}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <TextInput
                  colSize={6}
                  label="Tare Weight (Metric Tonnes)"
                  type="number"
                  id_val="first_weight"
                  value={formData.first_weight}
                  handleChange={handleChange}
                  name="first_weight"
                  onBlur={handleFirstWeightBlur}
                />
                <Col sm="10" className="my-2">
                  <div className="form-group">
                    <Label htmlFor="default-textarea" className="form-label">
                      WeighBridge Ticket Comments (Optional)
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        className="no-resize form-control"
                        type="textarea"
                        id="default-textarea"
                        value={formData.comments}
                        onChange={(e) => setFormData({ ...formData, ["comments"]: e.target.value })}
                      />
                    </div>
                  </div>
                </Col>
                {errorVal && (
                  <div className="mt-4">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" />
                      {errorVal}{" "}
                    </Alert>
                  </div>
                )}
                <Col xl="12" className="d-flex justify-content-end">
                  <Button
                    onClick={handleCreateDN}
                    size="lg"
                    type="submit"
                    color="primary"
                    disabled={loading || !(formData.loading_order_ref_no && formData.first_weight && formData.delivery_location_id)}
                  >
                    Generate Delivery Note
                  </Button>
                </Col>
              </>
              :
              <>
                <div className="mb-2 text-soft alert-icon text-center fw-bold fs-15px">
                  <BiErrorCircle className="text-warning fs-13" />
                  <p>Select a loading Order to View Details</p>
                </div>
              </>
          }
        </Row>
      </Card>
    </>
  );
};

export default SelectLoadingOrdersStep;