import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retrieve_specific_branch_location: { status: "idle" },
};

export const retrieve_specific_branch_location_Slice = createSlice({
    name: "retrieve_specific_branch_location",
    initialState,
    reducers: {
        success_retrieve_specific_branch_location: (state, action) => {
            state.retrieve_specific_branch_location = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_specific_branch_location: (state, action) => {
            state.retrieve_specific_branch_location = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_specific_branch_location: (state) => {
            state.retrieve_specific_branch_location = { status: app_consts.IDLE };
        },
        loading_retrieve_specific_branch_location: (state) => {
            state.retrieve_specific_branch_location = { status: app_consts.LOADING };
        },
    },
});

export const {
    success_retrieve_specific_branch_location,
    fail_retrieve_specific_branch_location,
    reset_retrieve_specific_branch_location,
    loading_retrieve_specific_branch_location,
} = retrieve_specific_branch_location_Slice.actions;

export default retrieve_specific_branch_location_Slice.reducer;
