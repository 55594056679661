import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    logged_in_cred: { status: "idle" },
};

export const logged_in_cred_Slice = createSlice({
    name: "logged_in_cred",
    initialState,
    reducers: {
        success_logged_in_cred: (state, action) => {
            state.logged_in_cred = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_logged_in_cred: (state, action) => {
            state.logged_in_cred = { status: app_consts.FAIL, message: action.payload };
        },
        reset_logged_in_cred: (state) => {
            state.logged_in_cred = { status: app_consts.IDLE };
        },
        loading_logged_in_cred: (state) => {
            state.logged_in_cred = { status: app_consts.LOADING };
        },
    }
});

export const { success_logged_in_cred, fail_logged_in_cred, reset_logged_in_cred, loading_logged_in_cred } = logged_in_cred_Slice.actions;

export default logged_in_cred_Slice.reducer;