import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { useSelector } from "react-redux";
import { success_sign_out } from "../../../../reducers/dashboard_reducers/auth/sign_out_Slice";
import { useDispatch } from "react-redux";
import { convertToTitleCase } from "../../../../utils/functionalUtils";
import { success_client_sign_out } from "../../../../reducers/client_reducers/auth/sign_out_slice";

const User = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const dispatch = useDispatch();

  const handleSignout = () => {
    dispatch(success_sign_out());
    dispatch(success_client_sign_out());
  };


  // Getting State Value for Credentials of Logged In User
  const log_cred = useSelector((state) => state.logged_in_cred.logged_in_cred);

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon={"user-alt"} className="sm" />
          <div className="user-info d-none d-md-block">
            <div className="user-status">{log_cred.data ? convertToTitleCase(log_cred.data.role_name) : "----"}</div>
            <span className="sub-text">{log_cred.data ? log_cred.data.username : "----"}</span>
            <div className="user-name dropdown-indicator">{log_cred.data ? <>{`${log_cred.data.first_name} ${log_cred.data.last_name}`}</> : "----"}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <UserAvatar icon={"user-alt"} className="sm" />
            </div>
            <div className="user-info">
              <span className="lead-text">{log_cred.data ? <>{`${log_cred.data.first_name} ${log_cred.data.last_name}`}</> : "----"}</span>
              <span className="sub-text">{log_cred.data ? log_cred.data.username : "----"}</span>
              <span className="sub-text">{log_cred.data ? log_cred.data.email : "----"}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href={`${process.env.PUBLIC_URL}/`} onClick={handleSignout}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
