import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    sign_out: { status: "idle" },
};

export const sign_out_Slice = createSlice({
    name: "sign_out",
    initialState,
    reducers: {
        success_client_sign_out: (state) => {
            state.sign_out = { status: app_consts.SUCCESS };
            localStorage.removeItem("access");
            localStorage.removeItem("log_cred");
        }
    }
});

export const { success_client_sign_out } = sign_out_Slice.actions;

export default sign_out_Slice.reducer;