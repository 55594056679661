import React from 'react';

const MenuText = ({ menuText, selected }) => {
    return (
        <>
            <div className="user-card p-2 border-bottom ">
                <div className="user-info">
                    <span className={`form-label ${menuText !== selected ? "text-soft" : null}`}>{menuText}</span>
                </div>
            </div>
        </>
    );
};

export default MenuText;