import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { Col, Icon, RSelect, Row } from '../../../../../../components/Component';
import { useDispatch } from 'react-redux';
import ReadOnlyTable from '../../../table/ReadOnlyTable';
import * as app_consts from "../../../../../../constants/react_app_consts";

const AddEmployeeModal = ({ page_data, openModal, successFunction }) => {

    const dispatch = useDispatch();
    const [step, setStep] = useState("Main Step");
    const [errorVal, setError] = useState("");

    return (
        <>
            <div className="d-flex justify-content-between">
                {step === 2 && (
                    <div
                        className="nk-block-head-sub mb-2"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            setStep(1);
                            // setFormData(insitialState);
                        }}
                    >
                        <Icon name="arrow-left" />
                        <span>back</span>
                    </div>
                )}
            </div>
            {errorVal && (
                <div className="mb-3 w-100">
                    <Alert color="danger" className="alert-icon">
                        <Icon name="alert-circle" /> {errorVal}{" "}
                    </Alert>
                </div>
            )}
            {step === "Main Step" ? (
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Row className="g-4 mt-4">
                            <Col size={6} className="mt-0">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Select User
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                // options={productTypeList}
                                                className="w-100"
                                                placeholder="Search"
                                                id="product_type"
                                            // onChange={(e) => { handleProductTypeChange(e.value, e.label); }}
                                            // isDisabled={productListLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col size={6} className="mt-0">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Department
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                // options={productTypeList}
                                                className="w-100"
                                                placeholder="Search"
                                                id="product_type"
                                            // onChange={(e) => { handleProductTypeChange(e.value, e.label); }}
                                            // isDisabled={productListLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col size={6} className="mt-0">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Role
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                // options={productTypeList}
                                                className="w-100"
                                                placeholder="Search"
                                                id="product_type"
                                            // onChange={(e) => { handleProductTypeChange(e.value, e.label); }}
                                            // isDisabled={productListLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <div className="form-label-group">
                                <label className="form-label text-soft fs-22px">Employee Role Details</label>
                            </div>
                        </Row>
                    </form>
                </>
            )
                :
                ""
            }
        </>
    );
};

export default AddEmployeeModal;