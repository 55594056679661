import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const ModalPDFViewer = ({ openPreview, setOpenPreview, title, pdf }) => {
  const [modalPrev, setModalPrev] = useState(openPreview);
  const [pdf_file, setPdfFile] = useState([]);
  const [modalTitle, setModalTitle] = useState("");

  const togglePrev = () => {
    setOpenPreview(!modalPrev);
  };

  // UseEffect for Opening Modal
  useEffect(() => {
    setModalPrev(openPreview);
  }, [openPreview]);

  // UseEffect
  useEffect(() => {
    setModalTitle(title);
  }, [title]);

  // UseEffect
  useEffect(() => {
    setPdfFile(pdf);
  }, [pdf]);



  // Plugins
  const default_layout_plugin = defaultLayoutPlugin();

  return (
    <>
      <Modal isOpen={modalPrev} toggle={togglePrev} className="modal-dialog-center modal-lg">
        <ModalBody>
          {pdf_file && (
            <>
              <ModalHeader toggle={togglePrev}>{modalTitle}</ModalHeader>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={pdf_file} plugins={[default_layout_plugin]} />
              </Worker>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalPDFViewer;
