import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
 retrieve_loading_order_details: { status: "idle" },
};

export const retrieve_loading_order_details_Slice = createSlice({
 name: "retrieve_loading_order_details",
 initialState,
 reducers: {
  success_retrieve_loading_order_details: (state, action) => {
   state.retrieve_loading_order_details = { status: app_consts.SUCCESS, data: action.payload };
  },
  fail_retrieve_loading_order_details: (state, action) => {
   state.retrieve_loading_order_details = { status: app_consts.FAIL, message: action.payload };
  },
  reset_retrieve_loading_order_details: (state) => {
   state.retrieve_loading_order_details = { status: app_consts.IDLE };
  },
  loading_retrieve_loading_order_details: (state) => {
   state.retrieve_loading_order_details = { status: app_consts.LOADING };
  },
 }
});

export const { success_retrieve_loading_order_details, fail_retrieve_loading_order_details, reset_retrieve_loading_order_details, loading_retrieve_loading_order_details } = retrieve_loading_order_details_Slice.actions;

export default retrieve_loading_order_details_Slice.reducer;