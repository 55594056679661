import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retrieve_goods_received_list: { status: "idle" },
};

export const retrieve_goods_received_list_Slice = createSlice({
    name: "retrieve_goods_received_list",
    initialState,
    reducers: {
        success_retrieve_goods_received_list: (state, action) => {
            state.retrieve_goods_received_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_goods_received_list: (state, action) => {
            state.retrieve_goods_received_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_goods_received_list: (state) => {
            state.retrieve_goods_received_list = { status: app_consts.IDLE };
        },
        loading_retrieve_goods_received_list: (state) => {
            state.retrieve_goods_received_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_retrieve_goods_received_list, fail_retrieve_goods_received_list, reset_retrieve_goods_received_list, loading_retrieve_goods_received_list } = retrieve_goods_received_list_Slice.actions;

export default retrieve_goods_received_list_Slice.reducer;