import React, { useEffect, useState } from 'react';
import Head from '../../../../layout/head/Head';
import Content from '../../../../layout/content/Content';
import { Block } from '../../../../components/Component';
import TablePageHeading from '../../components/page_heading/TablePageHeading';
import * as app_consts from "../../../../constants/react_app_consts";
import { Spinner } from 'reactstrap';
import { employee_dashboard_actions_helper } from '../../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { fail_emp_dep_roles_list, loading_emp_dep_roles_list, reset_emp_dep_roles_list, success_emp_dep_roles_list } from '../../../../reducers/dashboard_reducers/employee/emp_dep_roles_list_Slice';
import { RETRIEVE_EMP_DEPT_ROLE_LIST_ACCESS_DENIED_MSG, RETRIEVE_EMP_DEPT_ROLE_LIST_ERR_MSG, RETRIEVE_EMP_DEPT_ROLE_LIST_SUCCESS_MSG } from '../../../../constants/success_error_messages_consts';
import { RETRIEVE_EMPLOYEE_DEPARTMENT_AND_ROLES_LIST_API } from '../../../../api/api_data';
import { useSelector } from 'react-redux';
import { messageToast } from '../../../../utils/Utils';
import TableList from '../../components/table/TableList';
import ModalDetails from '../../components/modal/modal_details/ModalDetails';
import { BiErrorCircle } from 'react-icons/bi';

const type = app_consts.DEPARTMENT_AND_ROLES_LIST;

const DepartmentAndRolesList = () => {

    const [page_data, setPageData] = useState([]);
    const [page_list, setPageList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const [componentData, setComponentData] = useState([]);
    const [openModalDetails, setOpendModalDetails] = useState(false);
    const [detail, setDetail] = useState([]);
    const [selectedData, setSelectedData] = useState("");

    // When Page Opens 
    useEffect(() => {
        retrieve_page_data();
    }, []);

    // Retrieve Page Data
    const retrieve_page_data = () => {
        setPageData([]);
        setPageList([]);
        setError("");
        dispatch(
            employee_dashboard_actions_helper(
                loading_emp_dep_roles_list,
                success_emp_dep_roles_list,
                fail_emp_dep_roles_list,
                RETRIEVE_EMP_DEPT_ROLE_LIST_ERR_MSG,
                RETRIEVE_EMP_DEPT_ROLE_LIST_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_EMPLOYEE_DEPARTMENT_AND_ROLES_LIST_API,
                null,
                RETRIEVE_EMP_DEPT_ROLE_LIST_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Data Retrieval
    const emp_dep_roles_list = useSelector((state) => state.emp_dep_roles_list.emp_dep_roles_list);

    useEffect(() => {
        if (emp_dep_roles_list.status !== app_consts.IDLE)
        {
            if (emp_dep_roles_list.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (emp_dep_roles_list.status === app_consts.FAIL)
                {
                    messageToast(emp_dep_roles_list.message, app_consts.FAIL);
                    setError(emp_dep_roles_list.message);
                } else if (emp_dep_roles_list.status === app_consts.SUCCESS)
                {
                    setPageData(emp_dep_roles_list.data);
                    setPageList(emp_dep_roles_list.data);
                }
                dispatch(reset_emp_dep_roles_list());
            }
        }
    }, [emp_dep_roles_list]);

    // UseEffect for Specific Data
    useEffect(() => {
        if (selectedData)
        {
            setDetail(filterSpecificEmpDeptRole(selectedData));
        }
    }, [selectedData]);

    // Filter Specific Branch Location
    const filterSpecificEmpDeptRole = (selectedData) => {
        const filtereredBranchLocation = page_data?.filter((data) => data?.employee_department?.department_id === selectedData);
        return filtereredBranchLocation[0];
    };

    return (
        <>
            <Head title={`Departments and Roles`} />
            <Content>
                <TablePageHeading
                    title="Departments and Roles"
                    description={`Total Departments: ${page_data.length}`}
                    refresh={!loading}
                    refreshFunc={retrieve_page_data}
                    csv_export={!loading && !error}
                    //   csv_export
                    //   openAddList={openAddList}
                    //   add_to_list={componentData.add_branch_location}
                    page_type={type}
                    pageData={page_data}
                    setSelectedData={setSelectedData}
                />
                <Block className="items-center m-auto">
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                        <BiErrorCircle className="text-danger fs-3" />
                                        <p>{error}</p>
                                    </div>
                                </>
                                :
                                <>
                                    <TableList
                                        left_head_select
                                        type={type}
                                        data={page_data}
                                        rowOptionsData={componentData}
                                        // amendFunc={amend_Account_Status_modal}
                                        setOpendModalDetails={setOpendModalDetails}
                                    />
                                    {/* Modal For Viewing Employee Department and Roles Details */}
                                    <ModalDetails
                                        modalOpen={openModalDetails}
                                        setModalOpen={setOpendModalDetails}
                                        modalTitle={`${detail && `${detail.branch_area}'s Details`}`}
                                        modalType={type}
                                        modalData={detail}
                                    />
                                </>
                    }
                </Block>
            </Content>
        </>
    );
};

export default DepartmentAndRolesList;