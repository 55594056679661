import React, { useEffect, useState } from 'react';
import { Card, CardTitle } from 'reactstrap';
import { Col, Row } from '../../../../../../../../components/Component';
import { PDFViewer } from '@react-pdf/renderer';
import DeliveryNotePDF from '../../../../../pdf/DeliveryNotePDF';

const DeliveryNotePDFStep = ({ continue_processing_data, setWizardData, nextStep }) => {

 const [page_data, setpageData] = useState(continue_processing_data);

 useEffect(() => {
  setpageData(continue_processing_data);
 }, [continue_processing_data]);

 return (
  <>
   <Card className="card-inner">
    <CardTitle tag="h5">Delivery Note PDF</CardTitle>
    <Col lg="12">
     <div className="form-group d-flex justify-between">
      <div className="form-group">
       <div className="form-label-group">
        <label className="form-label text-soft">User Account :</label>
       </div>
       <div className="form-control-wrap">
        <label className="form-label text-primary">{page_data?.consigner?.name ? `${page_data?.consigner?.name} - ${page_data?.user_account?.unique_identification_number}` : "--"}</label>
       </div>
      </div>
     </div>
    </Col>
    <Row className="g-4">
     <div style={{ height: "90vh", width: "100%" }} className='border-2 border-text-gray-400'>
      <PDFViewer height="100%" width="100%" showToolbar>
       <DeliveryNotePDF page_data={page_data} />
      </PDFViewer>
     </div>
    </Row>
   </Card>
  </>
 );
};

export default DeliveryNotePDFStep;