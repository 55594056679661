import moment from "moment";
import * as app_consts from "../../../../../constants/react_app_consts";


export const export_to_csv_fun = async (data, type) => {
    let csv_data = [];
    let title;
    let filename;
    const cur_dt = moment().format("DD-MM-YYYY h:mm:ss a");

    switch (type)
    {
        case app_consts.USER_LIST_TYPE:
            title = "United African Grain Users List";
            filename = `United African Grain Users List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.WAREHOUSE_INVENTORY_LIST:
            title = "United African Grain Warehouse Inventory List";
            filename = `United African Grain Warehouse Inventory List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST:
            title = "United African Grain Goods Received List";
            filename = `United African Grain Goods Received List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST:
            title = "United African Grain Delivery Notes List";
            filename = `United African Grain Delivery Notes List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.CUSTOMER_USER_ACCOUNT_LIST:
            title = "United African Grain Individual Customerss Account List";
            filename = `United African Grain Individual Customers Account List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.COMPANY_USER_ACCOUNT_LIST:
            title = "United African Grain Company Accounts List";
            filename = `United African Grain Company Accounts List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.TRUCK_DRIVER_USER_ACCOUNT_LIST:
            title = "United African Grain Truck Driver Accounts List";
            filename = `United African Grain Truck Driver Accounts List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.EMPLOYEE_LIST_TYPE:
            title = "United African Grain Employee Accounts List";
            filename = `United African Grain Employee Accounts List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
        case app_consts.BRANCH_LOCATION_LIST:
            title = "United African Grain Branch Locations List";
            filename = `United African Grain Branch Locations List (as of ${cur_dt})`;

            // Restructure Export Data 
            csv_data = await restruc_csv_data(data, type);

            break;
    }

    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        filename: filename,
        useKeysAsHeaders: true,
    };

    return { csv_data, options };
};

// Restructure CVS ata
const restruc_csv_data = async (data, type) => {
    let csv_data;

    switch (type)
    {
        case app_consts.USER_LIST_TYPE:
            // Restructure Export Data 
            csv_data = data?.map((user_data) => ({
                first_name: user_data.first_name,
                middle_name: user_data.middle_name,
                surname: user_data.surname,
                phone_number: user_data.phone_number,
                email_address: user_data.email_address,
                account_status: user_data.account_status,
                registration_date: moment(user_data.registration_date).format("YYYY-MM-DD h:mm:ss a"),
                branch_physical_address: user_data.branch_physical_address,
                branch_area: user_data.branch_area,
                branch_province_name: user_data.branch_province_name,
                branch_country_name: user_data.branch_country_name,
            }));
            break;
        case app_consts.WAREHOUSE_INVENTORY_LIST:
            // Restructure Export Data 
            csv_data = data?.map((inventory) => ({
                product_id: inventory.product_id,
                product_name: inventory.product_name,
                product_type: inventory.product_type,
                physical_store_capacity: parseFloat(inventory.physical_store_capacity || 0.00).toFixed(2),
                virtaul_store_capacity: parseFloat(inventory.virtaul_store_capacity || 0.00).toFixed(2),
                total_warehouse_store: parseFloat(inventory.total_warehouse_store || 0.00).toFixed(2)
            }));
            break;
        case app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST:
            // Restructure Export Data 
            csv_data = data?.map((goods_received) => ({
                goods_received_ref_number: goods_received?.goods_received?.goods_received_ref_number,
                product_name: goods_received?.product?.product_name,
                user_account_no: goods_received?.user_account?.unique_identification_number,
                gross_weight: goods_received?.weigh_bridge_ticket?.first_weight ? parseFloat(goods_received?.weigh_bridge_ticket?.first_weight || 0.00).toFixed(2) : "--",
                tare_weight: goods_received?.weigh_bridge_ticket?.first_weight ? parseFloat(goods_received?.weigh_bridge_ticket?.second_weight || 0.00).toFixed(2) : "--",
                net_weight: goods_received?.weigh_bridge_ticket?.first_weight ? parseFloat(goods_received?.weigh_bridge_ticket?.net_weight || 0.00).toFixed(2) : "--",
                time_in: goods_received?.weigh_bridge_ticket?.time_in ? moment(goods_received?.weigh_bridge_ticket?.time_in).format("YYYY-MM-DD h:mm:ss") : "--",
                time_out: goods_received?.weigh_bridge_ticket?.time_out ? moment(goods_received?.weigh_bridge_ticket?.time_out).format("YYYY-MM-DD h:mm:ss") : "--",
                weigh_bridge_ticket_reference_no: goods_received?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no,
                weigh_bridge_ticket_status: goods_received?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status,
                weigh_bridge_ticket_type: goods_received?.weigh_bridge_ticket_type?.weigh_bridge_ticket_type,
                branch_area: goods_received?.branch_location?.branch_area,
                branch_physical_address: goods_received?.branch_location?.branch_physical_address,
                consigner_type: goods_received?.consigner_type?.consigner_type,
            }));
            break;
        case app_consts.DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST:
            // Restructure Export Data 
            csv_data = data?.map((delivery_notes) => ({
                delivery_note_ref_number: delivery_notes?.delivery_note?.delivery_note_ref_number,
                user_account_no: delivery_notes?.user_account?.unique_identification_number,
                property_area: delivery_notes?.user_property?.property_area,
                property_physical_address: delivery_notes?.user_property?.property_physical_address,
                gross_weight: delivery_notes?.weigh_bridge_ticket?.first_weight ? parseFloat(delivery_notes?.weigh_bridge_ticket?.first_weight || 0.00).toFixed(2) : "--",
                tare_weight: delivery_notes?.weigh_bridge_ticket?.first_weight ? parseFloat(delivery_notes?.weigh_bridge_ticket?.second_weight || 0.00).toFixed(2) : "--",
                net_weight: delivery_notes?.weigh_bridge_ticket?.first_weight ? parseFloat(delivery_notes?.weigh_bridge_ticket?.net_weight || 0.00).toFixed(2) : "--",
                time_in: delivery_notes?.weigh_bridge_ticket?.time_in ? moment(delivery_notes?.weigh_bridge_ticket?.time_in).format("YYYY-MM-DD h:mm:ss") : "--",
                time_out: delivery_notes?.weigh_bridge_ticket?.time_out ? moment(delivery_notes?.weigh_bridge_ticket?.time_out).format("YYYY-MM-DD h:mm:ss") : "--",
                weigh_bridge_ticket_reference_no: delivery_notes?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no,
                weigh_bridge_ticket_status: delivery_notes?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status,
                weigh_bridge_ticket_type: delivery_notes?.weigh_bridge_ticket_type?.weigh_bridge_ticket_type,
                branch_area: delivery_notes?.branch_location?.branch_area,
                branch_physical_address: delivery_notes?.branch_location?.branch_physical_address,
                consigner_type: delivery_notes?.consigner_type?.consigner_type,
                created_date: delivery_notes?.delivery_note?.created_date ? moment(delivery_notes?.delivery_note?.created_date).format("YYYY-MM-DD h:mm:ss") : "--",
            }));
            break;
        case app_consts.CUSTOMER_USER_ACCOUNT_LIST:
            // Restructure Export Data 
            csv_data = data?.map((customer) => ({
                username: customer?.user_account?.username,
                first_name: customer?.user?.first_name,
                surname: customer?.user?.surname,
                email_address: customer?.user?.email_address,
                phone_number: customer?.user?.phone_number,
                account_status: customer?.account_status?.account_status,
                date_created: customer?.user_account?.date_created ? moment(customer?.user_account?.date_created).format("YYYY-MM-DD h:mm:ss") : "--",
            }));
            break;
        case app_consts.COMPANY_USER_ACCOUNT_LIST:
            // Restructure Export Data 
            csv_data = data?.map((company) => ({
                username: company?.user_account?.username,
                company_name: company?.company?.company_name,
                company_email_address: company?.company?.company_email_address,
                company_mobile_number: company?.company?.company_mobile_number,
                account_status: company?.account_status?.account_status,
                date_created: company?.user_account?.date_created ? moment(company?.user_account?.date_created).format("YYYY-MM-DD h:mm:ss") : "--",
            }));
            break;
        case app_consts.TRUCK_DRIVER_USER_ACCOUNT_LIST:
            // Restructure Export Data 
            csv_data = data?.map((truck_driver) => ({
                username: truck_driver?.user_account?.username,
                first_name: truck_driver?.user?.first_name,
                surname: truck_driver?.user?.surname,
                email_address: truck_driver?.user?.email_address,
                phone_number: truck_driver?.user?.phone_number,
                account_status: truck_driver?.account_status?.account_status,
                date_created: truck_driver?.user_account?.date_created ? moment(truck_driver?.user_account?.date_created).format("YYYY-MM-DD h:mm:ss") : "--",
            }));
            break;
        case app_consts.EMPLOYEE_LIST_TYPE:
            // Restructure Export Data 
            csv_data = data?.map((employee) => ({
                employee_no: employee?.user_account?.unique_identification_number,
                first_name: employee?.user?.first_name,
                surname: employee?.user?.surname,
                phone_number: employee?.user?.phone_number,
                employee_role: employee?.employee_role?.role_name,
                employee_department: employee?.employee_department?.department_name,
                account_status: employee?.user_account?.account_status,
                branch_area: employee?.branch_location?.branch_area,
                branch_physical_address: employee?.branch_location?.branch_physical_address,
                date_created: employee?.employee?.inserted_at ? moment(employee?.employee?.inserted_at).format("YYYY-MM-DD h:mm:ss") : "--",
            }));
            break;
        case app_consts.BRANCH_LOCATION_LIST:
            // Restructure Export Data 
            csv_data = data?.map((branch_location) => ({
                branch_area: branch_location?.branch_area,
                branch_physical_address: branch_location?.branch_physical_address,
                branch_mobile_number: branch_location?.branch_mobile_number,
                province_name: branch_location?.province_name,
                account_status: branch_location?.account_status,
                date_created: branch_location?.branch_registered_date ? moment(branch_location?.branch_registered_date).format("YYYY-MM-DD h:mm:ss") : "--",
            }));
            break;
    }
    return csv_data;
}; 