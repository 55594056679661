import React, { useEffect, useState } from 'react';
import GoodsReceivedPDF from '../../../../../pdf/GoodsReceivedPDF';
import { Card, CardTitle } from 'reactstrap';
import { Col, Row } from '../../../../../../../../components/Component';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { PDFViewer } from '@react-pdf/renderer';

const GoodsReceivedTicketStep = ({
    weighBridgeFormData,
    setWeighBridgeFormData,
    continue_processing_data,
    setContinueProcessingData,
    nextStep
}) => {

    const [page_data, setPageData] = useState(continue_processing_data);

    useEffect(() => {
        setPageData(continue_processing_data);
    }, [continue_processing_data]);

    return (
        <>
            <Card className="card-inner">
                <CardTitle tag="h5">Goods Received Note</CardTitle>
                <Col lg="12">
                    <div className="form-group">
                        {weighBridgeFormData.consigner_type_id === app_consts.UAG_CONSIGNER_TYPE ? (
                            <>
                                <div className="form-label-group">
                                    <label className="form-label text-soft">User Account ID :</label>
                                </div>
                                <div className="form-control-wrap">
                                    <label className="form-label text-primary">UAG</label>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="form-label-group">
                                    <label className="form-label text-soft">User Account ID :</label>
                                </div>
                                <div className="form-control-wrap">
                                    <label className="form-label text-primary">{weighBridgeFormData.user_account_label}</label>
                                </div>
                            </>
                        )}
                    </div>
                </Col>
                <Row className="g-4">
                    <div style={{ height: "90vh", width: "100%" }} className='border-2 border-text-gray-400'>
                        <PDFViewer height="100%" width="100%" showToolbar>
                            <GoodsReceivedPDF page_data={page_data} />
                        </PDFViewer>
                    </div>
                </Row>
            </Card>
        </>
    );
};

export default GoodsReceivedTicketStep;