import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    complete_processing_delivery_note: { status: "idle" },
};

export const complete_processing_delivery_note_Slice = createSlice({
    name: "complete_processing_delivery_note",
    initialState,
    reducers: {
        success_complete_processing_delivery_note: (state, action) => {
            state.complete_processing_delivery_note = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_complete_processing_delivery_note: (state, action) => {
            state.complete_processing_delivery_note = { status: app_consts.FAIL, message: action.payload };
        },
        reset_complete_processing_delivery_note: (state) => {
            state.complete_processing_delivery_note = { status: app_consts.IDLE };
        },
        loading_complete_processing_delivery_note: (state) => {
            state.complete_processing_delivery_note = { status: app_consts.LOADING };
        },
    }
});

export const { success_complete_processing_delivery_note, fail_complete_processing_delivery_note, reset_complete_processing_delivery_note, loading_complete_processing_delivery_note } = complete_processing_delivery_note_Slice.actions;

export default complete_processing_delivery_note_Slice.reducer;