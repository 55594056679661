import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    verify_otp: { message: "idle" },
};

export const verify_otp_Slice = createSlice({
    name: "verify_otp",
    initialState,
    reducers: {
        success_verify_otp: (state, action) => {
            const { token, ...log_cred } = action?.payload;
            localStorage.setItem('access', JSON.stringify({ access_token: token }));
            localStorage.setItem('log_cred', JSON.stringify({ ...log_cred }));
            state.verify_otp = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_verify_otp: (state, action) => {
            state.verify_otp = { status: app_consts.FAIL, message: action.payload };
        },
        reset_verify_otp: (state) => {
            state.verify_otp = { status: app_consts.IDLE };
        },
        loading_verify_otp: (state) => {
            state.verify_otp = { status: app_consts.LOADING };
        },
    }
});

export const { success_verify_otp, fail_verify_otp, reset_verify_otp, loading_verify_otp } = verify_otp_Slice.actions;

export default verify_otp_Slice.reducer;