import React, { useEffect, useRef, useState } from 'react';
import { Block, Button, Icon } from '../../../../components/Component';
import Logo from "../../../../images/logo.png";
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import moment from 'moment';
import LatoRegular from "../../../../utils/fonts/Lato-Regular.ttf";
import LatoBold from '../../../../utils/fonts/Lato-Bold.ttf';

const GoodsReceivedPDF = ({ page_data }) => {

    const targetRef = useRef();

    const [pdf_data, setPDFData] = useState(page_data);
    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    useEffect(() => {
        setPDFData(page_data);
    }, [page_data]);

    return (
        <>
            {
                page_data && (
                    <>
                        <Document title={`${pdf_data?.goods_received?.goods_received_ref_number} Goods Received Number`} ref={targetRef}>
                            <Page size="A4" style={{
                                flexDirection: "row",
                                backgroundColor: "rgba(0,0,0,0)",
                                height: "100%",
                                width: "100%",
                                fontFamily: "Lato"
                            }}
                                orientation="landscape"
                            >
                                <View style={{ width: "100%", padding: 5 }}>
                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <View style={{ width: 150 }}>
                                                <Image src={Logo} />
                                            </View>
                                            <View style={{ fontWeight: 600, fontSize: 12, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <View>
                                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                        <Text style={{ marginLeft: 2, marginBottom: 2, marginTop: 2 }}>Plot 10111 off Mumbwa Road, Chinika Area</Text>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                        <Text style={{ marginLeft: 2, marginBottom: 2, marginTop: 2 }}>P.O. Box 30203, Lusaka, Zambia.</Text>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                        <Text style={{ marginLeft: 2 }}>Mobile:</Text>
                                                        <Text style={{ marginLeft: 2 }}>+260 764 299 293</Text>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                        <Text style={{ marginLeft: 2 }}>Email:</Text>
                                                        <Text style={{ marginLeft: 2 }}>office@iagzambia.com</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{ backgroundColor: "#2c3782", padding: 10, color: "white", border: "1px solid", borderRadius: 12, fontSize: 24, fontWeight: 700, marginRight: 20 }} >GOODS RECEIVED NOTE</Text>
                                            <View style={{ display: "flex", flexDirection: "column" }}>

                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 4 }}>
                                                    <Text style={{ marginLeft: 5, fontSize: 16, fontWeight: 500 }}>G.R.N:</Text>
                                                    <Text style={{ marginLeft: 5, fontWeight: 600, fontSize: 16, paddingLeft: 2 }}>{pdf_data?.goods_received?.goods_received_ref_number || "---"}</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                    <Text style={{ marginLeft: 5, fontSize: 16, fontWeight: 500 }}>Date Receieved: </Text>
                                                    <Text style={{ marginLeft: 5, fontWeight: 600, fontSize: 16, paddingLeft: 2 }}>{pdf_data?.goods_received?.date_created ? moment(pdf_data?.goods_received?.date_created).format("MMMM Do, YYYY") : "---"}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={{ display: "flex", marginHorizontal: 15, flexDirection: "row", justifyContent: "space-between", width: "100%" }} >
                                        <View style={{ display: "flex", flexDirection: "column", width: "50%", fontSize: 16 }}>

                                            {/* <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Supplier/Agent:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>+260 764 299 293</Text>
                                            </View> */}
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Customer:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>{pdf_data?.consigner?.name}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Transporter:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>{pdf_data?.weigh_bridge_ticket?.transporter_name}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Truck No:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>{pdf_data?.weigh_bridge_ticket?.truck_licence_plate}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Trailer No 1:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>{pdf_data?.weigh_bridge_ticket?.trailer_one}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Trailer No 2:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>{pdf_data?.weigh_bridge_ticket?.trailer_two}</Text>
                                            </View>
                                        </View>

                                        <View style={{ display: "flex", flexDirection: "column", width: "50%", fontSize: 16 }}>

                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Contract / Invoice No:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}></Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Time In:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>{pdf_data?.weigh_bridge_ticket?.time_in ? moment(pdf_data?.weigh_bridge_ticket?.time_in).format("YYYY-MM-DD, h:mm:ss") : "---"}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Time Out:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}>{pdf_data?.weigh_bridge_ticket?.time_out ? moment(pdf_data?.weigh_bridge_ticket?.time_out).format("YYYY-MM-DD, h:mm:ss") : "---"}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <Text style={{ width: "35%" }}>Stack No:</Text>
                                                <Text style={{ fontWeight: 600, width: "65%" }}></Text>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={{ marginTop: 7, marginX: 20, width: "100%", paddingLeft: 20, paddingRight: 20 }} className='mt-4'>
                                        <Text style={{ fontSize: 16, color: "#526484", marginBottom: "5px" }}>
                                            Weigh Bridge Weight
                                        </Text>
                                        <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14, marginTop: 6 }}>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>Ticket Number</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>1st Weight</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>2nd Weight</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>Net Weight</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>Comments</Text>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14 }}>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{pdf_data?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no || "---"}</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", }}>{pdf_data?.weigh_bridge_ticket?.first_weight ? `${parseFloat(pdf_data?.weigh_bridge_ticket?.first_weight).toFixed(2)} MT` : "---"}</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{pdf_data?.weigh_bridge_ticket?.second_weight ? `${parseFloat(pdf_data?.weigh_bridge_ticket?.second_weight).toFixed(2)} MT` : "---"}</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{pdf_data?.weigh_bridge_ticket?.net_weight ? `${parseFloat(pdf_data?.weigh_bridge_ticket?.net_weight).toFixed(2)} MT` : "---"}</Text>
                                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{pdf_data?.weigh_bridge_ticket?.comments || "---"}</Text>
                                        </View>
                                    </View>

                                    <View style={{ marginTop: 7, marginX: 20, width: "100%", paddingLeft: 20, paddingRight: 20 }} className='mt-4'>
                                        <Text style={{ fontSize: 16, color: "#526484", marginBottom: "5px" }}>
                                            Commodity Details
                                        </Text>
                                        <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 12, marginTop: 10 }}>
                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", fontSize: 10, color: "white" }}>Commodity</Text>
                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>UoM</Text>
                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>Moisture</Text>
                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>Packaging Total No.</Text>
                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>Total Capacity</Text>
                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, backgroundColor: "#2c3782", textAlign: "center", color: "white" }}>Total Capacity (MT)</Text>
                                        </View>
                                        {
                                            pdf_data?.goods_received_products && pdf_data?.goods_received_products?.map((goods_received) => {
                                                return (
                                                    <>
                                                        <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14 }}>
                                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{goods_received?.product_name || "---"}</Text>
                                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, textAlign: "center", }}>{goods_received?.uom ? goods_received?.uom : "---"}</Text>
                                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{goods_received?.moisture || "---"}</Text>
                                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{goods_received?.packaging_total_number ? goods_received?.packaging_total_number : "---"}</Text>
                                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{goods_received?.total_capacity_selected_measurement ? `${parseFloat(goods_received?.total_capacity_selected_measurement).toFixed(2)}` : "---"}</Text>
                                                            <Text style={{ width: "16%", border: "1px solid #526484", padding: 5, textAlign: "center" }}>{goods_received?.total_capacity_in_mt ? `${parseFloat(goods_received?.total_capacity_in_mt).toFixed(2)} MT` : "---"}</Text>
                                                        </View>
                                                    </>
                                                );
                                            })
                                        }
                                    </View>

                                    <View style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", marginTop: 4, width: "100%" }}>
                                        <View style={{ display: "flex", marginLeft: 25, flexDirection: "column", width: "50%", fontSize: 16 }}>

                                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                <Text>Driver's Name:</Text>
                                                <Text style={{ marginLeft: 15, fontWeight: 600, paddingLeft: 2 }}>{pdf_data?.weigh_bridge_ticket?.truck_driver_name}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                <Text>Signature:</Text>
                                                <Text style={{ marginLeft: 15, fontWeight: 600, paddingLeft: 2 }}></Text>
                                            </View>
                                        </View>

                                        <View style={{ display: "flex", marginLeft: 25, flexDirection: "column", width: "50%", fontSize: 16 }}>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                <Text>Received By:</Text>
                                                <Text style={{ marginLeft: 15, fontWeight: 600, paddingLeft: 2 }}>{[pdf_data?.goods_received?.issued_by_name]}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                <Text>Location:</Text>
                                                <Text style={{ marginLeft: 15, fontWeight: 600, paddingLeft: 2, width: "70%" }}>{pdf_data?.branch_location?.branch_area ? `${pdf_data?.branch_location?.branch_area}, ${pdf_data?.branch_location?.branch_physical_address}` : "---"}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* <Text style={{ display: "flex", flexDirection: "row", fontSize: 9, color: "#526484" }}>
                                        Invoice was created on a computer and is valid without the signature and seal.
                                    </Text> */}
                                </View>
                            </Page>
                        </Document>
                    </>
                )
            }
        </>
    );
};

export default GoodsReceivedPDF;