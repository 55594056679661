import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import TablePageHeading from '../components/page_heading/TablePageHeading';
import * as app_consts from "../../../constants/react_app_consts";
import { useSelector } from 'react-redux';
import TableList from '../components/table/TableList';
import { findModuleAction } from '../../../utils/functionalUtils';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { fail_retrieve_loading_order_list, loading_retrieve_loading_order_list, reset_retrieve_loading_order_list, success_retrieve_loading_order_list } from '../../../reducers/dashboard_reducers/loading_orders/retrieve_loading_order_list_Slice';
import { RETRIEVE_LOADING_ORDER_LIST_ACCESS_DENIED_MSG, RETRIEVE_LOADING_ORDER_LIST_ERR_MSG, RETRIEVE_LOADING_ORDER_LIST_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { RETRIEVE_LOADING_ORDERS_LIST_API } from '../../../api/api_data';
import { messageToast } from '../../../utils/Utils';
import { BiErrorCircle } from 'react-icons/bi';
import { Spinner } from 'reactstrap';

const type = app_consts.LOADING_ORDERS_LIST;

const initial_actions_data = {
    view_loading_orders: false,
    create_loading_order: false,
};

const LoadingOrderslist = () => {

    const dispatch = useDispatch();

    const [page_data, setPageData] = useState([]);
    const [componentData, setComponentData] = useState(initial_actions_data);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [account_actions, setAccountActions] = useState([]);

    // UseEffect that Runs When the Page Opens
    useEffect(() => {
        refreshList();
    }, []);

    // Retrieve Delivery Notes Tickets
    const retrieve_loading_order_list_func = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_retrieve_loading_order_list,
                success_retrieve_loading_order_list,
                fail_retrieve_loading_order_list,
                RETRIEVE_LOADING_ORDER_LIST_ERR_MSG,
                RETRIEVE_LOADING_ORDER_LIST_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_LOADING_ORDERS_LIST_API,
                null,
                RETRIEVE_LOADING_ORDER_LIST_ACCESS_DENIED_MSG
            )
        );
        if (localStorage.getItem("log_cred"))
        {
            setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
        }
    };

    // Listen to Changes In Account Actions
    useEffect(() => {
        if (account_actions)
        {
            let actions_data = initial_actions_data;
            account_actions?.map((module) => {
                if (module.module_name === app_consts.WAREHOUSE_MANAGEMENT) 
                {
                    // Actions Data
                    if (findModuleAction(app_consts.CREATE_LOADING_ORDER, module))
                    {
                        actions_data.create_loading_order = true;
                    }
                }
            });
            setComponentData(actions_data);
        }
    }, [account_actions]);

    // Refresh List Function
    const refreshList = () => {
        setError("");
        // Retrieve Weigh Bridge Tickets list
        //   retrieve_user_list_func();
        retrieve_loading_order_list_func();
        setPageData([]);
    };

    // Listen to Loading Order Changes
    const retrieve_loading_order_list = useSelector((state) => state.retrieve_loading_order_list.retrieve_loading_order_list);
    // 
    useEffect(() => {
        if (retrieve_loading_order_list.status !== app_consts.IDLE)
        {
            if (retrieve_loading_order_list.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (retrieve_loading_order_list.status === app_consts.FAIL)
                {
                    setError(retrieve_loading_order_list.message);
                    messageToast(RETRIEVE_LOADING_ORDER_LIST_ERR_MSG, app_consts.FAIL);
                } else if (retrieve_loading_order_list.status === app_consts.SUCCESS)
                {
                    setPageData(retrieve_loading_order_list.data);
                }
                dispatch(reset_retrieve_loading_order_list());
            }
        }
    }, [retrieve_loading_order_list]);

    return (
        <>
            <Head title="Loading Orders List" />
            <Content>
                {/* Table Page Heading */}
                <TablePageHeading
                    title="Loading Orders List"
                    description={`Total Loading Orders: ${page_data?.length}`}
                    refresh={!loading}
                    refreshFunc={refreshList}
                    csv_export={!loading && !error}
                    add_to_list={loading || error ? "" : findModuleAction(app_consts.CREATE_LOADING_ORDER, componentData) !== null}
                    componentData={componentData}
                    page_type={type}
                    pageData={page_data}
                //   openAddList={openAddListModal}
                />
                {
                    loading ?
                        <>
                            <div className='p-5 d-flex align-center justify-center'>
                                <Spinner />
                            </div>
                        </>
                        :
                        <>
                            {
                                error && error.length > 0 ?
                                    <>
                                        <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                            <BiErrorCircle className="text-danger fs-3" />
                                            <p>{error}</p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <TableList
                                            // left_head_select
                                            type={type}
                                            data={page_data}
                                            rowOptionsData={componentData}
                                        // amendFunc={amend_Account_Status_modal}
                                        // setOpendModalDetails={setOpendModalDetails}
                                        // setSelectedData={setSelectedUser}
                                        />
                                    </>
                            }
                        </>
                }
            </Content>
        </>
    );
};

export default LoadingOrderslist;