import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import PageHeading from "../../components/page_heading/PageHeading";
import WizardComp from "../../components/wizard/WizardComp";
import ModalInfo from "../../components/modal/modal_info/ModalInfo";
import * as app_consts from "../../../../constants/react_app_consts";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  fail_verify_user_kyc_status,
  loading_verify_user_kyc_status,
  reset_verify_user_kyc_status,
  success_verify_user_kyc_status,
} from "../../../../reducers/dashboard_reducers/user_kyc/verify_user_kyc_status_Slice";
import {
  RETRIEVE_USER_KYC_STATUS_ACCESS_DENIED_MSG,
  RETRIEVE_USER_KYC_STATUS_ERR_MSG,
  RETRIEVE_USER_KYC_STATUS_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { RETRIEVE_USER_ACTIVE_STATUS_KYC_STATUS, RETRIEVE_USER_KYC_STATUS } from "../../../../api/api_data";
import { useSelector } from "react-redux";
import { employee_dashboard_actions_helper } from "../../../../actions/actions_helper";
import { messageToast } from "../../../../utils/Utils";

const CreateCompanyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phone_number } = useParams();

  const [errorVal, setError] = useState();
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [acc_info, setAccInfo] = useState([]);

  // Check if User KYC Status is Complete
  useEffect(() => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_verify_user_kyc_status,
        success_verify_user_kyc_status,
        fail_verify_user_kyc_status,
        RETRIEVE_USER_KYC_STATUS_ERR_MSG,
        RETRIEVE_USER_KYC_STATUS_SUCCESS_MSG,
        app_consts.POST,
        RETRIEVE_USER_ACTIVE_STATUS_KYC_STATUS,
        { phone_number: phone_number },
        RETRIEVE_USER_KYC_STATUS_ACCESS_DENIED_MSG
      )
    );
  }, []);

  // Listen to Changes in Verify User KYC Reg Status Reducer
  const verify_user_kyc_status = useSelector((state) => state.verify_user_kyc_status.verify_user_kyc_status);
  //
  useEffect(() => {
    if (verify_user_kyc_status.status !== app_consts.IDLE)
    {
      if (verify_user_kyc_status.status !== app_consts.LOADING)
      {
        if (verify_user_kyc_status.status === app_consts.FAIL)
        {
          setError(verify_user_kyc_status.message);
          messageToast(verify_user_kyc_status.message, app_consts.FAIL);
          navigate(`/dashboard/onboarding/company`);
        } else if (verify_user_kyc_status.status === app_consts.SUCCESS)
        {
          const res_data = verify_user_kyc_status.data;
          if (res_data.active_and_kyc_reg_complete)
          {
            setAccInfo(verify_user_kyc_status.data);
          } else if (!res_data.active_and_kyc_reg_complete)
          {
            setOpenInfoModal(true);
          }
        }
        // It will be Reseted In the Wizard
        // dispatch(reset_verify_user_kyc_status());
      }
    }
  }, [verify_user_kyc_status]);

  // Close Info Modal Function
  const modalInfoCloseFunction = () => {
    setOpenInfoModal(false);
    navigate(`/dashboard/onboarding/company`);
  };

  return (
    <>
      <Head title="Onboard Company" />
      <Content>
        <PageHeading
          title="Onboard Company"
          title_addition={
            acc_info.first_name
              ? `${acc_info.first_name} ${acc_info.surname} - ${acc_info.phone_number} (Affiliated User)`
              : ""
          }
          description="Create Company Account by Filling in th Forms in the Steps Listed Below"
          backToText="Onboard Company"
          backToLink={`/dashboard/onboarding/user`}
        />
        <WizardComp type={app_consts.ONBOARD_COMPANY} />
      </Content>

      {/* Open This Modal if User KYC is Incomplete or Pending Updates */}
      <ModalInfo
        modalDescription="Customer Account Cannot be Created Until User Status is Active and User KYC Registration Status is Complete."
        modalTitle="KYC Registration Not Complete"
        openInfoModal={openInfoModal}
        modalInfoCloseFunction={modalInfoCloseFunction}
        modalButtonText="Return"
      />
    </>
  );
};

export default CreateCompanyAccount;
