import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import { Block, BlockHead, BlockTitle } from '../../../components/Component';
import TablePageHeading from '../../dashboard_pages/components/page_heading/TablePageHeading';
import { useParams } from 'react-router';
import PageHeading from '../../dashboard_pages/components/page_heading/PageHeading';
import CardTabs from '../../dashboard_pages/components/cards/CardTabs';
import { useDispatch } from 'react-redux';
import { client_actions_helper } from '../../../actions/actions_helper';
import { RETRIEVE_SPECIFIC_CLIENT_GOODS_RECEIVED_API } from '../../../api/api_data';
import { fail_retrieve_specific_goods_received, loading_retrieve_specific_goods_received, reset_retrieve_specific_goods_received, success_retrieve_specific_goods_received } from '../../../reducers/client_reducers/goods_received/retrieve_specific_goods_received_Slice';
import { Spinner } from 'reactstrap';
import { RETRIEVE_SPECIFIC_GOODS_RECEIVED_ACCESS_DENIED_MSG, RETRIEVE_SPECIFIC_GOODS_RECEIVED_ERR_MSG, RETRIEVE_SPECIFIC_GOODS_RECEIVED_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import * as app_consts from "../../../constants/react_app_consts";
import { messageToast } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import { BiErrorCircle } from 'react-icons/bi';
import ProfileDetailText from '../../dashboard_pages/components/text/ProfileDetailText';
import moment from 'moment';
import GoodsReceivedPDF from '../../dashboard_pages/components/pdf/GoodsReceivedPDF';

const SpecifcGoodsReceived = ({ account_type }) => {

    const dispatch = useDispatch();

    const { goods_received_ref_number } = useParams();
    const [activeCardTab, setActiveCardTab] = useState("Summary");
    const [dataFetched, setDataFetched] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [page_data, setPageData] = useState([]);

    // useEffect that runs first when Page Opens
    useEffect(() => {
        setError("");
        dispatch(
            client_actions_helper(
                loading_retrieve_specific_goods_received,
                success_retrieve_specific_goods_received,
                fail_retrieve_specific_goods_received,
                RETRIEVE_SPECIFIC_GOODS_RECEIVED_ERR_MSG,
                RETRIEVE_SPECIFIC_GOODS_RECEIVED_SUCCESS_MSG,
                app_consts.POST,
                RETRIEVE_SPECIFIC_CLIENT_GOODS_RECEIVED_API,
                account_type,
                { goods_received_ref_number: goods_received_ref_number },
                RETRIEVE_SPECIFIC_GOODS_RECEIVED_ACCESS_DENIED_MSG
            )
        );

    }, []);

    // Listen to Reducer
    const goods_received_details = useSelector((state) => state.retrieve_specific_goods_received.retrieve_specific_goods_received);
    // 
    useEffect(() => {
        if (goods_received_details.status !== app_consts.IDLE)
        {
            if (goods_received_details.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (goods_received_details.status === app_consts.FAIL)
                {

                    setError(goods_received_details.message);
                    messageToast(goods_received_details.message, app_consts.FAIL);
                } else if (goods_received_details.status === app_consts.SUCCESS)
                {
                    setPageData(goods_received_details.data);
                    setDataFetched(true);
                }
                dispatch(reset_retrieve_specific_goods_received());
            }
        }
    }, [goods_received_details]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Summary", icon: "user-circle"
        },
        {
            tabName: "Storage Information", icon: "user-circle"
        },
        {
            tabName: "Delivery Information", icon: "user-circle"
        },
        {
            tabName: "Goods Received PDF", icon: "user-circle"
        },
    ];

    return (
        <>
            <Head title={`Goods Received Details- ${goods_received_ref_number}`} />
            <Content>
                <Block className="items-center w-80 m-auto">
                    {/* Table Page Heading */}
                    <PageHeading
                        title="Goods Received Details"
                        title_addition={goods_received_ref_number}
                        description="Details of Goods Received"
                        backToText="Goods Recieved"
                        backToLink={`/client/goods_received`}
                    />
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                        {error}
                                    </div>
                                </>
                                :
                                <>
                                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />

                                    {/* Content */}
                                    <div className="card-inner">
                                        {
                                            activeCardTab === "Summary" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Goods Received Summary</BlockTitle>
                                                            <p>A Brief Summary of Goods Received.</p>
                                                        </BlockHead>
                                                        <BlockHead>
                                                            <BlockTitle tag="h6">Product Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Goods Received Reference Number" text={page_data?.goods_received?.goods_received_ref_number || "---"} />
                                                            <ProfileDetailText label="Status" text={page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status || "---"} textClassName={page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ? "text-success" : "text-warning"} />
                                                            <ProfileDetailText label="Product" text={page_data?.product?.product_name || "---"} />
                                                            <ProfileDetailText label="Quantity" text={page_data?.weigh_bridge_ticket?.first_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.first_weight).toFixed(2)}` : "---"} />
                                                            <ProfileDetailText label="Number of Bags" text={page_data?.product_weigh_bridge?.packaging_total_number || "---"} />
                                                            <ProfileDetailText label="Date Received" text={page_data?.goods_received?.date_created ? moment(page_data?.goods_received?.date_created).format("MMMM Do, YYYY h:mm:ss") : "---"} />
                                                        </div>
                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Consigner Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="User ID" text={page_data?.user_account?.unique_identification_number || "---"} />
                                                            <ProfileDetailText label="Name" text={page_data?.consigner?.name || "---"} />
                                                            <ProfileDetailText label="Email Address" text={page_data?.consigner?.email || "---"} />
                                                            <ProfileDetailText label="Mobile number" text={page_data?.consigner?.mobile_number || "---"} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                        {
                                            activeCardTab === "Storage Information" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Storage Information</BlockTitle>
                                                            <p>Information about Storage Type and Location</p>
                                                        </BlockHead>
                                                        <BlockHead>
                                                            <BlockTitle tag="h6">Branch Location Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Branch Location Reference Number" text={page_data?.branch_location?.branch_ref_no || "---"} />
                                                            <ProfileDetailText label="Branch Area" text={page_data?.branch_location?.branch_area || "---"} />
                                                            <ProfileDetailText label="Branch Physical Address" text={page_data?.branch_location?.branch_physical_address || "---"} />
                                                            <ProfileDetailText label="Branch Mobile Number" text={page_data?.branch_location?.branch_mobile_number || "---"} />
                                                        </div>
                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Warehouse Storage Details</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Warehouse Storage Reference Number" text={page_data?.warehouse_storage?.warehouse_storage_reference_number || "---"} />
                                                            <ProfileDetailText label="Warehouse Storage Type" text={page_data?.warehouse_storage?.warehouse_storage_type || "---"} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                    </div>
                                </>
                    }
                    {
                        activeCardTab === "Delivery Information" && (
                            <>
                                <Block>
                                    <BlockHead>
                                        <BlockTitle tag="h5">Delivery Information</BlockTitle>
                                        <p>Weigh Bridge Details</p>
                                    </BlockHead>
                                    <BlockHead>
                                        <BlockTitle tag="h6">Weigh Bridge Details</BlockTitle>
                                    </BlockHead>
                                    <div className="profile-ud-list">
                                        <ProfileDetailText label="Weigh Bridge Ticket Number" text={page_data?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no || "---"} />
                                        <ProfileDetailText label="Gross Weight" text={page_data?.weigh_bridge_ticket?.first_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.first_weight).toFixed(2)} MT` : "---"} />
                                        <ProfileDetailText label="Tare Weight" text={page_data?.weigh_bridge_ticket?.second_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.second_weight).toFixed(2)} MT` : "---"} />
                                        <ProfileDetailText label="Net Weight" text={page_data?.weigh_bridge_ticket?.net_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.net_weight).toFixed(2)} MT` : "---"} />
                                    </div>
                                    <BlockHead className='mt-5'>
                                        <BlockTitle tag="h6">Consigner Details</BlockTitle>
                                    </BlockHead>
                                    <div className="profile-ud-list">
                                        <ProfileDetailText label="User ID" text={page_data?.user_account?.unique_identification_number || "---"} />
                                        <ProfileDetailText label="Name" text={page_data?.consigner?.name || "---"} />
                                        <ProfileDetailText label="Email Address" text={page_data?.consigner?.email || "---"} />
                                        <ProfileDetailText label="Mobile number" text={page_data?.consigner?.mobile_number || "---"} />
                                    </div>
                                </Block>
                            </>
                        )
                    }
                    {
                        activeCardTab === "Goods Received PDF" && (
                            <>
                                {
                                    page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ?
                                        <>
                                            <Block>
                                                <BlockHead>
                                                    <BlockTitle tag="h5">Goods Received PDF</BlockTitle>
                                                    <p>Weigh Bridge Details</p>
                                                </BlockHead>
                                                <div className='p-1 border text-gray'>
                                                    <GoodsReceivedPDF page_data={page_data} />
                                                </div>
                                            </Block>
                                        </>
                                        :
                                        <>
                                            <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                                <BiErrorCircle className="text-danger fs-13" />
                                                <p>Cannot View Goods Received PDF.</p>
                                                <p>Goods Received Processing is Incomplete.</p>
                                            </div>
                                        </>
                                }
                            </>
                        )
                    }
                </Block>
            </Content>
        </>
    );
};

export default SpecifcGoodsReceived;