import React from 'react';
import { Button, Col, Icon, UserAvatar } from '../../../../components/Component';
import { Card } from 'reactstrap';
import { findUpper } from '../../../../utils/Utils';

const DocumentViewer = ({ name, url, onEditClick, sm = 6, lg = 4, xxl = 3, doc }) => {
 return (
  <>
   <Col sm={sm} lg={lg} xxl={xxl}>
    <Card className="gallery">
     <div className="gallery-body card-inner align-center justify-between flex-wrap g-2">
      <div className="user-card">
       <div className="user-info">
        <span className="lead-text fs-16px">{name}</span>
       </div>
      </div>
     </div>
     {
      doc ?
       <>
        {
         doc?.endsWith(".pdf") ? (
          <embed src={doc} type="application/pdf" width="100%" height="500px" />
         ) : (
          <img src={doc} alt={doc} className="w-full" />
         )
        }
       </>
       :
       <>
        <div className='border border-primary' style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
         <span className="lead-text fs-16px">{`${name} has not been Uploaded.`}</span>
        </div>
       </>
     }
     {/* <img className="w-100 rounded-top" src={doc} alt="" /> */}
     {/* <ImageContainer img={item.img} /> */}
    </Card>
   </Col>
  </>
 );
};

export default DocumentViewer;