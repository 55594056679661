import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../../../../../../components/Component";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import TextInput from "../../../../../../../components/inputs/TextInput";
import { NRC_REGEX } from "../../../../../../../../utils/RegexUtils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../../../../../utils/Utils";
import {
  fail_update_document_info,
  loading_update_document_info,
  reset_update_document_info,
  success_update_document_info,
} from "../../../../../../../../reducers/dashboard_reducers/user_kyc/update_document_info_Slice";
import {
  UPDATE_DOCUMENT_KYC_INFO_ACCESS_DENIED_MSG,
  UPDATE_DOCUMENT_KYC_INFO_ERR_MSG,
  UPDATE_DOCUMENT_KYC_INFO_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import { UPDATE_DOCUMENT_KYC_INFO } from "../../../../../../../../api/api_data";
import { employee_dashboard_actions_helper } from "../../../../../../../../actions/actions_helper";
import { useNavigate } from "react-router";

const initialState = { id_number: "", tpin: "", phone_number: "" };

const DocumentInfoStep = ({ togglestep, nextStep, data }) => {
  const dispatch = useDispatch();

  const [errorVal, setError] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [user_data, setUserData] = useState([]);

  //Checking Valid id_number
  const [iDNum, setIDNum] = useState("");
  const [validIDNum, setValidIDNum] = useState(false);
  const [inputUNameFocus, setInputUNameFocus] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handle Input Change
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.name === "id_number")
    {
      setIDNum(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  // Reading Data Passed Along
  useEffect(() => {
    if (data)
    {
      setFormData({ ...formData, ["phone_number"]: data.phone_number });
      setUserData(data);
    }
  }, [data]);

  useEffect(() => {
    if (user_data)
    {
    }
  }, [user_data]);

  //Checking States to see if ID Number is valid or not
  useEffect(() => {
    if (user_data.iso_code_2 === app_consts.DEFAULT_ISO_CODE_2)
    {
      const result = NRC_REGEX.test(iDNum);
      setValidIDNum(result);
    } else
    {
      setValidIDNum(true);
    }
  }, [iDNum]);

  // Submitting the Data
  const update_doc_info = () => {
    setError("");
    // Update Document Info
    dispatch(
      employee_dashboard_actions_helper(
        loading_update_document_info,
        success_update_document_info,
        fail_update_document_info,
        UPDATE_DOCUMENT_KYC_INFO_ERR_MSG,
        UPDATE_DOCUMENT_KYC_INFO_SUCCESS_MSG,
        app_consts.POST,
        UPDATE_DOCUMENT_KYC_INFO,
        formData,
        UPDATE_DOCUMENT_KYC_INFO_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Changes in Update Doc Info KYC Reducer
  const update_document_info = useSelector((state) => state.update_document_info.update_document_info);
  //
  useEffect(() => {
    if (update_document_info.status !== app_consts.IDLE)
    {
      if (update_document_info.status === app_consts.LOADING)
      {
        setIsLoading(true);
      } else
      {
        setIsLoading(false);
        if (update_document_info.status === app_consts.FAIL)
        {
          messageToast(update_document_info.message, app_consts.FAIL);
          setError(update_document_info.message, app_consts.FAIL);
        } else if (update_document_info.status === app_consts.SUCCESS)
        {
          messageToast("User Document Info Successfully Updated", app_consts.SUCCESS);
          navigate(`/dashboard/onboarding/user`);
        }
        dispatch(reset_update_document_info());
      }
    }
  }, [update_document_info]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Document Information</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="g-4">
            <TextInput
              label={
                user_data.iso_code_2 &&
                (user_data.iso_code_2 === app_consts.DEFAULT_ISO_CODE_2 ? "NRC Number" : "Passport Number")
              }
              type="text"
              id_val="id_number"
              value={formData.id_number}
              handleChange={handleChange}
              name="id_number"
              error_stat={inputUNameFocus && iDNum && !validIDNum ? true : false}
              err_Msg={"Id Number should be in the format ******/**/1 or ******/**/2 or ******/**/3"}
              onFocus={() => setInputUNameFocus(true)}
            />
            {user_data.iso_code_2 &&
              (user_data.iso_code_2 === app_consts.DEFAULT_ISO_CODE_2 ? (
                <>
                  <TextInput
                    label="TPIN"
                    type="text"
                    id_val="tpin"
                    maxlength="10"
                    minlength="10"
                    value={formData.tpin}
                    handleChange={handleChange}
                    name="tpin"
                  />
                </>
              ) : (
                ""
              ))}

            <Col xl="12">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-end">
                  <Button
                    onClick={update_doc_info}
                    color="primary"
                    size="lg"
                    disabled={
                      loading
                        ? loading
                        : user_data.iso_code_2 === app_consts.DEFAULT_ISO_CODE_2
                          ? !(formData.tpin && validIDNum)
                          : !formData.id_number
                    }
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Next"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default DocumentInfoStep;
