import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Link } from "react-router-dom";
import TextInput from "../components/inputs/TextInput";
import { Alert, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  fail_verify_otp,
  loading_verify_otp,
  reset_verify_otp,
  success_verify_otp,
} from "../../reducers/website_reducers/auth/verify_otp_Slice";
import * as app_consts from "../../constants/react_app_consts";
import { reset_send_otp_option } from "../../reducers/website_reducers/auth/send_otp_option_Slice";
import { VERIFY_OTP_AUTH_API } from "../../api/api_data";
import { VERIFY_OTP_AUTH_ERR_MSG, VERIFY_OTP_AUTH_SUCESS_MSG } from "../../constants/success_error_messages_consts";
import { website_actions_helper } from "../../actions/actions_helper";

const initialState = { username: "", otp: "" };

const VerifyOTP = ({ user_details }) => {
  const [errorVal, setError] = useState("");
  const [page_data, setPageData] = useState(user_details);
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // UseEffect for Initial Page Launch
  useEffect(() => {
    setFormData({ ...formData, ["username"]: user_details.username });
    setPageData(user_details);
    dispatch(reset_send_otp_option());
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
  };

  // Handle Submit
  const handleSubmit = () => {
    setError("");
    // Run Request Through Website Actions' Helper
    dispatch(
      website_actions_helper(
        loading_verify_otp,
        success_verify_otp,
        fail_verify_otp,
        VERIFY_OTP_AUTH_ERR_MSG,
        VERIFY_OTP_AUTH_SUCESS_MSG,
        app_consts.POST,
        VERIFY_OTP_AUTH_API,
        formData
      )
    );
  };

  // Use Selector and Effect to Listen if Changes Have Been Made
  const otp_verify = useSelector((state) => state.verify_otp.verify_otp);

  useEffect(() => {
    if (otp_verify.status !== app_consts.IDLE)
    {
      if (otp_verify.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (otp_verify.status === app_consts.FAIL)
        {
          setError(otp_verify.message);
        } else if (otp_verify.status === app_consts.SUCCESS)
        {
          window.location.assign("/");
        }
        dispatch(reset_verify_otp());
      }
    }
  }, [otp_verify]);

  return (
    <>
      <Head title="Sign-In. Verify OTP" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-website" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-website" src={Logo} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4" className="text-center">
                Sign-In
              </BlockTitle>
              <BlockTitle tag="h5">
                Verify OTP
                <strong className="text-primary small">
                  {page_data.first_name ? ` / ${page_data.first_name} ${page_data.surname}` : ""}
                </strong>
              </BlockTitle>
              <BlockDes>
                <p>Enter the OTP that was sent to your attached Email Address or Mobile Number.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <form
            className="is-alter"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="form-group">
              <TextInput
                label="OTP"
                colSize="12"
                name="otp"
                type="password"
                value={formData.otp}
                handleChange={handleChange}
              />
            </div>
            <div className="form-group">
              <Button
                color="primary"
                disabled={loading ? loading : !formData.otp}
                size="lg"
                className="btn-block"
                onClick={handleSubmit}
              >
                {loading ? <Spinner size="sm" color="light" /> : "Verify OTP"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/auth/signin`}>
              <strong>Return to login</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default VerifyOTP;
