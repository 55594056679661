import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import ListText from "../../../text/ListText";
import TextInput from "../../../../../components/inputs/TextInput";
import { Link } from "react-router-dom";
import { ADD_COMPANY_REPRESENTATIVE_ROLE } from "../../../../../../api/api_data";
import {
  fail_add_company_representative_role,
  loading_add_company_representative_role,
  reset_add_company_representative_role,
  success_add_company_representative_role,
} from "../../../../../../reducers/dashboard_reducers/company_representative/add_company_representative_role_Slice";
import {
  ADD_COMPANY_REPRESENTATIVE_ROLE_ACCESS_DENIED_MSG,
  ADD_COMPANY_REPRESENTATIVE_ROLE_ERR_MSG,
  ADD_COMPANY_REPRESENTATIVE_ROLE_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import { employee_dashboard_actions_helper } from "../../../../../../actions/actions_helper";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import { messageToast } from "../../../../../../utils/Utils";
import { reset_add_product_type } from "../../../../../../reducers/dashboard_reducers/products/add_product_type_Slice";
import { useSelector } from "react-redux";

const initialState = { company_representative_role_name: "" };
const AddCompRepRoleModal = ({ openOtherModalFunc, successFunction, openModal, setModalLoading, accountId }) => {
  const [step, setStep] = useState(1);
  const [errorVal, setError] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const prevStep = () => {
    setStep(1);
  };

  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  // Handle Submit
  const handleSubmit = () => {
    setError("");
    dispatch(
      employee_dashboard_actions_helper(
        loading_add_company_representative_role,
        success_add_company_representative_role,
        fail_add_company_representative_role,
        ADD_COMPANY_REPRESENTATIVE_ROLE_ERR_MSG,
        ADD_COMPANY_REPRESENTATIVE_ROLE_SUCCESS_MSG,
        app_consts.POST,
        ADD_COMPANY_REPRESENTATIVE_ROLE,
        formData,
        ADD_COMPANY_REPRESENTATIVE_ROLE_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to AddCompany Representative add_company_representative_role
  const add_company_representative_role = useSelector(
    (state) => state.add_company_representative_role.add_company_representative_role
  );
  // UseEffect
  useEffect(() => {
    if (add_company_representative_role.status !== app_consts.IDLE)
    {
      if (add_company_representative_role.status === app_consts.LOADING)
      {
        setIsLoading(true);
        setModalLoading(true);
      } else
      {
        setIsLoading(false);
        setModalLoading(false);
        if (add_company_representative_role.status === app_consts.FAIL)
        {
          setError(add_company_representative_role.message);
          prevStep();
        } else if (add_company_representative_role.status === app_consts.SUCCESS)
        {
          messageToast(ADD_COMPANY_REPRESENTATIVE_ROLE_SUCCESS_MSG, app_consts.SUCCESS);
          // Close Modal and Other Embedded Functions if Need Be
          openOtherModalFunc();
        }
        dispatch(reset_add_company_representative_role());
      }
    }
  }, [add_company_representative_role]);

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">Add Company Representative Role</h5>
        {step === 2 && (
          <div
            className="nk-block-head-sub mb-2"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            <Icon name="arrow-left" />
            <span>back</span>
          </div>
        )}
      </div>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}

      {step === 1 ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Row className="g-4">
              <TextInput
                label="Company Representative Role"
                required
                type="text"
                id_val="company_representative_role_name"
                maxlength="30"
                value={formData.company_representative_role_name}
                colSize="10"
                name="company_representative_role_name"
                handleChange={(e) => setFormData({ ...formData, ["company_representative_role_name"]: e.target.value })}
              />
              <Col xl="12" className="d-flex justify-content-center mt-8">
                <Button
                  onClick={handlePreview}
                  size="lg"
                  type="submit"
                  color="primary"
                  disabled={!formData.company_representative_role_name}
                >
                  Preview Role and Add
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : step === 2 ? (
        <>
          <ListText
            dataItem="data-item mySmall"
            label="Company Representative Role"
            value={formData.company_representative_role_name}
          />
          <Col xl="12" className="d-flex justify-content-center mt-4 mr-2">
            <Button onClick={handleSubmit} disabled={loading} color="primary">
              {loading ? <Spinner size="sm" color="light" /> : "Add Company Representative Role"}
            </Button>
          </Col>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddCompRepRoleModal;
