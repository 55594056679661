import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import TablePageHeading from '../components/page_heading/TablePageHeading';
import { useNavigate, useParams } from 'react-router';
import { Block, BlockHead, BlockTitle, Button, Icon } from '../../../components/Component';
import { Card, Spinner } from 'reactstrap';
import CardTabs from '../components/cards/CardTabs';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { fail_retrieve_specific_goods_received, loading_retrieve_specific_goods_received, reset_retrieve_specific_goods_received, success_retrieve_specific_goods_received } from '../../../reducers/dashboard_reducers/goods_received/retrieve_specific_goods_received_Slice';
import { useDispatch } from 'react-redux';
import { RETRIEVE_SPECIFIC_GOODS_RECEIVED_ACCESS_DENIED_MSG, RETRIEVE_SPECIFIC_GOODS_RECEIVED_ERR_MSG, RETRIEVE_SPECIFIC_GOODS_RECEIVED_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { RETRIEVE_SPECIFIC_GOODS_RECEIVED_API } from '../../../api/api_data';
import * as app_consts from "../../../constants/react_app_consts";
import { useSelector } from 'react-redux';
import { messageToast } from '../../../utils/Utils';
import { BiErrorCircle } from 'react-icons/bi';
import ProfileDetailText from '../components/text/ProfileDetailText';
import moment from 'moment';
import GoodsReceivedPDF from '../components/pdf/GoodsReceivedPDF';
import PageHeading from '../components/page_heading/PageHeading';
import { PDFViewer } from '@react-pdf/renderer';
import ReadOnlyTable from '../components/table/ReadOnlyTable';

const type = app_consts.SPECIFIC_GOODS_RECEIVED_DETAILS;
const goods_received_note_type = app_consts.SPECIFIC_GOODS_RECEIVED_DETAILS;

const initial_actions_data = { continue_processing_weigh_bridge_ticket: false };

const ViewGoodsRecievedDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { goods_received_ref_number } = useParams();
    const [sideBar, setSidebar] = useState(false);
    const [activeCardTab, setActiveCardTab] = useState("Summary");
    const [dataFetched, setDataFetched] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [page_data, setPageData] = useState([]);
    const [componentData, setComponentData] = useState(initial_actions_data);
    const [goods_received_product_list, setGoodsReceivedProductList] = useState([]);
    const [total_mt_capacity, setTotalMTCapacity] = useState(0);
    const [grn_history, setGrnHistory] = useState([]);

    // UseEffect to Run When the Page Opens
    useEffect(() => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_retrieve_specific_goods_received,
                success_retrieve_specific_goods_received,
                fail_retrieve_specific_goods_received,
                RETRIEVE_SPECIFIC_GOODS_RECEIVED_ERR_MSG,
                RETRIEVE_SPECIFIC_GOODS_RECEIVED_SUCCESS_MSG,
                app_consts.POST,
                RETRIEVE_SPECIFIC_GOODS_RECEIVED_API,
                { goods_received_ref_number: goods_received_ref_number },
                RETRIEVE_SPECIFIC_GOODS_RECEIVED_ACCESS_DENIED_MSG
            )
        );
    }, []);

    // Retrieve Module Actions Data
    const action_roles = useSelector((state) => state.auth_routes.auth_routes);
    // 
    useEffect(() => {
        if (action_roles.status === app_consts.SUCCESS)
        {
            if (action_roles.data.warehouse_management)
            {
                setComponentData(action_roles.data?.warehouse_management?.module_actions);
            }
        }
    }, [action_roles]);

    // Listen to Reducer
    const goods_received_details = useSelector((state) => state.retrieve_specific_goods_received.retrieve_specific_goods_received);
    // 
    useEffect(() => {
        if (goods_received_details.status !== app_consts.IDLE)
        {
            if (goods_received_details.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (goods_received_details.status === app_consts.FAIL)
                {
                    setError(goods_received_details.message);
                    messageToast(RETRIEVE_SPECIFIC_GOODS_RECEIVED_ERR_MSG, app_consts.FAIL);
                } else if (goods_received_details.status === app_consts.SUCCESS)
                {
                    console.log(goods_received_details.data);
                    setPageData(goods_received_details.data);
                    setGoodsReceivedProductList(goods_received_details.data?.goods_received_products);
                    setGrnHistory(goods_received_details.data?.goods_received?.grn_history);
                    setDataFetched(true);
                }
                dispatch(reset_retrieve_specific_goods_received());
            }
        }
    }, [goods_received_details]);

    // function to toggle sidebar
    const toggle = () => {
        setSidebar(!sideBar);
    };

    useEffect(() => {
        sideBar ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sideBar]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Summary"
        },
        {
            tabName: "Product Information"
        },
        {
            tabName: "Goods Received History"
        },
        {
            tabName: "Goods Received PDF"
        },
    ];


    const pageHeadingActions = (type, received_data) => {
        if (type === app_consts.CONTINUE_PROCESSING_GOODS_RECEIVED_ACTIONS)
        {
            if (page_data?.weigh_bridge_ticket_type?.weigh_bridge_ticket_type_id === app_consts.VIRTUAL_TICKET_TYPE)
            {
                navigate(`/dashboard/goods_received/continue_processing_virtual/${goods_received_ref_number}`);
            }
            else if (page_data?.weigh_bridge_ticket_type?.weigh_bridge_ticket_type_id === app_consts.PHYSICAL_TICKET_TYPE)
            {
                navigate(`/dashboard/goods_received/continue_processing_physical/${goods_received_ref_number}`);
            }
        }
    };

    // Get Sum of all Goods Received Products
    const totalCapacitySum = goods_received_product_list?.reduce((sum, item) => {
        // Convert total_capacity_in_mt to a number and add to the sum
        return sum + parseFloat(item?.total_capacity_in_mt);
    }, 0);

    //   Listen to Product Data Changes
    useEffect(() => {
        setTotalMTCapacity(totalCapacitySum);
    }, [goods_received_product_list]);

    return (
        <>
            <Head title="Goods Received Details" />
            <Content>
                {/* Table Page Heading */}
                <PageHeading
                    title="Goods Received Details"
                    title_addition={goods_received_ref_number}
                    description="Details of Goods Received"
                    backToText="Goods Recieved"
                    backToLink={`/dashboard/weigh_bridge_tickets/goods_received`}
                    showDropDown={page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id !== app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE}
                    headingRightData={componentData}
                    type={type}
                    pageHeadingActions={pageHeadingActions}
                />

                <Block>
                    <Card>
                        <div className="card-aside-wrap" id="user-detail-block">
                            <div className="card-content">
                                <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                {
                                    loading ?
                                        <>
                                            <div className='p-5 d-flex align-center justify-center'>
                                                <Spinner />
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                error && error.length > 0 ?
                                                    <>
                                                        <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                                            <BiErrorCircle className="text-danger fs-3" />
                                                            <p>{error}</p>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="card-inner">
                                                            {
                                                                activeCardTab === "Summary" && (
                                                                    <>
                                                                        <Block>
                                                                            <BlockHead>
                                                                                <BlockTitle tag="h5">Goods Received Summary</BlockTitle>
                                                                                <p>A Brief Summary of Goods Received.</p>
                                                                            </BlockHead>
                                                                            <div className="profile-ud-list">
                                                                                <ProfileDetailText label="Goods Received Reference Number" text={page_data?.goods_received?.goods_received_ref_number || "---"} />
                                                                                <ProfileDetailText label="Status" text={page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status || "---"} textClassName={page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ? "text-success" : "text-warning"} />
                                                                                <ProfileDetailText label="Total Products (Uom Variance Included)" text={goods_received_product_list?.length || 0} />
                                                                                <ProfileDetailText label="Product Total Quantity (MT)" text={total_mt_capacity ? `${parseFloat(total_mt_capacity).toFixed(2)}` : "---"} />
                                                                                <ProfileDetailText label="Date Received" text={page_data?.goods_received?.date_created ? moment(page_data?.goods_received?.date_created).format("MMMM Do, YYYY") : "---"} />
                                                                            </div>
                                                                            <BlockHead className='mt-5'>
                                                                                <BlockTitle tag="h6">Consigner Details</BlockTitle>
                                                                            </BlockHead>
                                                                            <div className="profile-ud-list">
                                                                                <ProfileDetailText label="User ID" text={page_data?.user_account?.unique_identification_number || "---"} />
                                                                                <ProfileDetailText label="Name" text={page_data?.consigner?.name || "---"} />
                                                                                <ProfileDetailText label="Email Address" text={page_data?.consigner?.email || "---"} />
                                                                                <ProfileDetailText label="Mobile number" text={page_data?.consigner?.mobile_number || "---"} />
                                                                            </div>
                                                                            <BlockHead className='mt-5'>
                                                                                <BlockTitle tag="h6">Weigh Bridge Details</BlockTitle>
                                                                            </BlockHead>
                                                                            <div className="profile-ud-list">
                                                                                <ProfileDetailText label="Weigh Bridge Ticket Number" text={page_data?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no || "---"} />
                                                                                <ProfileDetailText label="Gross Weight" text={page_data?.weigh_bridge_ticket?.first_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.first_weight).toFixed(2)} MT` : "---"} />
                                                                                <ProfileDetailText label="Tare Weight" text={page_data?.weigh_bridge_ticket?.second_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.second_weight).toFixed(2)} MT` : "---"} />
                                                                                <ProfileDetailText label="Net Weight" text={page_data?.weigh_bridge_ticket?.net_weight ? `${parseFloat(page_data?.weigh_bridge_ticket?.net_weight).toFixed(2)} MT` : "---"} />
                                                                            </div>
                                                                            <BlockHead className='mt-5'>
                                                                                <BlockTitle tag="h6">Transport Details</BlockTitle>
                                                                            </BlockHead>
                                                                            <div className="profile-ud-list">
                                                                                <ProfileDetailText label="Transporter Name" text={page_data?.weigh_bridge_ticket?.transporter_name || "---"} />
                                                                                <ProfileDetailText label="Truck Driver Name" text={page_data?.weigh_bridge_ticket?.truck_driver_name || "---"} />
                                                                                <ProfileDetailText label="Truck License Plate" text={page_data?.weigh_bridge_ticket?.truck_licence_plate || "---"} />
                                                                                <ProfileDetailText label="Trailer 1 No" text={page_data?.weigh_bridge_ticket?.trailer_one || "---"} />
                                                                                <ProfileDetailText label="Trailer 2 No" text={page_data?.weigh_bridge_ticket?.trailer_two || "---"} />
                                                                            </div>
                                                                            <BlockHead className='mt-5'>
                                                                                <BlockTitle tag="h6">Branch Location Details</BlockTitle>
                                                                            </BlockHead>
                                                                            <div className="profile-ud-list">
                                                                                <ProfileDetailText label="Branch Location Reference Number" text={page_data?.branch_location?.branch_ref_no || "---"} />
                                                                                <ProfileDetailText label="Branch Area" text={page_data?.branch_location?.branch_area || "---"} />
                                                                                <ProfileDetailText label="Branch Physical Address" text={page_data?.branch_location?.branch_physical_address || "---"} />
                                                                                <ProfileDetailText label="Branch Mobile Number" text={page_data?.branch_location?.branch_mobile_number || "---"} />
                                                                            </div>
                                                                        </Block>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                activeCardTab === "Product Information" && (
                                                                    <>
                                                                        <Block>
                                                                            <BlockHead>
                                                                                <BlockTitle tag="h5">Product Information</BlockTitle>
                                                                                <p>Products on Goods Received Note</p>
                                                                            </BlockHead>
                                                                            {/* Table */}
                                                                            <div className="my-3">
                                                                                <ReadOnlyTable data={goods_received_product_list} tableType={goods_received_note_type} />
                                                                            </div>
                                                                        </Block>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                activeCardTab === "Goods Received History" && (
                                                                    <>
                                                                        <Block>
                                                                            <BlockHead>
                                                                                <BlockTitle tag="h5">Goods Received History</BlockTitle>
                                                                                <p>Historical Data on Goods Received Changes</p>
                                                                            </BlockHead>
                                                                            {/* Table */}
                                                                            <div className="my-3">
                                                                                <ReadOnlyTable data={grn_history} tableType={app_consts.GRN_HISTORY} />
                                                                            </div>
                                                                        </Block>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                activeCardTab === "Goods Received PDF" && (
                                                                    <>
                                                                        {
                                                                            page_data?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ?
                                                                                <>
                                                                                    <Block>
                                                                                        <BlockHead>
                                                                                            <BlockTitle tag="h5">Goods Received PDF</BlockTitle>
                                                                                            <p>Weigh Bridge Details</p>
                                                                                        </BlockHead>
                                                                                        <div style={{ height: "90vh", width: "100%" }} className='border-2 border-text-gray-400'>
                                                                                            <PDFViewer height="100%" width="100%" showToolbar>
                                                                                                <GoodsReceivedPDF page_data={page_data} />
                                                                                            </PDFViewer>
                                                                                        </div>
                                                                                    </Block>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                                                                        <BiErrorCircle className="text-danger fs-13" />
                                                                                        <p>Cannot View Goods Received PDF.</p>
                                                                                        <p>Goods Received Processing is Incomplete.</p>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </Card>
                </Block>
            </Content>
        </>
    );
};

export default ViewGoodsRecievedDetails;