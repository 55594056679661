import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { Col, Row } from "../../../../../../components/Component";
import StepGuideCard from "../../wizard_steps_guide/StepGuide";
import { useDispatch } from "react-redux";
import BasicInfoStep from "./steps/complete_kyc_reg_steps/BasicInfoStep";
import { useSelector } from "react-redux";
import { reset_specific_user_kyc_det } from "../../../../../../reducers/dashboard_reducers/user_kyc/specific_user_kyc_det_Slice";
import * as app_consts from "../../../../../../constants/react_app_consts";
import DocumentInfoStep from "./steps/complete_kyc_reg_steps/DocumentInfoStep";
import AddressInfoStep from "./steps/complete_kyc_reg_steps/AddressInfoStep";
import NokInfoStep from "./steps/complete_kyc_reg_steps/NokInfoStep";
import ImageInfoStep from "./steps/complete_kyc_reg_steps/ImageInfoStep";

const CompleteKYCRegWizardIndex = ({ wizardType }) => {
  const [step, setStep] = useState(0);
  const [wizardData, setWizardData] = useState([]);
  const dispatch = useDispatch();

  // Listen to Changes in User KYC Details Reducer
  const specific_user_kyc_det = useSelector((state) => state.specific_user_kyc_det.specific_user_kyc_det);
  //
  useEffect(() => {
    if (specific_user_kyc_det.status === app_consts.SUCCESS)
    {
      setWizardData(specific_user_kyc_det.data);
      dispatch(reset_specific_user_kyc_det());
    }
  }, [specific_user_kyc_det]);

  // Setting Step According to Wizard Data
  useEffect(() => {
    if (wizardData)
    {
      setStep(wizardData.step);
    }
  }, [wizardData]);

  // Next Step
  const nextStep = () => {
    setStep(step + 1);
  };

  return (
    <>
      <Card className="card-bordered">
        <Row className="g-0 col-sep col-sep-md col-sep-xl">
          <StepGuideCard sentStep={step} wizard_type={wizardType} wizard_title="Complete User KYC Registration" />
          <Col className="col-md-8 col-xl-8">
            {step === 1 ? (
              <BasicInfoStep togglestep={step} nextStep={nextStep} data={wizardData} />
            ) : step === 2 ? (
              <DocumentInfoStep togglestep={step} nextStep={nextStep} data={wizardData} />
            ) : step === 3 ? (
              <AddressInfoStep togglestep={step} nextStep={nextStep} data={wizardData} />
            ) : step === 4 ? (
              <NokInfoStep togglestep={step} nextStep={nextStep} data={wizardData} />
            ) : step === 5 ? (
              <ImageInfoStep togglestep={step} nextStep={nextStep} data={wizardData} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CompleteKYCRegWizardIndex;
