import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retrieve_delivery_notes_ticket_list: { status: "idle" },
};

export const retrieve_delivery_notes_ticket_list_Slice = createSlice({
    name: "retrieve_delivery_notes_ticket_list",
    initialState,
    reducers: {
        success_retrieve_delivery_notes_ticket_list: (state, action) => {
            state.retrieve_delivery_notes_ticket_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_delivery_notes_ticket_list: (state, action) => {
            state.retrieve_delivery_notes_ticket_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_delivery_notes_ticket_list: (state) => {
            state.retrieve_delivery_notes_ticket_list = { status: app_consts.IDLE };
        },
        loading_retrieve_delivery_notes_ticket_list: (state) => {
            state.retrieve_delivery_notes_ticket_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_retrieve_delivery_notes_ticket_list, fail_retrieve_delivery_notes_ticket_list, reset_retrieve_delivery_notes_ticket_list, loading_retrieve_delivery_notes_ticket_list } = retrieve_delivery_notes_ticket_list_Slice.actions;

export default retrieve_delivery_notes_ticket_list_Slice.reducer;