import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    complete_adding_delivery_note_products: { status: "idle" },
};

export const complete_adding_delivery_note_products_Slice = createSlice({
    name: "complete_adding_delivery_note_products",
    initialState,
    reducers: {
        success_complete_adding_delivery_note_products: (state, action) => {
            state.complete_adding_delivery_note_products = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_complete_adding_delivery_note_products: (state, action) => {
            state.complete_adding_delivery_note_products = { status: app_consts.FAIL, message: action.payload };
        },
        reset_complete_adding_delivery_note_products: (state) => {
            state.complete_adding_delivery_note_products = { status: app_consts.IDLE };
        },
        loading_complete_adding_delivery_note_products: (state) => {
            state.complete_adding_delivery_note_products = { status: app_consts.LOADING };
        },
    },
});

export const { success_complete_adding_delivery_note_products, fail_complete_adding_delivery_note_products, reset_complete_adding_delivery_note_products, loading_complete_adding_delivery_note_products } =
    complete_adding_delivery_note_products_Slice.actions;

export default complete_adding_delivery_note_products_Slice.reducer;
