import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../components/Component";
import { Alert, Input, Label, Spinner } from "reactstrap";
import TextInput from "../../../../../components/inputs/TextInput";
import ListText from "../../../text/ListText";
import { useDispatch } from "react-redux";
import { retrieve_specific_country_all_user_kyc_det_action } from "../../../../../../actions/dashboard_actions/locations/locations_actions";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../../../utils/Utils";
import { reset_specific_country_all } from "../../../../../../reducers/dashboard_reducers/location/specific_country_all_Slice";
import * as app_consts from "../../../../../../constants/react_app_consts";
import { employee_dashboard_actions_helper } from "../../../../../../actions/actions_helper";
import { ADD_USER_ACCOUNT_PROPERTY } from "../../../../../../api/api_data";
import {
  fail_add_user_account_property,
  loading_add_user_account_property,
  reset_add_user_account_property,
  success_add_user_account_property,
} from "../../../../../../reducers/dashboard_reducers/user_account_property/add_user_account_property_Slice";
import {
  ADD_USER_ACCOUNT_PROPERTY_ACCESS_DENIED_MSG,
  ADD_USER_ACCOUNT_PROPERTY_ERR_MSG,
  ADD_USER_ACCOUNT_PROPERTY_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import countries from "../../../../../../utils/countries.json";
import provinces from "../../../../../../utils/provinces.json";
import districts from "../../../../../../utils/districts.json";

const initialState = {
  property_name: "",
  property_physical_address: "",
  user_account_id: "",
  country: "",
  province: "",
  district: ""
};

const AddUserPropertyModal = ({ successFunction, openModal, setModalLoading, accountId }) => {
  const [step, setStep] = useState(1);
  const [errorVal, setError] = useState("");
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const [province_list, setProvinceList] = useState([]);
  const [district_list, setDistrictList] = useState([]);
  const [district_data, setDistrictData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [province_name, setProvinceName] = useState("");
  const [district_name, setDistrictName] = useState("");

  //Listen if Modal is Open or Not
  useEffect(() => {
    if (openModal)
    {
      if (accountId)
      {
        setFormData({ ...formData, ["user_account_id"]: accountId });
      }
    }
  }, [accountId, openModal]);

  useEffect(() => {
    filterDistricts(selectedProvince);
  }, [selectedProvince]);

  // Filter Districts
  const filterDistricts = (received_id) => {
    let filteredDistricts = district_data?.filter((item) => {
      return item.province_id === received_id;
    });
    // Remapped Districts
    const mappedDistricts = filteredDistricts?.map((item) => {
      return {
        label: item.district_name,
        value: item.district_id,
      };
    });
    setDistrictList(mappedDistricts);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  // Submite Form
  const handleSubmit = () => {
    setError("");
    dispatch(
      employee_dashboard_actions_helper(
        loading_add_user_account_property,
        success_add_user_account_property,
        fail_add_user_account_property,
        ADD_USER_ACCOUNT_PROPERTY_ERR_MSG,
        ADD_USER_ACCOUNT_PROPERTY_SUCCESS_MSG,
        app_consts.POST,
        ADD_USER_ACCOUNT_PROPERTY,
        formData,
        ADD_USER_ACCOUNT_PROPERTY_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Changes in Add User Account Property Reducer
  const add_user_account_property = useSelector((state) => state.add_user_account_property.add_user_account_property);
  //
  useEffect(() => {
    if (add_user_account_property.status !== app_consts.IDLE)
    {
      if (add_user_account_property.status === app_consts.LOADING)
      {
        setIsLoading(true);
        setModalLoading(true);
      } else
      {
        setIsLoading(false);
        setModalLoading(false);
        if (add_user_account_property.status === app_consts.FAIL)
        {
          messageToast(add_user_account_property.message, app_consts.FAIL);
          setError(add_user_account_property.message, app_consts.FAIL);
        } else if (add_user_account_property.status === app_consts.SUCCESS)
        {
          messageToast(ADD_USER_ACCOUNT_PROPERTY_SUCCESS_MSG, app_consts.SUCCESS);
          successFunction(add_user_account_property.data);
        }
        dispatch(reset_add_user_account_property());
      }
    }
  }, [add_user_account_property]);

  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  const prevStep = () => {
    setStep(1);
    setDistrictName("");
    setProvinceName("");
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">Onboard User Property Form</h5>
        {step === 2 && (
          <div
            className="nk-block-head-sub mb-2"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            <Icon name="arrow-left" />
            <span>back</span>
          </div>
        )}
      </div>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      {step === 1 ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Row className="g-4">
              <TextInput
                label="Property Name"
                required
                type="text"
                id_val="property_name"
                maxlength="100"
                value={formData.property_name}
                colSize="6"
                name="property_name"
                handleChange={handleChange}
              />
              <TextInput
                label="Property Physical Address"
                required
                type="text"
                id_val="property_physical_address"
                maxlength="100"
                value={formData.property_physical_address}
                colSize="6"
                name="property_physical_address"
                handleChange={handleChange}
              />
              <Col lg="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="country">
                    Country
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <Input type="select" name="country" id="default-4" onChange={handleChange}>
                        {countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                </div>
              </Col>
              {
                formData.country === 'Zambia' ?
                  <>
                    <Col lg="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="province">
                          Province
                        </label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input type="select" name="province" id="default-4" onChange={handleChange}>
                              <option value="">Choose a Province</option>
                              {provinces.Zambia.map((province, index) => (
                                <option key={index} value={province}>
                                  {province}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                  :
                  <>
                    <TextInput
                      label="Province"
                      required
                      type="text"
                      id_val="province"
                      maxlength="100"
                      value={formData.province}
                      colSize="6"
                      name="province"
                      handleChange={handleChange}
                    />
                  </>
              }
              {
                formData.country === 'Zambia' ?
                  <>
                    <Col lg="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="district">
                          District
                        </label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input type="select" name="district" id="default-4" onChange={handleChange}>
                              <option value="">Choose a District</option>
                              {districts?.[formData?.province]?.map((district, index) => (
                                <option key={index} value={district}>
                                  {district}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                  :
                  <>
                    <TextInput
                      label="District"
                      required
                      type="text"
                      id_val="district"
                      maxlength="100"
                      value={formData.district}
                      colSize="6"
                      name="district"
                      handleChange={handleChange}
                    />
                  </>
              }
              <Col xl="12" className="d-flex justify-content-center mt-8">
                <Button
                  onClick={handlePreview}
                  size="lg"
                  type="submit"
                  color="primary"
                  disabled={
                    !(
                      formData.property_name &&
                      formData.user_account_id &&
                      formData.country &&
                      formData.province &&
                      formData.district &&
                      formData.property_physical_address
                    )
                  }
                >
                  Preview User Property and Onboard
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : step === 2 ? (
        <>
          <ListText dataItem="data-item mySmall" label="Property Name" value={formData.property_name} />
          <ListText
            dataItem="data-item mySmall"
            label="Property Physical Address"
            value={formData.property_physical_address}
          />
          <ListText dataItem="data-item mySmall" label="Country" value={formData.country} />
          <ListText dataItem="data-item mySmall" label="Province" value={formData.province} />
          <ListText dataItem="data-item mySmall" label="District" value={formData.district} />
          <Col xl="12" className="d-flex justify-content-center mt-4 mr-2">
            <Button onClick={handleSubmit} disabled={loading} color="primary">
              {loading ? <Spinner size="sm" color="light" /> : "Onboard User Property"}
            </Button>
          </Col>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddUserPropertyModal;
