import React, { useEffect, useState } from "react";
import { Button, Col, Icon, Row, RSelect } from "../../../../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { employee_dashboard_actions_helper } from "../../../../../../actions/actions_helper";
import {
  fail_active_users_list,
  loading_active_users_list,
  reset_active_users_list,
  success_active_users_list,
} from "../../../../../../reducers/dashboard_reducers/user/active_users_list_Slice";
import {
  RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG,
  RETRIEVE_ACTIVE_USERS_ERR_MSG,
  RETRIEVE_ACTIVE_USERS_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import { RETRIEVE_ACT_COMP_KYC_LIST_NO_COMP_ACCS_API, RETRIEVE_ACTIVE_COMPLETE_KYC_LIST } from "../../../../../../api/api_data";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import TextInput from "../../../../../components/inputs/TextInput";
import { BiErrorCircle } from "react-icons/bi";
import ReadOnlyTable from "../../../table/ReadOnlyTable";

const type = app_consts.ONBOARD_COMPANY_SEARCH;

const ModalOnboardCompanySearch = ({ isOpen, setIsOpen }) => {
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [openListener, setOpenListener] = useState(isOpen);
  const dispatch = useDispatch();
  const [user_list, setUserList] = useState([]);
  const [user_data, setUserData] = useState([]);
  const [onSearchText, setOnSearchText] = useState("");
  const [listError, setListError] = useState("");
  const [selected_data, setSelectedData] = useState([]);

  useEffect(() => {
    setOpenListener(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (openListener)
    {
      retrieveActiveUsersList();
    }
  }, [openListener]);

  // Function to Receive list of Users
  const retrieveActiveUsersList = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_active_users_list,
        success_active_users_list,
        fail_active_users_list,
        RETRIEVE_ACTIVE_USERS_ERR_MSG,
        RETRIEVE_ACTIVE_USERS_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACT_COMP_KYC_LIST_NO_COMP_ACCS_API,
        null,
        RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG,
      )
    );
  };

  // Read Active Users List State
  const active_users_list = useSelector((state) => state.active_users_list.active_users_list);

  useEffect(() => {
    if (active_users_list.status !== app_consts.IDLE)
    {
      if (active_users_list.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (active_users_list.status === app_consts.FAIL)
        {
          setListError(active_users_list.message);
        } else if (active_users_list.status === app_consts.SUCCESS)
        {
          setUserList(active_users_list.data);
          setUserData(active_users_list.data);
        }
        dispatch(reset_active_users_list());
      }
    }
  }, [active_users_list]);

  //function to declare the state change
  const onActionText = (e) => {
    if (selectedUser) setSelectedUser("");
    setOnSearchText(e);
  };

  //Function to Initiate Search
  //Changing state value when searching name
  useEffect(() => {
    if (onSearchText)
    {
      const filteredObject = user_data?.filter((item) => {
        console.log(item);
        return (
          item?.first_name.toLowerCase().includes(onSearchText?.toLowerCase()) ||
          item?.surname.toLowerCase().includes(onSearchText?.toLowerCase()) ||
          item?.phone_number.toLowerCase().includes(onSearchText?.toLowerCase()) ||
          item?.email_address.toLowerCase().includes(onSearchText?.toLowerCase())
        );
      });
      setUserList([...filteredObject]);
    } else
    {
      setUserList(user_data);
      setOnSearchText("");
    }
  }, [onSearchText]);

  // Refresh List Button
  const refreshList = () => {
    retrieveActiveUsersList();
    setListError("");
    setSelectedUser("");
  };

  useEffect(() => {
    if (selected_data)
    {
      setSelectedUser(selected_data?.phone_number);
    }
  }, [selected_data]);

  return (
    <>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      {
        !loading && (
          <>
            <div className="mt-2">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  refreshList();
                }}
              >
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                  <span>Refresh List</span>
                </Button>
              </Link>
            </div>
          </>
        )
      }
      {
        loading ?
          <>
            <div className='p-5 d-flex align-center justify-center'>
              <Spinner />
            </div>
          </>
          :
          <>
            {
              listError ?
                <>
                  <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                    <BiErrorCircle className="text-danger fs-3" />
                    <p>{listError}</p>
                  </div>
                </>
                :
                <>
                  <form>
                    <Col>
                      <TextInput
                        label="Search for User"
                        required
                        type="text"
                        id_val="user_search"
                        value={onSearchText}
                        colSize="6"
                        name="user_search"
                        handleChange={(e) => onActionText(e.target.value)}
                      />
                    </Col>
                    <p style={{ marginTop: 20 }}>Select a Row to Proceed</p>
                    <div className="my-3">
                      <ReadOnlyTable data={user_list} tableType={type} setSelectedData={setSelectedData} page_size={5} />
                    </div>
                    <Col xl="12">
                      <div className="form-group mt-2">
                        <div className="form-control-wrap-flex-wrap justify-end">
                          {selectedUser ? (
                            <Link to={`/dashboard/onboarding/company/${selectedUser}`}>
                              <Button color="primary" size="lg">
                                <span>Select User</span>
                              </Button>
                            </Link>
                          ) : (
                            <Button disabled color="primary" size="lg">
                              <span>Select User</span>
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </form>
                </>
            }
          </>
      }
    </>
  );
};

export default ModalOnboardCompanySearch;
