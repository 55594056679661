import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    continue_processing_delivery_note: { status: "idle" },
};

export const continue_processing_delivery_note_Slice = createSlice({
    name: "continue_processing_delivery_note",
    initialState,
    reducers: {
        success_continue_processing_delivery_note: (state, action) => {
            state.continue_processing_delivery_note = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_continue_processing_delivery_note: (state, action) => {
            state.continue_processing_delivery_note = { status: app_consts.FAIL, message: action.payload };
        },
        reset_continue_processing_delivery_note: (state) => {
            state.continue_processing_delivery_note = { status: app_consts.IDLE };
        },
        loading_continue_processing_delivery_note: (state) => {
            state.continue_processing_delivery_note = { status: app_consts.LOADING };
        },
    }
});

export const { success_continue_processing_delivery_note, fail_continue_processing_delivery_note, reset_continue_processing_delivery_note, loading_continue_processing_delivery_note } = continue_processing_delivery_note_Slice.actions;

export default continue_processing_delivery_note_Slice.reducer;