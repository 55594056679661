import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../components/Component";
import { Link } from "react-router-dom";
import { onOptionsSearch } from "../../../../../../../utils/Utils";
import { Spinner } from "reactstrap";
import * as app_consts from "../../../../../../../constants/react_app_consts";
import RadioInput from "../../../../input/RadioInput";

const SearchUserStep = ({ data, isLoadingList, refreshUsers, formData, setFormData, buttonClickFunc, isLoading }) => {
  const [user_list_data, setUserListData] = useState([]);
  const [onSerchText, setOnSearchText] = useState("");
  const [isSearch, setisSearch] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [inputData, setInputData] = useState(formData);
  const [btnLoading, setBtnLoading] = useState(isLoading);

  // UseEffect for FormData
  useEffect(() => {
    setInputData(formData);
  }, [formData]);

  // UseEffect for Data
  useEffect(() => {
    if (data)
    {
      setUserListData(data);
    }
  }, [data]);

  // UseEffect for Button Loading
  useEffect(() => {
    setBtnLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setLoadingList(isLoadingList);
  }, [isLoadingList]);

  const onActionText = (e) => {
    const { option, setSearch } = onOptionsSearch(e);
    setOnSearchText(option);
    setisSearch(setSearch);
  };

  //Function to Initiate Search
  //Changing state value when searching name
  useEffect(() => {
    if (isSearch)
    {
      const filteredObject = user_list_data?.filter((item) => {
        return (
          item.phone_number.toLowerCase().includes(onSerchText.toLowerCase()) ||
          item.surname.toLowerCase().includes(onSerchText.toLowerCase()) ||
          item.first_name.toLowerCase().includes(onSerchText.toLowerCase()) ||
          `${item.first_name.toLowerCase().includes(onSerchText.toLowerCase())} ${item.surname
            .toLowerCase()
            .includes(onSerchText.toLowerCase())}` ||
          `${item.surname.toLowerCase().includes(onSerchText.toLowerCase())} ${item.first_name
            .toLowerCase()
            .includes(onSerchText.toLowerCase())}`
        );
      });

      const mappedData = filteredObject?.map((user_det) => {
        return {
          label: `${user_det.first_name} ${user_det.surname} - ${user_det.phone_number}`,
          value: user_det.phone_number,
        };
      });
      setOptionsData([...mappedData]);
    } else
    {
      setOptionsData([]);
      setOnSearchText("");
    }
  }, [onSerchText]);

  // UseEffect for Selected Option
  useEffect(() => {
    if (selectedOption)
    {
      setSelectedUser(filterSelectedUser(selectedOption));
    }
  }, [selectedOption]);

  // Filter Selected User Based On Selected Option
  const filterSelectedUser = (selectedOption) => {
    const user_det = user_list_data?.filter((user) => {
      return user.phone_number.toLowerCase() === selectedOption.toLowerCase();
    });
    return user_det[0];
  };

  // Manage Changes When An Option is Selected
  const handleChange = (otp_option) => {
    setFormData({ ...formData, ["send_otp_type"]: otp_option });
  };

  const refreshOptionsList = () => {
    refreshUsers();
    setOptionsData([]);
    setSelectedOption("");
    setSelectedUser([]);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row>
          <Col>
            <div className="form-group">
              <label className="form-label mb-1 text-md" htmlFor="username">
                Enter at least 5 characters to initiate search
              </label>
              <p>Search by Name or Phone Number</p>
              <div className="form-control-wrap align-items-center w-100 d-flex flex-row mb-2 justify-between">
                <div className="w-85">
                  <RSelect
                    options={optionsData}
                    className="w-100"
                    placeholder="Search"
                    id="username"
                    onChange={(e) => {
                      setSelectedOption(e.value);
                      setFormData({ ...formData, ["phone_number"]: e.value });
                    }}
                    onInputChange={(e) => onActionText(e)}
                    isDisabled={loadingList}
                  />
                </div>
                <div className="w-12">
                  {
                    // Waiting for List to Load
                    loadingList ? (
                      <Spinner />
                    ) : (
                      <Link
                        to="#reload"
                        onClick={(e) => {
                          e.preventDefault();
                          refreshOptionsList();
                        }}
                      >
                        <Icon name="reload-alt btn-icon fs-22px"></Icon>
                      </Link>
                    )
                  }
                </div>
              </div>
            </div>
          </Col>
          <Col xl="12">
            <div className="form-group mt-2">
              <div className="form-control-wrap-flex-wrap justify-center">
                <Button
                  disabled={btnLoading ? btnLoading : !(inputData.phone_number)}
                  color="primary"
                  onClick={buttonClickFunc}
                  size="lg"
                >
                  <span>{btnLoading ? <Spinner size="sm" /> : "Proceed"}</span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default SearchUserStep;
