import React, { useEffect, useState } from 'react';
import Head from '../../../../layout/head/Head';
import { capitalizeWords } from '../../../../utils/functionalUtils';
import PageHeading from '../../components/page_heading/PageHeading';
import Content from '../../../../layout/content/Content';
import { useParams } from 'react-router';
import { Block, BlockHead, BlockTitle, Button, Col, Row } from '../../../../components/Component';
import { Card, CardText, Spinner } from 'reactstrap';
import CardTabs from '../../components/cards/CardTabs';
import { RETRIEVE_EMPLOYEE_ROLE_ACTIONS_API } from '../../../../api/api_data';
import * as app_consts from "../../../../constants/react_app_consts";
import { fail_emp_role_actions, loading_emp_role_actions, reset_emp_role_actions, success_emp_role_actions } from '../../../../reducers/dashboard_reducers/employee/emp_role_actions_Slice';
import { employee_dashboard_actions_helper } from '../../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { messageToast } from '../../../../utils/Utils';
import { useSelector } from 'react-redux';
import { RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_ACCESS_DENIED_MSG, RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_ERR_MSG, RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_SUCCESS_MSG } from '../../../../constants/success_error_messages_consts';
import ReadOnlyTable from '../../components/table/ReadOnlyTable';
import MenuText from '../../components/text/MenuText';

const role_employee_list = app_consts.ROLE_EMPLOYEES_LIST;

const DepartmentRolesDetails = () => {

    const { role_name, role_id } = useParams();
    const [activeCardTab, setActiveCardTab] = useState("Employees");
    const [dataFetched, setDataFetched] = useState(false);
    const [page_data, setPageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const [employee_list, setEmployeeList] = useState([]);
    const [emp_actions, setEmpActions] = useState([]);
    const [total_actions, setTotalActions] = useState(0);
    const [selected_module, setSelectModule] = useState([]);
    const [current_actions, SetCurrentActions] = useState([]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Employees", icon: "user-circle"
        },
        {
            tabName: "System Role Actions", icon: "user-circle"
        },
    ];

    // Run on Page Load 
    useEffect(() => {
        // Retrieve User's Information
        dispatch(
            employee_dashboard_actions_helper(
                loading_emp_role_actions,
                success_emp_role_actions,
                fail_emp_role_actions,
                RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_ERR_MSG,
                RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_SUCCESS_MSG,
                app_consts.GET,
                `${RETRIEVE_EMPLOYEE_ROLE_ACTIONS_API}/${role_id}`,
                null,
                RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_ACCESS_DENIED_MSG,
            )
        );
    }, []);

    // Listen to Reducer
    const emp_role_actions_listener = useSelector((state) => state.emp_role_actions.emp_role_actions);
    //   
    useEffect(() => {
        if (emp_role_actions_listener.status !== app_consts.IDLE)
        {
            if (emp_role_actions_listener.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (emp_role_actions_listener.status === app_consts.FAIL)
                {
                    messageToast(emp_role_actions_listener.message, app_consts.FAIL);
                    setError(emp_role_actions_listener.message);
                } else if (emp_role_actions_listener.status === app_consts.SUCCESS)
                {
                    setPageData(emp_role_actions_listener.data);
                    setDataFetched(true);
                    setEmployeeList(emp_role_actions_listener.data?.employees);
                    setEmpActions(emp_role_actions_listener.data?.module_actions);
                    if (emp_role_actions_listener.data?.module_actions)
                    {
                        setSelectModule(emp_role_actions_listener.data?.module_actions[0]?.module_name);
                        // Initialize total length variable
                        let totalLength = 0;

                        // Iterate over modules and sum up lengths of actions arrays
                        emp_role_actions_listener.data?.module_actions?.forEach(module => {
                            totalLength += module?.actions?.length;
                        });
                        setTotalActions(totalLength);
                    }
                }
                dispatch(reset_emp_role_actions());
            }
        }
    }, [emp_role_actions_listener]);

    // Handle Selected Module
    const onModuleClick = (module_name) => {
        setSelectModule(module_name);
        const filteredModuleData = emp_actions?.filter((module) => module.module_name === module_name);
        if (filteredModuleData) 
        {
            SetCurrentActions(filteredModuleData[0]?.actions);
        }
    };

    return (
        <>
            <Head title={`Department Role ${capitalizeWords(role_name)}`} />
            <Content>
                <PageHeading
                    title="Department Role Details"
                    title_addition={capitalizeWords(role_name)}
                    description="Details of Department Role"
                    backToText="Department and Roles"
                    backToLink={`/dashboard/admin/departments_and_roles`}
                />

                <Block>
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                        {error}
                                    </div>
                                </>
                                :
                                <>
                                    {/* Add Department Details */}
                                    <div className="text-soft d-flex items-center align-center">
                                        <div className='p-1 text-xl'>
                                            <p></p>Department
                                        </div>
                                        <p><strong className="text-primary">{page_data ? page_data?.employee_department?.department_name?.toUpperCase() : "---"}</strong></p>
                                    </div>
                                    <Card>
                                        <div className="card-aside-wrap" id="user-detail-block">
                                            <div className="card-content">
                                                <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                                {
                                                    activeCardTab === "Employees" && (
                                                        <>
                                                            <div className="card-inner">
                                                                <Block>
                                                                    <div className='flex-row items-center justify-between'>
                                                                        <BlockHead>
                                                                            <BlockTitle tag="h5">Employees</BlockTitle>
                                                                            <p>Employees: <strong className="text-primary">{employee_list?.length || 0}</strong></p>
                                                                        </BlockHead>
                                                                    </div>
                                                                    {/* Table */}
                                                                    <div className="my-3">
                                                                        <ReadOnlyTable data={employee_list} tableType={role_employee_list} />
                                                                    </div>
                                                                </Block>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                {
                                                    activeCardTab === "System Role Actions" && (
                                                        <>
                                                            <div className="card-inner">
                                                                <Block>
                                                                    <div className='flex-row items-center justify-between'>
                                                                        <BlockHead>
                                                                            <BlockTitle tag="h5">System Role Actions</BlockTitle>
                                                                            <p>Total: <strong className="text-primary">{total_actions || 0}</strong></p>
                                                                        </BlockHead>
                                                                    </div>
                                                                    {/* Table */}
                                                                    <div className="my-3">
                                                                        <Card className="card-bordered">
                                                                            <Row className="g-0 col-sep col-sep-md col-sep-xl">

                                                                                <div className="col-md-4 col-xl-4 p-2" style={{ cursor: "pointer" }}>
                                                                                    {
                                                                                        emp_actions?.map((action) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div onClick={() => onModuleClick(action.module_name)}>
                                                                                                        <CardText key={action.module_id}>
                                                                                                            <MenuText menuText={capitalizeWords(action.module_name)} selected={selected_module} />
                                                                                                        </CardText>
                                                                                                    </div>
                                                                                                </>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <Col className="col-md-8 col-xl-8 p-4">
                                                                                    <div className="mt-4 d-flex justify-start">
                                                                                        <Button
                                                                                            className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                                                                                            href="#view"
                                                                                            onClick={(ev) => {
                                                                                            }}
                                                                                        >
                                                                                            <span>Edit Roles</span>
                                                                                        </Button>
                                                                                    </div>
                                                                                    {
                                                                                        current_actions?.map((action) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <Row className="g-gs flex mb-2">
                                                                                                        <Col lg="4">
                                                                                                            <p>{action.module_action_name}</p>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </Card>
                                                                    </div>
                                                                </Block>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Card>
                                </>

                    }
                </Block>
            </Content>
        </>
    );
};

export default DepartmentRolesDetails;