import React, { useEffect, useState } from "react";
import { DataTableItem, DataTableRow, Icon, TooltipComponent, UserAvatar } from "../../../../../components/Component";
import { Link } from "react-router-dom";
import { findUpper } from "../../../../../utils/Utils";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import * as app_consts from "../../../../../constants/react_app_consts";
import {
  BranchLocationOptionsData,
  ClientGoodsReceivedOptionsData,
  EmpDepRoleData,
  GoodsReceivedOptionsData,
  ProductOptionsData,
  ProductTypeOptionsData,
  UnitMeasurementOptionsData,
  UserListData,
  WarehouseInventoryListOptionsData,
} from "./table_row_data";

const TableRow = ({
  item,
  rowLink,
  avatar,
  type,
  item_color,
  rowOptData,
  page,
  view_details,
  amendFunc,
  setOpendModalDetails,
  setSelectedData,
  table_type,
  className,
}) => {
  // Set User Role
  const [userId, setUserId] = useState("");
  // UseEffect
  useEffect(() => {
    if (localStorage.getItem("log_cred"))
    {
      setUserId(JSON.parse(localStorage.getItem("log_cred")).user_id);
    }
  }, []);

  return (
    <>
      {type === "rowMain" && (
        <DataTableRow>
          <Link to={rowLink}>
            <div className="user-card">
              {avatar && (
                <UserAvatar
                  // theme={item.avatarBg}
                  text={findUpper(`${item.first_name} ${item.surname}`)}
                  image={item.profile_img}
                ></UserAvatar>
              )}
              <div className="user-info">
                <span className="tb-lead">
                  {table_type === app_consts.BRANCH_LOCATION_LIST ? (
                    <>
                      {item.branch_area
                        ? item.branch_area.length > 20
                          ? `${item.branch_area.substring(0, 20)}...`
                          : item.branch_area
                        : ""}
                    </>
                  ) : table_type === app_consts.PRODUCT_LIST ? (
                    <>
                      {item.product_name
                        ? item.product_name.length > 20
                          ? `${item.product_name.substring(0, 20)}...`
                          : item.product_name
                        : ""}
                    </>
                  ) : table_type === app_consts.PRODUCT_TYPE_LIST ? (
                    <>
                      {item.product_type
                        ? item.product_type.length > 20
                          ? `${item.product_type.substring(0, 20)}...`
                          : item.product_type
                        : ""}
                    </>
                  ) : table_type === app_consts.UNIT_MEASUREMENT_LIST ? (
                    <>
                      {item.unit_measurement_full_name
                        ? item.unit_measurement_full_name.length > 20
                          ? `${item.unit_measurement_full_name.substring(0, 20)}...`
                          : item.unit_measurement_full_name
                        : ""}
                    </>
                  ) : table_type === app_consts.EMPLOYEE_LIST_TYPE ? (
                    <>
                      {item?.user_account?.unique_identification_number
                        ? item?.user_account?.unique_identification_number.length > 20
                          ? `${item?.user_account?.unique_identification_number.substring(0, 20)}...`
                          : item?.user_account?.unique_identification_number
                        : ""}
                    </>
                  ) :
                    // Goods Received
                    table_type === app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST || table_type === app_consts.CLIENT_GOODS_RECEIVED_LIST ? (
                      <>
                        {item?.goods_received?.goods_received_ref_number
                          ? item?.goods_received?.goods_received_ref_number.length > 20
                            ? `${item?.goods_received?.goods_received_ref_number.substring(0, 20)}...`
                            : item?.goods_received?.goods_received_ref_number
                          : ""}
                      </>
                    ) : table_type === app_consts.WAREHOUSE_INVENTORY_LIST ? (
                      <>
                        {item?.product_name
                          ? item?.product_name.length > 20
                            ? `${item?.product_name.substring(0, 20)}...`
                            : item?.product_name
                          : ""}
                      </>
                    )
                      // Delivery Note List
                      :
                      table_type === app_consts.DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST || table_type === app_consts.CLIENT_DELIVERY_NOTES_LIST ? (
                        <>
                          {item?.delivery_note?.delivery_note_ref_number
                            ? item?.delivery_note?.delivery_note_ref_number.length > 20
                              ? `${item?.delivery_note?.delivery_note_ref_number.substring(0, 20)}...`
                              : item?.delivery_note?.delivery_note_ref_number
                            : ""}
                        </>
                      ) : // User Account List
                        table_type === app_consts.CUSTOMER_USER_ACCOUNT_LIST || table_type === app_consts.COMPANY_USER_ACCOUNT_LIST || table_type === app_consts.TRUCK_DRIVER_USER_ACCOUNT_LIST ? (
                          <>
                            {item?.user_account?.username
                              ? item?.user_account?.username.length > 20
                                ? `${item?.user_account?.username.substring(0, 20)}...`
                                : item?.user_account?.username
                              : ""}
                          </>
                        ) : // Loading Order List
                          table_type === app_consts.DEPARTMENT_AND_ROLES_LIST ? (
                            <>
                              {item?.employee_role?.role_name
                                ? item?.employee_role?.role_name > 20
                                  ? `${item?.employee_role?.role_name.substring(0, 20)}...`
                                  : item?.employee_role?.role_name
                                : ""}
                            </>
                          ) : // Employee Roles and Departments List
                            table_type === app_consts.LOADING_ORDERS_LIST ? (
                              <>
                                {item?.loading_order_ref_no
                                  ? item?.loading_order_ref_no > 20
                                    ? `${item?.loading_order_ref_no.substring(0, 20)}...`
                                    : item?.loading_order_ref_no
                                  : ""}
                              </>
                            ) : (
                              <>{`${item.first_name} ${item.surname}`} </>
                            )}
                  <span
                    className={`dot dot-${item.status_id === 1 ? "success" : item.status_id === 2 ? "warning" : "danger"
                      } d-md-none ms-1`}
                  ></span>
                </span>
                {/* <span>{item.email_address}</span> */}
              </div>
            </div>
          </Link>
        </DataTableRow>
      )}
      {type === "rowNormal" && (
        <DataTableRow size="md" className={className ? className : ""}>
          <span>{item}</span>
        </DataTableRow>
      )}
      {type === "rowStatus" && (
        <DataTableRow size="md">
          <span className={`tb-status text-${item_color}`}>{item}</span>
        </DataTableRow>
      )}
      {type === "rowOptions" && (
        <>
          <DataTableRow className="nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              {table_type === app_consts.BRANCH_LOCATION_LIST ? (
                <>
                  <BranchLocationOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : table_type === app_consts.PRODUCT_LIST ? (
                <>
                  <ProductOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : table_type === app_consts.PRODUCT_TYPE_LIST ? (
                <>
                  <ProductTypeOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : table_type === app_consts.UNIT_MEASUREMENT_LIST ? (
                <>
                  <UnitMeasurementOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    rowOptData={rowOptData}
                    amendFunc={amendFunc}
                  />
                </>
              ) : table_type === app_consts.CLIENT_GOODS_RECEIVED_LIST ? (
                <>
                  <ClientGoodsReceivedOptionsData
                    item={item}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    view_details
                  />
                </>
              ) :
                // Warehouse Inventory List
                table_type === app_consts.WAREHOUSE_INVENTORY_LIST ? (
                  <>
                    <WarehouseInventoryListOptionsData
                      item={item}
                      setOpendModalDetails={setOpendModalDetails}
                      setSelectedData={setSelectedData}
                      view_details
                    />
                  </>
                ) :
                  // Goods Recieved List
                  table_type === app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST ? (
                    <>
                      <GoodsReceivedOptionsData
                        item={item}
                        setOpendModalDetails={setOpendModalDetails}
                        setSelectedData={setSelectedData}
                        view_details
                      />
                    </>
                  ) :
                    // Employee Department and Roles List
                    table_type === app_consts.DEPARTMENT_AND_ROLES_LIST ? (
                      <>
                        <EmpDepRoleData
                          item={item}
                          setOpendModalDetails={setOpendModalDetails}
                          setSelectedData={setSelectedData}
                          view_details
                        />
                      </>
                    ) : (
                      <>
                        <UserListData
                          item={item}
                          setOpendModalDetails={setOpendModalDetails}
                          setSelectedData={setSelectedData}
                          view_details
                          rowOptData={rowOptData}
                          userId={userId}
                          amendFunc={amendFunc}
                        />
                      </>
                    )}
            </ul>
          </DataTableRow>
        </>
      )}
    </>
  );
};

export default TableRow;
