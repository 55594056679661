import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  BackTo,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Component";
import { Alert, Row, Spinner } from "reactstrap";
import { OptionCardLink } from "../dashboard_pages/components/cards/CardLinks";
import { useSelector } from "react-redux";
import * as app_consts from "../../constants/react_app_consts";
import { useDispatch } from "react-redux";
import {
  fail_send_otp_option,
  loading_send_otp_option,
  reset_send_otp_option,
  success_send_otp_option,
} from "../../reducers/website_reducers/auth/send_otp_option_Slice";
import { SEND_OTP_AUTH_API } from "../../api/api_data";
import { SENT_OTP_AUTH_ERR_MSG, SENT_OTP_AUTH_SUCESS_MSG } from "../../constants/success_error_messages_consts";
import { website_actions_helper } from "../../actions/actions_helper";

const initialState = { username: "", send_otp_type: "" };
const initialHideState = { type: "", status: false };

const SendOTP = ({ user_details }) => {
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [page_data, setPageData] = useState(user_details);
  const [hideOption, setHideOption] = useState(initialHideState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    setError("");
    setHideOption({ ...hideOption, ["type"]: formData.send_otp_type.toLowerCase(), ["status"]: true });
    // Run Request Through Website Actions' Helper
    dispatch(
      website_actions_helper(
        loading_send_otp_option,
        success_send_otp_option,
        fail_send_otp_option,
        SENT_OTP_AUTH_ERR_MSG,
        SENT_OTP_AUTH_SUCESS_MSG,
        app_consts.POST,
        SEND_OTP_AUTH_API,
        formData
      )
    );
  };

  // useSelector for Send OTP Option
  const send_otp_req = useSelector((state) => state.send_otp_option.send_otp_option);
  // useEffect for Send OTP Option
  useEffect(() => {
    if (send_otp_req.status !== app_consts.IDLE)
    {
      if (send_otp_req.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (send_otp_req.status === app_consts.FAIL)
        {
          setError(send_otp_req.message);
          setHideOption(initialHideState);
          dispatch(reset_send_otp_option());
        } else if (send_otp_req.status === app_consts.SUCCESS)
        {
          navigate(`/auth/verify_otp/${user_details.username}`);
        }
      }
    }
  }, [send_otp_req]);

  // UseEffect for Initial Page Launch
  useEffect(() => {
    setFormData({ ...formData, ["username"]: user_details.username });
    setPageData(user_details);
  }, []);

  // Listen to FormData
  useEffect(() => {
    if (formData?.send_otp_type === "send_otp_mobile")
    {
      setError("This Feature is Unavailable");
    }
    else
    {
      setError("");
    }
  }, [formData?.send_otp_type]);

  return (
    <>
      <Head title="Send OTP" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-website" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-website" src={Logo} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BackTo link="/auth/signin" icon="arrow-left">
                Change Username
              </BackTo>
              <BlockTitle tag="h4">
                Sign-In{" "}
                <strong className="text-primary small">
                  {page_data.first_name ? ` / ${page_data.first_name} ${page_data.surname}` : ""}
                </strong>
              </BlockTitle>
              <BlockDes>
                <p>Select Where to Receive your One Time Login Passcode.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <form
            className="is-alter"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {/* Options to Select Where the OTP Should be Sent */}
            <Row>
              {page_data ? (
                <>
                  {/* For Phone Number */}
                  {page_data.phone_number &&
                    (hideOption.status ? hideOption.type === app_consts.SEND_OTP_MOBILE.toLowerCase() : true) && (
                      <OptionCardLink
                        linkHeading="Mobile Number"
                        link_name={app_consts.SEND_OTP_MOBILE.toLowerCase()}
                        linkValue={`+${page_data.phone_number}`}
                        setFormData={setFormData}
                        formData={formData}
                      />
                    )}
                  {/* For Email Address */}
                  {page_data.email_address &&
                    (hideOption.status ? hideOption.type === app_consts.SEND_OTP_EMAIL.toLowerCase() : true) && (
                      <OptionCardLink
                        linkHeading="Email Address"
                        link_name={app_consts.SEND_OTP_EMAIL.toLowerCase()}
                        linkValue={page_data.email_address}
                        setFormData={setFormData}
                        setHideOption={setHideOption}
                        formData={formData}
                      />
                    )}
                </>
              ) : (
                ""
              )}
            </Row>
            <div className="form-group">
              <Button
                onClick={handleSubmit}
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
                disabled={loading ? loading : errorVal ? errorVal : !formData.send_otp_type}
              >
                {loading ? <Spinner size="sm" color="light" /> : "Send Passcode"}
              </Button>
            </div>
          </form>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default SendOTP;
