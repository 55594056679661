import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    amend_delivery_note_status: { status: "idle" },
};

export const amend_delivery_note_status_Slice = createSlice({
    name: "amend_delivery_note_status",
    initialState,
    reducers: {
        success_amend_delivery_note_status: (state, action) => {
            state.amend_delivery_note_status = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_amend_delivery_note_status: (state, action) => {
            state.amend_delivery_note_status = { status: app_consts.FAIL, message: action.payload };
        },
        reset_amend_delivery_note_status: (state) => {
            state.amend_delivery_note_status = { status: app_consts.IDLE };
        },
        loading_amend_delivery_note_status: (state) => {
            state.amend_delivery_note_status = { status: app_consts.LOADING };
        },
    }
});

export const { success_amend_delivery_note_status, fail_amend_delivery_note_status, reset_amend_delivery_note_status, loading_amend_delivery_note_status } = amend_delivery_note_status_Slice.actions;

export default amend_delivery_note_status_Slice.reducer;