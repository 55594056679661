import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    book_goods_to_inventory: { status: "idle" },
};

export const book_goods_to_inventory_Slice = createSlice({
    name: "book_goods_to_inventory",
    initialState,
    reducers: {
        success_book_goods_to_inventory: (state, action) => {
            state.book_goods_to_inventory = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_book_goods_to_inventory: (state, action) => {
            state.book_goods_to_inventory = { status: app_consts.FAIL, message: action.payload };
        },
        reset_book_goods_to_inventory: (state) => {
            state.book_goods_to_inventory = { status: app_consts.IDLE };
        },
        loading_book_goods_to_inventory: (state) => {
            state.book_goods_to_inventory = { status: app_consts.LOADING };
        },
    }
});

export const { success_book_goods_to_inventory, fail_book_goods_to_inventory, reset_book_goods_to_inventory, loading_book_goods_to_inventory } = book_goods_to_inventory_Slice.actions;

export default book_goods_to_inventory_Slice.reducer;