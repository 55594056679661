import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    confirm_delivery_note_delivered: { status: "idle" },
};

export const confirm_delivery_note_delivered_Slice = createSlice({
    name: "confirm_delivery_note_delivered",
    initialState,
    reducers: {
        success_confirm_delivery_note_delivered: (state, action) => {
            state.confirm_delivery_note_delivered = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_confirm_delivery_note_delivered: (state, action) => {
            state.confirm_delivery_note_delivered = { status: app_consts.FAIL, message: action.payload };
        },
        reset_confirm_delivery_note_delivered: (state) => {
            state.confirm_delivery_note_delivered = { status: app_consts.IDLE };
        },
        loading_confirm_delivery_note_delivered: (state) => {
            state.confirm_delivery_note_delivered = { status: app_consts.LOADING };
        },
    }
});

export const { success_confirm_delivery_note_delivered, fail_confirm_delivery_note_delivered, reset_confirm_delivery_note_delivered, loading_confirm_delivery_note_delivered } = confirm_delivery_note_delivered_Slice.actions;

export default confirm_delivery_note_delivered_Slice.reducer;