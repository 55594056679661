import * as app_consts from "../../constants/react_app_consts";
import { findModuleAction } from "../../utils/functionalUtils";

let account_actions;

if (localStorage.getItem("log_cred"))
{
  account_actions = JSON.parse(localStorage.getItem('log_cred'))?.user_actions;
}

const mainSubMenuData = [
  {
    text: "Goods Received",
    link: "/dashboard/goods_received",
    icon: "file-docs",
  },
  {
    text: "Delivery Notes",
    link: "/dashboard/delivery_notes",
    icon: "file-docs",
  },
];

if (account_actions)
{
  account_actions?.map((module) => {
    if (module.module_name === app_consts.WAREHOUSE_MANAGEMENT) 
    {
      if (findModuleAction(app_consts.VIEW_LOADING_ORDERS, module) || findModuleAction(app_consts.CREATE_LOADING_ORDER, module))
      {
        mainSubMenuData.push(
          {
            text: "Loading Orders",
            icon: "card-view",
            link: "/dashboard/loading_orders",
          },
        );
      }
    }
  });
}


// Default Menu Data
let menu_data = [
  {
    heading: "Warehouse Management",
  },
  {
    text: "Weigh Bridge Tickets",
    icon: "file-docs",
    link: "/dashboard",
    active: true,
    subMenu: mainSubMenuData,
  },
];

if (account_actions)
{

  // Define the custom sorting order
  const sortOrder = ["warehouse_management", "onboarding", "user_management", "system_management"];

  // Custom sorting function
  const sortedOrder = (a, b) => {
    return sortOrder.indexOf(a.module_name) - sortOrder.indexOf(b.module_name);
  };

  account_actions.sort(sortedOrder);

  account_actions?.map((module) => {

    // Warehouse Management
    if (module.module_name === app_consts.WAREHOUSE_MANAGEMENT) 
    {
      if (findModuleAction(app_consts.VIEW_WAREHOUSE_INVENTORY, module))
      {
        menu_data.push(
          {
            text: "Warehouse Inventory",
            icon: "card-view",
            link: "/dashboard/warehouse_inventory",
          },
        );
      }
    }

    // User Onboarding
    if (module.module_name === app_consts.ONBOARDING) 
    {
      if (findModuleAction(app_consts.ONBOARD_USER, module) || findModuleAction(app_consts.ONBOARD_COMPANY, module))
      {
        menu_data.push(
          { heading: "OnBoarding", }
        );
        menu_data.push(
          {
            text: "User Onboarding",
            icon: "users",
            link: "/dashboard/onboarding/user",
          },
        );
      }
    }

    // User Management
    if (module.module_name === app_consts.USER_MANAGEMENT) 
    {
      if (findModuleAction(app_consts.VIEW_USERS, module) || findModuleAction(app_consts.VIEW_USER_ACCOUNTS, module))
      {
        menu_data.push(
          { heading: "System Users", }
        );

        let subMenu = [];
        // View Users
        if (findModuleAction(app_consts.VIEW_USERS, module))
        {
          subMenu.push(
            {
              text: "User List",
              link: "/dashboard/user",
              icon: "user-list",
            },);
        }

        // View User Accounts
        if (findModuleAction(app_consts.VIEW_USER_ACCOUNTS, module))
        {
          subMenu.push(
            {
              text: "User Account List",
              link: "/dashboard/user/accounts",
              icon: "user-check-fill",
            },);
        }

        menu_data.push({
          icon: "users",
          text: "Users",
          active: false,
          subMenu: subMenu
        });
      }
    }

    // System Management
    if (module.module_name === app_consts.SYSTEM_MANAGEMENT) 
    {
      let subMenu = [{ text: "Employees", link: "/dashboard/admin/employees", icon: "user-alt", }];

      // View Departments And Roles
      if (findModuleAction(app_consts.VIEW_DEPARTMENTS_AND_ROLES, module))
      {
        subMenu.push({
          text: "Departments and Roles",
          link: "/dashboard/admin/departments_and_roles",
          icon: "package-fill",
        },);
      }

      menu_data.push(
        { heading: "Admin Panel" },
        { icon: "account-setting", text: "Employees", active: false, link: "/dashboard/admin", subMenu: subMenu, }
      );

      // Branch Locations ----
      module?.module_actions?.map((action) => {
        if (action.module_action_name === app_consts.VIEW_BRANCH_LOCATIONS)
        {
          menu_data.push({ icon: "building", text: "Branch Locations", active: false, link: "/dashboard/admin", subMenu: [{ text: "Branch Locations", link: "/dashboard/admin/branch_locations", icon: "building-fill", },], });
        }
      });

      // Products ----
      if (findModuleAction(app_consts.VIEW_PRODUCT, module) || findModuleAction(app_consts.VIEW_PRODUCT_TYPE, module) || findModuleAction(app_consts.VIEW_UNIT_MEASUREMENT, module))
      {
        let subMenu = [];
        // View Product
        if (findModuleAction(app_consts.VIEW_PRODUCT, module))
        {
          subMenu.push({
            text: "Products",
            link: "/dashboard/admin/products",
            icon: "package-fill",
          },);
        }

        // View Product Type
        if (findModuleAction(app_consts.VIEW_PRODUCT_TYPE, module))
        {
          subMenu.push({
            text: "Product Types",
            link: "/dashboard/admin/product_types",
            icon: "package-fill",
          },);
        }

        // View Unit Measurement
        if (findModuleAction(app_consts.VIEW_UNIT_MEASUREMENT, module))
        {
          subMenu.push({
            text: "Unit Measurements",
            link: "/dashboard/admin/unit_measurements",
            icon: "meter",
          },);
        }

        menu_data.push({
          icon: "note-add",
          text: "Products",
          link: "/dashboard/admin",
          active: false,
          subMenu: subMenu
        });
      }
    }
  });

}


const menu = [

  {
    heading: "",
  },

];
export default menu_data;
