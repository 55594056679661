import React, { useEffect, useState } from "react";
import { Button, Col, Icon, Row } from "../../../../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import TextInput from "../../../../../components/inputs/TextInput";
import ListText from "../../../text/ListText";
import { employee_dashboard_actions_helper } from "../../../../../../actions/actions_helper";
import { ADD_UNIT_MEASUREMENT } from "../../../../../../api/api_data";
import {
  fail_add_unit_measurement,
  loading_add_unit_measurement,
  reset_add_unit_measurement,
  success_add_unit_measurement,
} from "../../../../../../reducers/dashboard_reducers/unit_measurement/add_unit_measurement_Slice";
import {
  ADD_UNIT_MEASUREMENT_ACCESS_DENIED_MSG,
  ADD_UNIT_MEASUREMENT_ERR_MSG,
  ADD_UNIT_MEASUREMENT_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../../../utils/Utils";

const insitialState = { unit_measurement_full_name: "", unit_measurement_abbrev: "" };

const AddUnitMeasurementModal = ({ successFunction, openModal, setModalLoading }) => {
  const [step, setStep] = useState(1);
  const [errorVal, setError] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [formData, setFormData] = useState(insitialState);
  const dispatch = useDispatch();

  const prevStep = () => {
    setStep(1);
  };

  // Handle Preview
  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  const handleSubmit = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_add_unit_measurement,
        success_add_unit_measurement,
        fail_add_unit_measurement,
        ADD_UNIT_MEASUREMENT_ERR_MSG,
        ADD_UNIT_MEASUREMENT_SUCCESS_MSG,
        app_consts.POST,
        ADD_UNIT_MEASUREMENT,
        formData,
        ADD_UNIT_MEASUREMENT_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Add Unit Measurement State
  const add_unit_measurement = useSelector((state) => state.add_unit_measurement.add_unit_measurement);
  // UseEffect
  useEffect(() => {
    if (add_unit_measurement.status !== app_consts.IDLE)
    {
      if (add_unit_measurement.status === app_consts.LOADING)
      {
        setAddLoading(true);
        setModalLoading(true);
      } else
      {
        setAddLoading(false);
        setModalLoading(false);
        if (add_unit_measurement.status === app_consts.FAIL)
        {
          setError(add_unit_measurement.message);
          prevStep();
        } else if (add_unit_measurement.status === app_consts.SUCCESS)
        {
          messageToast(`${formData.unit_measurement_full_name} has been Successfully Added.`, app_consts.SUCCESS);
          // Close Modal and Other Embedded Functions if Need Be
          successFunction();
        }
        dispatch(reset_add_unit_measurement());
      }
    }
  }, [add_unit_measurement]);

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">Add Unit Measurement Type Form</h5>
        {step === 2 && (
          <div
            className="nk-block-head-sub mb-2"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            <Icon name="arrow-left" />
            <span>back</span>
          </div>
        )}
      </div>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      {step === 1 ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Row className="g-4">
              <TextInput
                label="Unit Measurement Full Name"
                required
                type="text"
                id_val="producunit_measurement_full_namet_type"
                maxlength="50"
                value={formData.unit_measurement_full_name}
                colSize="10"
                name="unit_measurement_full_name"
                handleChange={(e) => {
                  setFormData({ ...formData, ["unit_measurement_full_name"]: e.target.value });
                }}
              />
              <TextInput
                label="Unit Measurement Abbreviation"
                required
                type="text"
                id_val="unit_measurement_abbrev"
                maxlength="10"
                value={formData.unit_measurement_abbrev}
                colSize="10"
                name="unit_measurement_abbrev"
                handleChange={(e) => {
                  setFormData({ ...formData, ["unit_measurement_abbrev"]: e.target.value });
                }}
              />
              <Col xl="12" className="d-flex justify-content-center">
                <Button
                  onClick={handlePreview}
                  size="lg"
                  type="submit"
                  color="primary"
                  disabled={!(formData.unit_measurement_abbrev && formData.unit_measurement_full_name)}
                >
                  Preview Unit Measurement and Add
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : step === 2 ? (
        <>
          <ListText
            dataItem="data-item mySmall"
            label="Unit Measurement Full Name"
            value={formData.unit_measurement_full_name}
          />
          <ListText
            dataItem="data-item mySmall"
            label="Unit Measurement Abbreviation"
            value={formData.unit_measurement_abbrev}
          />
          <Col xl="12" className="d-flex justify-content-center mt-4 mr-2">
            <Button onClick={handleSubmit} disabled={addLoading} color="primary">
              {addLoading ? <Spinner size="sm" color="light" /> : "Add Unit Measurement"}
            </Button>
          </Col>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddUnitMeasurementModal;
