import React, { useState } from 'react';
import * as app_consts from "../../../constants/react_app_consts";
import PageHeading from '../components/page_heading/PageHeading';
import Content from '../../../layout/content/Content';
import Head from '../../../layout/head/Head';
import WizardComp from '../components/wizard/WizardComp';
import { useParams } from 'react-router';
export const CREATE_PHYSICAL_DELIVERY_NOTE_TICKET = "CREATE_PHYSICAL_DELIVERY_NOTE_TICKET";
export const CREATE_VIRTUAL_DELIVERY_NOTE_TICKET = "CREATE_VIRTUAL_DELIVERY_NOTE_TICKET";

const CreateDeliveryNote = ({ page_type }) => {

    const { delivery_note_ref_number } = useParams();
    const [page, setPage] = useState(page_type);

    return (
        <>
            <Head title={page === app_consts.CREATE_PHYSICAL_DELIVERY_NOTE_TICKET ? `${delivery_note_ref_number ? "Continue Processing" : "Create"} Physical Delivery Note` : page === app_consts.CREATE_VIRTUAL_DELIVERY_NOTE_TICKET ? `${delivery_note_ref_number ? "Continue Processing" : "Create"} Virtual Delivery Note` : ""} />
            <Content>
                <PageHeading
                    title={page === app_consts.CREATE_PHYSICAL_DELIVERY_NOTE_TICKET ? `${delivery_note_ref_number ? "Continue Processing" : "Create"} Physical Delivery Note` : page === app_consts.CREATE_VIRTUAL_DELIVERY_NOTE_TICKET ? `${delivery_note_ref_number ? "Continue Processing" : "Create"} Virtual Delivery Note` : ""}
                    title_addition={delivery_note_ref_number}
                    description={page === app_consts.CREATE_PHYSICAL_DELIVERY_NOTE_TICKET ? "Process a Physical Delivery Note Ticket from your Designated Warehouse." : page === app_consts.CREATE_VIRTUAL_DELIVERY_NOTE_TICKET ? "Process a Virtual Delivery Note Ticket from Virtual Inventory." : ""}
                    backToText={`${delivery_note_ref_number ? `${delivery_note_ref_number} Ticket Details` : "Delivery Note Tickets"}`}
                    backToLink={`/dashboard/delivery_notes${delivery_note_ref_number ? `/${delivery_note_ref_number}` : ""}`}
                />
                {/* Multi-Step Form */}
                {
                    <WizardComp type={page} />
                }
            </Content>
        </>
    );
};

export default CreateDeliveryNote;