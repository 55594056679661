import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_loading_order: { status: "idle" },
};

export const create_loading_order_Slice = createSlice({
    name: "create_loading_order",
    initialState,
    reducers: {
        success_create_loading_order: (state, action) => {
            state.create_loading_order = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_loading_order: (state, action) => {
            state.create_loading_order = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_loading_order: (state) => {
            state.create_loading_order = { status: app_consts.IDLE };
        },
        loading_create_loading_order: (state) => {
            state.create_loading_order = { status: app_consts.LOADING };
        },
    }
});

export const { success_create_loading_order, fail_create_loading_order, reset_create_loading_order, loading_create_loading_order } = create_loading_order_Slice.actions;

export default create_loading_order_Slice.reducer;