import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import TablePageHeading from '../components/page_heading/TablePageHeading';
import * as app_consts from "../../../constants/react_app_consts";
import CardTabs from '../components/cards/CardTabs';
import { Block } from '../../../components/Component';
import TableList from '../components/table/TableList';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { fail_retrieve_user_accounts_list, loading_retrieve_user_accounts_list, reset_retrieve_user_accounts_list, success_retrieve_user_accounts_list } from '../../../reducers/dashboard_reducers/user_accounts/retrieve_user_accounts_list_Slice';
import { RETRIEVE_USER_ACCOUNTS_LIST_EXCEPT_EMPLOYEES_API } from '../../../api/api_data';
import { RETRIEVE_USER_ACCOUNTS_LIST_ACCESS_DENIED_MSG, RETRIEVE_USER_ACCOUNTS_LIST_ERR_MSG, RETRIEVE_USER_ACCOUNTS_LIST_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { useSelector } from 'react-redux';
import { messageToast } from '../../../utils/Utils';
import { Spinner } from 'reactstrap';
import { BiErrorCircle } from 'react-icons/bi';

const type = app_consts.USER_ACCOUNT_LIST;
const customer_account_type = app_consts.CUSTOMER_USER_ACCOUNT_LIST;
const company_account_type = app_consts.COMPANY_USER_ACCOUNT_LIST;

const UserAccountsList = () => {

    const dispatch = useDispatch();

    const [page_data, setPageData] = useState([]);
    const [activeCardTab, setActiveCardTab] = useState("Individual Customers");
    const [dataFetched, setDataFetched] = useState(false);
    const [componentData, setComponentData] = useState([]);
    const [company_list, setCompanyList] = useState([]);
    const [company_data, setCompanyData] = useState([]);
    const [customer_list, setCustomerList] = useState([]);
    const [customer_data, setCustomerData] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [page_type, setPageType] = useState(customer_account_type);
    const [csv_data, setCSVData] = useState([]);


    // UseEffect to Run When Page Opens
    useEffect(() => {
        retrieve_user_list_func();
    }, []);

    // Retrieve Users
    const retrieve_user_list_func = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_retrieve_user_accounts_list,
                success_retrieve_user_accounts_list,
                fail_retrieve_user_accounts_list,
                RETRIEVE_USER_ACCOUNTS_LIST_ERR_MSG,
                RETRIEVE_USER_ACCOUNTS_LIST_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_USER_ACCOUNTS_LIST_EXCEPT_EMPLOYEES_API,
                null,
                RETRIEVE_USER_ACCOUNTS_LIST_ACCESS_DENIED_MSG
            )
        );
    };

    useEffect(() => {
        if (activeCardTab === "Individual Customers")
        {
            setPageType(customer_account_type);
            setCSVData(customer_data);
        }
        else if (activeCardTab === "Companies")
        {
            setPageType(company_account_type);
            setCSVData(company_data);
        }
    }, [activeCardTab]);

    // Listen to User List Reducer
    const retrieve_user_accounts_list = useSelector((state) => state.retrieve_user_accounts_list.retrieve_user_accounts_list);

    useEffect(() => {
        if (retrieve_user_accounts_list.status !== app_consts.IDLE)
        {
            if (retrieve_user_accounts_list.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (retrieve_user_accounts_list.status === app_consts.FAIL)
                {
                    messageToast(retrieve_user_accounts_list.message, app_consts.FAIL);
                    setError(retrieve_user_accounts_list.message);
                } else if (retrieve_user_accounts_list.status === app_consts.SUCCESS)
                {
                    setPageData(retrieve_user_accounts_list.data);
                }
                dispatch(reset_retrieve_user_accounts_list());
            }
        }
    }, [retrieve_user_accounts_list]);

    // Page Data
    useEffect(() => {

        const cust_data = page_data?.filter((data) => data?.user_account?.account_type_id === app_consts.CUSTOMER_ACCOUNT_TYPE);
        const comp_data = page_data?.filter((data) => data?.user_account?.account_type_id === app_consts.COMPANY_ACCOUNT_TYPE);

        setCustomerData(cust_data);
        setCompanyData(comp_data);
        setActiveCardTab("Individual Customers");
        setCSVData(cust_data);

        setCustomerList(cust_data);
        setCompanyList(comp_data);
    }, [page_data]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Individual Customers", icon: "user-circle"
        },
        {
            tabName: "Companies", icon: "building"
        },
    ];

    // Refresh List Function
    const refreshList = () => {
        setError("");
        // Retrieve User list
        retrieve_user_list_func();
        setPageData([]);
    };

    return (
        <>
            <Head title="User Accounts List" />
            <Content>
                <Block>
                    {/* Table Page Heading */}
                    <TablePageHeading
                        title="User Accounts List"
                        description={`Total User Accounts: ${page_data.length}`}
                        refresh={!loading}
                        refreshFunc={refreshList}
                        csv_export={!loading && !error}
                        // add_to_list={componentData.onboard_user}
                        page_type={page_type}
                        pageData={csv_data}
                    // openAddList={openAddListModal}
                    />
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                        <BiErrorCircle className="text-danger fs-3" />
                                        <p>{error}</p>
                                    </div>
                                </>
                                :
                                <>
                                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                    {
                                        activeCardTab === "Individual Customers" && (
                                            <>
                                                <TableList
                                                    // left_head_select
                                                    type={customer_account_type}
                                                    data={customer_list}
                                                    rowOptionsData={componentData}
                                                // amendFunc={amend_Account_Status_modal}
                                                // setOpendModalDetails={setOpendModalDetails}
                                                // setSelectedData={setSelectedUser}
                                                />
                                            </>
                                        )
                                    }
                                    {
                                        activeCardTab === "Companies" && (
                                            <>
                                                <TableList
                                                    // left_head_select
                                                    type={company_account_type}
                                                    data={company_list}
                                                    rowOptionsData={componentData}
                                                // amendFunc={amend_Account_Status_modal}
                                                // setOpendModalDetails={setOpendModalDetails}
                                                // setSelectedData={setSelectedUser}
                                                />
                                            </>
                                        )
                                    }
                                </>
                    }
                </Block>
            </Content>
        </>
    );
};

export default UserAccountsList;