import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
 finish_adding_loading_order_products: { status: "idle" },
};

export const finish_adding_loading_order_products_Slice = createSlice({
 name: "finish_adding_loading_order_products",
 initialState,
 reducers: {
  success_finish_adding_loading_order_products: (state, action) => {
   state.finish_adding_loading_order_products = { status: app_consts.SUCCESS, data: action.payload };
  },
  fail_finish_adding_loading_order_products: (state, action) => {
   state.finish_adding_loading_order_products = { status: app_consts.FAIL, message: action.payload };
  },
  reset_finish_adding_loading_order_products: (state) => {
   state.finish_adding_loading_order_products = { status: app_consts.IDLE };
  },
  loading_finish_adding_loading_order_products: (state) => {
   state.finish_adding_loading_order_products = { status: app_consts.LOADING };
  },
 }
});

export const { success_finish_adding_loading_order_products, fail_finish_adding_loading_order_products, reset_finish_adding_loading_order_products, loading_finish_adding_loading_order_products } = finish_adding_loading_order_products_Slice.actions;

export default finish_adding_loading_order_products_Slice.reducer;