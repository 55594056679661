import axios from "axios";

const API_URL = "https://uagzambia-api-prod.afrocentec.com/api";

// API Extensions
const CLIENT_API_URL = `${API_URL}/customer_users/`;
const COMPANY_API_URL = `${API_URL}/company_users/`;
const EMPLOYEE_DASHBOARD_API_URL = `${API_URL}/emp_dash_users/`;

// Client API Helper
export const makeClientApiRequest = (method, endpoint, data = null, account_type_id) => {
 const headers = {
  Accept: "application/json",
  "Content-Type": "application/json, multipart/form-data",
 };

 if (localStorage.getItem("access"))
 {
  headers["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem("access")).access_token}`;
 }

 let config = {};

 if (account_type_id === 2)
 {
  config = {
   method,
   url: CLIENT_API_URL + endpoint,
   headers,
  };
 }
 else if (account_type_id = 4)
 {
  config = {
   method,
   url: COMPANY_API_URL + endpoint,
   headers,
  };
 }



 if (data)
 {
  if (method === "get")
  {
   config.params = data;
  } else
  {
   config.data = data;
  }
 }

 return axios(config);
};

// Create a function for making API requests
export const makeEmployeeDashboardApiRequest = (method, endpoint, data = null) => {
 const headers = {
  Accept: "application/json",
  "Content-Type": "application/json, multipart/form-data",
 };

 if (localStorage.getItem("access"))
 {
  headers["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem("access")).access_token}`;
 }

 const config = {
  method,
  url: EMPLOYEE_DASHBOARD_API_URL + endpoint,
  headers,
 };

 if (data)
 {
  if (method === "get")
  {
   config.params = data;
  } else
  {
   config.data = data;
  }
 }

 return axios(config);
};

// Create a function for making API requests
export const makeUnAuthApiRequest = (method, endpoint, data = null) => {
 const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
 };
 const config = {
  method,
  url: `${API_URL}/${endpoint}`,
  headers,
 };
 if (data)
 {
  if (method === "get")
  {
   config.params = data;
  } else
  {
   config.data = data;
  }
 }

 return axios(config);
};
