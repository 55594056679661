import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
 retrieve_loading_order_list: { status: "idle" },
};

export const retrieve_loading_order_list_Slice = createSlice({
 name: "retrieve_loading_order_list",
 initialState,
 reducers: {
  success_retrieve_loading_order_list: (state, action) => {
   state.retrieve_loading_order_list = { status: app_consts.SUCCESS, data: action.payload };
  },
  fail_retrieve_loading_order_list: (state, action) => {
   state.retrieve_loading_order_list = { status: app_consts.FAIL, message: action.payload };
  },
  reset_retrieve_loading_order_list: (state) => {
   state.retrieve_loading_order_list = { status: app_consts.IDLE };
  },
  loading_retrieve_loading_order_list: (state) => {
   state.retrieve_loading_order_list = { status: app_consts.LOADING };
  },
 }
});

export const { success_retrieve_loading_order_list, fail_retrieve_loading_order_list, reset_retrieve_loading_order_list, loading_retrieve_loading_order_list } = retrieve_loading_order_list_Slice.actions;

export default retrieve_loading_order_list_Slice.reducer;