import React from 'react';

const ProfileDetailText = ({ text, label, textClassName }) => {
    return (
        <>
            <div className="profile-ud-item">
                <div className="profile-ud wider">
                    <span className="profile-ud-label">{label}</span>
                    <span className={`profile-ud-value ${textClassName}`}>{text}</span>
                </div>
            </div>
        </>
    );
};

export default ProfileDetailText;