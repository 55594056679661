import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import TablePageHeading from "../../components/page_heading/TablePageHeading";
import Content from "../../../../layout/content/Content";
import { Block } from "../../../../components/Component";
import TableList from "../../components/table/TableList";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as app_consts from "../../../../constants/react_app_consts";
import { employee_dashboard_actions_helper } from "../../../../actions/actions_helper";
import {
  fail_unit_measurement_list,
  loading_unit_measurement_list,
  reset_unit_measurement_list,
  success_unit_measurement_list,
} from "../../../../reducers/dashboard_reducers/unit_measurement/unit_measurements_list_Slice";
import {
  AMEND_UNIT_MEASUREMENT_STATUS_ACCESS_DENIED_MSG,
  AMEND_UNIT_MEASUREMENT_STATUS_ERR_MSG,
  AMEND_UNIT_MEASUREMENT_STATUS_SUCCESS_MSG,
  RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG,
  RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../utils/Utils";
import { AMEND_UNIT_MEASUREMENT_STATUS, RETRIEVE_UNIT_MEASUREMENT_LIST } from "../../../../api/api_data";
import ModalDetails from "../../components/modal/modal_details/ModalDetails";
import ModalConfirmCancel from "../../components/modal/modal_confirm_cancel/ModalConfirmCancel";
import {
  fail_amend_unit_measurement_status,
  loading_amend_unit_measurement_status,
  reset_amend_unit_measurement_status,
  success_amend_unit_measurement_status,
} from "../../../../reducers/dashboard_reducers/unit_measurement/amend_unit_measurement_status_Slice";
import ModalAdd from "../../components/modal/modal_add/ModalAdd";
import { findModuleAction } from "../../../../utils/functionalUtils";
import { Spinner } from "reactstrap";
import { BiErrorCircle } from "react-icons/bi";

const type = app_consts.UNIT_MEASUREMENT_LIST;
const initial_actions_data = { add_unit_measurement: false, amend_unit_measurement_status: false };
const initial_amend_user_status = { unit_measurement_id: "", account_status_id: "" };

const UnitMeasurementList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page_data, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const [openModalDetails, setOpendModalDetails] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [acc_status, setAccStatus] = useState("");
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [amendStatusFormData, setAmendStatusFormData] = useState(initial_amend_user_status);
  const [detail, setDetail] = useState([]);
  const [errAmendAccStat, setErrAmendAccStat] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [account_actions, setAccountActions] = useState([]);
  const [error, setError] = useState("");

  //Retrieve Unit Measurement List on Page Load
  useEffect(() => {
    retrieve_unit_measurements_list();
    if (localStorage.getItem("log_cred"))
    {
      setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
    }
  }, []);

  // Listen to Changes In Account Actions
  useEffect(() => {
    if (account_actions)
    {
      let actions_data = initial_actions_data;
      account_actions?.map((module) => {
        if (module.module_name === app_consts.SYSTEM_MANAGEMENT) 
        {
          // Actions Data
          if (findModuleAction(app_consts.ADD_UNIT_MEASUREMENT, module))
          {
            actions_data.add_unit_measurement = true;
          }
          if (findModuleAction(app_consts.AMEND_UNIT_MEASUREMENT_STATUS, module))
          {
            actions_data.amend_unit_measurement_status = true;
          }
        }
      });
      setComponentData(actions_data);
    }
  }, [account_actions]);

  // Retrieve Unit Measurement list
  const retrieve_unit_measurements_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_unit_measurement_list,
        success_unit_measurement_list,
        fail_unit_measurement_list,
        RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG,
        RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_UNIT_MEASUREMENT_LIST,
        null,
        RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  //Listen to State of Unit Measurement List
  const unit_measurement_list = useSelector((state) => state.unit_measurement_list.unit_measurement_list);

  useEffect(() => {
    if (unit_measurement_list.status !== app_consts.IDLE)
    {
      if (unit_measurement_list.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (unit_measurement_list.status === app_consts.FAIL)
        {
          setError(unit_measurement_list.message, app_consts.FAIL);
          messageToast(unit_measurement_list.message, app_consts.FAIL);
        } else if (unit_measurement_list.status === app_consts.SUCCESS)
        {
          setPageData(unit_measurement_list.data);
        }
        dispatch(reset_unit_measurement_list());
      }
    }
  }, [unit_measurement_list]);

  // RefreshList Function
  const refreshList = () => {
    retrieve_unit_measurements_list();
    setLoading(true);
    setPageData([]);
  };

  // Function to Amend Product Status
  const amend_Account_Status_modal = (unit_measurement_id, account_status_id, account_status) => {
    setAccStatus(account_status);
    setOpenConfirmCancelModal(true);
    setSelectedData(unit_measurement_id);
    setAmendStatusFormData({
      ...amendStatusFormData,
      ["unit_measurement_id"]: unit_measurement_id,
      ["account_status_id"]: account_status_id,
    });
  };

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedData)
    {
      setDetail(filterSpecificUnitMeasurement(selectedData));
    }
  }, [selectedData]);

  // Filter Specific Unit Measurement
  const filterSpecificUnitMeasurement = (selectedData) => {
    const filtereredUnitMeasurement = page_data?.filter((data) => data.unit_measurement_id === selectedData);
    return filtereredUnitMeasurement[0];
  };

  // Function to Amend Unit Measurement Status
  const amend_Account_Status = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_amend_unit_measurement_status,
        success_amend_unit_measurement_status,
        fail_amend_unit_measurement_status,
        AMEND_UNIT_MEASUREMENT_STATUS_ERR_MSG,
        AMEND_UNIT_MEASUREMENT_STATUS_SUCCESS_MSG,
        app_consts.POST,
        AMEND_UNIT_MEASUREMENT_STATUS,
        amendStatusFormData,
        AMEND_UNIT_MEASUREMENT_STATUS_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Amend Unit Measurement Status Reducer
  const amend_unit_measurement_status = useSelector(
    (state) => state.amend_unit_measurement_status.amend_unit_measurement_status
  );
  // UseEffect for Amend Unit Measurement Status
  useEffect(() => {
    if (amend_unit_measurement_status.status !== app_consts.IDLE)
    {
      if (amend_unit_measurement_status.status !== app_consts.LOADING)
      {
        if (amend_unit_measurement_status.status === app_consts.FAIL)
        {
          setErrAmendAccStat(amend_unit_measurement_status.message);
        } else if (amend_unit_measurement_status.status === app_consts.SUCCESS)
        {
          messageToast(
            `${detail && `${detail.unit_measurement_full_name}'s `} Status has been Successfully Amended.`,
            app_consts.SUCCESS
          );
          setAccStatus("");
          setDetail([]);
          refreshList();
          setSelectedData("");
          setErrAmendAccStat("");
          setOpenConfirmCancelModal(false);
        }
        dispatch(reset_amend_unit_measurement_status());
      }
    }
  }, [amend_unit_measurement_status]);

  // Open Modal to Add to LIst
  const openAddList = () => {
    setOpenAddModal(true);
  };

  // Success Function for Adding Unit Measurement Via Modal
  const successAddFunc = () => {
    setOpenAddModal(false);
    refreshList();
  };

  return (
    <>
      <Head title="Unit Measurement List" />
      <Content>
        <TablePageHeading
          title="Unit Measurement List"
          description={`Total Unit Measurements: ${page_data.length}`}
          refresh={!loading}
          refreshFunc={refreshList}
          csv_export={!loading && !error}
          openAddList={openAddList}
          add_to_list={loading || error ? "" : componentData.add_unit_measurement}
          //   page_type={type}
          pageData={page_data}
        />
        <Block>
          {
            loading ?
              <>
                <div className='w-full p-5 d-flex justify-center items-center'>
                  <Spinner />
                </div>
              </>
              :
              error && error.length > 0 ?
                <>
                  <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                    <BiErrorCircle className="text-danger fs-3" />
                    <p>{error}</p>
                  </div>
                </>
                :
                <>
                  <TableList
                    type={type}
                    data={page_data}
                    rowOptionsData={componentData}
                    amendFunc={amend_Account_Status_modal}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                  />
                  {/* Add Modal */}
                  <ModalAdd
                    openModal={openAddModal}
                    setOpenModal={setOpenAddModal}
                    modalTitle="Add Unit Measurement"
                    modalDescription="Please Complete this form to Add a Unit Measurement"
                    type={type}
                    size="md"
                    succFunc={successAddFunc}
                  />
                  {/* Yes or No Modal */}
                  <ModalConfirmCancel
                    openModal={openConfirmCancelModal}
                    setOpenModal={setOpenConfirmCancelModal}
                    modalTitle={`${acc_status} ${detail && `${detail.unit_measurement_full_name}`}?`}
                    modalDescription={`Are you sure that you want to ${acc_status} ${detail.unit_measurement_full_name}?`}
                    buttonConfirm="Confirm"
                    buttonCancel="Cancel"
                    succFunc={amend_Account_Status}
                    errAmend={errAmendAccStat}
                    setErrAmend={setErrAmendAccStat}
                  />
                  {/* Modal For Viewing Product Type Details */}
                  <ModalDetails
                    modalOpen={openModalDetails}
                    setModalOpen={setOpendModalDetails}
                    modalTitle={`${detail && `${detail.unit_measurement_full_name}'s Details`}`}
                    modalType={app_consts.UNIT_MEASUREMENT_LIST}
                    modalData={detail}
                  />
                </>
          }
        </Block>
      </Content>
    </>
  );
};

export default UnitMeasurementList;
