import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retrieve_goods_received_weigh_bridge_tickets: { status: "idle" },
};

export const retrieve_goods_received_weigh_bridge_tickets_Slice = createSlice({
    name: "retrieve_goods_received_weigh_bridge_tickets",
    initialState,
    reducers: {
        success_retrieve_goods_received_weigh_bridge_tickets: (state, action) => {
            state.retrieve_goods_received_weigh_bridge_tickets = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_goods_received_weigh_bridge_tickets: (state, action) => {
            state.retrieve_goods_received_weigh_bridge_tickets = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_goods_received_weigh_bridge_tickets: (state) => {
            state.retrieve_goods_received_weigh_bridge_tickets = { status: app_consts.IDLE };
        },
        loading_retrieve_goods_received_weigh_bridge_tickets: (state) => {
            state.retrieve_goods_received_weigh_bridge_tickets = { status: app_consts.LOADING };
        },
    }
});

export const { success_retrieve_goods_received_weigh_bridge_tickets, fail_retrieve_goods_received_weigh_bridge_tickets, reset_retrieve_goods_received_weigh_bridge_tickets, loading_retrieve_goods_received_weigh_bridge_tickets } = retrieve_goods_received_weigh_bridge_tickets_Slice.actions;

export default retrieve_goods_received_weigh_bridge_tickets_Slice.reducer;