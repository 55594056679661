import React, { useEffect, useState } from 'react';
// import Logo from "../../../../layout/logo/Logo";
import { Button, Icon } from '../../../../components/Component';
import moment from 'moment';
import Logo from "../../../../images/logo.png";
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import LatoRegular from "../../../../utils/fonts/Lato-Regular.ttf";
import LatoBold from '../../../../utils/fonts/Lato-Bold.ttf';

const DeliveryNotePDF = ({ page_data }) => {

    const [pdf_data, setPDFData] = useState(page_data);
    const [total_mt_cap, setTotalMTCap] = useState(0);
    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    useEffect(() => {
        setPDFData(page_data);
        const totalMtCapacity = pdf_data?.product_list?.reduce((total, product) => {
            return total + parseFloat(product?.total_capacity_in_mt || 0);
        }, 0).toFixed(2);
        setTotalMTCap(totalMtCapacity);
    }, [page_data]);

    useEffect(() => {
    }, [pdf_data]);

    return (
        <>  {
            pdf_data &&
            (
                <>
                    <Document /* title={`${pdf_data?.goods_received?.goods_received_ref_number} Goods Received Number`} */>
                        {/* <div className="invoice-action">
                         <Link to={`${process.env.PUBLIC_URL}/invoice-print/${user.id}`} target="_blank"> 
                        <Button size="lg" color="primary" outline className="btn-icon btn-white btn-dim">
                            <Icon name="printer-fill"></Icon>
                        </Button>
                         </Link> 
                    </div> */}
                        <Page size="A4" style={{
                            flexDirection: "row",
                            backgroundColor: "rgba(0,0,0,0)",
                            height: "100%",
                            width: "100%",
                            fontFamily: "Lato"
                        }}
                            orientation="landscape"
                        >

                            <View style={{ width: "100%", padding: 5 }}>
                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <View style={{ width: 150 }}>
                                            <Image src={Logo} />
                                        </View>
                                        <View style={{ fontWeight: 600, fontSize: 12, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                    <Text style={{ marginLeft: 2, marginBottom: 2, marginTop: 2 }}>Plot 10111 off Mumbwa Road, Chinika Area</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                    <Text style={{ marginLeft: 2, marginBottom: 2, marginTop: 2 }}>P.O. Box 30203, Lusaka, Zambia.</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                    <Text style={{ marginLeft: 2 }}>Mobile:</Text>
                                                    <Text style={{ marginLeft: 2 }}>+260 764 299 293</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                    <Text style={{ marginLeft: 2 }}>Email:</Text>
                                                    <Text style={{ marginLeft: 2 }}>office@iagzambia.com</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ paddingRight: 10 }}>
                                        <Text style={{ backgroundColor: "#2c3782", padding: 10, color: "white", border: "1px solid", borderRadius: 12, fontSize: 24, fontWeight: 700, marginRight: 20 }} >DELIVERY NOTE</Text>
                                        <View style={{ display: "flex", flexDirection: "column" }}>

                                            <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 4 }}>
                                                <Text style={{ marginLeft: 5, fontSize: 16, fontWeight: 500 }}>Stack No:</Text>
                                                <Text style={{ marginLeft: 5, fontWeight: 600, fontSize: 16, paddingLeft: 2 }}>{pdf_data?.stack_no || "---"}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                <Text style={{ marginLeft: 5, fontSize: 16, fontWeight: 500 }}>Date Dispatched: </Text>
                                                <Text style={{ marginLeft: 5, fontWeight: 600, fontSize: 16, paddingLeft: 2 }}>{pdf_data?.delivery_note?.transit_date_time ? moment(pdf_data?.delivery_note?.transit_date_time).format("MMMM Do, YYYY") : "---"}</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                                                <Text style={{ marginLeft: 5, fontSize: 16, fontWeight: 500 }}>Weigh Bridge #: </Text>
                                                <Text style={{ marginLeft: 5, fontWeight: 600, fontSize: 16, paddingLeft: 2 }}>{pdf_data?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no || "---"}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ padding: 5 }}>
                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", border: "1px solid #A9A9A9", borderRadius: "1px" }}>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%", borderRight: "2px solid #A9A9A9" }}>
                                            <View style={{ borderBottom: "1px solid #A9A9A9" }}>
                                                <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Customer Details</Text>
                                            </View>
                                            <View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Property Name</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.user_property?.property_area}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Location/Area</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.user_property?.property_physical_address}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Customer Name</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.consigner?.name}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Contact Details</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.consigner?.mobile_number}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Comments</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.comments}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%" }}>
                                            <View style={{ borderBottom: "1px solid #A9A9A9" }}>
                                                <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Transporter Details</Text>
                                            </View>
                                            <View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Truck Reg No.</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.truck_licence_plate}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9", width: "100%" }}>
                                                    <View style={{ borderRight: "1px solid #A9A9A9", width: "50%" }}>
                                                        <View style={{ paddingLeft: 5, borderRight: "1px solid #A9A9A9", width: "60%" }}>
                                                            <Text style={{ fontSize: 14 }}>Trailer No. (1)</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.trailer_one}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: "50%" }}>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 14 }}>Trailer No. (2)</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.trailer_two}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Transporter Name</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.transporter_name}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Driver Name</Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.truck_driver_name}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                    <View style={{ paddingLeft: 5, width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                        <Text style={{ fontSize: 14 }}>Driver Contact </Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.comments}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ padding: 5 }}>
                                    <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", border: "1px solid #A9A9A9", borderRadius: "1px", marginTop: 10 }}>
                                        <View style={{ borderBottom: "1px solid #A9A9A9", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <View style={{ fontSize: 14 }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase", fontSize: 12 }}>Product Description</Text>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <View style={{ fontSize: 14 }}>
                                                    <Text style={{ textAlign: "center" }}>Unit of Measure</Text>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <View style={{ fontSize: 14 }}>
                                                    <Text style={{ textAlign: "center" }}>Packaging Number</Text>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <View style={{ fontSize: 14 }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Total Kgs</Text>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", padding: 2, borderRight: "2px solid #A9A9A9" }}>
                                                <View style={{ fontSize: 14 }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Total Lts</Text>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", padding: 2 }}>
                                                <View style={{ fontSize: 14 }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Total MT</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", borderRadius: "1px" }}>
                                            {
                                                pdf_data?.product_list?.map((product, index) => {
                                                    return (
                                                        <>
                                                            <View style={{ display: "flex", flexDirection: "row" }}>
                                                                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2, borderBottom: index !== pdf_data?.product_list?.length - 1 ? "1px solid #A9A9A9" : "" }}>
                                                                    <View style={{ paddingLeft: 5 }}>
                                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{product?.product_name}</Text>
                                                                    </View>
                                                                </View>
                                                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2, borderBottom: index !== pdf_data?.product_list?.length - 1 ? "1px solid #A9A9A9" : "", textAlign: "center" }}>
                                                                    <View style={{ paddingLeft: 5 }}>
                                                                        <Text style={{ fontSize: 14, fontWeight: "bold" }}>{product?.unit_measurement_abbrev}</Text>
                                                                    </View>
                                                                </View>
                                                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2, borderBottom: index !== pdf_data?.product_list?.length - 1 ? "1px solid #A9A9A9" : "", textAlign: "center" }}>
                                                                    <View style={{ fontSize: 14 }}>
                                                                        <Text style={{ textAlign: "center" }}>{product?.packaging_total_number}</Text>
                                                                    </View>
                                                                </View>
                                                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2, borderBottom: index !== pdf_data?.product_list?.length - 1 ? "1px solid #A9A9A9" : "", textAlign: "center" }}>
                                                                    <View style={{ fontSize: 14 }}>
                                                                        <Text style={{ textAlign: "center" }}>{product?.unit_measurement_abbrev?.includes("kg") || product?.unit_measurement_abbrev?.includes("KG") ? product.total_capacity_selected_measurement : ""}</Text>
                                                                    </View>
                                                                </View>
                                                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2, borderBottom: index !== pdf_data?.product_list?.length - 1 ? "1px solid #A9A9A9" : "", textAlign: "center" }}>
                                                                    <View style={{ fontSize: 14 }}>
                                                                        <Text style={{ textAlign: "center" }}>{product?.unit_measurement_abbrev?.includes("lt") || product?.unit_measurement_abbrev?.includes("LT") ? product.total_capacity_selected_measurement : "--"}</Text>
                                                                    </View>
                                                                </View>
                                                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", textAlign: "center", padding: 2, borderBottom: index !== pdf_data?.product_list?.length - 1 ? "1px solid #A9A9A9" : "" }}>
                                                                    <View style={{ fontSize: 14 }}>
                                                                        <Text style={{ textAlign: "center" }}>{product?.total_capacity_in_mt}</Text>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </>
                                                    );
                                                })
                                            }
                                        </View>
                                    </View>
                                </View>
                                <View style={{ padding: 5 }}>
                                    <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "30%", padding: 2 }}>
                                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontSize: 10 }}>
                                                <View>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase" }}>D/Note Issued BY:</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ textAlign: "left", marginLeft: 2, textTransform: "uppercase", fontWeight: "bold" }}>{pdf_data?.delivery_note?.issued_by_name}</Text>
                                                </View>
                                            </View>

                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "25%", padding: 2 }}>
                                            <View style={{ fontSize: 12 }}>
                                                <Text style={{ textAlign: "center", fontWeight: "bold" }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "15%", padding: 2 }}>
                                            <View style={{ fontSize: 14 }}>
                                                <Text style={{ textAlign: "center", fontWeight: "bold" }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "15%", padding: 2 }}>
                                            <View style={{ fontSize: 14 }}>
                                                <Text style={{ textAlign: "right", textTransform: "uppercase" }}>Total Quantity</Text>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "15%", padding: 2, borderBottom: "1px solid #A9A9A9" }}>
                                            <View style={{ fontSize: 14 }}>
                                                <Text style={{ textAlign: "center", fontWeight: "bold" }}>{total_mt_cap}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ padding: 5 }}>
                                    <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", border: "1px solid #A9A9A9", borderRadius: "1px", marginTop: 10 }}>
                                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%", borderRight: "2px solid #A9A9A9" }}>
                                                <View style={{ borderBottom: "1px solid #A9A9A9" }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase", fontSize: 14 }}>Customer Details</Text>
                                                </View>
                                                <View>
                                                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontSize: 12 }}>Customer Name</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>{pdf_data?.delivery_note?.customer_receiving_name}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontSize: 12 }}>Customer Signature</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 12, fontWeight: "bold" }}></Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontSize: 12 }}>Customer Contact Details</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>{pdf_data?.delivery_note?.customer_receiving_primary_mobile}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontSize: 12 }}>Date Received</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 12, fontWeight: "bold" }}></Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontSize: 12 }}>Customer GRN Number</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>{pdf_data?.weigh_bridge_ticket?.comments}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "18%", padding: 2, borderRight: "2px solid #A9A9A9" }}>
                                                <View style={{ fontSize: 12 }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase", borderBottom: "1px solid #A9A9A9" }}>Customer Stamp</Text>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "32%", padding: 2, borderRight: "2px solid #A9A9A9" }}>
                                                <View style={{ fontSize: 12 }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase", borderBottom: "1px solid #A9A9A9" }}>Goods Received  in Good Order</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", padding: 2 }}>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ fontSize: 8, textAlign: "center", fontWeight: "bold" }}>Please Indicate any Delivery Shortage or Discrepancy</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", borderRadius: "1px" }}>

                                        </View>
                                    </View>
                                </View>

                            </View>
                        </Page>
                    </Document>
                </>
            )
        }
        </>
    );
};

export default DeliveryNotePDF;;