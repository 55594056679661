import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    emp_role_actions: { status: "idle" },
};

export const emp_role_actions_Slice = createSlice({
    name: "emp_role_actions",
    initialState,
    reducers: {
        success_emp_role_actions: (state, action) => {
            state.emp_role_actions = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_emp_role_actions: (state, action) => {
            state.emp_role_actions = { status: app_consts.FAIL, message: action.payload };
        },
        reset_emp_role_actions: (state) => {
            state.emp_role_actions = { status: app_consts.IDLE };
        },
        loading_emp_role_actions: (state) => {
            state.emp_role_actions = { status: app_consts.LOADING };
        },
    }
});

export const { success_emp_role_actions, fail_emp_role_actions, reset_emp_role_actions, loading_emp_role_actions } = emp_role_actions_Slice.actions;

export default emp_role_actions_Slice.reducer;