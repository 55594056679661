import React from 'react';
import Head from '../../layout/head/Head';
import { CardLinkInfo } from '../dashboard_pages/components/cards/CardLinks';
import Content from '../../layout/content/Content';
import PageHeading from '../dashboard_pages/components/page_heading/PageHeading';
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, Row } from '../../components/Component';
import * as app_consts from "../../constants/react_app_consts";
import { useSelector } from 'react-redux';

const HomeClient = ({ account_type }) => {

    // Getting State Value for Credentials of Logged In User
    const log_cred = useSelector((state) => state.logged_in_cred.logged_in_cred);

    return (
        <>
            <Head title="Home" />
            <Content>
                <Block size="lg" className="items-center w-80 m-auto">
                    <PageHeading
                        title={`Welcome ${log_cred.data ? `${log_cred.data.first_name} ${log_cred.data.last_name}` : "----"}`}
                        description="View Goods Received, Delivery Notes and Profile"
                    />
                    <PreviewCard>
                        <BlockHead>
                            <BlockHeadContent className="text-center">
                                <BlockTitle tag="h4">Select an Option</BlockTitle>
                                <p>Onboard a Company or Attach a Registered User a Company as One of its Representatives.</p>
                            </BlockHeadContent>
                        </BlockHead>
                        <Row className="g-gs flex mb-2 items-center mt-3">
                            <CardLinkInfo
                                colSize="6"
                                iconType="in-built"
                                icon="building"
                                cardTitle="Goods Received"
                                cardTextInfo="View Goods Received"
                                buttonLink="/client/goods_received"
                                buttonText="View Goods Received"
                                // comp_data={{ modal_type: app_consts.ONBOARD_COMPANY }}
                                buttonType={app_consts.BUTTON_OPEN_PAGE_TYPE}
                            // buttonModuleFunction={open_search_module}
                            />
                            <CardLinkInfo
                                colSize="6"
                                iconType="in-built"
                                icon="building"
                                cardTitle="Delivery Note"
                                cardTextInfo="View Delivery Notes"
                                buttonLink="/client/delivery_notes"
                                buttonText="View Delivery Notes"
                                // comp_data={{ modal_type: app_consts.ONBOARD_COMPANY }}
                                buttonType={app_consts.BUTTON_OPEN_PAGE_TYPE}
                            // buttonModuleFunction={open_search_module}
                            />
                        </Row>
                    </PreviewCard>
                </Block>
            </Content>
        </>
    );
};

export default HomeClient;