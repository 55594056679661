import React, { useEffect, useState } from 'react';
import { Button, Col, Icon, RSelect, Row } from '../../../../../../components/Component';
import TextInput from '../../../../../components/inputs/TextInput';
import ListText from '../../../text/ListText';
import { Alert, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CREATE_WAREHOUSE_STORAGE_API, RETRIEVE_WAREHOUSE_STORAGE_TYPES_API } from '../../../../../../api/api_data';
import { fail_retreive_warehouse_storage_types_list, loading_retreive_warehouse_storage_types_list, reset_retreive_warehouse_storage_types_list, success_retreive_warehouse_storage_types_list } from '../../../../../../reducers/dashboard_reducers/warehouse/retreive_warehouse_storage_types_list_Slice';
import { CREATE_WAREHOUSE_STORAGE_ACCESS_DENIED_MSG, CREATE_WAREHOUSE_STORAGE_ERR_MSG, CREATE_WAREHOUSE_STORAGE_SUCCESS_MSG, RETRIEVE_WAREHOUSE_STORAGE_TYPE_ACCESS_DENIED_MSG, RETRIEVE_WAREHOUSE_STORAGE_TYPE_ERR_MSG, RETRIEVE_WAREHOUSE_STORAGE_TYPE_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import * as app_consts from "../../../../../../constants/react_app_consts";
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { messageToast } from '../../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import { fail_create_warehouse_storage, loading_create_warehouse_storage, reset_create_warehouse_storage, success_create_warehouse_storage } from '../../../../../../reducers/dashboard_reducers/warehouse/create_warehouse_storage_Slice';

const initialState = { warehouse_storage_capacity: "", branch_location_id: "", branch_location: "", warehouse_storage_type_id: "" };
const previewState = { warehouse_storage_type: "", warehouse_storage_capacity: "", branch_location_id: "", branch_location: "" };

const AddWarehouseStorage = ({ openOtherModalFunc, openModal, successFunction, accountId }) => {

    const [step, setStep] = useState(1);
    const [errorVal, setError] = useState("");
    const [formData, setFormData] = useState(initialState);
    const [previewData, setPreviewData] = useState(previewState);
    const [loading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [warehouseStorageLoading, setWarehouseStorageLoading] = useState(true);
    const [warehouseStorageData, setWarehouseStorageData] = useState([]);

    const prevStep = () => {
        setStep(1);
    };

    const handlePreview = () => {
        setError("");
        setStep(2);
    };
    // Handle Storage Capacity Blur
    const handleStorageCapacityBlur = () => {
        if (formData?.warehouse_storage_capacity)
        {
            setFormData({ ...formData, ["warehouse_storage_capacity"]: parseFloat(formData.warehouse_storage_capacity).toFixed(2) });
            setPreviewData({ ...formData, ["warehouse_storage_capacity"]: parseFloat(formData.warehouse_storage_capacity).toFixed(2) });
        }
    };

    // Retrieve Warehouse Storage Types on Page Load
    useEffect(() => {
        if (openModal)
        {
            dispatch(
                employee_dashboard_actions_helper(
                    loading_retreive_warehouse_storage_types_list,
                    success_retreive_warehouse_storage_types_list,
                    fail_retreive_warehouse_storage_types_list,
                    RETRIEVE_WAREHOUSE_STORAGE_TYPE_ERR_MSG,
                    RETRIEVE_WAREHOUSE_STORAGE_TYPE_SUCCESS_MSG,
                    app_consts.GET,
                    RETRIEVE_WAREHOUSE_STORAGE_TYPES_API,
                    null,
                    RETRIEVE_WAREHOUSE_STORAGE_TYPE_ACCESS_DENIED_MSG,
                    app_consts.MAP,
                    app_consts.WAREHOUSE_STORAGE_TYPES_MAP
                )
            );
            setFormData({ ...formData, ["branch_location_id"]: accountId?.branch?.branch_location_id, ["branch_location"]: `${accountId?.branch?.branch_physical_address}, ${accountId?.branch?.branch_area}.` });
            setPreviewData({ ...formData, ["branch_ref_no"]: accountId?.branch?.branch_location_id, ["branch_location"]: `${accountId?.branch?.branch_physical_address}, ${accountId?.branch?.branch_area}.` });
        }
    }, [openModal]);

    // Listen to State Changes for Warehouse Storage Types List
    const retreive_warehouse_storage_types_list = useSelector((state) => state.retreive_warehouse_storage_types_list.retreive_warehouse_storage_types_list);

    useEffect(() => {
        if (retreive_warehouse_storage_types_list.status !== app_consts.IDLE)
        {
            if (retreive_warehouse_storage_types_list.status === app_consts.LOADING)
            {
                setWarehouseStorageLoading(true);
            } else
            {
                setWarehouseStorageLoading(false);
                if (retreive_warehouse_storage_types_list.status === app_consts.FAIL)
                {
                    messageToast(retreive_warehouse_storage_types_list.message, app_consts.FAIL);
                } else if (retreive_warehouse_storage_types_list.status === app_consts.SUCCESS)
                {
                    setWarehouseStorageData(retreive_warehouse_storage_types_list.data);
                }
                dispatch(reset_retreive_warehouse_storage_types_list());
            }
        }
    }, [retreive_warehouse_storage_types_list]);

    // Handle Submit
    const handleSubmit = () => {
        setError("");
        dispatch(
            employee_dashboard_actions_helper(
                loading_create_warehouse_storage,
                success_create_warehouse_storage,
                fail_create_warehouse_storage,
                CREATE_WAREHOUSE_STORAGE_ERR_MSG,
                CREATE_WAREHOUSE_STORAGE_SUCCESS_MSG,
                app_consts.POST,
                CREATE_WAREHOUSE_STORAGE_API,
                formData,
                CREATE_WAREHOUSE_STORAGE_ACCESS_DENIED_MSG
            )
        );

    };

    // State Listener to Create Warehuose Storage
    const create_warehouse_storage = useSelector((state) => state.create_warehouse_storage.create_warehouse_storage);

    useEffect(() => {
        if (create_warehouse_storage.status !== app_consts.IDLE)
        {
            if (create_warehouse_storage.status === app_consts.LOADING)
            {
                setIsLoading(true);
            } else
            {
                setIsLoading(false);
                if (create_warehouse_storage.status === app_consts.FAIL)
                {
                    messageToast(create_warehouse_storage.message, app_consts.FAIL);
                    setError(create_warehouse_storage.message);
                } else if (create_warehouse_storage.status === app_consts.SUCCESS)
                {
                    messageToast(CREATE_WAREHOUSE_STORAGE_SUCCESS_MSG, app_consts.SUCCESS);
                    successFunction(create_warehouse_storage.data);
                }
                dispatch(reset_create_warehouse_storage());
            }
        }
    }, [create_warehouse_storage]);

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <h5 className="card-title text-start w-100">Add Warehouse Storage</h5>
                {step === 2 && (
                    <div
                        className="nk-block-head-sub mb-2"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            prevStep();
                            setFormData({ ...formData, ["warehouse_storage_type_id"]: "" });
                        }}
                    >
                        <Icon name="arrow-left" />
                        <span>back</span>
                    </div>
                )}
            </div>
            {errorVal && (
                <div className="mb-3 w-100">
                    <Alert color="danger" className="alert-icon">
                        <Icon name="alert-circle" /> {errorVal}{" "}
                    </Alert>
                </div>
            )}

            {step === 1 ? (
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Row className="g-4">
                            <Col lg="6">
                                <TextInput
                                    label="Warehouse Storage Capacity (MT)"
                                    required
                                    type="number"
                                    id_val="warehouse_storage_capacity"
                                    value={formData.warehouse_storage_capacity}
                                    colSize="10"
                                    name="warehouse_storage_capacity"
                                    handleChange={(e) => { setFormData({ ...formData, ["warehouse_storage_capacity"]: e.target.value }); setPreviewData({ ...previewData, ["warehouse_storage_type"]: e.target.value }); }}
                                    onBlur={handleStorageCapacityBlur}
                                />
                            </Col>
                            <Col lg="6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="warehouse_storage_type_id">
                                        Warehouse Storage Type
                                    </label>
                                    <div className="form-control-wrap">
                                        <RSelect
                                            options={warehouseStorageData}
                                            className="w-100%"
                                            id="comap_rep"
                                            onChange={(e) => {
                                                setFormData({ ...formData, ["warehouse_storage_type_id"]: e.value });
                                                setPreviewData({ ...previewData, ["warehouse_storage_type"]: e.label });
                                            }}
                                            isDisabled={warehouseStorageLoading}
                                        />
                                    </div>
                                    {openOtherModalFunc && !warehouseStorageLoading && (
                                        <div>
                                            <Link
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openOtherModalFunc();
                                                }}
                                                className="blue d-flex justify-end mt-4 text-blue underline-text items-center"
                                            >
                                                Add Warehouse Stroage Type
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xl="12" className="d-flex justify-content-center mt-8">
                                <Button
                                    onClick={handlePreview}
                                    size="lg"
                                    type="submit"
                                    color="primary"
                                    disabled={!(formData.warehouse_storage_capacity && formData.warehouse_storage_type_id)}
                                >
                                    Preview
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </>
            ) : step === 2 ? (
                <>
                    <ListText
                        dataItem="data-item mySmall"
                        label="Warehouse Storage Capacity"
                        value={`${previewData.warehouse_storage_capacity} MT`}
                    />
                    <ListText
                        dataItem="data-item mySmall"
                        label="Warehouse Storage Type"
                        value={previewData.warehouse_storage_type}
                    />
                    <ListText
                        dataItem="data-item mySmall"
                        label="Branch Location"
                        value={formData.branch_location}
                    />
                    <Col xl="12" className="d-flex justify-content-center mt-4 mr-2">
                        <Button onClick={handleSubmit} disabled={loading} color="primary">
                            {loading ? <Spinner size="sm" color="light" /> : "Add Warehouse Storage"}
                        </Button>
                    </Col>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default AddWarehouseStorage;