import React, { useEffect, useState } from 'react';
import { Button, Col, Icon, Row } from '../../../../../../components/Component';
import { Alert, Spinner } from 'reactstrap';
import TextInput from '../../../../../components/inputs/TextInput';
import ListText from '../../../text/ListText';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CREATE_WAREHUOSE_STORAGE_TYPE_API } from '../../../../../../api/api_data';
import { fail_create_warehouse_storage_type, loading_create_warehouse_storage_type, reset_create_warehouse_storage_type, success_create_warehouse_storage_type } from '../../../../../../reducers/dashboard_reducers/warehouse/create_warehouse_storage_type_Slice';
import { messageToast } from '../../../../../../utils/Utils';
import { CREATE_WAREHUOSE_STORAGE_TYPE_ACCESS_DENIED_MSG, CREATE_WAREHUOSE_STORAGE_TYPE_ERR_MSG, CREATE_WAREHUOSE_STORAGE_TYPE_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import * as app_consts from "../../../../../../constants/react_app_consts";
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';

const initialState = { warehouse_storage_type: "" };

const AddWarehouseStorageType = ({ openOtherModalFunc, successFunction, openModal, setModalLoading }) => {
    const [step, setStep] = useState(1);
    const [errorVal, setError] = useState("");
    const [formData, setFormData] = useState(initialState);
    const [loading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const prevStep = () => {
        setStep(1);
    };

    const handlePreview = () => {
        setError("");
        setStep(2);
    };

    // Handle Submit
    const handleSubmit = () => {
        setError("");
        dispatch(
            employee_dashboard_actions_helper(
                loading_create_warehouse_storage_type,
                success_create_warehouse_storage_type,
                fail_create_warehouse_storage_type,
                CREATE_WAREHUOSE_STORAGE_TYPE_ERR_MSG,
                CREATE_WAREHUOSE_STORAGE_TYPE_SUCCESS_MSG,
                app_consts.POST,
                CREATE_WAREHUOSE_STORAGE_TYPE_API,
                formData,
                CREATE_WAREHUOSE_STORAGE_TYPE_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to AddCompany Representative 
    const create_warehouse_storage_type = useSelector(
        (state) => state.create_warehouse_storage_type.create_warehouse_storage_type
    );
    // UseEffect
    useEffect(() => {
        if (create_warehouse_storage_type.status !== app_consts.IDLE)
        {
            if (create_warehouse_storage_type.status === app_consts.LOADING)
            {
                setIsLoading(true);
                setModalLoading(true);
            } else
            {
                setIsLoading(false);
                setModalLoading(false);
                if (create_warehouse_storage_type.status === app_consts.FAIL)
                {
                    setError(create_warehouse_storage_type.message);
                    prevStep();
                } else if (create_warehouse_storage_type.status === app_consts.SUCCESS)
                {
                    messageToast(CREATE_WAREHUOSE_STORAGE_TYPE_SUCCESS_MSG, app_consts.SUCCESS);
                    // Close Modal and Other Embedded Functions if Need Be
                    successFunction();
                }
                dispatch(reset_create_warehouse_storage_type());
            }
        }
    }, [create_warehouse_storage_type]);

    return (
        <>
            <div className="d-flex justify-content-end mb-2">
                {/* <h5 className="card-title text-start w-100">Warehouse Storage Type</h5> */}
                {step === 2 && (
                    <div
                        className="nk-block-head-sub mb-2"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            prevStep();
                        }}
                    >
                        <Icon name="arrow-left" />
                        <span>back</span>
                    </div>
                )}
            </div>
            {errorVal && (
                <div className="mb-3 w-100">
                    <Alert color="danger" className="alert-icon">
                        <Icon name="alert-circle" /> {errorVal}{" "}
                    </Alert>
                </div>
            )}

            {step === 1 ? (
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Row className="g-4">
                            <TextInput
                                label="Warehouse Storage Type"
                                required
                                type="text"
                                id_val="warehouse_storage_type"
                                maxlength="30"
                                value={formData.warehouse_storage_type}
                                colSize="12"
                                name="warehouse_storage_type"
                                handleChange={(e) => setFormData({ ...formData, ["warehouse_storage_type"]: e.target.value })}
                            />
                            <Col xl="12" className="d-flex justify-content-center mt-8">
                                <Button
                                    onClick={handlePreview}
                                    size="lg"
                                    type="submit"
                                    color="primary"
                                    disabled={!formData.warehouse_storage_type}
                                >
                                    Preview
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </>
            ) : step === 2 ? (
                <>
                    <ListText
                        dataItem="data-item mySmall"
                        label="Warehouse Storage Type"
                        value={formData.warehouse_storage_type}
                    />
                    <Col xl="12" className="d-flex justify-content-center mt-4 mr-2">
                        <Button onClick={handleSubmit} disabled={loading} color="primary">
                            {loading ? <Spinner size="sm" color="light" /> : "Add Warehouse Storage Type"}
                        </Button>
                    </Col>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default AddWarehouseStorageType;