import { DataTableItem } from "../../../../../components/Component";
import RadioInput from "../../input/RadioInput";
import ReadOnlyTableRow from "./ReadOnlyTableRow";
import * as app_consts from "../../../../../constants/react_app_consts";

export const CustomerFeedBackCheckListData = ({
    data,
    currentItems,
    amendFunction,
    editMode
}) => {
    return (
        <>
            {data.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <DataTableItem key={item?.delivery_note_product_id}>
                                <ReadOnlyTableRow
                                    row_type="first_col"
                                    item={
                                        item?.product_name ? (
                                            <>
                                                {item?.product_name.length > 20
                                                    ? `${`${item?.product_name}`.substring(0, 20)}...`
                                                    : `${item.product_name}`}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                />
                                <ReadOnlyTableRow
                                    row_type="normal_col"
                                    item={item?.packaging_total_number ? item?.packaging_total_number : ""
                                    }
                                />
                                {
                                    editMode ?
                                        <>
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={[
                                                    <RadioInput
                                                        id={app_consts.GREATER_THAN}
                                                        className="custom-control custom-control-md custom-radio"
                                                        handleChange={(e) => amendFunction(item?.delivery_note_product_id, app_consts.GREATER_THAN)}
                                                    />
                                                ]}
                                            />
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={[
                                                    <RadioInput
                                                        id={app_consts.EQUAL_TO}
                                                        className="custom-control custom-control-md custom-radio"
                                                        handleChange={(e) => amendFunction(item?.delivery_note_product_id, app_consts.EQUAL_TO)}
                                                    />
                                                ]}
                                            />
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={[
                                                    <RadioInput
                                                        id={app_consts.LESS_THAN}
                                                        className="custom-control custom-control-md custom-radio"
                                                        handleChange={(e) => amendFunction(item?.delivery_note_product_id, app_consts.LESS_THAN)}
                                                    />
                                                ]}
                                            />
                                        </>
                                        :
                                        <>
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={
                                                    item?.customer_packaging_number_feedback === app_consts.GREATER_THAN && (
                                                        <>
                                                            <div className="custom-control color-control">
                                                                <input type="radio" className="custom-control-input" id="productColor6" name="productColor" />
                                                                <label className="custom-control-label dot dot-xl bg-indigo" htmlFor="productColor6"></label>
                                                            </div>
                                                        </>
                                                    )}
                                            />
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={
                                                    item?.customer_packaging_number_feedback === app_consts.EQUAL_TO && (
                                                        <>
                                                            <div className="custom-control color-control">
                                                                <input type="radio" className="custom-control-input" id="productColor6" name="productColor" />
                                                                <label className="custom-control-label dot dot-xl bg-indigo" htmlFor="productColor6"></label>
                                                            </div>
                                                        </>
                                                    )}
                                            />
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={
                                                    item?.customer_packaging_number_feedback === app_consts.LESS_THAN && (
                                                        <>
                                                            <div className="custom-control color-control">
                                                                <input type="radio" className="custom-control-input" id="productColor6" name="productColor" />
                                                                <label className="custom-control-label dot dot-xl bg-indigo" htmlFor="productColor6"></label>
                                                            </div>
                                                        </>
                                                    )}
                                            />
                                        </>
                                }
                            </DataTableItem>
                        </>
                    );
                }
                )
            }
        </>
    );
};