import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    emp_dep_roles_list: { status: "idle" },
};

export const emp_dep_roles_list_Slice = createSlice({
    name: "emp_dep_roles_list",
    initialState,
    reducers: {
        success_emp_dep_roles_list: (state, action) => {
            state.emp_dep_roles_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_emp_dep_roles_list: (state, action) => {
            state.emp_dep_roles_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_emp_dep_roles_list: (state) => {
            state.emp_dep_roles_list = { status: app_consts.IDLE };
        },
        loading_emp_dep_roles_list: (state) => {
            state.emp_dep_roles_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_emp_dep_roles_list, fail_emp_dep_roles_list, reset_emp_dep_roles_list, loading_emp_dep_roles_list } = emp_dep_roles_list_Slice.actions;

export default emp_dep_roles_list_Slice.reducer;