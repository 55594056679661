import React from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import { useParams } from 'react-router';
import PageHeading from '../components/page_heading/PageHeading';
import * as app_consts from "../../../constants/react_app_consts";
import WizardComp from '../components/wizard/WizardComp';

const type = app_consts.CREATE_LOADING_ORDER;

const CreateLoadingOrder = () => {

    const { loading_list_ref_number } = useParams();

    return (
        <>
            <Head title={`${loading_list_ref_number ? "Continue Processing" : "Create"} Loading Order`} />
            <Content>
                <PageHeading
                    title={`${loading_list_ref_number ? "Continue Processing" : "Create"} Loading Order`}
                    title_addition={loading_list_ref_number}
                    description={`${loading_list_ref_number ? "Continue Processing" : "Create"} Loading Order for Physical Delivery Note (s)`}
                    backToLink={`/dashboard/loading_orders`}
                    backToText="Loading Orders"
                />
                {/* Multi-Step Form */}
                {
                    <WizardComp type={type} />
                }
            </Content>
        </>
    );
};

export default CreateLoadingOrder;