import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
 user_account_details: { status: "idle" },
};

export const user_account_details_Slice = createSlice({
 name: "user_account_details",
 initialState,
 reducers: {
  success_user_account_details: (state, action) => {
   state.user_account_details = { status: app_consts.SUCCESS, data: action.payload };
  },
  fail_user_account_details: (state, action) => {
   state.user_account_details = { status: app_consts.FAIL, message: action.payload };
  },
  reset_user_account_details: (state) => {
   state.user_account_details = { status: app_consts.IDLE };
  },
  loading_user_account_details: (state) => {
   state.user_account_details = { status: app_consts.LOADING };
  },
 }
});

export const { success_user_account_details, fail_user_account_details, reset_user_account_details, loading_user_account_details } = user_account_details_Slice.actions;

export default user_account_details_Slice.reducer;