// ---------------------------------------------- Success and Errors for Website Actions ------------------------------------------

// --------------------- Auth -------------------

// Username Verify
export const USERNAME_VERIFY_ERR_MSG = "Sign In Failed";
export const USERNAME_VERIFY_SUCESS_MSG = "User Account Found";

// Send OTP Auth
export const SENT_OTP_AUTH_ERR_MSG = "Failed to Send OTP";
export const SENT_OTP_AUTH_SUCESS_MSG = "OTP Sent Successfully";

// Verify OTP Auth
export const VERIFY_OTP_AUTH_ERR_MSG = "Failed to Verify OTP";
export const VERIFY_OTP_AUTH_SUCESS_MSG = "OTP Verification Successful";

// ------------------------------------------------------------ Success and Errors for Dashboard Actions ------------------------------------------

// ------------------------------------------------------------ Auth --------------------------------------------------
export const AUTH_ROUTES_ERR_MSG = "Could Not Retrieve Employee Roles";
export const AUTH_ROUTES_SUCCESS_MSG = "Active Employee Role Actions Retrieved Successfully";

// ------------------------------------------------------------ Users -------------------------------------------------------

// User List With Images
export const USER_LIST_IMGS_ERR_MSG = "Could Not Retrieve User List.";
export const USER_LIST_IMGS_SUCCESS_MSG = "Users Retrieved Successfully";
export const USER_LIST_IMGS_ACCESS_DENIED_MSG = "Could Not Retrieve User List. Access Denied.";

// Create Member Access Account
export const CREATE_USER_ERR_MSG = "Could Not Create User.";
export const CREATE_USERS_SUCCESS_MSG = "User successfully Onboarded";
export const CREATE_USERS_ACCESS_DENIED_MSG = "Could Not Create User. Access Denied.";

// Amend User Status
export const AMEND_USER_STATUS_ERR_MSG = "Could Not Amend User Status.";
export const AMEND_USER_STATUS_SUCCESS_MSG = "User Status Successfully Amended";
export const AMEND_USER_STATUS_ACCESS_DENIED_MSG = "Could Not Amend User Status. Access Denied.";

// Retrive Active Users List
export const RETRIEVE_ACTIVE_USERS_ERR_MSG = "Could Not Retrieve Active Users.";
export const RETRIEVE_ACTIVE_USERS_SUCCESS_MSG = "Active Users Retrieved Successfully";
export const RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG = "Could Not Retrieve Active Users. Access Denied";

// Retrive user KYC Status
export const RETRIEVE_USER_KYC_STATUS_ERR_MSG = "Could Not Retrieve User's KYC Status.";
export const RETRIEVE_USER_KYC_STATUS_SUCCESS_MSG = "User Details Successfully Retrieved.";
export const RETRIEVE_USER_KYC_STATUS_ACCESS_DENIED_MSG =
  "Could Not Retrieve User's KYC Status. Access Denied.";

// Retrieve Employees List
export const RETRIEVE_EMPLOYEE_LIST_ERR_MSG = "Could not Retrieve Employee List.";
export const RETRIEVE_EMPLOYEE_LIST_SUCCESS_MSG = "Employee List Retrieved Successfully.";
export const RETRIEVE_EMPLOYEE_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Employee List. Access Denied.";

// Retrieve Specific User Details
export const RETRIEVE_USER_DETAILS_ERR_MSG = "Could not Retrieve User Details.";
export const RETRIEVE_USER_DETAILS_SUCCESS_MSG = "User Details Retrieved Successfully.";
export const RETRIEVE_USER_DETAILS_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Details. Access Denied.";

// ------------------------------------------------------------ User Accounts ------------------------------------------------------

// Retrive Active Specific Unassigned User Accounts
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ERR_MSG = "Could Not Retrieve User Account List.";
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_SUCCESS_MSG = "User Accounts Successfully Retrieved.";
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Account List. Access Denied.";

// Retrive Active User Accounts List
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Retrieve User Account List.";
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG = "User Accounts Successfully Retrieved.";
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Account List. Access Denied.";

// Create Truck Driver Account
export const CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Create Truck Driver User Account.";
export const CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_SUCCESS_MSG = "Truck Driver Account Created Successfully.";
export const CREATE_TRUCK_DRIVER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Create Truck Driver User Account. Access Denied.";

// Create Customer Account
export const CREATE_CUSTOMER_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Create Customer Account.";
export const CREATE_CUSTOMER_USER_ACCOUNT_LIST_SUCCESS_MSG = "Customer Account Created Successfully.";
export const CREATE_CUSTOMER_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Create Customer User Account. Access Denied.";

// Create Company Account
export const CREATE_COMPANY_USER_ACCOUNT_LIST_ERR_MSG = "Could Not Create Company Account.";
export const CREATE_COMPANY_USER_ACCOUNT_LIST_SUCCESS_MSG = "Company Account Created Successfully.";
export const CREATE_COMPANY_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG =
  "Could Not Create Company User Account. Access Denied.";

// Retrieve User Accounts List
export const RETRIEVE_USER_ACCOUNTS_LIST_ERR_MSG = "Could Not Retrieve User Accounts List.";
export const RETRIEVE_USER_ACCOUNTS_LIST_SUCCESS_MSG = "User Accounts List Retrieved Successfully.";
export const RETRIEVE_USER_ACCOUNTS_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Accounts. Access Denied List.";

// Retrieve Specific User Account
export const RETRIEVE_SPECIFIC_USER_ACCOUNT_ERR_MSG = "Could Not Retrieve User Accounts Details.";
export const RETRIEVE_SPECIFIC_USER_ACCOUNT_SUCCESS_MSG = "User Accounts Details Retrieved Successfully.";
export const RETRIEVE_SPECIFIC_USER_ACCOUNT_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Account Details. Access Denied List.";

// ------------------------------------------------------------ User KYC -------------------------------------------------------

// Retrive Gender Types
export const RETRIEVE_GENDER_TYPE_ERR_MSG = "Could Not Retrieve Gender Types.";
export const RETRIEVE_GENDER_TYPE_SUCCESS_MSG = "Gender Types Retrieved Succesfully.";
export const RETRIEVE_GENDER_TYPE_ACCESS_DENIED_MSG = "Could Not Retrieve Gender Types. Access Denied.";

// Retrive Marital Status
export const RETRIEVE_MARITAL_STATUS_ERR_MSG = "Could Not Retrieve Marital Statuses.";
export const RETRIEVE_MARITAL_STATUS_SUCCESS_MSG = "Marital Statuses Retrieved Succesfully.";
export const RETRIEVE_MARITAL_STATUS_ACCESS_DENIED_MSG = "Could Not Retrieve Marital Statuses. Access Denied.";

// Retrive User Title
export const RETRIEVE_USER_TITLE_ERR_MSG = "Could Not Retrieve User Titles.";
export const RETRIEVE_USER_TITLE_SUCCESS_MSG = "User Titles Retrieved Succesfully.";
export const RETRIEVE_USER_TITLE_ACCESS_DENIED_MSG = "Could Not Retrieve User Titles. Access Denied.";

// Update Basic KYC Info
export const UPDATE_BASIC_KYC_INFO_ERR_MSG = "Could Not Update User's Basic Information.";
export const UPDATE_BASIC_KYC_INFO_SUCCESS_MSG = "User Basic Info Successfully Updated.";
export const UPDATE_BASIC_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Basic Information. Access Denied.";

// Update Document KYC Info
export const UPDATE_DOCUMENT_KYC_INFO_ERR_MSG = "Could Not Update User's Document Information.";
export const UPDATE_DOCUMENT_KYC_INFO_SUCCESS_MSG = "User Document Info Successfully Updated.";
export const UPDATE_DOCUMENT_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Document Information. Access Denied.";

// Update Address KYC Info
export const UPDATE_ADDRESS_KYC_INFO_ERR_MSG = "Could Not Update User's Address Information.";
export const UPDATE_ADDRESS_KYC_INFO_SUCCESS_MSG = "User Address Info Successfully Updated.";
export const UPDATE_ADDRESS_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Address Information. Access Denied.";

// Update NOK KYC Info
export const UPDATE_NOK_KYC_INFO_ERR_MSG = "Could Not Update User's Next of Kin Information.";
export const UPDATE_NOK_KYC_INFO_SUCCESS_MSG = "User Next of Kin Info Successfully Updated.";
export const UPDATE_NOK_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Next of Kin Information. Access Denied.";

// Update NOK KYC Info
export const UPDATE_IMAGE_KYC_INFO_ERR_MSG = "Could Not Update User's Image Information.";
export const UPDATE_IMAGE_KYC_INFO_SUCCESS_MSG = "User Image Info Successfully Updated.";
export const UPDATE_IMAGE_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Update User's Image Information. Access Denied.";

// Specific User KYC Info
export const SPECIFIC_USER_KYC_INFO_ERR_MSG = "Could Not Retrieve User's KYC Details.";
export const SPECIFIC_USER_KYC_INFO_SUCCESS_MSG = "User Details Successfully Retrieved.";
export const SPECIFIC_USER_KYC_INFO_ACCESS_DENIED_MSG =
  "Could Not Retrieve User's KYC Details. Access Denied.";

// Uncomplete User's KYC List
export const UNCOMPLETE_USER_KYC_LIST_ERR_MSG = "Could Not Retrieve Uncomplete User's KYC List.";
export const UNCOMPLETE_USER_KYC_LIST_SUCCESS_MSG = "Uncompleted KYC User List Retrieved Successfully.";
export const UNCOMPLETE_USER_KYC_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Uncomplete User's KYC List. Access Denied.";

// ------------------------------------------------------------ Branch Location -------------------------------------------------------

// Retrieve Branch Location List
export const RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG = "Could Not Retrieve Branch Location List.";
export const RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG = "Branch Location List Retrieved Successfully.";
export const RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Branch Location List. Access Denied.";

// Add Branch Location
export const ADD_BRANCH_LOCATION_ERR_MSG = "Could Not Add Branch Location.";
export const ADD_BRANCH_LOCATION_SUCCESS_MSG = "Branch Location Added Successfully.";
export const ADD_BRANCH_LOCATION_ACCESS_DENIED_MSG = "Could Not Add Branch Location. Access Denied.";

// Amend Branch Location Status
export const AMEND_BRANCH_LOCATION_STATUS_ERR_MSG = "Could Not Amend Branch Location Status.";
export const AMEND_BRANCH_LOCATION_STATUS_SUCCESS_MSG = "Branch Location Status Amended Successfully.";
export const AMEND_BRANCH_LOCATION_STATUS_ACCESS_DENIED_MSG =
  "Could Not Amend Branch Location Status. Access Denied.";

// Retrieve Specific BranchLocation
export const RETIREVE_SPECIFIC_BRANCH_LOCATION_ERR_MSG = "Could Not Retrieve Branch Location.";
export const RETIREVE_SPECIFIC_BRANCH_LOCATION_SUCCESS_MSG = "Branch Location Retrieved Successfully.";
export const RETIREVE_SPECIFIC_BRANCH_LOCATION_ACCESS_DENIED_MSG =
  "Could Not Retrieve Branch Location. Access Denied.";

// ------------------------------------------------------------ Locations -------------------------------------------------------

// Retrieve Provinces List
export const RETRIEVE_PROVINCES_LIST_ERR_MSG = "Could Not Retrieve Provinces List.";
export const RETRIEVE_PROVINCES_LIST_SUCCESS_MSG = "Provinces Retrieved Succesfully.";
export const RETRIEVE_PROVINCES_LIST_ACCESS_DENIED_MSG = "Could Not Retrieve Provinces List. Access Denied.";

// ------------------------------------------------------------ Products -------------------------------------------------------

// Retrieve Products List
export const RETRIEVE_PRODUCTS_LIST_ERR_MSG = "Could Not Retrieve Products List.";
export const RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG = "Product List Retrieved Sucessfully.";
export const RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG = "Could Not Retrieve Products List. Access Denied.";

// Amend Product Status
export const AMEND_PRODUCT_STATUS_ERR_MSG = "Could Not Amend Product Status.";
export const AMEND_PRODUCT_STATUS_SUCCESS_MSG = "Product Status Amended Successfully.";
export const AMEND_PRODUCT_STATUS_ACCESS_DENIED_MSG = "Could Not Amend Product Status. Access Denied.";

// Add Product
export const ADD_PRODUCT_ERR_MSG = "Could Not Add Product.";
export const ADD_PRODUCT_SUCCESS_MSG = "Product Added Successfully.";
export const ADD_PRODUCT_ACCESS_DENIED_MSG = "Could Not Add Product. Access Denied.";

// Amend Product Type Status
export const AMEND_PRODUCT_TYPE_STATUS_ERR_MSG = "Could Not Amend Product Type Status.";
export const AMEND_PRODUCT_TYPE_STATUS_SUCCESS_MSG = "Product Type Status Amended Successfully.";
export const AMEND_PRODUCT_TYPE_STATUS_ACCESS_DENIED_MSG =
  "Could Not Amend Product Type Status. Access Denied.";

// Add Product Type
export const ADD_PRODUCT_TYPE_ERR_MSG = "Could Not Add Product Type.";
export const ADD_PRODUCT_TYPE_SUCCESS_MSG = "Product Type Added Successfully.";
export const ADD_PRODUCT_TYPE_ACCESS_DENIED_MSG = "Could Not Add Product Type. Access Denied.";

// Retrieve Products Types List
export const RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG = "Could Not Retrieve Product Types List.";
export const RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG = "Product Types List Retrieved Sucessfully.";
export const RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Product Types List. Access Denied.";

// ------------------------------------------------------------ Unit Measurements -------------------------------------------------------

// Unit Measurement Status
export const RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG = "Could Not Retrieve Unit Measurements List.";
export const RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG = "Unit Measurements List Retrieved Sucessfully.";
export const RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Unit Measurements. Access Denied.";

// Amend Unit Measurement Status
export const AMEND_UNIT_MEASUREMENT_STATUS_ERR_MSG = "Could Not Amend Unit Measurement Status.";
export const AMEND_UNIT_MEASUREMENT_STATUS_SUCCESS_MSG = "Unit Measurement Status Amended Sucessfully.";
export const AMEND_UNIT_MEASUREMENT_STATUS_ACCESS_DENIED_MSG =
  "Could Not Amend Unit Measurement Status. Access Denied.";

// Add Unit Measurement
export const ADD_UNIT_MEASUREMENT_ERR_MSG = "Could Not Add Unit Measurement.";
export const ADD_UNIT_MEASUREMENT_SUCCESS_MSG = "Unit Measurement Status Added Sucessfully.";
export const ADD_UNIT_MEASUREMENT_ACCESS_DENIED_MSG = "Could Not Add Unit Measurement. Access Denied.";

// ------------------------------------------------------------ User Account Property -------------------------------------------------------

// User Account Property
export const ADD_USER_ACCOUNT_PROPERTY_ERR_MSG = "Could Not Add User Account Property.";
export const ADD_USER_ACCOUNT_PROPERTY_SUCCESS_MSG = "User Account Property Added Successfully.";
export const ADD_USER_ACCOUNT_PROPERTY_ACCESS_DENIED_MSG =
  "Could Not Add User Account Property. Access Denied.";

// Retrieve User Account Property List
export const RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_ERR_MSG = "Could Not Retrieve User Property List.";
export const RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_SUCCESS_MSG = "User Account Properties Successfully Retrieved.";
export const RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve User Property List. Access Denied.";

// ------------------------------------------------------------ Company Representative -------------------------------------------------------

// Add Company Representative Role
export const ADD_COMPANY_REPRESENTATIVE_ROLE_ERR_MSG = "Could Not Add Company Representative Role.";
export const ADD_COMPANY_REPRESENTATIVE_ROLE_SUCCESS_MSG = "Company Representative Role Added Successfully.";
export const ADD_COMPANY_REPRESENTATIVE_ROLE_ACCESS_DENIED_MSG =
  "Could Not Add Company Representative Role. Access Denied.";

// Retrieve Company Representative Role List
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ERR_MSG = "Could Not Retrieve Company Representative Roles.";
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_SUCCESS_MSG =
  "Company Representative Roles Retrieved Successfully.";
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLE_LIST_ACCESS_DENIED_MSG =
  "Could Not  Retrieve Company Representative Roles. Access Denied.";

// Add Company Representative
export const ADD_COMPANY_REPRESENTATIVE_ERR_MSG = "Could Not Add Company Representative.";
export const ADD_COMPANY_REPRESENTATIVE_SUCCESS_MSG = "Company Representative Added Successfully.";
export const ADD_COMPANY_REPRESENTATIVE_ACCESS_DENIED_MSG =
  "Could Not  Add Company Representative. Access Denied.";

// ------------------------------------------------------------ Trucks and Trailers -------------------------------------------------------

// Retrieve Truck and Trailers List
export const RETRIEVE_TURCKS_TRAILERS_LIST_ERR_MSG = "Could Not Retrieve Truck and Trailers List.";
export const RETRIEVE_TURCKS_TRAILERS_LIST_SUCCESS_MSG = "Truck and Trailers List Retrieved Successfully.";
export const RETRIEVE_TURCKS_TRAILERS_LIST_ACCESS_DENIED_MSG =
  "Could Not  Retrieve Truck and Trailers List. Access Denied.";

// ------------------------------------------------------------ Weigh Bridge Ticket -------------------------------------------------------

// Retrieve Weigh Bridge Ticket Type List
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ERR_MSG = "Could not Retrieve Weigh Bridge Ticket Type List.";
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_SUCCESS_MSG = "Weigh Bridge Ticket Type List Retrieved Successfully.";
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ACCESS_DENIED_MSG =
  "Could Not Retrieve Weigh Bridge Ticket Type List. Access Denied.";

// Create Goods Recieved  Ticket
export const CREATE_GOODS_RECEIVED_TICKET_ERR_MSG = "Could not Create Goods Recieved Ticket";
export const CREATE_GOODS_RECEIVED_TICKET_SUCCESS_MSG = "Goods Recieved Ticket Created Successfully.";
export const CREATE_GOODS_RECEIVED_TICKET_ACCESS_DENIED_MSG =
  "Could Not  Create Goods Recieved Ticket. Access Denied.";

// Retrieve Goods Recieved Weighbridge Ticket
export const RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ERR_MSG = "Could not Retrieve Goods Received Weigh Bridge Tickets";
export const RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_SUCCESS_MSG = "Goods Received Weigh Bridge Tickets Retrieved Successfully.";
export const RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ACCESS_DENIED_MSG =
  "Could not Retrieve Goods Received Weigh Bridge Tickets. Access Denied.";

// Book Goods Received Into Storage 
export const BOOK_GOODS_RECEIVED_INT0_INVENTORY_ERR_MSG = "Could not Book Goods Received Into Inventory";
export const BOOK_GOODS_RECEIVED_INT0_INVENTORY_SUCCESS_MSG = "Goods Received Successfully Booked in Inventory.";
export const BOOK_GOODS_RECEIVED_INT0_INVENTORY_ACCESS_DENIED_MSG =
  "Could not Book Goods Received Into Inventory. Access Denied.";

// ------------------------------------------------------------ Warehouse -------------------------------------------------------

// Create Warehouse Storage Type
export const CREATE_WAREHUOSE_STORAGE_TYPE_ERR_MSG = "Could not Create Warehouse Storage Type.";
export const CREATE_WAREHUOSE_STORAGE_TYPE_SUCCESS_MSG = "Warehouse Storage Type Added Successfully.";
export const CREATE_WAREHUOSE_STORAGE_TYPE_ACCESS_DENIED_MSG =
  "Could not Create Warehouse Storage Type. Access Denied.";

// Retrieve Warehouse Storage Types
export const RETRIEVE_WAREHOUSE_STORAGE_TYPE_ERR_MSG = "Could not Retrieve Warehouse Storage Types.";
export const RETRIEVE_WAREHOUSE_STORAGE_TYPE_SUCCESS_MSG = "Warehouse Storage Types List Retrieved Successfully.";
export const RETRIEVE_WAREHOUSE_STORAGE_TYPE_ACCESS_DENIED_MSG =
  "Could not Retrieve Warehouse Storage Types. Access Denied.";

// Create Warehouse Storage
export const CREATE_WAREHOUSE_STORAGE_ERR_MSG = "Could not Create Warehouse Storage.";
export const CREATE_WAREHOUSE_STORAGE_SUCCESS_MSG = "Warehouse Storage Added Successfully.";
export const CREATE_WAREHOUSE_STORAGE_ACCESS_DENIED_MSG =
  "Could not Create Warehouse Storage. Access Denied.";

// Retrieve Warehouse Storage List
export const RETRIEVE_WAREHOUSE_STORAGE_LIST_ERR_MSG = "Could not Retrieve Warehouse Storages List.";
export const RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS_MSG = "Warehouse Storages List Retrieved Successfully.";
export const RETRIEVE_WAREHOUSE_STORAGE_LIST_ACCESS_DENIED_MSG =
  "Could not Retrieve Warehouse Storages List. Access Denied.";

// Retrieve Warehouse Inventory List
export const RETRIEVE_WAREHOUSE_INVNENTORY_LIST_ERR_MSG = "Could not Retrieve Warehouse Inventory List.";
export const RETRIEVE_WAREHOUSE_INVNENTORY_LIST_SUCCESS_MSG = "Warehouse Inventory List Retrieved Successfully.";
export const RETRIEVE_WAREHOUSE_INVNENTORY_LIST_ACCESS_DENIED_MSG =
  "Could not Retrieve Warehouse Inventory List. Access Denied.";

// Retrieve Specific Goods Received
export const RETRIEVE_SPECIFIC_GOODS_RECEIVED_ERR_MSG = "Could Not Retrieve Goods Receveied Details.";
export const RETRIEVE_SPECIFIC_GOODS_RECEIVED_SUCCESS_MSG = "Goods Received Details Retrieved Successfully.";
export const RETRIEVE_SPECIFIC_GOODS_RECEIVED_ACCESS_DENIED_MSG =
  "Could Not Retrieve Goods Receveied Details. Access Denied.";

// Retrieve Active Products in Active Branch Location
export const RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_ERR_MSG = "Could Not Retrieve Product Inventory List.";
export const RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_SUCCESS_MSG = "Product Inventory List Retrieved Successfully.";
export const RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_ACCESS_DENIED_MSG =
  "Could Not Retrieve Product Inventory List. Access Denied.";

// Retrieve Warehouse Inventory Product Details
export const RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_ERR_MSG = "Could Not Retrieve Warehouse Inventory Product Details.";
export const RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_SUCCESS_MSG = "Warehouse Inventory Product Details Retrieved Successfully.";
export const RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_ACCESS_DENIED_MSG =
  "Could Not Retrieve Warehouse Inventory Product Details. Access Denied.";

// ------------------------------------------------------------ Delivery Notes -------------------------------------------------------

// Create Delivery Note Ticket
export const CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_ERR_MSG = "Could not Create Delivery Note Ticket.";
export const CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_SUCCESS_MSG = "Delivery Note Ticket Created Successfully.";
export const CREATE_PHYSICAL_DELIVERY_NOTE_TICKET_ACCESS_DENIED_MSG =
  "Could not Create Delivery Note Ticket. Access Denied.";

// Retrieve Delivery Notes
export const RETRIEVE_DELIVERY_NOTES_TICKETS_ERR_MSG = "Could not Retrieve Delivery Notes Tickets.";
export const RETRIEVE_DELIVERY_NOTES_TICKETS_SUCCESS_MSG = "Delivery Notes Tickets Retrieved Successfully.";
export const RETRIEVE_DELIVERY_NOTES_TICKETS_ACCESS_DENIED_MSG =
  "Could not Retrieve Delivery Notes Tickets. Access Denied.";

// Create Delivery Note Product
export const CREATE_DELIVERY_NOTE_PRODUCT_ERR_MSG = "Could not Add Delivery Note Product.";
export const CREATE_DELIVERY_NOTE_PRODUCT_SUCCESS_MSG = "Delivery Note Product Added Successfully.";
export const CREATE_DELIVERY_NOTE_PRODUCT_ACCESS_DENIED_MSG =
  "Could not Add Delivery Note Product. Access Denied.";

// Complete Adding Delivery Note Products
export const COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_ERR_MSG = "Could not Complete Adding Delivery Note Products.";
export const COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_SUCCESS_MSG = "Delivery Note Ticket Successfully Updated.";
export const COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_ACCESS_DENIED_MSG =
  "Could not Complete Adding Delivery Note Products. Access Denied.";

// Complete Processing Delivery Note
export const COMPLETE_PROCESSING_DELIVERY_NOTE_ERR_MSG = "Could not Complete Processing Delivery Note.";
export const COMPLETE_PROCESSING_DELIVERY_NOTE_SUCCESS_MSG = "Delivery Note Processing Completed Successfully.";
export const COMPLETE_PROCESSING_DELIVERY_NOTE_ACCESS_DENIED_MSG =
  "Could not Complete Processing Delivery Note. Access Denied.";

// Retrieve Specific Delivery Note
export const RETRIEVE_SPECIFIC_DELIVERY_NOTE_ERR_MSG = "Could Not Retrieve Delivery Note Details.";
export const RETRIEVE_SPECIFIC_DELIVERY_NOTE_SUCCESS_MSG = "Delivery Note Details Retrieved Successfully.";
export const RETRIEVE_SPECIFIC_DELIVERY_NOTE_ACCESS_DENIED_MSG =
  "Could Not Retrieve Delivery Note Details. Access Denied.";

// Amend Delivery Note Status
export const AMEND_DELIVERY_NOTE_STATUS_ERR_MSG = "Could Note Amend Delivery Note Status.";
export const AMEND_DELIVERY_NOTE_STATUS_SUCCESS_MSG = "Delivery Note Status Successfully Amended.";
export const AMEND_DELIVERY_NOTE_STATUS_ACCESS_DENIED_MSG =
  "Could Note Amend Delivery Note Status. Access Denied.";

// Confirm Delivery Note Delivered
export const CONFIRM_DELIVERY_NOTE_DELIVERED_ERR_MSG = "Could Not Confirm Delivery.";
export const CONFIRM_DELIVERY_NOTE_DELIVERED_SUCCESS_MSG = "Delivery Note Status Successfully Confirmed.";
export const CONFIRM_DELIVERY_NOTE_DELIVERED_ACCESS_DENIED_MSG =
  "Could Not Confirm Delivery. Access Denied.";

// Submit Delivery Note Feedback
export const SUBMIT_DELIVERY_NOTE_FEEDBACK_ERR_MSG = "Could Not Submit Feedback.";
export const SUBMIT_DELIVERY_NOTE_FEEDBACK_SUCCESS_MSG = "Feedback Submitted Successfully.";
export const SUBMIT_DELIVERY_NOTE_FEEDBACK_ACCESS_DENIED_MSG =
  "Could Not Submit Feedback Access Denied.";

//  Continue Processing Delivery Note
export const CONTINUE_PROCESSING_DELIVERY_NOTE_ERR_MSG = "Could Not Continue Processing Delivery Note.";
export const CONTINUE_PROCESSING_DELIVERY_NOTE_SUCCESS_MSG = "Delivery Note Ticket Data Retrieved Successfully.";
export const CONTINUE_PROCESSING_DELIVERY_NOTE_ACCESS_DENIED_MSG =
  "Could Not Continue Processing Delivery Note.. Access Denied.";

// ------------------------------------------------------------ Goods Received -------------------------------------------------------

//  Retrieve Goods Received List
export const RETRIEVE_GOODS_RECEIVED_LIST_ERR_MSG = "Could not Retrieve Goods Received.";
export const RETRIEVE_GOODS_RECEIVED_LIST_SUCCESS_MSG = "Goods Received Retrieved Successfully.";
export const RETRIEVE_GOODS_RECEIVED_LIST_ACCESS_DENIED_MSG =
  "Could not Retrieve Goods Received. Access Denied.";

//  Continue Processing Goods Received
export const CONTINUE_PROCESSING_GOODS_RECEIVED_ERR_MSG = "Could Not Continue Processing Goods Received.";
export const CONTINUE_PROCESSING_GOODS_RECEIVED_SUCCESS_MSG = "Goods Recieved Ticket Data Successfully Retrieved.";
export const CONTINUE_PROCESSING_GOODS_RECEIVED_ACCESS_DENIED_MSG =
  "Could Not Continue Processing Goods Received. Access Denied.";

// Complete Adding Goods Received Note Products
export const COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_ERR_MSG = "Could not Complete Adding Goods Received Note Products.";
export const COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_SUCCESS_MSG = "Goods Recieved Ticket Successfully Updated.";
export const COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_ACCESS_DENIED_MSG =
  "Could not Complete Adding Goods Received Note Products. Access Denied.";

// ------------------------------------------------------------ Employee -------------------------------------------------------
// Employee Department and Roles List
export const RETRIEVE_EMP_DEPT_ROLE_LIST_ERR_MSG = "Could Not Retrieve Employee Department and Role List.";
export const RETRIEVE_EMP_DEPT_ROLE_LIST_SUCCESS_MSG = "Employee Department and Role List Successfully Retrieved.";
export const RETRIEVE_EMP_DEPT_ROLE_LIST_ACCESS_DENIED_MSG =
  "Could Not Retrieve Employee Department and Role List. Access Denied.";

// Employee Role Details
export const RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_ERR_MSG = "Could Not Retrieve Employee Role Details.";
export const RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_SUCCESS_MSG = "Employee Role Details Successfully Retrieved.";
export const RETRIEVE_EMP_ROLE_ACTIONS_DETAILS_ACCESS_DENIED_MSG =
  "Could Not Retrieve Employee Role Details. Access Denied.";

// Create Delivery Note Product
export const CREATE_GOODS_RECEIVED_NOTE_PRODUCT_ERR_MSG = "Could not Add Goods Received Note Product.";
export const CREATE_GOODS_RECEIVED_NOTE_PRODUCT_SUCCESS_MSG = "Goods Received Note Product Added Successfully.";
export const CREATE_GOODS_RECEIVED_NOTE_PRODUCT_ACCESS_DENIED_MSG =
  "Could not Add Goods Received Note Product. Access Denied.";

// ------------------------------------------------------------ Loading Order -------------------------------------------------------
// Create Loading Order
export const CREATE_LOADING_ORDER_ERR_MSG = "Could not Create Loading Order.";
export const CREATE_LOADING_ORDER_SUCCESS_MSG = "Loading Order Created Successfully.";
export const CREATE_LOADING_ORDER_ACCESS_DENIED_MSG =
  "Could not Create Loading Order. Access Denied.";

// Retrieve Loading Order List
export const RETRIEVE_LOADING_ORDER_LIST_ERR_MSG = "Could not Retrieve Loading Orders.";
export const RETRIEVE_LOADING_ORDER_LIST_SUCCESS_MSG = "Loading Orders Retrieved Successfully.";
export const RETRIEVE_LOADING_ORDER_LIST_ACCESS_DENIED_MSG =
  "Could not Retrieve Loading Order. Access Denied.";

// Retrieve Loading Order List
export const ADD_LOADING_ORDER_PRODUCT_ERR_MSG = "Could not Add Product to Loading Order.";
export const ADD_LOADING_ORDER_PRODUCT_SUCCESS_MSG = "Loading Order Product Added Successfully.";
export const ADD_LOADING_ORDER_PRODUCT_ACCESS_DENIED_MSG =
  "Could not Add Product to Loading Order. Access Denied.";

// Retrieve Loading Order List
export const FINISH_ADDING_LOADING_ORDER_PRODUCTS_ERR_MSG = "Could not Complete Process.";
export const FINISH_ADDING_LOADING_ORDER_PRODUCTS_SUCCESS_MSG = "Process Completed Successfully.";
export const FINISH_ADDING_LOADING_ORDER_PRODUCTS_ACCESS_DENIED_MSG =
  "Could not Complete Process. Access Denied.";

// Retrieve Loading Order List
export const RETRIEVE_LOADING_ORDER_DETAILS_ERR_MSG = "Could not Retrieve Loading Order Details.";
export const RETRIEVE_LOADING_ORDER_DETAILS_SUCCESS_MSG = "Loading Order Details Retrieved Successfully.";
export const RETRIEVE_LOADING_ORDER_DETAILS_ACCESS_DENIED_MSG =
  "Could not Retrieve Loading Order Details. Access Denied.";

// Continue Processing Loading Order List
export const CONTINUE_PROCSSING_LOADING_ORDER_ERR_MSG = "Could not Continue Processing Loading Order.";
export const CONTINUE_PROCSSING_LOADING_ORDER_SUCCESS_MSG = "Loading Order Details Retrieved Successfully.";
export const CONTINUE_PROCSSING_LOADING_ORDER_ACCESS_DENIED_MSG =
  "Could not Continue Processing Loading Order. Access Denied.";

// ------------------------------------------------------------ Locations -------------------------------------------------------
// Generic
export const RETRIEVE_LOCATION_ERR_MSG = "Could Not Retrieve Locations.";
export const RETRIEVE_LOCATION_SUCCESS_MSG = "Specific Country Locations Retrieved Succesfully.";
export const RETRIEVE_LOCATION_ACCESS_DENIED_MSG =
  "Could Not Retrieve Locations. Access Denied.";

