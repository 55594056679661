import React, { useEffect, useRef, useState } from "react";
import Logo from "../../images/logo.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../components/inputs/TextInput";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fail_username_verify,
  loading_username_verify,
  reset_username_verify,
  success_username_verify,
} from "../../reducers/website_reducers/auth/username_verify_Slice";
import * as app_consts from "../../constants/react_app_consts";
import { website_actions_helper } from "../../actions/actions_helper";
import { USERNAME_VERIFY_API } from "../../api/api_data";
import { USERNAME_VERIFY_ERR_MSG, USERNAME_VERIFY_SUCESS_MSG } from "../../constants/success_error_messages_consts";
import { reset_send_otp_option } from "../../reducers/website_reducers/auth/send_otp_option_Slice";

// Inital State for Login
const initialState = { username: "" };
// Functional Component for User Login
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Form Data State
  const [formData, setFormData] = useState(initialState);

  const inputRef = useRef(null);

  useEffect(() => {
    // When the component mounts, focus the input element
    if (inputRef.current)
    {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
  };

  const handleSubmit = () => {
    setError("");
    // Run Request Through Website Actions' Helper
    dispatch(
      website_actions_helper(
        loading_username_verify,
        success_username_verify,
        fail_username_verify,
        USERNAME_VERIFY_ERR_MSG,
        USERNAME_VERIFY_SUCESS_MSG,
        app_consts.POST,
        USERNAME_VERIFY_API,
        formData
      )
    );
  };

  // UseSelector to Listen to Any Changes in The Username Request Check
  const username_check = useSelector((state) => state.username_verify.username_verify);

  // UseState for Username Request Check
  useEffect(() => {
    if (username_check.status !== app_consts.IDLE)
    {
      if (username_check.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (username_check.status === app_consts.FAIL)
        {
          setError(username_check.message);
          dispatch(reset_username_verify());
        } else if (username_check.status === app_consts.SUCCESS)
        {
          dispatch(reset_send_otp_option());
          navigate(`/auth/send_otp/${username_check.data.username}`);
        }
      }
    }
  }, [username_check]);

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-website" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-website" src={Logo} alt="logo-dark" />
          </Link>
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Sign-In</BlockTitle>
              <BlockDes>
                <p>Enter your United African Grain Username</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <form
            className="is-alter"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="mb-3">
              <TextInput
                label="Username"
                value={formData.username}
                colSize="12"
                name="username"
                handleChange={handleChange}
                inputRef={inputRef}
                maxlength="11"
              />
            </div>
            <div className="form-group">
              <Button
                onClick={handleSubmit}
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
                disabled={loading ? loading : !formData.username}
              >
                {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
              </Button>
            </div>
          </form>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default Login;
