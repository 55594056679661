import React, { useEffect, useState } from 'react';
import ModalLogout from '../pages/dashboard_pages/components/modal/ModalLogout';
import { Navigate, Route, Routes } from 'react-router';
import LoadingApp from '../pages/dashboard_pages/loading/LoadingApp';
import LayoutNoSidebar from "../layout/Index-nosidebar";
import HomeClient from '../pages/client_pages/HomeClient';
import Layout from "../layout/Index";
import GoodsReceivedList from '../pages/client_pages/goods_received/GoodsReceivedList';
import DeliverynotesList from '../pages/client_pages/delivery_notes/DeliverynotesList';
import LayoutTopBarOnly from '../layout/index_topbar_only';
import { useSelector } from 'react-redux';
import * as app_consts from "../constants/react_app_consts";
import SpecifcGoodsReceived from '../pages/client_pages/goods_received/SpecifcGoodsReceived';
import SpecificDeliveryNote from '../pages/client_pages/delivery_notes/SpecificDeliveryNote';

const ClientRoutes = ({ account_type }) => {

  // Logout Code
  const [logout, setLogout] = useState(false);
  const [app_loading, setAppLoading] = useState(false);
  const [errorVal, setError] = useState("");

  // Listen to 401 Error
  const logout_trigger = useSelector((state) => state.sign_out_modal.sign_out_modal);

  useEffect(() => {
    if (logout_trigger.status === app_consts.SUCCESS)
    {
      setLogout(true);
      setError(app_consts.PAGE_ACCESS_DENIED);
      setAppLoading(false);
      // Remember to Put Code to Recoke the Token In The API
      // console.log(logout_trigger)
    }
  }, [logout_trigger]);

  // UseSelector for Signing Out
  const signout = useSelector((state) => state.sign_out.sign_out);

  // UseEffect for Sign Out
  useEffect(() => {
    if (signout.status === app_consts.SUCCESS)
    {
      window.location.assign("/");
    }
  }, [signout]);

  return (
    <>
      <ModalLogout logout_var={logout} />
      <Routes>
        <Route path="/" element={<Navigate to={`${process.env.PUBLIC_URL}/client`} replace />} />

        <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/client`} replace />} />
        {
          // App Loading
          app_loading ? (
            <>
              <Route path={`${process.env.PUBLIC_URL}/client`} element={<LayoutNoSidebar />}>
                <Route index element={<LoadingApp />}></Route>
                <Route path="*" element={<Navigate to={`/`} replace />} />
              </Route>
            </>
          ) : (
            <>
              <Route path={`${process.env.PUBLIC_URL}/client`} element={<LayoutTopBarOnly />}>
                <Route index element={<HomeClient account_type={account_type} />}></Route>

                <Route path="goods_received">
                  <Route index element={<GoodsReceivedList account_type={account_type} />} />
                  <Route exact path=":goods_received_ref_number" element={<SpecifcGoodsReceived account_type={account_type} />} />
                </Route>
                <Route path="delivery_notes">
                  <Route index element={<DeliverynotesList account_type={account_type} />} />
                  <Route exact path=":delivery_note_ref_number" element={<SpecificDeliveryNote account_type={account_type} />} />

                </Route>

              </Route>
            </>
          )
        }
      </Routes>
    </>
  );
};

export default ClientRoutes;