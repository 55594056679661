import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { messageToast } from "../../../../../../../../utils/Utils";
import {
  fail_gender_type_list,
  loading_gender_type_list,
  reset_gender_type_list,
  success_gender_type_list,
} from "../../../../../../../../reducers/dashboard_reducers/gender/gender_type_list_Slice";
import {
  fail_update_basic_kyc_info,
  loading_update_basic_kyc_info,
  reset_update_basic_kyc_info,
  success_update_basic_kyc_info,
} from "../../../../../../../../reducers/dashboard_reducers/user_kyc/update_basic_kyc_info_Slice";
import { employee_dashboard_actions_helper } from "../../../../../../../../actions/actions_helper";
import {
  RETRIEVE_GENDER_TYPE_ACCESS_DENIED_MSG,
  RETRIEVE_GENDER_TYPE_ERR_MSG,
  RETRIEVE_GENDER_TYPE_SUCCESS_MSG,
  RETRIEVE_USER_DETAILS_ACCESS_DENIED_MSG,
  RETRIEVE_USER_DETAILS_ERR_MSG,
  RETRIEVE_USER_DETAILS_SUCCESS_MSG,
  UPDATE_BASIC_KYC_INFO_ACCESS_DENIED_MSG,
  UPDATE_BASIC_KYC_INFO_ERR_MSG,
  UPDATE_BASIC_KYC_INFO_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import {
  RETRIEVE_GENDER_TYPE_LIST,
  RETRIEVE_MARITAL_STATUS_LIST,
  RETRIEVE_SPECIFIC_USER_DETAILS_API,
  RETRIEVE_USER_TITLE_LIST,
  UPDATE_BASIC_KYC_INFO,
} from "../../../../../../../../api/api_data";
import TextInput from "../../../../../../../components/inputs/TextInput";
import { fail_specific_user_details, loading_specific_user_details, reset_specific_user_details, success_specific_user_details } from "../../../../../../../../reducers/dashboard_reducers/user/specific_user_details_Slice";
import { useParams } from "react-router";
import { reset_specific_country_all } from "../../../../../../../../reducers/dashboard_reducers/location/specific_country_all_Slice";
import { retrieve_specific_country_all_user_kyc_det_action } from "../../../../../../../../actions/dashboard_actions/locations/locations_actions";

const initialState = { gender_type_id: "", marital_status_id: "", user_title_id: "", phone_number: "", address: "", district_id: "" };

const BasicInfoStep = ({ togglestep, nextStep, data }) => {

  const { phone_number } = useParams();
  const [errorVal, setError] = useState("");
  const [step, setStep] = useState(togglestep);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);

  const [gender, setGender] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [user_data, setuserData] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [province_list, setProvinceList] = useState([]);
  const [district_list, setDistrictList] = useState([]);
  const [district_data, setDistrictData] = useState([]);

  // Only Run Code When Step is equal to One - 1
  useEffect(() => {
    if (togglestep === 1)
    {
      // Retrieve DropDown Data
      // Gender
      dispatch(
        employee_dashboard_actions_helper(
          loading_gender_type_list,
          success_gender_type_list,
          fail_gender_type_list,
          RETRIEVE_GENDER_TYPE_ERR_MSG,
          RETRIEVE_GENDER_TYPE_SUCCESS_MSG,
          app_consts.GET,
          RETRIEVE_GENDER_TYPE_LIST,
          null,
          RETRIEVE_GENDER_TYPE_ACCESS_DENIED_MSG,
          app_consts.MAP,
          app_consts.GENDER_MAP
        )
      );
      // Retrieve User Information
      dispatch(
        employee_dashboard_actions_helper(
          loading_specific_user_details,
          success_specific_user_details,
          fail_specific_user_details,
          RETRIEVE_USER_DETAILS_ERR_MSG,
          RETRIEVE_USER_DETAILS_SUCCESS_MSG,
          app_consts.POST,
          RETRIEVE_SPECIFIC_USER_DETAILS_API,
          { phone_number: phone_number },
          RETRIEVE_USER_DETAILS_ACCESS_DENIED_MSG
        )
      );
      setStep(togglestep);


      dispatch(
        retrieve_specific_country_all_user_kyc_det_action(app_consts.MAP, { phone_number: phone_number })
      );
    }
  }, [togglestep]);

  // Listen to Reducer State
  const specific_user_details = useSelector((state) => state.specific_user_details.specific_user_details);
  // 
  useEffect(() => {
    if (specific_user_details.status !== app_consts.IDLE)
    {
      if (specific_user_details.status === app_consts.LOADING)
      {
      } else
      {
        if (specific_user_details.status === app_consts.FAIL)
        {
          messageToast(specific_user_details.message, app_consts.FAIL);
        } else if (specific_user_details.status === app_consts.SUCCESS)
        {
          setuserData(specific_user_details.data);
        }
        dispatch(reset_specific_user_details());
      }
    }
  }, [specific_user_details]);

  // Listen to Changes in Specific Country's Provinces and Districts Reducer
  const specific_country_all = useSelector((state) => state.specific_country_all.specific_country_all);
  //
  useEffect(() => {
    if (specific_country_all.status !== app_consts.IDLE && specific_country_all.status !== app_consts.LOADING)
    {
      if (specific_country_all.status === app_consts.FAIL)
      {
        messageToast(specific_country_all.message, app_consts.FAIL);
      } else if (specific_country_all.status === app_consts.SUCCESS)
      {
        // setGender(specific_country_all.data)
        setProvinceList(specific_country_all.data.provinces);
        setDistrictData(specific_country_all.data.districts);
      }
      dispatch(reset_specific_country_all());
    }
  }, [specific_country_all]);

  // Reading Data Passed Along
  useEffect(() => {
    if (data)
    {
      setFormData({ ...formData, ["phone_number"]: data.phone_number });
    }
  }, [data]);

  // Listen to Changes in Gender List Retrieval
  const gender_type_list = useSelector((state) => state.gender_type_list.gender_type_list);
  //
  useEffect(() => {
    if (gender_type_list.status !== app_consts.IDLE && gender_type_list.status !== app_consts.LOADING)
    {
      if (gender_type_list.status === app_consts.FAIL)
      {
        messageToast(gender_type_list.message, app_consts.FAIL);
      } else if (gender_type_list.status === app_consts.SUCCESS)
      {
        setGender(gender_type_list.data);
      }
      dispatch(reset_gender_type_list());
    }
  }, [gender_type_list]);

  // Submit Form
  const submitBasicInfo = () => {
    setError("");
    // Update Basic Info
    dispatch(
      employee_dashboard_actions_helper(
        loading_update_basic_kyc_info,
        success_update_basic_kyc_info,
        fail_update_basic_kyc_info,
        UPDATE_BASIC_KYC_INFO_ERR_MSG,
        UPDATE_BASIC_KYC_INFO_SUCCESS_MSG,
        app_consts.POST,
        UPDATE_BASIC_KYC_INFO,
        formData,
        UPDATE_BASIC_KYC_INFO_ACCESS_DENIED_MSG
      )
    );
  };
  // Listen to State of Basic Info
  const update_basic_kyc_info = useSelector((state) => state.update_basic_kyc_info.update_basic_kyc_info);
  //
  useEffect(() => {
    if (update_basic_kyc_info.status !== app_consts.IDLE)
    {
      if (update_basic_kyc_info.status === app_consts.LOADING)
      {
        setIsLoading(true);
      } else
      {
        setIsLoading(false);
        if (update_basic_kyc_info.status === app_consts.FAIL)
        {
          messageToast(update_basic_kyc_info.message, app_consts.FAIL);
          setError(update_basic_kyc_info.message, app_consts.FAIL);
        } else if (update_basic_kyc_info.status === app_consts.SUCCESS)
        {
          messageToast("User Basic Info Successfully Updated", app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_update_basic_kyc_info());
      }
    }
  }, [update_basic_kyc_info]);


  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    filterDistricts(selectedProvince);
  }, [selectedProvince]);

  // Filter Districts
  const filterDistricts = (received_id) => {
    let filteredDistricts = district_data?.filter((item) => {
      return item.province_id === received_id;
    });
    // Remapped Districts
    const mappedDistricts = filteredDistricts?.map((item) => {
      return {
        label: item.district_name,
        value: item.district_id,
      };
    });
    setDistrictList(mappedDistricts);
  };

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Basic Information</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <Col lg="6" className="mb-3">
              <div className="form-group">
                <label className="form-label" htmlFor="gender_type">
                  Gender
                </label>
                <div className="form-control-wrap">
                  <RSelect
                    options={gender}
                    className="w-100%"
                    id="gender_type"
                    onChange={(e) => setFormData({ ...formData, ["gender_type_id"]: e.value })}
                  />
                </div>
              </div>
            </Col>
            <TextInput
              label="Country"
              type="text"
              id_val="country"
              disabled
              value={user_data.country ? user_data.country : ""}
              name="country"
            />
            <TextInput
              label="Address"
              type="text"
              id_val="address"
              value={user_data.address}
              handleChange={handleChange}
              name="address"
              maxlength="100"
            />
            {user_data?.country &&
              (user_data?.country === "Zambia" ? (
                <>
                  <Col lg="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="province_state">
                        Province/ State
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={province_list}
                          className="w-100%"
                          id="province_state"
                          onChange={(e) => {
                            setFormData({ ...formData, ["district_id"]: "" });
                            setSelectedProvince(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="district_id">
                        County/ District
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={district_list}
                          className="w-100%"
                          id="district_id"
                          onChange={(e) => setFormData({ ...formData, ["district_id"]: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                ""
              ))}
            <Col xl="12" className="mb-3">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-end">
                  <Button
                    onClick={submitBasicInfo}
                    color="primary"
                    size="lg"
                    disabled={
                      loading
                        ? loading
                        : user_data?.country === "Zambia"
                          ? !(formData.address && formData.district_id && formData.phone_number && formData.gender_type_id)
                          : !(formData.phone_number && formData.gender_type_id)
                    }
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Next"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default BasicInfoStep;
