import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retrieve_employee_list: { status: "idle" },
};

export const retrieve_employee_list_Slice = createSlice({
    name: "retrieve_employee_list",
    initialState,
    reducers: {
        success_retrieve_employee_list: (state, action) => {
            state.retrieve_employee_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_employee_list: (state, action) => {
            state.retrieve_employee_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_employee_list: (state) => {
            state.retrieve_employee_list = { status: app_consts.IDLE };
        },
        loading_retrieve_employee_list: (state) => {
            state.retrieve_employee_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_retrieve_employee_list, fail_retrieve_employee_list, reset_retrieve_employee_list, loading_retrieve_employee_list } = retrieve_employee_list_Slice.actions;

export default retrieve_employee_list_Slice.reducer;