import React, { useEffect, useState } from 'react';
import { Card, CardTitle } from 'reactstrap';
import { Col, Row } from '../../../../../../../../components/Component';
import { PDFViewer } from '@react-pdf/renderer';
import LoadingOrderPDF from '../../../../../pdf/LoadingOrderPDF';

const LoadingOrderPDFStep = ({ wizard_data, setWizardData, nextStep }) => {

 const [page_data, setpageData] = useState(wizard_data);

 useEffect(() => {
  setpageData(wizard_data);
 }, [wizard_data]);

 return (
  <Card className="card-inner">
   <CardTitle tag="h5">Goods Received Note</CardTitle>
   <Col lg="12">
    <div className="form-group d-flex justify-between">
     <div className="form-group">
      <div className="form-label-group">
       <label className="form-label text-soft">User Account :</label>
      </div>
      <div className="form-control-wrap">
       <label className="form-label text-primary">{page_data?.consigner?.username ? `${page_data?.consigner?.username} - ${page_data?.consigner?.unique_identification_number}` : "--"}</label>
      </div>
     </div>
    </div>
   </Col>
   <Row className="g-4">
    <div style={{ height: "90vh", width: "100%" }} className='border-2 border-text-gray-400'>
     <PDFViewer height="100%" width="100%" showToolbar>
      <LoadingOrderPDF page_data={page_data} />
     </PDFViewer>
    </div>
   </Row>
  </Card>
 );
};

export default LoadingOrderPDFStep;