import React, { useEffect, useState } from 'react';
import { Block, DataTable, DataTableBody, DataTableHead, DataTableRow, PaginationComponent } from '../../../../components/Component';
import { check_list_Columns } from './table_list_data/table_list_columns';
import { CustomerFeedBackCheckListData } from './table_components/check_list_row_data';
import * as app_consts from "../../../../constants/react_app_consts";

const CheckListTable = ({ tableType, data, editMode, amendFunction }) => {

    const [tableData, setTableData] = useState(data);
    const [tableColumns, setTableColumns] = useState(check_list_Columns(tableType));
    const [type, setType] = useState(tableType);
    const [edit, setEdit] = useState(editMode);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);

    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = tableData?.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        setType(tableType);
        setTableColumns(check_list_Columns(tableType));
    }, [tableType]);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode]);

    useEffect(() => {
        setTableData(data);
    }, [data]);


    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>

            <Block>
                <DataTable className="card-stretch">
                    <DataTableBody>
                        <DataTableHead>
                            {tableColumns &&
                                tableColumns?.map((column) => {
                                    return (
                                        <DataTableRow key={column.key}>
                                            <span>{column.label}</span>
                                        </DataTableRow>
                                    );
                                })}
                        </DataTableHead>
                        {/* Table Rows */}
                        {
                            // Property Data
                            type === app_consts.CUSTOMER_FEEDBACK_TABLE ? (
                                <CustomerFeedBackCheckListData
                                    data={tableData}
                                    currentItems={currentItems}
                                    amendFunction={amendFunction}
                                    editMode={editMode}
                                />
                            )
                                : ""
                        }
                    </DataTableBody>
                    <div className="card-inner">
                        {tableData.length > 0 ? (
                            <PaginationComponent
                                noDown
                                itemPerPage={itemPerPage}
                                totalItems={tableData.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        ) : (
                            <div className="text-center">
                                <span className="text-silent">No Data Found</span>
                            </div>
                        )}
                    </div>
                </DataTable>
            </Block>
        </>
    );
};

export default CheckListTable;