import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BlockBetween, BlockHeadContent } from "../../../../../components/Component";
import ListText from "../../text/ListText";
import {
  CreateBranchLocationPrev,
  CreateTrailerPrev,
  CreateTruckPrev,
  CreateUserPrev,
  CreatePhysicalWeighBridgeTicketPrev,
  BookPhysicalGoodsRecievedIntoStoragePrev,
  CreatePhysicaDeliveryNotelWeighBridgeTicketPrev,
  FinalDeliveryNoteDetailsPrev,
} from "./modal_preveiw_data/ModalPreviewData";
import * as app_consts from "../../../../../constants/react_app_consts";

const ModalPreview = ({
  openPreview,
  setOpenPreview,
  moduleButtonText,
  modalTitle,
  modalDescription,
  prevType,
  prevData,
  buttonFunction,
}) => {
  const [modalPrev, setModalPrev] = useState(openPreview);

  const togglePrev = () => {
    setOpenPreview(!modalPrev);
  };

  // UseEffect for Opening Modal
  useEffect(() => {
    setModalPrev(openPreview);
  }, [openPreview]);

  return (
    <>
      <Modal isOpen={modalPrev} toggle={togglePrev} className="modal-dialog-center">
        <ModalHeader toggle={togglePrev}>{modalTitle}</ModalHeader>
        <ModalBody>
          <p>{modalDescription}</p>
          {
            // Preview User
            prevType === "create_user" ? (
              <CreateUserPrev prevData={prevData} />
            ) : // Preview Truck
              prevType === app_consts.PREVIEW_ONBOARD_TRUCK ? (
                <CreateTruckPrev prevData={prevData} />
              ) : // Preview Trailer
                prevType === app_consts.PREVIEW_ONBOARD_TRAILER ? (
                  <CreateTrailerPrev prevData={prevData} />
                ) : //Preview Add Branch Location
                  prevType === app_consts.PREVIEW_ADD_BRANCH_LOCATION ? (
                    <CreateBranchLocationPrev prevData={prevData} />
                  ) : //Preview Create WeighBridge Ticket
                    prevType === app_consts.PREVIEW_CREATE_PHYSICAL_WEIGHBRIDGE_TICKET ? (
                      <CreatePhysicalWeighBridgeTicketPrev prevData={prevData} />
                    ) ://Preview Book Physical Goods Received Into Storage
                      prevType === app_consts.BOOK_PHYSICAL_GOODS_RECEIVED_INTO_STORAGE ? (
                        <BookPhysicalGoodsRecievedIntoStoragePrev prevData={prevData} />
                      ) ://Preview Create Physical Delivery Note Weigh Bridge Ticket
                        prevType === app_consts.PREVIEW_CREATE_PHYSICAL_DELIVERY_NOTE_WEIGHBRIDGE_TICKET ? (
                          <CreatePhysicaDeliveryNotelWeighBridgeTicketPrev prevData={prevData} />
                        ) : //Preview Final Delivery Note Details Step
                          prevType === app_consts.FINAL_DELIVERY_NOTE_DETAILS ? (
                            <FinalDeliveryNoteDetailsPrev prevData={prevData} />
                          ) : (
                            ""
                          )
          }
        </ModalBody>
        <ModalFooter className="bg-light">
          <BlockBetween>
            <BlockHeadContent>
              <Button onClick={buttonFunction} color="primary">
                {moduleButtonText}
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalPreview;
