import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Label, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../../components/Component";
import { Link } from "react-router-dom";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import RadioInput from "../../../../../input/RadioInput";
import { employee_dashboard_actions_helper } from "../../../../../../../../actions/actions_helper";
import { CREATE_GOODS_RECEIVED_TICKET_API, RETRIEVE_ACTIVE_PRODUCT_LIST, RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST, RETRIEVE_PRODUCTS_LIST, RETRIEVE_TRUCKS_TRAILERS_LIST } from "../../../../../../../../api/api_data";
import {
  CREATE_GOODS_RECEIVED_TICKET_ACCESS_DENIED_MSG,
  CREATE_GOODS_RECEIVED_TICKET_ERR_MSG,
  CREATE_GOODS_RECEIVED_TICKET_SUCCESS_MSG,
  RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_PRODUCTS_LIST_ERR_MSG,
  RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
  RETRIEVE_TURCKS_TRAILERS_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_TURCKS_TRAILERS_LIST_ERR_MSG,
  RETRIEVE_TURCKS_TRAILERS_LIST_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import {
  fail_retrieve_trucks_trailers_list,
  loading_retrieve_trucks_trailers_list,
  reset_retrieve_trucks_trailers_list,
  success_retrieve_trucks_trailers_list,
} from "../../../../../../../../reducers/dashboard_reducers/truck/retrieve_trucks_trailers_list_Slice";
import { messageToast, rSelectMapFun } from "../../../../../../../../utils/Utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextInput from "../../../../../../../components/inputs/TextInput";
import ModalPreview from "../../../../../modal/modal_preview/ModalPreview";
import { formatDate } from "@fullcalendar/core";
import { fail_create_goods_recieved_ticket, loading_create_goods_recieved_ticket, reset_create_goods_recieved_ticket, success_create_goods_recieved_ticket } from "../../../../../../../../reducers/dashboard_reducers/warehouse/create_goods_received_ticket_Slice";
import { fail_product_type_list, loading_product_type_list, reset_product_type_list, success_product_type_list } from "../../../../../../../../reducers/dashboard_reducers/products/product_type_list_Slice";
import { fail_product_list, loading_product_list, reset_product_list, success_product_list } from "../../../../../../../../reducers/dashboard_reducers/products/product_list_Slice";
import { retreive_rSelectVariables } from "../../../../../../../../utils/functionalUtils";

const TruckDetailsStep = ({
  weighBridgeFormData,
  setWeighBridgeFormData,
  truck_accounts_list,
  loadingList,
  refreshList,
  sentStep,
  prevStep,
  nextStep,
  setContinueProcessingData
}) => {
  const [errorVal, setErrorVal] = useState("");
  const [formData, setFormData] = useState(weighBridgeFormData);
  const [listLoading, setListLoading] = useState(loadingList);
  const [addTrailer, setAddTrailer] = useState(false);
  const dispatch = useDispatch();
  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState("");
  const [trailers_one, setTrailersOne] = useState([]);
  const [trailers_two, setTrailersTwo] = useState([]);

  useEffect(() => {
    setFormData(weighBridgeFormData);
  }, [weighBridgeFormData]);

  useEffect(() => {
    setWeighBridgeFormData(formData);
  }, [formData]);

  useEffect(() => {
    if (sentStep === 2)
    {
      retrieve_product_type_list();
      retrieve_product_list();
    }
  }, [sentStep]);

  useEffect(() => {
    setListLoading(loadingList);
  }, [loadingList]);

  // Previous Button
  const previousStep = () => {
    setWeighBridgeFormData({
      ...weighBridgeFormData, ["truck_driver_name"]: "", ["truck_licence_plate"]: "", ["trailer_one"]: "", ["trailer_two"]: "", ["comments"]: "", ["first_weight"]: "", ["ticket_type"]: "Physical", ["transporter_name"]: "", ["branch_location_id"]: "", ["branch_location_name"]: ""
    });

    prevStep();
  };

  // Filter Trailer 2
  const filterTrailers = (received_id) => {
    let filteredTrailer = trailers_one?.filter((item) => {
      return item.value !== received_id;
    });
    setTrailersTwo(filteredTrailer);
  };

  // Filter Trailer Two Options
  useEffect(() => {
    if (formData.truck_trailer_id_one)
    {
      filterTrailers(formData.truck_trailer_id_one);
    }
  }, [formData.truck_trailer_id_one]);

  // Handle Change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle First Weight Blur
  const handleFirstWeightBlur = () => {
    if (formData?.first_weight)
    {
      setFormData({ ...formData, ["first_weight"]: parseFloat(formData.first_weight).toFixed(2) });
    }
  };

  //Preview Details
  const handlePreview = () => {
    setErrorVal("");
    setOpenPreview(true);
  };

  //Preview Details
  const handleSubmit = () => {
    setErrorVal("");
    setOpenPreview(false);
    // Create Good Received Ticket
    dispatch(
      employee_dashboard_actions_helper(
        loading_create_goods_recieved_ticket,
        success_create_goods_recieved_ticket,
        fail_create_goods_recieved_ticket,
        CREATE_GOODS_RECEIVED_TICKET_ERR_MSG,
        CREATE_GOODS_RECEIVED_TICKET_SUCCESS_MSG,
        app_consts.POST,
        CREATE_GOODS_RECEIVED_TICKET_API,
        {
          consigner_type_id: formData.consigner_type_id, first_weight: formData.first_weight, weigh_bridge_ticket_type_id: formData.weigh_bridge_ticket_type_id, truck_driver_name: formData.truck_driver_name, user_account_id: formData.user_account_id, transporter_name: formData.transporter_name, truck_licence_plate: formData.truck_licence_plate, comments: formData.comments, trailer_one: formData.trailer_one, trailer_two: formData.trailer_two, branch_location_id: formData.branch_location_id
        },
        CREATE_GOODS_RECEIVED_TICKET_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Create Goods Receieved Listener
  const create_goods_recieved_ticket = useSelector((state) => state.create_goods_recieved_ticket.create_goods_recieved_ticket);

  useEffect(() => {
    if (create_goods_recieved_ticket.status !== app_consts.IDLE)
    {
      if (create_goods_recieved_ticket.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (create_goods_recieved_ticket.status === app_consts.FAIL)
        {
          setErrorVal(create_goods_recieved_ticket.message);
          messageToast(create_goods_recieved_ticket.message, app_consts.FAIL);
        } else if (create_goods_recieved_ticket.status === app_consts.SUCCESS)
        {
          setContinueProcessingData(create_goods_recieved_ticket.data);
          messageToast(CREATE_GOODS_RECEIVED_TICKET_SUCCESS_MSG, app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_create_goods_recieved_ticket());
      }
    }
  }, [create_goods_recieved_ticket]);

  // Retrieve Product Type List
  const retrieve_product_type_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_product_type_list,
        success_product_type_list,
        fail_product_type_list,
        RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
        RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST,
        null,
        RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG,
        app_consts.MAP,
        app_consts.PRODUCT_TYPE_MAP
      )
    );
  };


  // Retrieve Product list
  const retrieve_product_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_product_list,
        success_product_list,
        fail_product_list,
        RETRIEVE_PRODUCTS_LIST_ERR_MSG,
        RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_PRODUCT_LIST,
        null,
        RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Select Delivery Details</CardTitle>
        <Col lg="12">
          <div className="form-group">
            {weighBridgeFormData.consigner_type_id === app_consts.UAG_CONSIGNER_TYPE ? (
              <>
                <div className="form-label-group">
                  <label className="form-label text-soft">User Account ID :</label>
                </div>
                <div className="form-control-wrap">
                  <label className="form-label text-primary">UAG</label>
                </div>
              </>
            ) : (
              <>
                <div className="form-label-group">
                  <label className="form-label text-soft">User Account ID :</label>
                </div>
                <div className="form-control-wrap">
                  <label className="form-label text-primary">{weighBridgeFormData.user_account_label}</label>
                </div>
              </>
            )}
          </div>
        </Col>
        <hr />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="g-4">
            <div className="form-label-group">
              <label className="form-label text-soft fs-22px">Truck Details</label>
            </div>
            <TextInput
              colSize={5}
              label="Transporter"
              type="text"
              id_val="transporter_name"
              value={formData.transporter_name}
              handleChange={handleChange}
              name="transporter_name"
            />
            <TextInput
              colSize={5}
              label="Truck Driver"
              type="text"
              id_val="truck_driver_name"
              value={formData.truck_driver_name}
              handleChange={handleChange}
              name="truck_driver_name"
            />
            <TextInput
              colSize={5}
              label="Truck License Plate"
              type="text"
              id_val="truck_licence_plate"
              value={formData.truck_licence_plate}
              handleChange={handleChange}
              name="truck_licence_plate"
            />
            <TextInput
              colSize={5}
              label="Gross Weight (Metric Tonnes)"
              type="number"
              min={0.00}
              id_val="first_weight"
              value={formData.first_weight}
              handleChange={handleChange}
              name="first_weight"
              onBlur={handleFirstWeightBlur}
            />
            <TextInput
              colSize={5}
              label="Trailer 1 (Optional)"
              type="text"
              id_val="trailer_one"
              value={formData.trailer_one}
              handleChange={handleChange}
              name="trailer_one"
              onBlur={handleFirstWeightBlur}
            />
            <TextInput
              colSize={5}
              label="Trailer 2 (Optional)"
              type="text"
              id_val="trailer_two"
              value={formData.trailer_two}
              handleChange={handleChange}
              name="trailer_two"
              onBlur={handleFirstWeightBlur}
            />
            {formData.truck_id && (
              <>
                <hr />
                <Col size="12" className="mt-0">
                  <div className="form-group">
                    <div className="form-control-wrap-flex-wrap justify-start">
                      <Button
                        onClick={() => {
                          setAddTrailer(!addTrailer);
                          setFormData({ ...formData, ["truck_trailer_id_one"]: "", ["truck_trailer_id_two"]: "", ["trailer_1"]: "", ["trailer_2"]: "" });
                        }}
                        className="btn btn-dim btn-outline-primary"
                        size="md"
                      >
                        {addTrailer ? "Remove Trailers" : "Add Trailers (Optional)"}
                      </Button>
                    </div>
                  </div>
                </Col>
              </>
            )}
            <hr />
            <Col sm="10" className="mt-0">
              <div className="form-group">
                <Label htmlFor="default-textarea" className="form-label">
                  WeighBridge Ticket Comments (Optional)
                </Label>
                <div className="form-control-wrap">
                  <textarea
                    className="no-resize form-control"
                    type="textarea"
                    id="default-textarea"
                    value={formData.comments}
                    onChange={(e) => setFormData({ ...formData, ["comments"]: e.target.value })}
                  />
                </div>
              </div>
            </Col>
            <Col xl="12">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-between items center">
                  <Button className="btn btn-dim btn-outline-primary" size="md" onClick={previousStep}>
                    Previous
                  </Button>
                  <Button
                    onClick={handlePreview}
                    disabled={
                      loading
                        ? loading
                        : !(formData.truck_driver_name && formData.truck_licence_plate && formData.first_weight && formData.transporter_name)
                    }
                    color="primary"
                    size="md"
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Preview & Create Ticket"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {errorVal && (
            <div className="mt-4">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" />
                {errorVal}{" "}
              </Alert>
            </div>
          )}
        </form>
      </Card>
      {/* Modal For Previewing Before Create */}
      <ModalPreview
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        modalTitle={`Create Goods Received Preview`}
        modalDescription={`Please Confirm Goods Received Details and Select 'Create Weigh Bridge Ticket' to Create Goods Received Ticket.`}
        moduleButtonText="Create Weigh Bridge Ticket"
        prevType={app_consts.PREVIEW_CREATE_PHYSICAL_WEIGHBRIDGE_TICKET}
        prevData={weighBridgeFormData}
        buttonFunction={handleSubmit}
      />
    </>
  );
};

export default TruckDetailsStep;
