import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retrieve_specific_goods_received: { status: "idle" },
};

export const retrieve_specific_goods_received_Slice = createSlice({
    name: "retrieve_specific_goods_received",
    initialState,
    reducers: {
        success_retrieve_specific_goods_received: (state, action) => {
            state.retrieve_specific_goods_received = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_specific_goods_received: (state, action) => {
            state.retrieve_specific_goods_received = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_specific_goods_received: (state) => {
            state.retrieve_specific_goods_received = { status: app_consts.IDLE };
        },
        loading_retrieve_specific_goods_received: (state) => {
            state.retrieve_specific_goods_received = { status: app_consts.LOADING };
        },
    }
});

export const { success_retrieve_specific_goods_received, fail_retrieve_specific_goods_received, reset_retrieve_specific_goods_received, loading_retrieve_specific_goods_received } = retrieve_specific_goods_received_Slice.actions;

export default retrieve_specific_goods_received_Slice.reducer;