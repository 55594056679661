import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retrieve_user_account_property_list: { status: "idle" },
};

export const retrieve_user_account_property_list_Slice = createSlice({
    name: "retrieve_user_account_property_list",
    initialState,
    reducers: {
        success_retrieve_user_account_property_list: (state, action) => {
            state.retrieve_user_account_property_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_user_account_property_list: (state, action) => {
            state.retrieve_user_account_property_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_user_account_property_list: (state) => {
            state.retrieve_user_account_property_list = { status: app_consts.IDLE };
        },
        loading_retrieve_user_account_property_list: (state) => {
            state.retrieve_user_account_property_list = { status: app_consts.LOADING };
        },
    },
});

export const {
    success_retrieve_user_account_property_list,
    fail_retrieve_user_account_property_list,
    reset_retrieve_user_account_property_list,
    loading_retrieve_user_account_property_list,
} = retrieve_user_account_property_list_Slice.actions;

export default retrieve_user_account_property_list_Slice.reducer;
