import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    sign_out_modal: { status: "idle" },
};

export const sign_out_modal_Slice = createSlice({
    name: "sign_out_modal",
    initialState,
    reducers: {
        success_sign_out_modal: (state) => {
            state.sign_out_modal = { status: app_consts.SUCCESS };
        }
    }
});

export const { success_sign_out_modal } = sign_out_modal_Slice.actions;

export default sign_out_modal_Slice.reducer;