import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import * as app_consts from "../constants/react_app_consts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ModalLogout from "../pages/dashboard_pages/components/modal/ModalLogout";
import LoadingApp from "../pages/dashboard_pages/loading/LoadingApp";
import OnBoardingIndex from "../pages/dashboard_pages/onboarding/OnboardingIndex";
import UserOnboardingIndex from "../pages/dashboard_pages/onboarding/user/UserOnboardingIndex";
import CreateUser from "../pages/dashboard_pages/onboarding/user/CreateUser";
import UserList from "../pages/dashboard_pages/users/UserList";
import AssignUserIndex from "../pages/dashboard_pages/onboarding/user/AssignUserIndex";
import WeighBridgeTicketIndex from "../pages/dashboard_pages/warehouse/WeighBridgeTicketIndex";
import CreateGoodsReceived from "../pages/dashboard_pages/warehouse/CreateGoodsReceived";
import ViewGoodsRecievedDetails from "../pages/dashboard_pages/warehouse/ViewGoodsRecievedDetails";
import AssignCustomer from "../pages/dashboard_pages/onboarding/user/AssignCustomer";
import CompleteUserKYC from "../pages/dashboard_pages/onboarding/user/CompleteUserKYC";
import ErrorApp from "../pages/dashboard_pages/loading/ErrorApp";
import VehicleOnboardingIndex from "../pages/dashboard_pages/onboarding/vehicle/VehicleOnboardingIndex";
import TruckOnboarding from "../pages/dashboard_pages/onboarding/vehicle/TruckOnboarding";
import TrailerOnboarding from "../pages/dashboard_pages/onboarding/vehicle/TrailerOnboarding";
import AssignTruckDriver from "../pages/dashboard_pages/onboarding/user/AssignTruckDriver";
import {
  fail_auth_routes,
  loading_auth_routes,
  success_auth_routes,
} from "../reducers/dashboard_reducers/auth/auth_routes_Slice";
import EmployeeList from "../pages/dashboard_pages/admin/employees/EmployeeList";
import BranchLocationsList from "../pages/dashboard_pages/admin/branch_locations/BranchLocationsList";
import AddBranchLocation from "../pages/dashboard_pages/admin/branch_locations/AddBranchLocation";
import ProductList from "../pages/dashboard_pages/admin/products/ProductList";
import ProductTypeList from "../pages/dashboard_pages/admin/products/ProductTypeList";
import UnitMeasurementList from "../pages/dashboard_pages/admin/products/UnitMeasurementList";
import CompanyOnboardingIndex from "../pages/dashboard_pages/onboarding/company/CompanyOnboardingIndex";
import CreateCompanyAccount from "../pages/dashboard_pages/onboarding/company/CreateCompanyAccount";
import GoodsReceivedWeighBridgeTicketList from "../pages/dashboard_pages/warehouse/GoodsReceivedWeighBridgeTicketList";
import SpecificBranchLocation from "../pages/dashboard_pages/admin/branch_locations/SpecificBranchLocation";
import WarehouseInventoryList from "../pages/dashboard_pages/warehouse/WarehouseInventoryList";
import DeliveryNotesList from "../pages/dashboard_pages/warehouse/DeliveryNotesList";
import CreateDeliveryNote from "../pages/dashboard_pages/warehouse/CreateDeliveryNote";
import UserAccountsList from "../pages/dashboard_pages/users/UserAccountsList";
import DeliveryNoteDetails from "../pages/dashboard_pages/warehouse/DeliveryNoteDetails";
import UserDetails from "../pages/dashboard_pages/users/UserDetails";
import WarehouseInventoryProduct from "../pages/dashboard_pages/warehouse/WarehouseInventoryProduct";
import CompanyAccountDetails from "../pages/dashboard_pages/users/CompanyAccountDetails";
import DepartmentAndRolesList from "../pages/dashboard_pages/admin/employees/DepartmentAndRolesList";
import DepartmentRolesDetails from "../pages/dashboard_pages/admin/employees/DepartmentRolesDetails";
import LoadingOrderslist from "../pages/dashboard_pages/warehouse/LoadingOrderslist";
import { findModuleAction } from "../utils/functionalUtils";
import CreateLoadingOrder from "../pages/dashboard_pages/warehouse/CreateLoadingOrder";
import LoadingOrderDetails from "../pages/dashboard_pages/warehouse/LoadingOrderDetails";

const DashboardRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [account_actions, setAccountActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);


  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("log_cred"))
    {
      setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
    }
  }, []);

  useEffect(() => {
    if (account_actions)
    {
      dispatch(success_auth_routes(account_actions));
      // Simulate loading for 2 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer); // Clean up the timer on component unmount
    }
    ;
  }, [account_actions]);

  // UseSelector for Signing Out
  const signout = useSelector((state) => state.sign_out.sign_out);

  // UseEffect for Sign Out
  useEffect(() => {
    if (signout.status === app_consts.SUCCESS)
    {
      window.location.assign("/");
    }
  }, [signout]);

  // Logout Code
  const [logout, setLogout] = useState(false);

  // Listen to 401 Error
  const logout_trigger = useSelector((state) => state.sign_out_modal.sign_out_modal);

  useEffect(() => {
    if (logout_trigger.status === app_consts.SUCCESS)
    {
      setLogout(true);
    }
  }, [logout_trigger]);

  if (!account_actions)
  {
    // Render a loading state while waiting for local storage data
    return <LoadingApp />;
  }

  return (
    <>
      <ModalLogout logout_var={logout} />
      <Routes>

        <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Layout />}>

          {/* Onboarding */}
          {
            account_actions?.map((module) => {
              if (module.module_name === app_consts.ONBOARDING)
              {
                return (
                  <>
                    <Route path="onboarding">

                      {/* Nesting User */}
                      <Route path="company">
                        <Route index element={<CompanyOnboardingIndex />} />
                        <Route exact path=":phone_number" element={<CreateCompanyAccount />} />
                        <Route exact path=":company_id" element={<TrailerOnboarding />} />
                      </Route>
                      <Route path="user">
                        <Route index element={<UserOnboardingIndex />} />

                        <Route exact path="create_user" element={<CreateUser />} />
                      </Route>
                    </Route>
                  </>
                );
              }
            })
          }

          {/* Users */}
          {
            account_actions?.map((module) => {
              if (module.module_name === app_consts.USER_MANAGEMENT) 
              {
                if (findModuleAction(app_consts.VIEW_USERS, module) || findModuleAction(app_consts.VIEW_USER_ACCOUNTS, module))
                {
                  return (
                    <Route path="user">
                      <Route index element={<UserList />} />
                      <Route path="accounts">
                        <Route index exact element={<UserAccountsList />} />
                        <Route exact path="company/:user_account" element={<CompanyAccountDetails />} />
                      </Route>
                      <Route exact path=":phone_number" element={<UserDetails />} />

                      {/*<Route exact path="create_user" element={<CreateUser />} />
                                </Route> */}
                    </Route>
                  );
                }
                else if (findModuleAction(app_consts.VIEW_USER_ACCOUNTS, module))
                {
                }
              }
            })
          }

          {/* Goods Received */}
          <Route path="goods_received">
            <Route index element={<GoodsReceivedWeighBridgeTicketList />} />
            <Route exact path=":goods_received_ref_number" element={<ViewGoodsRecievedDetails />} />
            <Route exact path="create_physical_goods_received_ticket" element={<CreateGoodsReceived page_type={app_consts.CREATE_PHYSICAL_GOODS_RECEIVED_TICKET} />} />
            <Route exact path="create_virtual_goods_received_ticket" element={<CreateGoodsReceived page_type={app_consts.CREATE_VIRTUAL_GOODS_RECEIVED_TICKET} />} />
            <Route exact path="continue_processing_virtual/:goods_received_ref_number" element={<CreateGoodsReceived page_type={app_consts.CREATE_VIRTUAL_GOODS_RECEIVED_TICKET} />} />
            <Route exact path="continue_processing_physical/:goods_received_ref_number" element={<CreateGoodsReceived page_type={app_consts.CREATE_PHYSICAL_GOODS_RECEIVED_TICKET} />} />
          </Route>

          {/* Delivery Notes */}
          <Route path="delivery_notes">
            <Route index element={<DeliveryNotesList />} />
            <Route exact path="create_physical_delivery_note_ticket" element={<CreateDeliveryNote page_type={app_consts.CREATE_PHYSICAL_DELIVERY_NOTE_TICKET} />} />
            <Route exact path="create_virtual_delivery_note_ticket" element={<CreateDeliveryNote page_type={app_consts.CREATE_VIRTUAL_DELIVERY_NOTE_TICKET} />} />
            <Route exact path=":delivery_note_ref_number" element={<DeliveryNoteDetails />} />
            <Route exact path="continue_processing_virtual/:delivery_note_ref_number" element={<CreateDeliveryNote page_type={app_consts.CREATE_VIRTUAL_DELIVERY_NOTE_TICKET} />} />
            <Route exact path="continue_processing_physical/:delivery_note_ref_number" element={<CreateDeliveryNote page_type={app_consts.CREATE_PHYSICAL_DELIVERY_NOTE_TICKET} />} />
          </Route>

          {/*  */}

          {/* Warehouse Inventory */}
          {
            account_actions?.map((module) => {
              if (module.module_name === app_consts.WAREHOUSE_MANAGEMENT)
              {
                return (
                  <>
                    <Route path="warehouse_inventory" element={<WarehouseInventoryList />} />
                    <Route exact path="warehouse_inventory/:product_id" element={<WarehouseInventoryProduct />} />
                    {
                      findModuleAction(app_consts.VIEW_LOADING_ORDERS, module) || findModuleAction(app_consts.CREATE_LOADING_ORDER, module) ? (
                        <>
                          <Route exact path="loading_orders" element={<LoadingOrderslist />} />
                          <Route exact path="loading_orders/:loading_order_ref_no" element={<LoadingOrderDetails />} />
                          {
                            findModuleAction(app_consts.CREATE_LOADING_ORDER, module) ? (
                              <>
                                <Route exact path="loading_orders/create" element={<CreateLoadingOrder />} />
                                <Route exact path="loading_orders/continue_processing_physical/:loading_order_ref_no" element={<CreateLoadingOrder />} />
                              </>
                            )
                              :
                              ""
                          }
                        </>
                      )
                        :
                        ""
                    }
                  </>
                );
              }
            })
          }
          {/* Admin */}
          {
            account_actions?.map((module) => {
              if (module.module_name === app_consts.SYSTEM_MANAGEMENT)
              {
                return (
                  <>

                    <Route path="admin">
                      <Route index exact path="employees" element={<EmployeeList />} />
                      {
                        findModuleAction(app_consts.VIEW_DEPARTMENTS_AND_ROLES, module) && (
                          <>
                            {/* Departments and Roles */}
                            <Route path="departments_and_roles">
                              <Route index exact element={<DepartmentAndRolesList />} />
                              <Route exact path=":role_id/:role_name" element={<DepartmentRolesDetails />} />
                            </Route>
                          </>
                        )
                      }
                      {
                        (findModuleAction(app_consts.VIEW_PRODUCT, module) || findModuleAction(app_consts.VIEW_PRODUCT_TYPE, module) || findModuleAction(app_consts.ADD_PRODUCT, module) || findModuleAction(app_consts.ADD_PRODUCT_TYPE, module)) && (
                          <>
                            {/* Products */}
                            {
                              (findModuleAction(app_consts.VIEW_PRODUCT, module) || findModuleAction(app_consts.ADD_PRODUCT, module)) && (
                                <>
                                  <Route path="products">
                                    <Route index exact element={<ProductList />} />
                                    {
                                      findModuleAction(app_consts.ADD_PRODUCT, module) && (
                                        <>
                                          <Route exact path="add_product" element={<AddBranchLocation />} />
                                        </>
                                      )
                                    }
                                  </Route>
                                </>
                              )
                            }
                            {/* Product Types */}
                            {
                              (findModuleAction(app_consts.VIEW_PRODUCT_TYPE, module) || findModuleAction(app_consts.ADD_PRODUCT_TYPE, module)) && (
                                <>
                                  <Route path="product_types">
                                    <Route index exact element={<ProductTypeList />} />
                                    {
                                      findModuleAction(app_consts.ADD_PRODUCT_TYPE, module) && (
                                        <>
                                          <Route exact path="add_product" element={<AddBranchLocation />} />
                                        </>
                                      )
                                    }
                                  </Route>
                                </>
                              )
                            }
                          </>
                        )
                      }
                      {
                        (findModuleAction(app_consts.VIEW_UNIT_MEASUREMENT, module) || findModuleAction(app_consts.ADD_PRODUCT_TYPE, module)) && (
                          <>
                            <Route path="unit_measurements">
                              <Route index exact element={<UnitMeasurementList />} />
                            </Route>
                          </>
                        )
                      }
                      {
                        // Branch Locations 
                        (findModuleAction(app_consts.VIEW_BRANCH_LOCATIONS, module) || findModuleAction(app_consts.ADD_PRODUCT_TYPE, module)) && (
                          <>
                            <Route path="branch_locations">
                              <Route index exact element={<BranchLocationsList />} />
                              <Route exact path=":branch_location_id/:branch_area" element={<SpecificBranchLocation />} />
                              {
                                findModuleAction(app_consts.ADD_BRANCH_LOCATION, module) && (
                                  <>
                                    <Route exact path="add" element={<AddBranchLocation />} />
                                  </>
                                )
                              }
                            </Route>
                          </>
                        )
                      }
                    </Route>
                  </>

                  //   <Route path="admin">
                  //     <Route index exact path="employees" element={<EmployeeList />} />
                  //     {/* Branch Locations */}
                  //     <Route path="branch_locations">
                  //       <Route index exact element={<BranchLocationsList />} />
                  //       <Route exact path=":branch_location_id/:branch_area" element={<SpecificBranchLocation />} />
                  //       <Route exact path="add" element={<AddBranchLocation />} />
                  //     </Route>
                  //     {/* Departments and Roles */}
                  //     <Route path="departments_and_roles">
                  //       <Route index exact element={<DepartmentAndRolesList />} />
                  //       <Route exact path=":role_id/:role_name" element={<DepartmentRolesDetails />} />
                  //     </Route>
                  //     {/* Products */}
                  //     <Route path="products">
                  //       <Route index exact element={<ProductList />} />
                  //       <Route exact path="add_product" element={<AddBranchLocation />} />
                  //     </Route>
                  //     {/* Product Types*/}
                  //     <Route path="product_types">
                  //       <Route index exact element={<ProductTypeList />} />
                  //       {/* <Route exact path="add_product" element={<AddBranchLocation />} /> */}
                  //     </Route>
                  //     {/* Unit Measurements */}
                  //     <Route path="unit_measurements">
                  //       <Route index exact element={<UnitMeasurementList />} />
                  //       {/* <Route exact path="add_product" element={<AddBranchLocation />} /> */}
                  //     </Route>
                  //   </Route>
                  // </>
                );
              }
            })
          }


        </Route >

        <Route path="*" element={<Navigate to="dashboard/goods_received" replace />} />
        <Route path="/" element={<Navigate to="dashboard/goods_received" replace />} />;

        {/* Default Redirection Route */}

        {/* Default Not Found Route */}

      </Routes>
    </>
    // <>
    //  
    //   <Routes>
    //     <Route path="/" element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/goods_received`} replace />} />

    //     <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/goods_received`} replace />} />

    //     <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Layout />}>

    //       {/* Warehouse Management */}
    //       {/* Goods Received */}
    //       <Route exact index path="goods_received" element={<GoodsReceivedWeighBridgeTicketList />} />
    //       <Route exact path="goods_received/:goods_received_ref_number" element={<ViewGoodsRecievedDetails />} />
    //       <Route exact path="goods_received/create_physical_goods_received_ticket" element={<CreateGoodsReceived page_type={app_consts.CREATE_PHYSICAL_GOODS_RECEIVED_TICKET} />} />
    //       <Route exact path="goods_received/create_virtual_goods_received_ticket" element={<CreateGoodsReceived page_type={app_consts.CREATE_VIRTUAL_GOODS_RECEIVED_TICKET} />} />
    //       <Route exact path="goods_received/continue_processing_virtual/:goods_received_ref_number" element={<CreateGoodsReceived page_type={app_consts.CREATE_VIRTUAL_GOODS_RECEIVED_TICKET} />} />
    //       <Route exact path="goods_received/continue_processing_physical/:goods_received_ref_number" element={<CreateGoodsReceived page_type={app_consts.CREATE_PHYSICAL_GOODS_RECEIVED_TICKET} />} />


    //       {/* Delivery Notes */}

    //       {/* Set Authentication */}
    //       {
    //         account_actions?.map((module_actions) => {
    //           return (
    //             <>
    //               {/* ------------------------------------ Admin ------------------------------------ */}
    //               {
    //                 module_actions.module_name === app_consts.SYSTEM_MANAGEMENT &&
    //                 (
    //                   <>
    //                     <Route path="admin">
    //                       <Route index exact path="employees" element={<EmployeeList />} />
    //                       {/* Branch Locations */}
    //                       <Route path="branch_locations">
    //                         <Route index exact element={<BranchLocationsList />} />
    //                         <Route exact path=":branch_location_id/:branch_area" element={<SpecificBranchLocation />} />
    //                         <Route exact path="add" element={<AddBranchLocation />} />
    //                       </Route>
    //                       {/* Products */}
    //                       <Route path="products">
    //                         <Route index exact element={<ProductList />} />
    //                         <Route exact path="add_product" element={<AddBranchLocation />} />
    //                       </Route>
    //                       {/* Product Types*/}
    //                       <Route path="product_types">
    //                         <Route index exact element={<ProductTypeList />} />
    //                         {/* <Route exact path="add_product" element={<AddBranchLocation />} /> */}
    //                       </Route>
    //                       {/* Unit Measurements */}
    //                       <Route path="unit_measurements">
    //                         <Route index exact element={<UnitMeasurementList />} />
    //                         {/* <Route exact path="add_product" element={<AddBranchLocation />} /> */}
    //                       </Route>
    //                     </Route>
    //                   </>
    //                 )
    //               }
    //               {/* ------------------------------------ Onboarding ------------------------------------ */}
    //               {
    //                 module_actions.module_name === app_consts.ONBOARDING &&
    //                 (
    //                   <>
    //                     <Route path="onboarding">
    //                       {/* <Route index element={<OnBoardingIndex />} /> */}
    //                       {/* Nesting User */}
    //                       <Route path="user">
    //                         <Route index element={<UserOnboardingIndex />} />
    //                         {/* Set Onboaring Actions */}
    //                         {
    //                           // Onboard User
    //                           onboardingActions.onboard_user && (
    //                             <Route exact path="create_user" element={<CreateUser />} />
    //                           )
    //                         }
    //                         {
    //                           // Assign User User
    //                           (onboardingActions.assign_customer || onboardingActions.assign_truck_driver) && (
    //                             <>
    //                               {/* Nesting Assign User */}
    //                               <Route path="assign/:phone_number">
    //                                 <Route index element={<AssignUserIndex />} />
    //                                 {
    //                                   // Assign Customer
    //                                   onboardingActions.assign_customer && (
    //                                     <Route exact path="customer" element={<AssignCustomer />} />
    //                                   )
    //                                 }
    //                                 {
    //                                   // Assign Truck Driver
    //                                   onboardingActions.assign_truck_driver && (
    //                                     <Route exact path="truck_driver" element={<AssignTruckDriver />} />
    //                                   )
    //                                 }
    //                               </Route>
    //                             </>
    //                           )
    //                         }
    //                         {
    //                           // Complete KYC Registration
    //                           onboardingActions.onboard_user && (
    //                             <Route exact path="complete_kyc/:phone_number" element={<CompleteUserKYC />} />
    //                           )
    //                         }
    //                       </Route>
    //                       {/* Nesting Vehicle TrailerOnboarding*/}
    //                       {/* {
    //                                   // Onboard Truck or Trailer
    //                                   (onboardingActions.onboard_truck || onboardingActions.onboard_trailer) && (
    //                                     <>
    //                                       <Route path="vehicle">
    //                                         <Route index element={<VehicleOnboardingIndex />} />
    //                                         {
    //                                           // Onboard Truck
    //                                           onboardingActions.onboard_truck && (
    //                                             <Route exact path="truck" element={<TruckOnboarding />} />
    //                                           )
    //                                         }
    //                                         {
    //                                           // Onboard Trailer
    //                                           onboardingActions.onboard_trailer && (
    //                                             <Route exact path="trailer" element={<TrailerOnboarding />} />
    //                                           )
    //                                         }
    //                                       </Route>
    //                                     </>
    //                                   )
    //                                 } */}
    //                       {/* Nesting Onboarding*/}
    //                       {
    //                         // Onboard Company
    //                         (onboardingActions.assign_company_representative || onboardingActions.onboard_company) && (
    //                           <>
    //                             <Route path="company">
    //                               <Route index element={<CompanyOnboardingIndex />} />
    //                               {
    //                                 // Onboard Truck
    //                                 onboardingActions.onboard_truck && (
    //                                   <Route exact path=":phone_number" element={<CreateCompanyAccount />} />
    //                                 )
    //                               }
    //                               {
    //                                 // Onboard Trailer
    //                                 onboardingActions.onboard_trailer && (
    //                                   <Route exact path=":company_id" element={<TrailerOnboarding />} />
    //                                 )
    //                               }
    //                             </Route>
    //                           </>
    //                         )
    //                       }
    //                     </Route>
    //                   </>
    //                 )
    //               }
    //               {/* ------------------------------------ User management ------------------------------------ */}
    //               {
    //                 module_actions.module_name === app_consts.USER_MANAGEMENT &&
    //                 (
    //                   <>
    //                     <Route path="user">
    //                       <Route index element={<UserList />} />
    //                       <Route path="accounts">
    //                         <Route index exact element={<UserAccountsList />} />
    //                         <Route exact path="company/:user_account" element={<CompanyAccountDetails />} />
    //                       </Route>
    //                       <Route exact path=":phone_number" element={<UserDetails />} />

    //                       {/*<Route exact path="create_user" element={<CreateUser />} />
    //                               </Route> */}
    //                     </Route>
    //                   </>
    //                 )
    //               }
    //             </>
    //           );
    //         })
    //       }

    //       {/* Default Pages That Can be Viewed by All */}
    //       <Route path="warehouse_inventory" element={<WarehouseInventoryList />} />
    //       <Route exact path="warehouse_inventory/:product_name" element={<WarehouseInventoryProduct />} />
    //       <Route
    //         path="*"
    //         element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/goods_received`} replace />}
    //       />
    //       {/*  */}
    //     </Route>
    //   </Routes>
    // </>
  );
};
export default DashboardRoutes;
