import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    specific_delivery_note_details: { status: "idle" },
};

export const specific_delivery_note_details_Slice = createSlice({
    name: "specific_delivery_note_details",
    initialState,
    reducers: {
        success_specific_delivery_note_details: (state, action) => {
            state.specific_delivery_note_details = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_specific_delivery_note_details: (state, action) => {
            state.specific_delivery_note_details = { status: app_consts.FAIL, message: action.payload };
        },
        reset_specific_delivery_note_details: (state) => {
            state.specific_delivery_note_details = { status: app_consts.IDLE };
        },
        loading_specific_delivery_note_details: (state) => {
            state.specific_delivery_note_details = { status: app_consts.LOADING };
        },
    }
});

export const { success_specific_delivery_note_details, fail_specific_delivery_note_details, reset_specific_delivery_note_details, loading_specific_delivery_note_details } = specific_delivery_note_details_Slice.actions;

export default specific_delivery_note_details_Slice.reducer;