import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    add_goods_received_products: { status: "idle" },
};

export const add_goods_received_products_Slice = createSlice({
    name: "add_goods_received_products",
    initialState,
    reducers: {
        success_add_goods_received_products: (state, action) => {
            state.add_goods_received_products = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_add_goods_received_products: (state, action) => {
            state.add_goods_received_products = { status: app_consts.FAIL, message: action.payload };
        },
        reset_add_goods_received_products: (state) => {
            state.add_goods_received_products = { status: app_consts.IDLE };
        },
        loading_add_goods_received_products: (state) => {
            state.add_goods_received_products = { status: app_consts.LOADING };
        },
    }
});

export const { success_add_goods_received_products, fail_add_goods_received_products, reset_add_goods_received_products, loading_add_goods_received_products } = add_goods_received_products_Slice.actions;

export default add_goods_received_products_Slice.reducer;