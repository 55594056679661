import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
 add_product_to_loading_order: { status: "idle" },
};

export const add_product_to_loading_order_Slice = createSlice({
 name: "add_product_to_loading_order",
 initialState,
 reducers: {
  success_add_product_to_loading_order: (state, action) => {
   state.add_product_to_loading_order = { status: app_consts.SUCCESS, data: action.payload };
  },
  fail_add_product_to_loading_order: (state, action) => {
   state.add_product_to_loading_order = { status: app_consts.FAIL, message: action.payload };
  },
  reset_add_product_to_loading_order: (state) => {
   state.add_product_to_loading_order = { status: app_consts.IDLE };
  },
  loading_add_product_to_loading_order: (state) => {
   state.add_product_to_loading_order = { status: app_consts.LOADING };
  },
 },
});

export const {
 success_add_product_to_loading_order,
 fail_add_product_to_loading_order,
 reset_add_product_to_loading_order,
 loading_add_product_to_loading_order,
} = add_product_to_loading_order_Slice.actions;

export default add_product_to_loading_order_Slice.reducer;
