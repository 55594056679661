import React, { useEffect, useState } from 'react';
import Head from '../../../../layout/head/Head';
import { Block, BlockHead, BlockTitle, Button, Col } from '../../../../components/Component';
import { Card } from 'reactstrap';
import CardTabs from '../../components/cards/CardTabs';
import { useParams } from 'react-router';
import Content from '../../../../layout/content/Content';
import { useDispatch } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../../actions/actions_helper';
import { fail_retrieve_specific_branch_location, loading_retrieve_specific_branch_location, reset_retrieve_specific_branch_location, success_retrieve_specific_branch_location } from '../../../../reducers/dashboard_reducers/branch_locations/retrieve_specific_branch_location_Slice';
import { RETRIEVE_SPECIFIC_BRANCH_LOCATION_API } from '../../../../api/api_data';
import { RETIREVE_SPECIFIC_BRANCH_LOCATION_ACCESS_DENIED_MSG, RETIREVE_SPECIFIC_BRANCH_LOCATION_ERR_MSG, RETIREVE_SPECIFIC_BRANCH_LOCATION_SUCCESS_MSG } from '../../../../constants/success_error_messages_consts';
import { useSelector } from 'react-redux';
import * as app_consts from "../../../../constants/react_app_consts";
import { messageToast } from '../../../../utils/Utils';
import ProfileDetailText from '../../components/text/ProfileDetailText';
import PageHeading from '../../components/page_heading/PageHeading';
import ReadOnlyTable from '../../components/table/ReadOnlyTable';
import ModalAdd from '../../components/modal/modal_add/ModalAdd';
import { capitalizeWords } from '../../../../utils/functionalUtils';

const storage_type = app_consts.SPECIFIC_BRANCH_LOCATION_STORAGES;
const specific_employee_type = app_consts.SPECIFIC_BRANCH_EMPLOYEES;

const SpecificBranchLocation = () => {

    const { branch_location_id, branch_area } = useParams();
    const [sideBar, setSidebar] = useState(false);
    const [activeCardTab, setActiveCardTab] = useState("Branch Location Details");
    const [dataFetched, setDataFetched] = useState(false);
    const dispatch = useDispatch();
    const [page_data, setPageData] = useState();
    const [storage_data, setStorageData] = useState([]);
    const [employee_data, setEmployeeData] = useState([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openStorageTypeModal, setOpenStorageTypeModal] = useState(false);
    const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);


    // function to toggle sidebar
    const toggle = () => {
        setSidebar(!sideBar);
    };

    useEffect(() => {
        sideBar ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sideBar]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Branch Location Details", icon: "user-circle"
        },
        {
            tabName: "Storage Information", icon: "user-circle"
        },
        {
            tabName: "Employees", icon: "user-circle"
        },
    ];

    // Retrieve Branch Location Details RETRIEVE_SPECIFIC_BRANCH_LOCATION
    useEffect(() => {
        // Retrieve User's Information
        dispatch(
            employee_dashboard_actions_helper(
                loading_retrieve_specific_branch_location,
                success_retrieve_specific_branch_location,
                fail_retrieve_specific_branch_location,
                RETIREVE_SPECIFIC_BRANCH_LOCATION_ERR_MSG,
                RETIREVE_SPECIFIC_BRANCH_LOCATION_SUCCESS_MSG,
                app_consts.POST,
                RETRIEVE_SPECIFIC_BRANCH_LOCATION_API,
                { branch_location_id: branch_location_id },
                RETIREVE_SPECIFIC_BRANCH_LOCATION_ACCESS_DENIED_MSG,
            )
        );
    }, []);
    //   Listen to Branch Location Reducer
    const retrieve_branch_location_listener = useSelector((state) => state.retrieve_specific_branch_location.retrieve_specific_branch_location);
    //   
    useEffect(() => {
        if (retrieve_branch_location_listener.status !== app_consts.IDLE && retrieve_branch_location_listener.status !== app_consts.LOADING)
        {
            if (retrieve_branch_location_listener.status === app_consts.FAIL)
            {
                messageToast(retrieve_branch_location_listener.message, app_consts.FAIL);
            } else if (retrieve_branch_location_listener.status === app_consts.SUCCESS)
            {
                setPageData(retrieve_branch_location_listener.data);
            }
            dispatch(reset_retrieve_specific_branch_location());
        }
    }, [retrieve_branch_location_listener]);

    // UseEffect to Listen to Page Data
    useEffect(() => {
        if (page_data) 
        {
            setStorageData(page_data?.warehouse_storages);
            setEmployeeData(page_data?.employee_list);
        }
    }, [page_data]);

    //   Open Modals
    const openWarehouseStorageModal = () => {
        setOpenAddModal(true);
        setOpenStorageTypeModal(false);
    };

    const openAddWarehouseStorageTypeModal = () => {
        setOpenAddModal(false);
        setOpenStorageTypeModal(true);
    };

    const openAddEmployeeToBranchModal = () => {
        setOpenAddModal(false);
        setOpenAddEmployeeModal(true);
    };

    // Successfuntionf for Adding a Warehouse Storage
    const successAddFunc = (storage_det = null) => {
        setOpenAddModal(false);
        setStorageData([...storage_data, storage_det]);
    };

    return (
        <>
            <Head title={`Branch Location Details ${capitalizeWords(branch_area)}`} />
            <Content>
                <PageHeading
                    title="Branch Location Details"
                    title_addition={capitalizeWords(branch_area)}
                    description="Details of Branch Location"
                    backToText="Branch Locations"
                    backToLink={`/dashboard/admin/branch_locations`}
                />

                <Block>
                    <Card>
                        <div className="card-aside-wrap" id="user-detail-block">
                            <div className="card-content">
                                <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                {
                                    activeCardTab === "Branch Location Details" && (
                                        <>
                                            <div className="card-inner">
                                                <Block>
                                                    <BlockHead>
                                                        <BlockTitle tag="h5">Branch Location Details</BlockTitle>
                                                        <p>Location and Contact Information</p>
                                                    </BlockHead>
                                                    <div className="profile-ud-list">
                                                        <ProfileDetailText label="Branch Code" text={page_data?.branch?.branch_ref_no} />
                                                        <ProfileDetailText label="Branch Area" text={page_data?.branch?.branch_area} />
                                                        <ProfileDetailText label="Physical Address" text={page_data?.branch?.branch_physical_address} />
                                                        <ProfileDetailText label="Mobile Number" text={page_data?.branch?.branch_mobile_number} />
                                                        <ProfileDetailText label="Account Status" text={page_data?.branch?.account_status} />
                                                        <ProfileDetailText label="Province" text={page_data?.branch?.province_name} />
                                                        <ProfileDetailText label="Country" text={page_data?.branch?.country_name} />
                                                    </div>
                                                </Block>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    activeCardTab === "Storage Information" && (
                                        <>
                                            <div className="card-inner">
                                                <Block>
                                                    <div className='flex-row items-center justify-between'>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Storage Information</BlockTitle>
                                                            <p>Warehouse Stroage Information</p>
                                                        </BlockHead>
                                                        <Col xl="12" className="my-3 mt-1">
                                                            <div className="form-group">
                                                                <div className="form-control-wrap-flex-wrap justify-start">
                                                                    <Button onClick={openWarehouseStorageModal} className="btn btn-dim btn-outline-primary" size="md">
                                                                        Add Warehouse Storage
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                    {/* Table */}
                                                    <div className="my-3">
                                                        <ReadOnlyTable data={storage_data} tableType={storage_type} />
                                                    </div>
                                                </Block>
                                            </div>
                                            {/* Add Modal For Warehouse Storage */}
                                            <ModalAdd
                                                openModal={openAddModal}
                                                setOpenModal={setOpenAddModal}
                                                modalTitle="Add Warehouse Storage"
                                                modalDescription="Please Complete this form to Add a Warehouse Storage"
                                                type={app_consts.ADD_WAREHOUSE_STORAGE}
                                                succFunc={successAddFunc}
                                                accountId={page_data}
                                                openOtherModalFunc={openAddWarehouseStorageTypeModal}
                                            />
                                            {/* Add Modal For Warehouse Storage Type*/}
                                            <ModalAdd
                                                openModal={openStorageTypeModal}
                                                setOpenModal={setOpenStorageTypeModal}
                                                modalTitle="Add Warehouse Storage Type"
                                                modalDescription="Please Complete this form to Add a Warehouse Storage Type"
                                                type={app_consts.ADD_WAREHOUSE_STORAGE_TYPE}
                                                succFunc={openWarehouseStorageModal}
                                                size="md"
                                                openOtherModalFunc={openWarehouseStorageModal}
                                            />
                                        </>
                                    )
                                }
                                {
                                    activeCardTab === "Employees" && (
                                        <>
                                            <div className="card-inner">
                                                <Block>
                                                    <div className='flex-row items-center justify-between'>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Employees</BlockTitle>
                                                            <p>Branch Employee Information</p>
                                                        </BlockHead>
                                                        <Col xl="12" className="my-3 mt-1">
                                                            <div className="form-group">
                                                                <div className="form-control-wrap-flex-wrap justify-start">
                                                                    <Button onClick={openAddEmployeeToBranchModal} className="btn btn-dim btn-outline-primary" size="md">
                                                                        Add Employee
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                    {/* Table */}
                                                    <div className="my-3">
                                                        <ReadOnlyTable data={employee_data} tableType={specific_employee_type} />
                                                    </div>
                                                </Block>
                                            </div>
                                            {/* Modal For Adding Employee */}
                                            <ModalAdd
                                                openModal={openAddEmployeeModal}
                                                setOpenModal={setOpenAddEmployeeModal}
                                                modalTitle={`Add Employe to ${capitalizeWords(branch_area)} Branch`}
                                                modalDescription="Please Select the Employee and Employee Role"
                                                type={app_consts.ADD_EMPLOYEE}
                                                // succFunc={openWarehouseStorageModal}
                                                size="md"
                                            // openOtherModalFunc={openWarehouseStorageModal}
                                            />
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </Card>
                </Block>
            </Content>
        </>
    );
};

export default SpecificBranchLocation;