import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_goods_recieved_ticket: { status: "idle" },
};

export const create_goods_recieved_ticket_Slice = createSlice({
    name: "create_goods_recieved_ticket",
    initialState,
    reducers: {
        success_create_goods_recieved_ticket: (state, action) => {
            state.create_goods_recieved_ticket = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_goods_recieved_ticket: (state, action) => {
            state.create_goods_recieved_ticket = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_goods_recieved_ticket: (state) => {
            state.create_goods_recieved_ticket = { status: app_consts.IDLE };
        },
        loading_create_goods_recieved_ticket: (state) => {
            state.create_goods_recieved_ticket = { status: app_consts.LOADING };
        },
    }
});

export const { success_create_goods_recieved_ticket, fail_create_goods_recieved_ticket, reset_create_goods_recieved_ticket, loading_create_goods_recieved_ticket } = create_goods_recieved_ticket_Slice.actions;

export default create_goods_recieved_ticket_Slice.reducer;