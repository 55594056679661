import { configureStore } from "@reduxjs/toolkit";
// Auth
import loggedInCredReducer from "../reducers/client_reducers/auth/logged_in_cred_Slice";
import signOutReducer from "../reducers/client_reducers/auth/sign_out_slice";
import signOutModalReducer from "../reducers/client_reducers/auth/sign_out_modal_Slice";
// Goods Recieved
import retrieveGoodsReceivedReducer from "../reducers/client_reducers/goods_received/retrieve_goods_received_list_Slice";
import retrieveSpecificGoodsReceivedReducer from "../reducers/client_reducers/goods_received/retrieve_specific_goods_received_Slice";
// Delivery Notes
import retrieveDeliveryNotesReducer from "../reducers/client_reducers/delivery_notes/retrieve_delivery_notes_list_Slice";
import retrieveSpecifcDeliveryNoteReducer from "../reducers/client_reducers/delivery_notes/retrieve_specific_delivery_note_Slice";
import confirmDeliveryNoteDeliveredReducer from "../reducers/client_reducers/delivery_notes/confirm_delivery_note_delivered_Slice";
import submiteedbackReducer from "../reducers/client_reducers/delivery_notes/submit_feedback_Slice";


// Store For All Reducers
export const client_store = configureStore({
    reducer: {
        // Auth
        logged_in_cred: loggedInCredReducer,
        sign_out_modal: signOutModalReducer,
        sign_out: signOutReducer,

        // Goods Recieved
        retrieve_goods_received_list: retrieveGoodsReceivedReducer,
        retrieve_specific_goods_received: retrieveSpecificGoodsReceivedReducer,

        // Delivery Notes
        retrieve_delivery_notes_list: retrieveDeliveryNotesReducer,
        retrieve_specific_delivery_note: retrieveSpecifcDeliveryNoteReducer,
        confirm_delivery_note_delivered: confirmDeliveryNoteDeliveredReducer,
        submit_feedback: submiteedbackReducer,
    }
});