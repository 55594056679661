import React, { useEffect, useState } from "react";
import SearchUserStep from "./modal_wiz_steps/modal_complete_kyc_reg_wiz_step/SearchUserStep";
import { useDispatch } from "react-redux";
import {
  retrieve_uncomplete_kyc_list_action,
  retrieve_user_kyc_reg_status_action,
} from "../../../../../actions/dashboard_actions/user_kyc/user_kyc_actions";
import { useSelector } from "react-redux";
import {
  fail_active_users_list,
  loading_active_users_list,
  reset_active_users_list,
  success_active_users_list,
} from "../../../../../reducers/dashboard_reducers/user/active_users_list_Slice";
import * as app_consts from "../../../../../constants/react_app_consts";
import { Alert } from "reactstrap";
import { Icon } from "../../../../../components/Component";
import {
  send_complete_kyc_reg_otp_action,
  verify_complete_kyc_reg_otp_action,
} from "../../../../../actions/dashboard_actions/otp/otp_actions";
import { reset_send_otp } from "../../../../../reducers/dashboard_reducers/otp/send_otp_Slice";
import VerifyOTPStep from "./modal_wiz_steps/modal_complete_kyc_reg_wiz_step/VerifyOTPStep";
import { reset_verify_otp } from "../../../../../reducers/dashboard_reducers/otp/verify_otp_Slice";
import { useNavigate } from "react-router";
import { employee_dashboard_actions_helper } from "../../../../../actions/actions_helper";
import {
  UNCOMPLETE_USER_KYC_LIST_ACCESS_DENIED_MSG,
  UNCOMPLETE_USER_KYC_LIST_ERR_MSG,
  UNCOMPLETE_USER_KYC_LIST_SUCCESS_MSG,
} from "../../../../../constants/success_error_messages_consts";
import { UNCOMPLETE_ACTIVE_USER_KYC_LIST } from "../../../../../api/api_data";

const initial_data = { phone_number: "", send_otp_type: "" };
const verify_otp_data = { otp: "", phone_number: "" };

const ModalCompleteKycRegWiz = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [openListener, setOpenListener] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [user_list_data, setUserListData] = useState([]);
  const [errorVal, setError] = useState("");
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [formData, setFormData] = useState(initial_data);
  const [verifyOtpData, setVerifyOtpData] = useState(verify_otp_data);
  const [selectedUser, setSelectedUser] = useState([]);

  // Check if The Modal is Open or Not
  useEffect(() => {
    setOpenListener(isOpen);
  }, [isOpen]);
  // Send a Dispatch to Load the User Data when the Modal Loads
  useEffect(() => {
    if (openListener)
    {
      retrieveUncompleteKycList();
    }
  }, [openListener]);

  // Retrieve Uncomplete KYC List
  const retrieveUncompleteKycList = () => {
    // Retrieve User's Information
    dispatch(
      employee_dashboard_actions_helper(
        loading_active_users_list,
        success_active_users_list,
        fail_active_users_list,
        UNCOMPLETE_USER_KYC_LIST_ERR_MSG,
        UNCOMPLETE_USER_KYC_LIST_SUCCESS_MSG,
        app_consts.GET,
        UNCOMPLETE_ACTIVE_USER_KYC_LIST,
        null,
        UNCOMPLETE_USER_KYC_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Read Uncomplete KYC Users List
  const active_users_list = useSelector((state) => state.active_users_list.active_users_list);
  //
  useEffect(() => {
    if (active_users_list.status !== app_consts.IDLE)
    {
      if (active_users_list.status === app_consts.LOADING)
      {
        setIsLoadingList(true);
      } else
      {
        setIsLoadingList(false);
        if (active_users_list.status === app_consts.FAIL)
        {
          setError(active_users_list.message);
        } else if (active_users_list.status === app_consts.SUCCESS)
        {
          setUserListData(active_users_list.data);
        }
        dispatch(reset_active_users_list());
      }
    }
  }, [active_users_list]);

  // Refresh User
  const refreshUsers = () => {
    retrieveUncompleteKycList();
    setFormData(initial_data);
    setError("");
  };

  // Send OTP Function
  const sendOTP = () => {
    setError("");
    dispatch(send_complete_kyc_reg_otp_action(formData));
  };

  // Verify OTP Function
  const VerifyOtp = () => {
    setError("");
    dispatch(verify_complete_kyc_reg_otp_action(formData));
  };

  // Read Changes in Sent OTP Request
  const send_otp_listener = useSelector((state) => state.send_otp.send_otp);
  //
  useEffect(() => {
    if (send_otp_listener.status !== app_consts.IDLE)
    {
      if (send_otp_listener.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (send_otp_listener.status === app_consts.FAIL)
        {
          setError(send_otp_listener.message);
        } else if (send_otp_listener.status === app_consts.SUCCESS)
        {
          setSelectedUser(filterSelectedUser(formData.phone_number));
          // Set Step Plus 1
          setVerifyOtpData({ ...verify_otp_data, ["phone_number"]: formData.phone_number });
          nextStep();
        }
        dispatch(reset_send_otp());
      }
    }
  }, [send_otp_listener]);

  // Filter Selected Data and Send it to Step 2
  const filterSelectedUser = (selectedOption) => {
    const user_det = user_list_data?.filter((user) => {
      return user.phone_number.toLowerCase() === selectedOption.toLowerCase();
    });
    return user_det[0];
  };

  // Read Changes in Verify OTP
  const verify_otp_listener = useSelector((state) => state.verify_otp.verify_otp);
  //
  useEffect(() => {
    if (verify_otp_listener.status !== app_consts.IDLE)
    {
      if (verify_otp_listener.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (verify_otp_listener.status === app_consts.FAIL)
        {
          setError(verify_otp_listener.message);
          dispatch(reset_verify_otp());
        } else if (verify_otp_listener.status === app_consts.SUCCESS)
        {
          // Set Step Plus 1
          navigate(`/dashboard/onboarding/user/complete_kyc/${formData.phone_number}`);
        }
      }
    }
  }, [verify_otp_listener]);

  // Next Step
  const nextStep = () => setStep(step + 1);
  // Previous Step
  const previousStep = () => {
    setStep(step + 1);
    setFormData(initial_data);
    setVerifyOtpData(verify_otp_data);
  };

  return (
    <>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      <SearchUserStep
        data={user_list_data}
        isLoadingList={isLoadingList}
        refreshUsers={refreshUsers}
        formData={formData}
        setFormData={setFormData}
        buttonClickFunc={VerifyOtp}
        isLoading={loading}
      />
    </>
  );
};

export default ModalCompleteKycRegWiz;
