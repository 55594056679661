import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import TablePageHeading from "../../components/page_heading/TablePageHeading";
import Content from "../../../../layout/content/Content";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../constants/react_app_consts";
import { employee_dashboard_actions_helper } from "../../../../actions/actions_helper";
import {
  fail_branch_location_list,
  loading_branch_location_list,
  reset_branch_location_list,
  success_branch_location_list,
} from "../../../../reducers/dashboard_reducers/branch_locations/branch_location_list_Slice";
import {
  AMEND_BRANCH_LOCATION_STATUS_ACCESS_DENIED_MSG,
  AMEND_BRANCH_LOCATION_STATUS_ERR_MSG,
  AMEND_BRANCH_LOCATION_STATUS_SUCCESS_MSG,
  RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG,
  RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { AMEND_BRANCH_LOCATION_STATUS, RETRIEVE_BRANCH_LOCATION_LIST } from "../../../../api/api_data";
import { useSelector } from "react-redux";
import { Block } from "../../../../components/Component";
import TableList from "../../components/table/TableList";
import { messageToast } from "../../../../utils/Utils";
import { useNavigate } from "react-router";
import ModalDetails from "../../components/modal/modal_details/ModalDetails";
import ModalConfirmCancel from "../../components/modal/modal_confirm_cancel/ModalConfirmCancel";
import {
  fail_amend_branch_location_status,
  loading_amend_branch_location_status,
  reset_amend_branch_location_status,
  success_amend_branch_location_status,
} from "../../../../reducers/dashboard_reducers/branch_locations/amend_branch_location_status_Slice";
import { reset_amend_user_acc_status } from "../../../../reducers/dashboard_reducers/user/amend_user_acc_status_Slice";
import { findModuleAction } from "../../../../utils/functionalUtils";
import { BiErrorCircle } from "react-icons/bi";
import { Spinner } from "reactstrap";

const initial_actions_data = { add_branch_location: false, amend_branch_location_status: false };
const type = app_consts.BRANCH_LOCATION_LIST;
const initial_amend_user_status = { user_id: "", status_id: "" };

const BranchLocationsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page_data, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page_action_data, setPageActionData] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const [openModalDetails, setOpendModalDetails] = useState(false);
  const [detail, setDetail] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [amendStatusFormData, setAmendStatusFormData] = useState(initial_amend_user_status);
  const [acc_status, setAccStatus] = useState("");
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [errAmendAccStat, setErrAmendAccStat] = useState("");
  const [account_actions, setAccountActions] = useState([]);
  const [error, setError] = useState("");

  // Retrieve Branch Location list
  useEffect(() => {
    retrieve_branch_locations();
    setLoading(true);
    if (localStorage.getItem("log_cred"))
    {
      setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
    }
  }, []);

  // Listen to Changes In Account Actions
  useEffect(() => {
    if (account_actions)
    {
      let actions_data = initial_actions_data;
      account_actions?.map((module) => {
        if (module.module_name === app_consts.SYSTEM_MANAGEMENT) 
        {
          // Actions Data
          if (findModuleAction(app_consts.ADD_BRANCH_LOCATION, module))
          {
            actions_data.add_branch_location = true;
          }
          if (findModuleAction(app_consts.AMEND_BRANCH_LOCATION_STATUS, module))
          {
            actions_data.amend_branch_location_status = true;
          }
        }
      });
      setComponentData(actions_data);
    }
  }, [account_actions]);

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // UseEffect to Place Page Data
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS)
    {
      setPageActionData(action_roles.data);
      if (action_roles.data.system_management)
      {
        setComponentData(action_roles.data.system_management.module_actions);
      }
    }
  }, [action_roles]);

  // Retrieve Branch Location List
  const retrieve_branch_locations = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_branch_location_list,
        success_branch_location_list,
        fail_branch_location_list,
        RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG,
        RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_BRANCH_LOCATION_LIST,
        null,
        RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG
      )
    );
  };
  // Refresh List Function
  const refreshList = () => {
    setError("");
    // Retrieve User list
    retrieve_branch_locations();
    setLoading(true);
    setPageData([]);
  };

  //List to State of Branch Location List
  const branch_location_list = useSelector((state) => state.branch_location_list.branch_location_list);

  useEffect(() => {
    if (branch_location_list.status !== app_consts.IDLE)
    {
      if (branch_location_list.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (branch_location_list.status === app_consts.FAIL)
        {
          setError(branch_location_list.message, app_consts.FAIL);
          messageToast(branch_location_list.message, app_consts.FAIL);
        } else if (branch_location_list.status === app_consts.SUCCESS)
        {
          setPageData(branch_location_list.data);
        }
        dispatch(reset_branch_location_list());
      }
    }
  }, [branch_location_list]);

  // Open Page To Add Branch Location
  const openAddList = () => {
    navigate("/dashboard/admin/branch_locations/add");
  };

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedData)
    {
      setDetail(filterSpecificBranchLocation(selectedData));
    }
  }, [selectedData]);

  // Filter Specific Branch Location
  const filterSpecificBranchLocation = (selectedData) => {
    const filtereredBranchLocation = page_data?.filter((data) => data.branch_location_id === selectedData);
    return filtereredBranchLocation[0];
  };

  // Function to Amend Branch Location Status
  const amend_Account_Status_modal = (branch_location_id, account_status_id, account_status) => {
    setAccStatus(account_status);
    setOpenConfirmCancelModal(true);
    setSelectedData(branch_location_id);
    setAmendStatusFormData({
      ...amendStatusFormData,
      ["branch_location_id"]: branch_location_id,
      ["account_status_id"]: account_status_id,
    });
  };

  // Function to Amend Branch Location Account Status
  const amend_Account_Status = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_amend_branch_location_status,
        success_amend_branch_location_status,
        fail_amend_branch_location_status,
        AMEND_BRANCH_LOCATION_STATUS_ERR_MSG,
        AMEND_BRANCH_LOCATION_STATUS_SUCCESS_MSG,
        app_consts.POST,
        AMEND_BRANCH_LOCATION_STATUS,
        amendStatusFormData,
        AMEND_BRANCH_LOCATION_STATUS_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Amend Branch Location Status Reducer
  const amend_branch_location_status = useSelector(
    (state) => state.amend_branch_location_status.amend_branch_location_status
  );
  // UseEffect for Amend User Account Status
  useEffect(() => {
    if (amend_branch_location_status.status !== app_consts.IDLE)
    {
      if (amend_branch_location_status.status !== app_consts.LOADING)
      {
        if (amend_branch_location_status.status === app_consts.FAIL)
        {
          setErrAmendAccStat(amend_branch_location_status.message);
        } else if (amend_branch_location_status.status === app_consts.SUCCESS)
        {
          messageToast(
            `${detail && `${detail.branch_area}'s `} Branch Location Status has been Successfully Amended.`,
            app_consts.SUCCESS
          );
          setAccStatus("");
          setDetail([]);
          refreshList();
          setSelectedData("");
          setErrAmendAccStat("");
          setOpenConfirmCancelModal(false);
        }
        dispatch(reset_amend_branch_location_status());
      }
    }
  }, [amend_branch_location_status]);

  return (
    <>
      <Head title="Branch Locations List" />
      <Content>
        <TablePageHeading
          title="Branch Locations List"
          description={`Total Branches: ${page_data.length}`}
          refresh={!loading}
          refreshFunc={refreshList}
          csv_export={!loading && !error}
          openAddList={openAddList}
          add_to_list={loading || error ? "" : componentData.add_branch_location}
          page_type={type}
          pageData={page_data}
        />
        <Block>
          {
            loading ?
              <>
                <div className='w-full p-5 d-flex justify-center items-center'>
                  <Spinner />
                </div>
              </>
              :
              error && error.length > 0 ?
                <>
                  <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                    <BiErrorCircle className="text-danger fs-3" />
                    <p>{error}</p>
                  </div>
                </>
                :
                <>
                  <TableList
                    left_head_select
                    type={type}
                    data={page_data}
                    placeholder="All Branch Locations"
                    rowOptionsData={componentData}
                    amendFunc={amend_Account_Status_modal}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                  />
                  {/* Yes or No Modal */}
                  <ModalConfirmCancel
                    openModal={openConfirmCancelModal}
                    setOpenModal={setOpenConfirmCancelModal}
                    modalTitle={`${acc_status} ${detail && `${detail.branch_area}'s `} Branch Location?`}
                    modalDescription={`Are you sure that you want to ${acc_status} ${detail.branch_area} Branch Location? ${acc_status === app_consts.ACTIVATE_ACCOUNT
                      ? ""
                      : "Employees Registered at this Location will not be able to Login."
                      }`}
                    buttonConfirm="Confirm"
                    buttonCancel="Cancel"
                    succFunc={amend_Account_Status}
                    errAmend={errAmendAccStat}
                    setErrAmend={setErrAmendAccStat}
                  />
                  {/* Modal For Viewing Branch Location Details */}
                  <ModalDetails
                    modalOpen={openModalDetails}
                    setModalOpen={setOpendModalDetails}
                    modalTitle={`${detail && `${detail.branch_area}'s Details`}`}
                    modalType={app_consts.BRANCH_LOCATION_LIST}
                    modalData={detail}
                  />
                </>
          }
        </Block>
      </Content>
    </>
  );
};

export default BranchLocationsList;
