import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import { Block, BlockHead, BlockTitle, Button, Col, Row } from '../../../components/Component';
import { useParams } from 'react-router';
import PageHeading from '../components/page_heading/PageHeading';
import { Spinner } from 'reactstrap';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { fail_user_account_details, loading_user_account_details, reset_user_account_details, success_user_account_details } from '../../../reducers/dashboard_reducers/user_accounts/user_account_details_Slice';
import { messageToast } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RETRIEVE_SPECIFIC_USER_ACCOUNT_ACCESS_DENIED_MSG, RETRIEVE_SPECIFIC_USER_ACCOUNT_ERR_MSG, RETRIEVE_SPECIFIC_USER_ACCOUNT_SUCCESS_MSG, RETRIEVE_USER_ACCOUNTS_LIST_ACCESS_DENIED_MSG, RETRIEVE_USER_ACCOUNTS_LIST_ERR_MSG, RETRIEVE_USER_ACCOUNTS_LIST_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { RETRIEVE_SPECIFIC_USER_ACCOUNT_DETAILS_API, RETRIEVE_USER_ACCOUNTS_LIST_EXCEPT_EMPLOYEES_API } from '../../../api/api_data';
import * as app_consts from "../../../constants/react_app_consts";
import CardTabs from '../components/cards/CardTabs';
import ProfileDetailText from '../components/text/ProfileDetailText';
import moment from 'moment';
import DocumentViewer from '../components/pdf/DocumentViewer';
import { ReadOnlyCompanyRepData } from '../components/table/table_components/read_only_row_data';
import ReadOnlyTable from '../components/table/ReadOnlyTable';
import ModalAdd from '../components/modal/modal_add/ModalAdd';
import { findModuleAction } from '../../../utils/functionalUtils';

const initial_actions_data = { onboard_company: false, add_user_property: false };
const onboard_property_type = app_consts.ONBOARD_USER_PROPERTY;

const CompanyAccountDetails = () => {

    const { user_account } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [page_data, setPageData] = useState([]);
    const [activeCardTab, setActiveCardTab] = useState("Main Details");
    const dispatch = useDispatch();
    const [dataFetched, setDataFetched] = useState(false);
    const [open_add_properties_modal, setAddPropertiesModal] = useState(false);
    const [account_actions, setAccountActions] = useState([]);
    const [componentData, setComponentData] = useState(initial_actions_data);

    // On Page Load
    useEffect(() => {
        retrieve_user_account_details_func();
        if (localStorage.getItem("log_cred"))
        {
            setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
        }
    }, []);

    // Listen to Changes In Account Actions
    useEffect(() => {
        if (account_actions)
        {
            let actions_data = initial_actions_data;
            account_actions?.map((module) => {
                if (module.module_name === app_consts.USER_MANAGEMENT) 
                {
                    if (findModuleAction(app_consts.AMEND_USER_STATUS, module))
                    {
                        actions_data.add_user_property = true;
                    }
                }

                if (module.module_name === app_consts.ONBOARDING) 
                {
                    if (findModuleAction(app_consts.ONBOARD_COMPANY, module))
                    {
                        actions_data.onboard_company = true;
                    }
                }
            });
            setComponentData(actions_data);
        }
    }, [account_actions]);

    // Retrieve User Account Details
    const retrieve_user_account_details_func = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_user_account_details,
                success_user_account_details,
                fail_user_account_details,
                RETRIEVE_SPECIFIC_USER_ACCOUNT_ERR_MSG,
                RETRIEVE_SPECIFIC_USER_ACCOUNT_SUCCESS_MSG,
                app_consts.GET,
                `${RETRIEVE_SPECIFIC_USER_ACCOUNT_DETAILS_API}/${user_account}`,
                null,
                RETRIEVE_SPECIFIC_USER_ACCOUNT_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to User Account Details Reducer
    const specific_user_account_details = useSelector((state) => state.user_account_details.user_account_details);
    // 
    useEffect(() => {
        if (specific_user_account_details.status !== app_consts.IDLE)
        {
            if (specific_user_account_details.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (specific_user_account_details.status === app_consts.FAIL)
                {
                    setError(specific_user_account_details.message);
                    messageToast(specific_user_account_details.message, app_consts.FAIL);
                } else if (specific_user_account_details.status === app_consts.SUCCESS)
                {
                    setDataFetched(true);
                    setPageData(specific_user_account_details.data);
                    console.log(specific_user_account_details.data);
                }
                dispatch(reset_user_account_details());
            }
        }
    }, [specific_user_account_details]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Main Details", icon: "user-circle"
        },
        {
            tabName: "Representatives", icon: "user-circle"
        },
        {
            tabName: "Properties", icon: "user-circle"
        },
    ];

    // Modal Success Function
    const successAddPropertyFunc = (property_data = null) => {
        setAddPropertiesModal(false);
        setPageData({
            ...page_data,
            company_properties: [...(page_data.company_properties || []), property_data]
        });
    };


    const openCompRepModal = () => {
        setAddPropertiesModal(true);
    };

    return (
        <>
            <Head title={`Company Account Details - ${user_account}`} />
            <Content>
                <Block>
                    {/* Table Page Heading */}
                    <PageHeading
                        title="Company Account Details"
                        title_addition={user_account}
                        description="Company Details, Representative and Company Clients"
                        backToText="User Accounts"
                        backToLink={`/dashboard/user/accounts`}
                    />{

                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                        {error}
                                    </div>
                                </>
                                :
                                <>
                                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={dataFetched} />
                                    {
                                        activeCardTab === "Main Details" && (
                                            <>
                                                <Block>
                                                    <BlockHead>
                                                        <BlockTitle tag="h5">Main Details</BlockTitle>
                                                        <p>Names, Address, Contact Information and Documents</p>
                                                    </BlockHead>
                                                    <BlockHead>
                                                        <BlockTitle tag="h6">Name and Contact Information</BlockTitle>
                                                    </BlockHead>
                                                    <div className="profile-ud-list">
                                                        <ProfileDetailText label="Company Name" text={page_data?.company_details?.company_name || "---"} />
                                                        <ProfileDetailText label="Email Address" text={page_data?.company_details?.company_email_address || "---"} />
                                                        <ProfileDetailText label="Mobile Number" text={page_data?.company_details?.company_mobile_number || "---"} />
                                                        <ProfileDetailText label="Registration Number" text={page_data?.company_details?.pacra_registration_number || "---"} />
                                                        <ProfileDetailText label="Tax Number" text={page_data?.company_details?.tax_number || "---"} />
                                                        <ProfileDetailText label="Registration Date" text={page_data?.company_details?.inserted_at ? moment(page_data?.company_details?.inserted_at).format("MMMM Do, YYYY h:mm:ss") : "---"} />
                                                    </div>
                                                    <BlockHead className='mt-5'>
                                                        <BlockTitle tag="h6">Documents</BlockTitle>
                                                    </BlockHead>
                                                    <Row className="g-gs w-100">
                                                        <DocumentViewer lg={6} xxl={6} name="Pacra Document" doc={page_data?.company_details?.company_pacra_doc} />
                                                        <DocumentViewer lg={6} xxl={6} name="Tax Document" doc={page_data?.company_details?.company_tpin_doc} />
                                                    </Row>
                                                </Block>
                                            </>
                                        )
                                    }
                                    {
                                        activeCardTab === "Representatives" && (
                                            <>
                                                <div className="my-3">
                                                    <ReadOnlyTable data={page_data?.company_representatives} tableType={app_consts.ONBOARD_COMPANY_REPRESENTATIVE} />
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        activeCardTab === "Properties" && (
                                            <>
                                                <Col xl="12" className="my-3">
                                                    <div className="form-group">
                                                        <div className="form-control-wrap-flex-wrap justify-end">
                                                            <Button onClick={openCompRepModal} className="btn btn-dim btn-outline-primary" size="md">
                                                                Add Company Property
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <div className="my-3">
                                                    <ReadOnlyTable data={page_data?.company_properties} tableType={app_consts.ONBOARD_USER_PROPERTY} />
                                                </div>
                                                {/* Add Property Modal */}
                                                <ModalAdd
                                                    openModal={open_add_properties_modal}
                                                    setOpenModal={setAddPropertiesModal}
                                                    modalTitle="Onboard Company Property"
                                                    modalDescription="Please Complete this form to Onboard Company User Property"
                                                    type={onboard_property_type}
                                                    succFunc={successAddPropertyFunc}
                                                    accountId={page_data?.user_account?.unique_identification_number}
                                                />
                                            </>
                                        )
                                    }
                                </>
                    }
                </Block>
            </Content>
        </>
    );
};

export default CompanyAccountDetails;