import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_delivery_note_ticket: { status: "idle" },
};

export const create_delivery_note_ticket_Slice = createSlice({
    name: "create_delivery_note_ticket",
    initialState,
    reducers: {
        success_create_delivery_note_ticket: (state, action) => {
            state.create_delivery_note_ticket = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_delivery_note_ticket: (state, action) => {
            state.create_delivery_note_ticket = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_delivery_note_ticket: (state) => {
            state.create_delivery_note_ticket = { status: app_consts.IDLE };
        },
        loading_create_delivery_note_ticket: (state) => {
            state.create_delivery_note_ticket = { status: app_consts.LOADING };
        },
    }
});

export const { success_create_delivery_note_ticket, fail_create_delivery_note_ticket, reset_create_delivery_note_ticket, loading_create_delivery_note_ticket } = create_delivery_note_ticket_Slice.actions;

export default create_delivery_note_ticket_Slice.reducer;