import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import TextInput from "../../../../../components/inputs/TextInput";
import ListText from "../../../text/ListText";
import { Link } from "react-router-dom";
import { employee_dashboard_actions_helper } from "../../../../../../actions/actions_helper";
import {
  fail_product_type_list,
  loading_product_type_list,
  reset_product_type_list,
  success_product_type_list,
} from "../../../../../../reducers/dashboard_reducers/products/product_type_list_Slice";
import {
  ADD_PRODUCT_ACCESS_DENIED_MSG,
  ADD_PRODUCT_ERR_MSG,
  ADD_PRODUCT_SUCCESS_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import {
  ADD_PRODUCT,
  RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST,
  RETRIEVE_PRODUCT_TYPES_LIST,
} from "../../../../../../api/api_data";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../../../utils/Utils";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import {
  fail_add_product,
  loading_add_product,
  reset_add_product,
  success_add_product,
} from "../../../../../../reducers/dashboard_reducers/products/add_product_Slice";

const insitialState = { product_name: "", product_type_id: "", product_type_name: "" };
const previewState = { product_name: "", product_type_name: "" };

const AddProductModal = ({ successFunction, openModal, setModalLoading }) => {
  const [formData, setFormData] = useState(insitialState);
  const [previewData, setPreviewData] = useState(previewState);
  const [step, setStep] = useState(1);
  const [errorVal, setError] = useState("");
  const [listLoadnig, setListLoading] = useState(false);
  const dispatch = useDispatch();
  const [productTypeList, setProductTypeList] = useState([]);
  const [addLoading, setAddLoading] = useState(false);

  // UseEffect to Listen to Modal Opening
  useEffect(() => {
    if (openModal)
    {
      // Fetch Product Type List
      retrieve_product_type_list();
    }
  }, [openModal]);

  // Retrieve Product Type List
  const retrieve_product_type_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_product_type_list,
        success_product_type_list,
        fail_product_type_list,
        RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
        RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST,
        null,
        RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG,
        app_consts.MAP,
        app_consts.PRODUCT_TYPE_MAP
      )
    );
  };

  //Listen to State of Product Type List
  const product_type_list = useSelector((state) => state.product_type_list.product_type_list);
  //
  useEffect(() => {
    if (product_type_list.status !== app_consts.IDLE)
    {
      if (product_type_list.status === app_consts.LOADING)
      {
        setListLoading(true);
      } else
      {
        setListLoading(false);
        if (product_type_list.status === app_consts.FAIL)
        {
          messageToast(product_type_list.message, app_consts.FAIL);
        } else if (product_type_list.status === app_consts.SUCCESS)
        {
          setProductTypeList(product_type_list.data);
        }
        dispatch(reset_product_type_list());
      }
    }
  }, [product_type_list]);

  //   Refresh Product Type List
  const refreshList = () => {
    retrieve_product_type_list();
  };

  // Handle Preview
  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  const handleSubmit = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_add_product,
        success_add_product,
        fail_add_product,
        ADD_PRODUCT_ERR_MSG,
        ADD_PRODUCT_SUCCESS_MSG,
        app_consts.POST,
        ADD_PRODUCT,
        formData,
        ADD_PRODUCT_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Add Product State
  const add_product = useSelector((state) => state.add_product.add_product);
  // UseEffect
  useEffect(() => {
    if (add_product.status !== app_consts.IDLE)
    {
      if (add_product.status === app_consts.LOADING)
      {
        setAddLoading(true);
        setModalLoading(true);
      } else
      {
        setAddLoading(false);
        setModalLoading(false);
        if (add_product.status === app_consts.FAIL)
        {
          setError(add_product.message);
          prevStep();
        } else if (add_product.status === app_consts.SUCCESS)
        {
          messageToast(`${formData.product_name} has been Successfully Added.`, app_consts.SUCCESS);
          // Close Modal and Other Embedded Functions if Need Be
          successFunction();
        }
        dispatch(reset_add_product());
      }
    }
  }, [add_product]);

  //Previous Step
  const prevStep = () => {
    setFormData({ ...formData, ["product_type_id"]: "" });
    setStep(1);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">Add Product Form</h5>
        {step === 2 && (
          <div
            className="nk-block-head-sub mb-2"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            <Icon name="arrow-left" />
            <span>back</span>
          </div>
        )}
      </div>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      {step === 1 ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Row className="g-4">
              <TextInput
                label="Product Name"
                required
                type="text"
                id_val="product_name"
                maxlength="100"
                value={formData.product_name}
                colSize="10"
                name="product_name"
                handleChange={(e) => {
                  setFormData({ ...formData, ["product_name"]: e.target.value });
                  setPreviewData({ ...previewData, ["product_name"]: e.target.value });
                }}
              />
              <Col>
                <div className="form-group">
                  <label className="form-label mb-1 text-md" htmlFor="username">
                    Product Type
                  </label>
                  <div className="form-control-wrap align-items-center w-100 d-flex flex-row mb-2 justify-between">
                    <div className="w-85">
                      <RSelect
                        options={productTypeList}
                        className="w-100"
                        placeholder="Search"
                        id="username"
                        onChange={(e) => {
                          setFormData({ ...formData, ["product_type_id"]: e.value });
                          setPreviewData({ ...previewData, ["product_type_name"]: e.label });
                        }}
                        isDisabled={listLoadnig}
                      />
                    </div>
                    <div className="w-12">
                      {
                        // Waiting for List to Load
                        listLoadnig ? (
                          <Spinner />
                        ) : (
                          <Link
                            to="#reload"
                            onClick={(e) => {
                              e.preventDefault();
                              refreshList();
                            }}
                          >
                            <Icon name="reload-alt btn-icon fs-22px"></Icon>
                          </Link>
                        )
                      }
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="12" className="d-flex justify-content-center">
                <Button
                  onClick={handlePreview}
                  size="lg"
                  type="submit"
                  color="primary"
                  disabled={!(formData.product_name && formData.product_type_id)}
                >
                  Preview Product and Add
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : step === 2 ? (
        <>
          <ListText dataItem="data-item mySmall" label="Product Name" value={previewData.product_name} />
          <ListText dataItem="data-item mySmall" label="Product Type" value={previewData.product_type_name} />
          <Col xl="12" className="d-flex justify-content-end mt-4 mr-2">
            <Button onClick={handleSubmit} disabled={addLoading} color="primary">
              {addLoading ? <Spinner size="sm" color="light" /> : "Add Product"}
            </Button>
          </Col>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddProductModal;
