import DashboardRoutes from "./route/dashboard_routes";
import ThemeProvider from "./layout/provider/Theme";
import WebsiteRoutes from "./route/website_routes";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { website_store } from "./app/website_store";
import { dashboard_store } from "./app/dashboard_store";
import { ToastContainer } from "react-toastify";
import { client_store } from "./app/client_store";
import ClientRoutes from "./route/ClientRoutes";
import * as app_consts from "./constants/react_app_consts";

// Declaring Login Status and User Type Variables
let login_status;
let user_type;
//Set Login State before React App fully Initializes
if (localStorage.getItem("access"))
{
  // Logs in User if Variables are present
  login_status = true;
  if (localStorage.getItem("log_cred"))
  {
    user_type = JSON.parse(localStorage.getItem("log_cred")).account_type;
  }
} else
{
  // Logs out user if Variables aren't present
  login_status = false;
  user_type = null;
}

const App = () => {
  // State that holds Login Status
  const [isSignIn, setIsSignIn] = useState(login_status);
  const [account_type, setAccountType] = useState(user_type);

  // UseEffect to Get Account Type ID
  useEffect(() => {
    setAccountType(user_type);
  }, [user_type]);

  return (
    <>
      <ThemeProvider>
        {!isSignIn ? (
          <>
            {/* Website Pages */}
            {/* Website Store */}
            <Provider store={website_store}>
              <WebsiteRoutes />
            </Provider>
          </>
        ) : (
          <>
            {/* Direct to Which Dashboard Access Based On Account Type */}
            {
              account_type === app_consts.DASHBOARD_USER_ACCOUNT_TYPE_STRING ?
                <>
                  {/* Employee Dashboard */}
                  {/* Wrapping Around Provider */}
                  <Provider store={dashboard_store}>
                    <DashboardRoutes />
                    <ToastContainer />
                  </Provider>
                </>
                :
                <Provider store={client_store}>
                  <ClientRoutes account_type={account_type} />
                  <ToastContainer />
                </Provider>
            }
          </>
        )}
      </ThemeProvider >
    </>
  );
};
export default App;