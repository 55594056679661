import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BlockBetween, BlockHeadContent } from '../../../../components/Component';
import { success_sign_out } from '../../../../reducers/dashboard_reducers/auth/sign_out_Slice';
import { success_client_sign_out } from '../../../../reducers/client_reducers/auth/sign_out_slice';

const ModalLogout = ({ logout_var }) => {

    const dispatch = useDispatch();
    const [modalTop, setModalTop] = useState(logout_var);

    const toggleTop = () => {
        dispatch(success_sign_out());
        dispatch(success_client_sign_out());
    };

    useEffect(() => {
        setModalTop(logout_var);
    }, [logout_var]);


    return (
        <>
            <Modal isOpen={modalTop} toggle={toggleTop} className="modal-dialog-top">
                <ModalHeader
                    toggle={toggleTop}
                >
                    Session Expired
                </ModalHeader>
                <ModalBody>
                    <p>
                        Please Sign in Again to Continue Using the System.
                    </p>
                </ModalBody>
                <ModalFooter className="bg-light">
                    <BlockBetween>
                        <BlockHeadContent>
                            <Button onClick={toggleTop} color="danger">
                                Sign In
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </ ModalFooter>
            </Modal>
        </>
    );
};

export default ModalLogout;