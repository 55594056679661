import React, { useEffect, useState } from 'react';
import Content from '../../../layout/content/Content';
import Head from '../../../layout/head/Head';
import { useParams } from 'react-router';
import { Block, BlockHead, BlockTitle, Button, Col, Row } from '../../../components/Component';
import PageHeading from '../components/page_heading/PageHeading';
import { Card, Spinner } from 'reactstrap';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { RETRIEVE_SPECIFIC_USER_DETAILS_API } from '../../../api/api_data';
import { fail_specific_user_details, loading_specific_user_details, reset_specific_user_details, success_specific_user_details } from '../../../reducers/dashboard_reducers/user/specific_user_details_Slice';
import { RETRIEVE_USER_DETAILS_ACCESS_DENIED_MSG, RETRIEVE_USER_DETAILS_ERR_MSG, RETRIEVE_USER_DETAILS_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { findUpper, messageToast } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import * as app_consts from "../../../constants/react_app_consts";
import CardTabs from '../components/cards/CardTabs';
import ProfileDetailText from '../components/text/ProfileDetailText';
import moment from 'moment';
import ImageContainer from '../../../components/partials/gallery/GalleryImage';
import ReadOnlyTable from '../components/table/ReadOnlyTable';

const type = app_consts.USER_ACCOUNT_LIST;

const UserDetails = () => {

    const { phone_number } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [page_data, setPageData] = useState([]);
    const dispatch = useDispatch();
    const [activeCardTab, setActiveCardTab] = useState("Main Details");
    const [dataFetched, setDataFetched] = useState(false);
    const [user_account_list, setUserAccountList] = useState([]);

    // UseEffect that Runs When Page first Opends
    useEffect(() => {

        dispatch(
            employee_dashboard_actions_helper(
                loading_specific_user_details,
                success_specific_user_details,
                fail_specific_user_details,
                RETRIEVE_USER_DETAILS_ERR_MSG,
                RETRIEVE_USER_DETAILS_SUCCESS_MSG,
                app_consts.POST,
                RETRIEVE_SPECIFIC_USER_DETAILS_API,
                { phone_number: phone_number },
                RETRIEVE_USER_DETAILS_ACCESS_DENIED_MSG
            )
        );
    }, []);

    // Listen to Reducer State
    const specific_user_details = useSelector((state) => state.specific_user_details.specific_user_details);
    // 
    useEffect(() => {
        if (specific_user_details.status !== app_consts.IDLE)
        {
            if (specific_user_details.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (specific_user_details.status === app_consts.FAIL)
                {
                    setError(specific_user_details.message);
                    messageToast(specific_user_details.message, app_consts.FAIL);
                } else if (specific_user_details.status === app_consts.SUCCESS)
                {
                    setPageData(specific_user_details.data);
                    setUserAccountList(specific_user_details.data?.user_accounts);
                }
                dispatch(reset_specific_user_details());
            }
        }
    }, [specific_user_details]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Main Details", icon: "user-circle"
        },
        {
            tabName: "User Accounts", icon: "user-circle"
        },
    ];

    return (
        <>
            <Head title={`User Details - ${phone_number}`} />
            <Content>
                <Block>
                    {/* Table Page Heading */}
                    <PageHeading
                        title="User Details"
                        title_addition={phone_number}
                        description="User Details, KYC Information and Transaction Information"
                        backToText="Users"
                        backToLink={`/dashboard/user`}
                    />
                    {
                        loading ?
                            <>
                                <div className='w-full p-5 d-flex justify-center items-center'>
                                    <Spinner />
                                </div>
                            </>
                            :
                            error && error.length > 0 ?
                                <>
                                    <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                        {error}
                                    </div>
                                </>
                                :
                                <>
                                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={dataFetched} />
                                    <div className="card-inner">
                                        {
                                            activeCardTab === "Main Details" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">Main Details</BlockTitle>
                                                            <p>Names, Address and Contact Information</p>
                                                        </BlockHead>
                                                        <BlockHead>
                                                            <BlockTitle tag="h6">Names and Contact Information</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="First Name" text={page_data?.first_name || "---"} />
                                                            <ProfileDetailText label="Middle Name" text={page_data?.middle_name || "---"} />
                                                            <ProfileDetailText label="Surname" text={page_data?.surname || "---"} />
                                                            <ProfileDetailText label="Email Address" text={page_data?.email_address || "---"} />
                                                            <ProfileDetailText label="Phone Number" text={page_data?.phone_number || "---"} />
                                                            <ProfileDetailText textClassName={page_data.account_status_id === 1
                                                                ? "text-success" : "text-warning"} label="Account Status" text={page_data?.account_status || "---"} />
                                                            <ProfileDetailText textClassName={page_data?.kyc_registration_status_id === 1 ? "text-success" : "text-warning"} label="KYC Status" text={page_data?.kyc_registration_status || "---"} />
                                                            <ProfileDetailText label="Registration Date" text={page_data?.registration_date ? moment(page_data?.registration_date).format("MMMM Do, YYYY h:mm:ss") : "---"} />
                                                        </div>
                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Address Information</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Physical Address" text={page_data?.address || "---"} />
                                                            <ProfileDetailText label="District" text={page_data?.district || "---"} />
                                                            <ProfileDetailText label="Country" text={page_data?.country || "---"} />
                                                        </div>
                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Registered Branch</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="Branch Area" text={page_data?.branch_area || "---"} />
                                                            <ProfileDetailText label="Branch Physical Address" text={page_data?.branch_physical_address || "---"} />
                                                            <ProfileDetailText label="Province" text={page_data?.branch_province_name || "---"} />
                                                            <ProfileDetailText label="Country" text={page_data?.branch_country_name || "---"} />
                                                        </div>

                                                        <BlockHead className='mt-5'>
                                                            <BlockTitle tag="h6">Document Information</BlockTitle>
                                                        </BlockHead>
                                                        <div className="profile-ud-list">
                                                            <ProfileDetailText label="ID Number" text={page_data?.id_number || "---"} />
                                                            <ProfileDetailText label="Tpin" text={page_data?.tpin || "---"} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                        {
                                            activeCardTab === "User Accounts" && (
                                                <>
                                                    <Block>
                                                        <BlockHead>
                                                            <BlockTitle tag="h5">User Accounts</BlockTitle>
                                                            <p>Assigned User Accounts</p>
                                                        </BlockHead>
                                                        {/* Table */}
                                                        <div className="my-3">
                                                            <ReadOnlyTable data={user_account_list} tableType={type} />
                                                        </div>
                                                    </Block>
                                                </>
                                            )
                                        }
                                    </div>
                                </>
                    }
                </Block>
            </Content>
        </>
    );
};

export default UserDetails;