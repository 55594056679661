import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
 continue_processing_loading_order: { status: "idle" },
};

export const continue_processing_loading_order_Slice = createSlice({
 name: "continue_processing_loading_order",
 initialState,
 reducers: {
  success_continue_processing_loading_order: (state, action) => {
   state.continue_processing_loading_order = { status: app_consts.SUCCESS, data: action.payload };
  },
  fail_continue_processing_loading_order: (state, action) => {
   state.continue_processing_loading_order = { status: app_consts.FAIL, message: action.payload };
  },
  reset_continue_processing_loading_order: (state) => {
   state.continue_processing_loading_order = { status: app_consts.IDLE };
  },
  loading_continue_processing_loading_order: (state) => {
   state.continue_processing_loading_order = { status: app_consts.LOADING };
  },
 }
});

export const { success_continue_processing_loading_order, fail_continue_processing_loading_order, reset_continue_processing_loading_order, loading_continue_processing_loading_order } = continue_processing_loading_order_Slice.actions;

export default continue_processing_loading_order_Slice.reducer;