import React, { useEffect, useState } from "react";
import { Card, CardTitle } from "reactstrap";
import { Button, Col } from "../../../../../../../../components/Component";
import ModalAdd from "../../../../../modal/modal_add/ModalAdd";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import ReadOnlyTable from "../../../../../table/ReadOnlyTable";
import { useNavigate } from "react-router";

const type = app_consts.ONBOARD_USER_PROPERTY;

const OnboardCompanyPropertyStep = ({ togglestep, nextStep, data, uniqueID }) => {
  const [user_account_id, setUserAccountId] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (uniqueID.user_account_id)
    {
      setUserAccountId(uniqueID.user_account_id);
    }
  }, [uniqueID]);

  //   Open Add Property Modal
  const openPropertyModal = () => {
    setOpenAddModal(true);
  };

  // Modal Success Function
  const successAddFunc = (property_data = null) => {
    setOpenAddModal(false);
    setPropertyData([...propertyData, property_data]);
  };


  return (
    <>
      <Card className="card-inner">
        <div className="d-flex justify-between items-center">
          <CardTitle tag="h5">Onboard Company Property</CardTitle>
          <Button onClick={() => navigate(`/dashboard/onboarding/user`)} color="primary" size="md">
            Finish
          </Button>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Col lg="12" className="mb-3">
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label text-soft">User Account ID :</label>
              </div>
              <div className="form-control-wrap">
                <label className="form-label text-primary">{user_account_id ? user_account_id : ""}</label>
              </div>
            </div>
          </Col>
          <Col xl="12" className="mb-3 mt-3">
            <div className="form-group">
              <div className="form-control-wrap-flex-wrap justify-start">
                <Button onClick={openPropertyModal} className="btn btn-dim btn-outline-primary" size="md">
                  Add Property
                </Button>
              </div>
            </div>
          </Col>
        </form>
        {/* Table */}
        <div className="my-3">
          <ReadOnlyTable data={propertyData} tableType={type} />
        </div>
      </Card>
      {/* Add Modal */}
      <ModalAdd
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        modalTitle="Onboard Company Property"
        modalDescription="Please Complete this form to Onboard Company User Property"
        type={type}
        succFunc={successAddFunc}
        accountId={user_account_id}
      />
    </>
  );
};

export default OnboardCompanyPropertyStep;
