import React, { useEffect, useState } from 'react';
import { Button, Col, Icon, RSelect, Row } from '../../../../../../components/Component';
import { Alert, Spinner } from 'reactstrap';
import { retreive_rSelectVariables } from '../../../../../../utils/functionalUtils';
import { messageToast, rSelectMapFun } from '../../../../../../utils/Utils';
import * as app_consts from "../../../../../../constants/react_app_consts";
import TextInput from '../../../../../components/inputs/TextInput';
import { useDispatch } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { fail_product_list, loading_product_list, reset_product_list, success_product_list } from '../../../../../../reducers/dashboard_reducers/products/product_list_Slice';
import { CREATE_DELIVERY_NOTE_PRODUCT_ACCESS_DENIED_MSG, CREATE_DELIVERY_NOTE_PRODUCT_ERR_MSG, CREATE_DELIVERY_NOTE_PRODUCT_SUCCESS_MSG, RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_ACCESS_DENIED_MSG, RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_ERR_MSG, RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_SUCCESS_MSG, RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG, RETRIEVE_PRODUCTS_LIST_ERR_MSG, RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG, RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG, RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG, RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import { CREATE_PHYSICAL_DELIVERY_NOTE_PRODUCT_API, RETRIEVE_ACTIVE_PRODUCT_IN_SPECIFIC_ACTIVE_BRANCHES, RETRIEVE_UNIT_MEASUREMENTS_BY_STATUS_ENDPOINT } from '../../../../../../api/api_data';
import { useSelector } from 'react-redux';
import { fail_unit_measurement_list, loading_unit_measurement_list, reset_unit_measurement_list, success_unit_measurement_list } from '../../../../../../reducers/dashboard_reducers/unit_measurement/unit_measurements_list_Slice';
import ListText from '../../../text/ListText';
import { fail_create_delivery_note_product, loading_create_delivery_note_product, reset_create_delivery_note_product, success_create_delivery_note_product } from '../../../../../../reducers/dashboard_reducers/delivery_notes/create_delivery_note_product_Slice';

const insitialState = { product_id: "", product_type: "", product: "", warehouse_storage_id: "", warehouse_storage: "", unit_measurement_id: "", unit_measurement: "", total_capacity_selected_measurement: "", packaging_total_number: "", total_available_capacity_mt: "", total_capacity_in_mt: "", remaining_capacity: "" };

const AddDeliveryNoteProductModal = ({ page_data, openModal, successFunction }) => {

    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [errorVal, setError] = useState("");
    const [productTypeList, setProductTypeList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productListLoading, setProductListLoading] = useState(true);
    const [formData, setFormData] = useState(insitialState);
    const [selectedProductType, setSelectedProductType] = useState([]);
    const [warehouse_storages, setWarehouseStorages] = useState([]);
    const [receivedData, setReceivedData] = useState(page_data);
    const [ismodalOpen, setIsModalOpen] = useState(openModal);
    const [api_data, setRetievedData] = useState([]);
    const [unit_measurements, setUnitMeasurements] = useState([]);
    const [addLoading, setAddLoading] = useState(false);

    // Useffect to Run when the Modal Opens
    useEffect(() => {
        setReceivedData(page_data);
        if (openModal && page_data)
        {
            // Retrieve Unit Measurement
            retrieve_unit_measurements_list();
            // Retrieve Product List
            dispatch(
                employee_dashboard_actions_helper(
                    loading_product_list,
                    success_product_list,
                    fail_product_list,
                    RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_ERR_MSG,
                    RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_SUCCESS_MSG,
                    app_consts.POST,
                    RETRIEVE_ACTIVE_PRODUCT_IN_SPECIFIC_ACTIVE_BRANCHES,
                    { inventory_type_id: 2, branch_location_id: page_data?.continue_processing_data?.branch_location_id || page_data?.continue_processing_data?.branch_location?.branch_location_id },
                    RETRIEVE_ACTIVE_PRODUCTS_IN_ACTIVE_BRANCH_LOCATION_ACCESS_DENIED_MSG
                )
            );
        }
    }, [openModal, page_data]);

    //Listen to State of Product List
    const product_list = useSelector((state) => state.product_list.product_list);

    useEffect(() => {
        if (product_list.status !== app_consts.IDLE)
        {
            if (product_list.status === app_consts.LOADING)
            {
                setProductListLoading(true);
            } else
            {
                setProductListLoading(false);
                if (product_list.status === app_consts.FAIL)
                {
                    messageToast(product_list.message, app_consts.FAIL);
                } else if (product_list.status === app_consts.SUCCESS)
                {
                    setRetievedData(product_list.data);
                }
                dispatch(reset_product_list());
            }
        }
    }, [product_list]);

    // Product List Changes
    useEffect(() => {
        if (api_data) 
        {
            handleProductType();
        }
    }, [api_data]);

    const handleProductType = async () => {
        let mappedData = [];


        await Promise.all(
            api_data?.map(async (data_to_map) => {
                const { label, value } = retreive_rSelectVariables(app_consts.PRODUCT_TYPE_MAP, data_to_map);
                const result = await rSelectMapFun(label, value);
                mappedData.push(result);
            })
        );

        setProductTypeList(mappedData);
    };


    // Handle Product Type Change
    const handleProductTypeChange = async (value, label) => {
        if (value && label) 
        {
            setFormData({ ...formData, ["product_type"]: label, ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["warehouse_storage_id"]: "", ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_available_capacity_mt"]: "", ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
            let mappedData = [];

            const filtereredData = api_data?.filter((data) => data.product_type_id === value);

            await Promise.all(
                filtereredData?.map(async (data_to_map) => {
                    const { label, value } = retreive_rSelectVariables(app_consts.PRODUCTS_MAP, data_to_map);
                    const result = await rSelectMapFun(label, value);
                    mappedData.push(result);
                })
            );
            setSelectedProductType(mappedData);
        }
        else
        {
            setFormData({ ...formData, ["product_type"]: "", ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["warehouse_storage_id"]: "", ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_available_capacity_mt"]: "", ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
        }
    };

    const handleProductChange = async (value, label) => {
        if (value && label) 
        {
            setFormData({ ...formData, ["product_id"]: value, ["product"]: label, ["warehouse_storage_id"]: "", ["warehouse_storage_id"]: "", ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_available_capacity_mt"]: "", ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
            let mappedData = [];

            const filtereredData = api_data?.filter((data) => data.product_id === value);

            await Promise.all(
                filtereredData?.map(async (data_to_map) => {
                    const { label, value } = retreive_rSelectVariables(app_consts.WAREHOUSE_STORAGE_TYPES_MAP, data_to_map);
                    const result = await rSelectMapFun(label, value);
                    mappedData.push(result);
                })
            );
            setWarehouseStorages(mappedData);
        }
        else
        {
            setFormData({ ...formData, ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["warehouse_storage_id"]: "", ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_available_capacity_mt"]: "", ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
        }
    };

    // Handle Change
    const handleChange = (e) => {
        if (e.target.name === "packaging_total_number") 
        {
            setFormData({ ...formData, ["packaging_total_number"]: e.target.value, ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "", ["total_capacity_selected_measurement"]: "" });
        }
        else if (e.target.name === "total_capacity_selected_measurement") 
        {
            if (e.target.value) 
            {
                setFormData({ ...formData, ["total_capacity_selected_measurement"]: e.target.value, ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
            }
            else
            {
                setFormData({ ...formData, ["total_capacity_selected_measurement"]: "", ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
            }
            // setFormData({ ...formData, ["packaging_total_number"]: e.target.value, ["unit_measurement_id"]: "", ["unit_measurement"]: "" });
        }
        else if (e.target.name === "total_capacity_in_mt") 
        {
            if (e.target.value) 
            {
                setFormData({ ...formData, ["total_capacity_in_mt"]: e.target.value, ["remaining_capacity"]: "" });
            }
            else
            {
                setFormData({ ...formData, ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
            }
            // setFormData({ ...formData, ["packaging_total_number"]: e.target.value, ["unit_measurement_id"]: "", ["unit_measurement"]: "" });
        }
    };

    // Retrieve Unit Measurement list
    const retrieve_unit_measurements_list = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_unit_measurement_list,
                success_unit_measurement_list,
                fail_unit_measurement_list,
                RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG,
                RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG,
                app_consts.POST,
                RETRIEVE_UNIT_MEASUREMENTS_BY_STATUS_ENDPOINT,
                { unit_measurement_status_id: app_consts.ACTIVATE_ACCOUNT_ID },
                RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG,
                app_consts.MAP,
                app_consts.UNIT_MEASUREMENTS_MAP
            )
        );
    };

    //Listen to State of Unit Measurement List
    const unit_measurement_list = useSelector((state) => state.unit_measurement_list.unit_measurement_list);

    useEffect(() => {
        if (unit_measurement_list.status !== app_consts.IDLE)
        {
            if (unit_measurement_list.status === app_consts.LOADING)
            {
                // setLoading(true);
            } else
            {
                // setLoading(false);
                if (unit_measurement_list.status === app_consts.FAIL)
                {
                    messageToast(unit_measurement_list.message, app_consts.FAIL);
                } else if (unit_measurement_list.status === app_consts.SUCCESS)
                {
                    setUnitMeasurements(unit_measurement_list.data);
                }
                dispatch(reset_unit_measurement_list());
            }
        }
    }, [unit_measurement_list]);

    const handleStorageTypeChange = (value, label) => {
        if (value && label) 
        {

            const filtereredData = api_data?.filter((data) => data.product_id === formData.product_id && data.warehouse_storage_type_id === value);
            if (filtereredData) 
            {
                setFormData({ ...formData, ["warehouse_storage_id"]: filtereredData[0]?.warehouse_storage_id, ["warehouse_storage"]: label, ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_available_capacity_mt"]: filtereredData[0]?.capacity });
            }
            else
            {
                setFormData({ ...formData, ["warehouse_storage_id"]: value, ["warehouse_storage"]: label, ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "" });
            }
        }
        else
        {
            setFormData({ ...formData, ["warehouse_storage_id"]: "", ["warehouse_storage_id"]: "", ["unit_measurement_id"]: "", ["unit_measurement"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_available_capacity_mt"]: "" });
        }
    };

    const handleUnitMeasurementChange = (value, label) => {
        if (value && label) 
        {
            setFormData({ ...formData, ["unit_measurement_id"]: value, ["unit_measurement"]: label });

        }
        else
        {
            setFormData({ ...formData, ["unit_measurement_id"]: "", ["unit_measurement"]: "" });
        }
    };

    const handleOnblur = (e) => {
        if (e.target.name === "total_capacity_in_mt")
        {
            if (e.target.value)
            {
                if (parseFloat(e.target.value) > parseFloat(formData?.total_available_capacity_mt))
                {
                    setFormData({ ...formData, ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
                }
                else
                {
                    setFormData({ ...formData, ["total_capacity_in_mt"]: parseFloat(e.target.value).toFixed(2), ["remaining_capacity"]: parseFloat(parseFloat(formData?.total_available_capacity_mt - e.target.value)).toFixed(2) });
                }
            }
            else
            {
                setFormData({ ...formData, ["total_capacity_in_mt"]: "", ["remaining_capacity"]: "" });
            }
        }
        else
        {
            if (e.target.value)
            {
                setFormData({ ...formData, [e.target.name]: parseFloat(e.target.value).toFixed(2) });
            }
            else
            {
                setFormData({ ...formData, [e.target.name]: "" });
            }
        }
    };

    // Handle Preview
    const handlePreview = () => {
        setError("");
        setStep(2);
    };

    // Handle Submit
    const handleSubmit = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_create_delivery_note_product,
                success_create_delivery_note_product,
                fail_create_delivery_note_product,
                CREATE_DELIVERY_NOTE_PRODUCT_ERR_MSG,
                CREATE_DELIVERY_NOTE_PRODUCT_SUCCESS_MSG,
                app_consts.POST,
                CREATE_PHYSICAL_DELIVERY_NOTE_PRODUCT_API,
                { delivery_note_id: receivedData?.continue_processing_data?.delivery_note_id, product_id: formData.product_id, warehouse_storage_id: formData.warehouse_storage_id, unit_measurement_id: formData.unit_measurement_id, total_capacity_selected_measurement: formData.total_capacity_selected_measurement, packaging_total_number: formData.packaging_total_number, total_capacity_in_mt: formData.total_capacity_in_mt },
                CREATE_DELIVERY_NOTE_PRODUCT_ACCESS_DENIED_MSG
            )
        );
        // 
    };

    //Listen to State of Product List
    const create_delivery_note_product = useSelector((state) => state.create_delivery_note_product.create_delivery_note_product);

    useEffect(() => {
        if (create_delivery_note_product.status !== app_consts.IDLE)
        {
            if (create_delivery_note_product.status === app_consts.LOADING)
            {
                setAddLoading(true);
            } else
            {
                setAddLoading(false);
                if (create_delivery_note_product.status === app_consts.FAIL)
                {
                    messageToast(create_delivery_note_product.message, app_consts.FAIL);
                    setError(create_delivery_note_product.message);
                } else if (create_delivery_note_product.status === app_consts.SUCCESS)
                {
                    messageToast(CREATE_DELIVERY_NOTE_PRODUCT_SUCCESS_MSG, app_consts.SUCCESS);
                    successFunction(create_delivery_note_product.data);
                }
                dispatch(reset_create_delivery_note_product());
            }
        }
    }, [create_delivery_note_product]);

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <h5 className="card-title text-start w-100">Add Delivery Note Ticket Product</h5>
                {step === 2 && (
                    <div
                        className="nk-block-head-sub mb-2"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            setStep(1);
                            setFormData(insitialState);
                        }}
                    >
                        <Icon name="arrow-left" />
                        <span>back</span>
                    </div>
                )}
            </div>
            {errorVal && (
                <div className="mb-3 w-100">
                    <Alert color="danger" className="alert-icon">
                        <Icon name="alert-circle" /> {errorVal}{" "}
                    </Alert>
                </div>
            )}{step === 1 ? (
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Row className="g-4">
                            <div className="form-label-group">
                                <label className="form-label text-soft fs-22px">Product Details</label>
                            </div>
                            <Col size={6} className="mt-0">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Product Type
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={productTypeList}
                                                className="w-100"
                                                placeholder="Search"
                                                id="product_type"
                                                onChange={(e) => { handleProductTypeChange(e.value, e.label); }}
                                                isDisabled={productListLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col size={6} className="mt-0">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Product
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={selectedProductType}
                                                className="w-100"
                                                placeholder="Search"
                                                id="product_id"
                                                onChange={(e) => { handleProductChange(e.value, e.label); }}
                                                isDisabled={!formData.product_type}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <hr />
                            <div className="form-label-group">
                                <label className="form-label text-soft fs-22px">Warehouse Storage Details</label>
                            </div>
                            <TextInput
                                parentClassName="mt-0"
                                label="Branch Ref Code"
                                id_val="branch_ref_code"
                                maxlength="160"
                                type="text"
                                value={receivedData?.property_ref_code}
                                colSize="6"
                                name="branch_ref_code"
                                disabled
                            />
                            <Col size={6} className="mt-0">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="warehouse_storage_id">
                                        Warehouse Storage Type
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={warehouse_storages}
                                                className="w-100"
                                                placeholder="Search"
                                                id="warehouse_storage_id"
                                                onChange={(e) => { handleStorageTypeChange(e.value, e.label); }}
                                                isDisabled={productListLoading || !formData.product}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <TextInput
                                parentClassName="mt-2"
                                label="Packaging Total Number"
                                id_val="packaging_total_number"
                                type="number"
                                value={formData?.packaging_total_number}
                                colSize="6"
                                name="packaging_total_number"
                                handleChange={handleChange}
                                disabled={!formData.warehouse_storage_id}
                            />
                            <Col size={6} className="mt-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">
                                        Unit Measurement
                                    </label>
                                    <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
                                        <div className="w-100">
                                            <RSelect
                                                options={unit_measurements}
                                                className="w-100"
                                                placeholder="Search"
                                                id="unit_measurement_id"
                                                onChange={(e) => { handleUnitMeasurementChange(e.value, e.label); }}
                                                isDisabled={productListLoading || !formData.packaging_total_number}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <TextInput
                                parentClassName="mt-2"
                                label={`Total Capacity ${formData.unit_measurement ? `(${formData.unit_measurement})` : ""}`}
                                id_val="total_capacity_selected_measurement"
                                type="number"
                                value={formData?.total_capacity_selected_measurement}
                                colSize="6"
                                name="total_capacity_selected_measurement"
                                disabled={productListLoading || !formData.unit_measurement}
                                handleChange={handleChange}
                                onBlur={handleOnblur}
                            />
                            <TextInput
                                parentClassName="mt-2"
                                label="Delivery Note Capacity (MT)"
                                id_val="total_capacity_in_mt"
                                maxlength="160"
                                type="number"
                                value={formData?.total_capacity_in_mt}
                                colSize="6"
                                name="total_capacity_in_mt"
                                handleChange={handleChange}
                                disabled={productListLoading || !formData.total_capacity_selected_measurement}
                                onBlur={handleOnblur}
                            />
                            <TextInput
                                parentClassName="mt-2"
                                label="Total Available Capacity (MT)"
                                id_val="total_available_capacity_mt"
                                maxlength="160"
                                type="number"
                                value={formData?.total_available_capacity_mt}
                                colSize="6"
                                name="total_available_capacity_mt"
                                disabled
                            />
                            <TextInput
                                parentClassName="mt-2"
                                label="Remaining Capacity After Deduction (MT)"
                                id_val="remaining_capacity"
                                maxlength="160"
                                type="number"
                                value={formData?.remaining_capacity}
                                colSize="6"
                                name="remaining_capacity"
                                disabled
                            />
                            <Col xl="12" className="d-flex justify-content-center">
                                <Button
                                    onClick={handlePreview}
                                    size="lg"
                                    type="submit"
                                    color="primary"
                                    disabled={!(formData.product_id && formData.warehouse_storage_id && formData.unit_measurement_id && formData.total_capacity_selected_measurement && formData.total_available_capacity_mt && formData.packaging_total_number && formData.total_capacity_in_mt && formData.remaining_capacity)}
                                >
                                    Preview Product and Add
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </>
            )
                : step === 2 ? (
                    <>
                        <ListText dataItem="data-item mySmall" label="Product Name" value={formData.product} />
                        <ListText dataItem="data-item mySmall" label="Product Type" value={formData.product_type} />
                        <ListText dataItem="data-item mySmall" label="Warehouse Storage Type" value={formData.warehouse_storage} />
                        <ListText dataItem="data-item mySmall" label="Unit Measurement" value={formData.unit_measurement} />
                        <ListText dataItem="data-item mySmall" label="Packaging Total Number" value={formData.packaging_total_number} />
                        <ListText dataItem="data-item mySmall" label={`Total Capacity ${formData.unit_measurement ? `(${formData.unit_measurement})` : ""}`} value={formData.total_capacity_selected_measurement} />
                        <ListText dataItem="data-item mySmall" label="Delivery Note Capacity (MT)" value={formData.total_capacity_in_mt} />
                        <ListText dataItem="data-item mySmall" label="Total Available Capacity (MT)" value={formData.total_available_capacity_mt} />
                        <ListText dataItem="data-item mySmall" label="Remaining Capacity After Deduction (MT)" value={formData.remaining_capacity} />
                        <Col xl="12" className="d-flex justify-content-end mt-4 mr-2">
                            <Button onClick={handleSubmit} disabled={addLoading} color="primary">
                                {addLoading ? <Spinner size="sm" color="light" /> : "Add Product"}
                            </Button>
                        </Col>
                    </>
                ) : (
                    ""
                )
            }
        </>
    );
};

export default AddDeliveryNoteProductModal;