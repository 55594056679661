import React from "react";
import * as app_consts from "../../../../constants/react_app_consts";
import CreatePhysicalGoodsReceivedWizardIndex from "./wizard_pages/warehouse/CreatePhysicalGoodsReceivedWizardIndex";
import CompleteKYCRegWizardIndex from "./wizard_pages/onboarding/CompleteKYCRegWizardIndex";
import AssignCustomerAccountIndex from "./wizard_pages/onboarding/AssignCustomerAccountIndex";
import OnboardCompanyWizardIndex from "./wizard_pages/onboarding/OnboardCompanyWizardIndex";
import CreatePhsyicalDeliveryNoteIndex from "./wizard_pages/onboarding/CreatePhsyicalDeliveryNoteIndex";
import CreateLoadingOrderIndex from "./wizard_pages/onboarding/CreateLoadingOrderIndex";

const WizardComp = ({ type }) => {
  return (
    <>
      {
        // Create Physical Goods Received
        type === app_consts.CREATE_PHYSICAL_GOODS_RECEIVED_TICKET ? (
          <CreatePhysicalGoodsReceivedWizardIndex wizardType={type} />
        )
          :
          // Complete KYC
          type === app_consts.COMPLETE_KYC_REGISTRATION ? (
            <CompleteKYCRegWizardIndex wizardType={type} />
          )
            :
            // Assign Customer Account
            type === app_consts.ASSIGN_CUSTOMER_ACCOUNT ? (
              <AssignCustomerAccountIndex wizardType={type} />
            )
              :
              // Onboard Company 
              type === app_consts.ONBOARD_COMPANY ? (
                <OnboardCompanyWizardIndex wizardType={type} />
              )
                :
                // Create Physical Delivery Note
                type === app_consts.CREATE_PHYSICAL_DELIVERY_NOTE_TICKET ? (
                  <CreatePhsyicalDeliveryNoteIndex wizardType={type} />
                )
                  :
                  // Create Loading Order
                  type === app_consts.CREATE_LOADING_ORDER ? (
                    <CreateLoadingOrderIndex wizardType={type} />
                  )
                    :
                    (
                      ""
                    )}
    </>
  );
};

export default WizardComp;
