
import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_warehouse_storage: { status: "idle" },
};

export const create_warehouse_storage_Slice = createSlice({
    name: "create_warehouse_storage",
    initialState,
    reducers: {
        success_create_warehouse_storage: (state, action) => {
            state.create_warehouse_storage = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_warehouse_storage: (state, action) => {
            state.create_warehouse_storage = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_warehouse_storage: (state) => {
            state.create_warehouse_storage = { status: app_consts.IDLE };
        },
        loading_create_warehouse_storage: (state) => {
            state.create_warehouse_storage = { status: app_consts.LOADING };
        },
    }
});

export const { success_create_warehouse_storage, fail_create_warehouse_storage, reset_create_warehouse_storage, loading_create_warehouse_storage } = create_warehouse_storage_Slice.actions;

export default create_warehouse_storage_Slice.reducer;