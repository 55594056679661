import React, { useEffect, useState } from 'react';
import { Alert, Card, CardTitle, Spinner } from 'reactstrap';
import { BlockHead, BlockTitle, Button, Col, Icon, RSelect, Row } from '../../../../../../../../components/Component';
import RadioInput from '../../../../../input/RadioInput';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { Link } from 'react-router-dom';
import TextInput from '../../../../../../../components/inputs/TextInput';
import { CREATE_LOADING_ORDER_API } from '../../../../../../../../api/api_data';
import { fail_create_loading_order, loading_create_loading_order, reset_create_loading_order, success_create_loading_order } from '../../../../../../../../reducers/dashboard_reducers/loading_orders/create_loading_order_Slice';
import { employee_dashboard_actions_helper } from '../../../../../../../../actions/actions_helper';
import { CREATE_LOADING_ORDER_ACCESS_DENIED_MSG, CREATE_LOADING_ORDER_ERR_MSG, CREATE_LOADING_ORDER_SUCCESS_MSG } from '../../../../../../../../constants/success_error_messages_consts';
import { useSelector } from 'react-redux';
import { messageToast } from '../../../../../../../../utils/Utils';
import { useDispatch } from 'react-redux';
import ReadOnlyTable from '../../../../../table/ReadOnlyTable';

const driver_instructions_table_type = app_consts.DRIVER_INSTRUCTIONS_TABLE_EDIT;

const ConsignerAndTruckStep = ({
    refreshList,
    list_data,
    loadingList,
    company_accounts_list,
    customer_accounts_list,
    nextStep,
    setWizardData,
    wizard_data
}) => {


    const [errorVal, setErrorVal] = useState("");
    const [consignerType, setConsignerType] = useState("");
    const [company_accounts, setCompanyAccounts] = useState([]);
    const [listLoading, setListLoading] = useState();
    const [customer_accounts, setCustomerAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(wizard_data);
    const [driver_insructions, setDriverInstructions] = useState([]);
    const [selected_table_data, setSelectedTableData] = useState([]);
    const [user_list_data, setUserListData] = useState([]);

    useEffect(() => {
        setFormData(wizard_data);
    }, [wizard_data]);

    useEffect(() => {
        setUserListData(list_data);
    }, [list_data]);

    useEffect(() => {
        setListLoading(loadingList);
    }, [loadingList]);

    useEffect(() => {
        const mappedList = company_accounts_list?.map((comp_acc) => {
            return {
                value: comp_acc.unique_identification_number,
                label: `${comp_acc.unique_identification_number} - ${comp_acc.company_name}`,
            };
        });
        setCompanyAccounts(mappedList);
    }, [company_accounts_list]);

    useEffect(() => {
        const mappedList = customer_accounts_list?.map((cust_acc) => {
            return {
                value: cust_acc.unique_identification_number,
                label: `${cust_acc.unique_identification_number} - ${cust_acc.first_name} ${cust_acc.surname}`,
            };
        });
        setCustomerAccounts(mappedList);
    }, [customer_accounts_list]);

    // Handle Selection Check
    const handleUserAccountSelection = (value, label) => {
        setFormData({ ...formData, ["user_account_id"]: value });
    };

    const consigner_type_selection = (typeSelection) => {
        setConsignerType(typeSelection);
        setFormData({ ...formData, ["consigner_type_id"]: typeSelection });
    };

    // Create Loading Order
    const create_loading_order = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_create_loading_order,
                success_create_loading_order,
                fail_create_loading_order,
                CREATE_LOADING_ORDER_ERR_MSG,
                CREATE_LOADING_ORDER_SUCCESS_MSG,
                app_consts.POST,
                CREATE_LOADING_ORDER_API,
                { truck_license_plate: formData?.truck_licence_plate, truck_driver_name: formData?.truck_driver_name, transporter_name: formData?.transporter_name, contact_name: formData?.contact_name, contact_mobile: formData?.contact_mobile, driver_instructions: formData?.driver_instructions, consigner_type_id: formData?.consigner_type_id, user_account_id: formData.user_account_id },
                CREATE_LOADING_ORDER_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Create Loading Order Changes
    const create_loading_order_listener = useSelector((state) => state.create_loading_order.create_loading_order);
    // 
    useEffect(() => {
        if (create_loading_order_listener.status !== app_consts.IDLE)
        {
            if (create_loading_order_listener.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (create_loading_order_listener.status === app_consts.FAIL)
                {
                    setErrorVal(create_loading_order_listener.message);
                    messageToast(CREATE_LOADING_ORDER_ERR_MSG, app_consts.FAIL);
                } else if (create_loading_order_listener.status === app_consts.SUCCESS)
                {
                    messageToast(CREATE_LOADING_ORDER_SUCCESS_MSG, app_consts.SUCCESS);
                    setWizardData(create_loading_order_listener.data);
                    nextStep();
                }
                dispatch(reset_create_loading_order());
            }
        }
    }, [create_loading_order_listener]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    // Form Data

    // Add Driver Instruction
    const addDriverInstructionFunc = () => {
        if (formData?.add_driver_instructions)
        {
            if (selected_table_data && selected_table_data?.action)
            {
                if (selected_table_data?.action === app_consts.EDIT)
                {
                    const instructions = driver_insructions;
                    instructions[selected_table_data?.index] = formData?.add_driver_instructions;
                    setFormData({ ...formData, ["driver_instructions"]: instructions, ["add_driver_instructions"]: "" });
                    setDriverInstructions(instructions);
                    messageToast("Driver Instruction Successfully Edited.", app_consts.SUCCESS);
                }
            }
            else
            {
                const instructions = formData?.driver_instructions || [];
                instructions?.push(formData?.add_driver_instructions);
                setFormData({ ...formData, ["driver_instructions"]: instructions, ["add_driver_instructions"]: "" });
                setDriverInstructions(instructions);
                messageToast("Driver Instruction Successfully Added.", app_consts.SUCCESS);
            }
        }
    };

    // Table Click Listener
    useEffect(() => {
        if (selected_table_data)
        {
            if (selected_table_data?.action === app_consts.EDIT)
            {
                // Filter Out Selected Index
                setFormData({ ...formData, ["add_driver_instructions"]: driver_insructions[selected_table_data?.index] });
            }
            else if (selected_table_data?.action === app_consts.DELETE)
            {
                // Filter Out Selected Index
                const instructions = driver_insructions;
                instructions.splice(selected_table_data?.index, 1);
                setDriverInstructions(instructions);
                messageToast("Driver Instruction Successfully Removed.", app_consts.SUCCESS);
                setSelectedTableData([]);
            }
        }
    }, [selected_table_data]);

    return (
        <>
            <Card className="card-inner">
                <CardTitle tag="h5">Select Loading Order Consigner and Truck Details</CardTitle>
                <hr />
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <Row className="g-4">
                        <Col>
                            <div className="form-group">
                                <label className="form-label" htmlFor="">
                                    Consigner Type
                                </label>
                                <div className="w-80 form-control-wrap d-flex flex-row">
                                    <RadioInput
                                        id={app_consts.UAG_CONSIGNER_TYPE}
                                        label="UAG"
                                        className="custom-control custom-control-md custom-radio"
                                        handleChange={(e) => consigner_type_selection(e)}
                                    />
                                    <RadioInput
                                        id={app_consts.COMPANY_CONSIGNER_TYPE}
                                        label="Company"
                                        className="custom-control custom-control-md custom-radio"
                                        handleChange={consigner_type_selection}
                                    />
                                    <RadioInput
                                        id={app_consts.CUSTOMER_CONSIGNER_TYPE}
                                        label="Individual Customer"
                                        className="custom-control custom-control-md custom-radio"
                                        handleChange={consigner_type_selection}
                                    />
                                </div>
                            </div>
                        </Col>
                        {(consignerType === 2 || consignerType === 3) && (
                            <>
                                <div className="mt-4">
                                    <BlockHead>
                                        <BlockTitle tag="h6">Select Consigner</BlockTitle>
                                    </BlockHead>
                                </div>
                                <Col>
                                    <div className="form-group">
                                        <label className="form-label mb-1 text-md" htmlFor="username">
                                            {`Search for ${consignerType === 2 ? ` Company ` : consignerType === 3 ? `Customer ` : ""} by ${consignerType === 2 ? `Company ` : consignerType === 3 ? `Customer ` : ""
                                                } Account Number or Name`}
                                        </label>
                                        {consignerType === 2 && (
                                            <div className="form-control-wrap align-items-center w-65 d-flex flex-row mb-2 justify-between">
                                                <div className="w-80">
                                                    <RSelect
                                                        options={company_accounts}
                                                        className="w-100"
                                                        placeholder="Search"
                                                        id="username"
                                                        onChange={(e) => handleUserAccountSelection(e.value, e.label)}
                                                        // onInputChange={(e) => onActionText(e)}
                                                        isDisabled={listLoading}
                                                    />
                                                </div>
                                                <div className="w-15">
                                                    {/* // Waiting for List to Load */}
                                                    {listLoading ? (
                                                        <Spinner size="sm" />
                                                    ) : (
                                                        <Link
                                                            to="#reload"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                                                            }}
                                                        >
                                                            <Icon name="reload-alt btn-icon fs-22px"></Icon>
                                                        </Link>
                                                    )}
                                                    {/*  } */}
                                                </div>
                                            </div>
                                        )}
                                        {consignerType === 3 && (
                                            <div className="form-control-wrap align-items-center w-50 d-flex flex-row mb-2 justify-between">
                                                <div className="w-80">
                                                    <RSelect
                                                        options={customer_accounts}
                                                        className="w-100"
                                                        placeholder="Search"
                                                        id="username"
                                                        onChange={(e) => handleUserAccountSelection(e.value, e.label)}
                                                        // onInputChange={(e) => onActionText(e)}
                                                        isDisabled={listLoading}
                                                    />
                                                </div>
                                                <div className="w-15">
                                                    {/* // Waiting for List to Load */}
                                                    {listLoading ? (
                                                        <Spinner size="sm" />
                                                    ) : (
                                                        <Link
                                                            to="#reload"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                                                            }}
                                                        >
                                                            <Icon name="reload-alt btn-icon fs-22px"></Icon>
                                                        </Link>
                                                    )}
                                                    {/*  } */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </>
                        )}
                        <TextInput
                            colSize={5}
                            label="Contact Person Name"
                            type="text"
                            id_val="contact_name"
                            value={formData.contact_name}
                            handleChange={handleChange}
                            name="contact_name"
                        />
                        <TextInput
                            colSize={5}
                            label="Contact Person Mobile"
                            type="text"
                            id_val="contact_mobile"
                            value={formData.contact_mobile}
                            handleChange={handleChange}
                            name="contact_mobile"
                        />
                        <div className="form-label-group">
                            <label className="form-label text-soft fs-22px">Truck Details</label>
                        </div>
                        <TextInput
                            colSize={5}
                            label="Transporter"
                            type="text"
                            id_val="transporter_name"
                            value={formData.transporter_name}
                            handleChange={handleChange}
                            name="transporter_name"
                        />
                        <TextInput
                            colSize={5}
                            label="Truck Driver"
                            type="text"
                            id_val="truck_driver_name"
                            value={formData.truck_driver_name}
                            handleChange={handleChange}
                            name="truck_driver_name"
                        />
                        <TextInput
                            colSize={5}
                            label="Truck License Plate"
                            type="text"
                            id_val="truck_licence_plate"
                            value={formData.truck_licence_plate}
                            handleChange={handleChange}
                            name="truck_licence_plate"
                        />
                        <TextInput
                            colSize={5}
                            label="Trailer 1 (Optional)"
                            type="text"
                            id_val="trailer_one"
                            value={formData.trailer_one}
                            handleChange={handleChange}
                            name="trailer_one"
                        />
                        <TextInput
                            colSize={5}
                            label="Trailer 2 (Optional)"
                            type="text"
                            id_val="trailer_two"
                            value={formData.trailer_two}
                            handleChange={handleChange}
                            name="trailer_two"
                        />
                        <div className='d-flex flex-row items-center'>
                            <TextInput
                                colSize={8}
                                label="Driver Instructions (Optional)"
                                type="text"
                                id_val="add_driver_instructions"
                                value={formData.add_driver_instructions}
                                handleChange={handleChange}
                                name="add_driver_instructions"
                            />
                            <Col lg="4">
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label className="form-label">
                                            <br />
                                        </label>
                                    </div>
                                    <div className="form-control-wrap mx-4 d-flex justify-between">
                                        <Button color="primary" onClick={addDriverInstructionFunc}>
                                            <Icon name={selected_table_data && selected_table_data?.action === app_consts.EDIT ? "pen" : "plus"} />
                                            <span>{selected_table_data && selected_table_data?.action === app_consts.EDIT ? "Edit" : "Add"}</span>
                                        </Button>
                                        {
                                            selected_table_data && selected_table_data?.action === app_consts.EDIT && (
                                                <>
                                                    <Button color="light" className="ml-3" onClick={() => { setSelectedTableData([]); setFormData({ ...formData, ["add_driver_instructions"]: "" }); }}>
                                                        <Icon name="cross" />
                                                        <span>Cancel</span>
                                                    </Button>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <div className="form-label-group">
                            <label className="form-label text-soft fs-16px">Driver Instructions</label>
                        </div>
                        {/* Table */}
                        <div className="my-3">
                            <ReadOnlyTable data={driver_insructions} tableType={driver_instructions_table_type} setSelectedData={setSelectedTableData} />
                        </div>
                        {errorVal && (
                            <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                    {" "}
                                    <Icon name="alert-circle" />
                                    {errorVal}{" "}
                                </Alert>
                            </div>
                        )}
                        <Col xl="12" className="mb-3">
                            <div className="form-group">
                                <div className="form-control-wrap-flex-wrap justify-end">
                                    <Button
                                        onClick={create_loading_order}
                                        color="primary"
                                        size="lg"
                                        disabled={
                                            loading
                                                ? loading
                                                :
                                                consignerType === app_consts.UAG_CONSIGNER_TYPE ? !(formData?.consigner_type_id && formData?.contact_name && formData?.contact_mobile && formData?.transporter_name && formData?.truck_driver_name && formData?.truck_licence_plate) :
                                                    !(formData?.consigner_type_id && formData?.contact_name && formData?.contact_mobile && formData?.transporter_name && formData?.truck_driver_name && formData?.truck_licence_plate && formData?.user_account_id)
                                        }
                                    >
                                        {loading ? <Spinner size="sm" color="light" /> : "Next: Add Products"}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </Card >
        </>
    );
};

export default ConsignerAndTruckStep;