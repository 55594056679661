import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    retreive_warehouse_storage_types_list: { status: "idle" },
};

export const retreive_warehouse_storage_types_list_Slice = createSlice({
    name: "retreive_warehouse_storage_types_list",
    initialState,
    reducers: {
        success_retreive_warehouse_storage_types_list: (state, action) => {
            state.retreive_warehouse_storage_types_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retreive_warehouse_storage_types_list: (state, action) => {
            state.retreive_warehouse_storage_types_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retreive_warehouse_storage_types_list: (state) => {
            state.retreive_warehouse_storage_types_list = { status: app_consts.IDLE };
        },
        loading_retreive_warehouse_storage_types_list: (state) => {
            state.retreive_warehouse_storage_types_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_retreive_warehouse_storage_types_list, fail_retreive_warehouse_storage_types_list, reset_retreive_warehouse_storage_types_list, loading_retreive_warehouse_storage_types_list } = retreive_warehouse_storage_types_list_Slice.actions;

export default retreive_warehouse_storage_types_list_Slice.reducer;