import React, { useEffect, useState } from 'react';
import { Icon } from '../../../../components/Component';

const CardTabs = ({ cardTabs, activeCardTab, setActiveCardTab, dataFetched }) => {

    const [tabs, setTabs] = useState(cardTabs);
    const [activeTab, setActiveTab] = useState(activeCardTab);

    useEffect(() => {
        setTabs(cardTabs);
        setActiveTab(activeCardTab);
    }, [activeCardTab, cardTabs]);


    return (
        <>
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                {
                    tabs?.map((item, index) => (
                        <>
                            <li className="nav-item">
                                <a
                                    href='#'
                                    className={`nav-link ${activeTab === item?.tabName ? "active" : ""}`}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setActiveCardTab(item?.tabName);
                                    }}
                                >
                                    <Icon name={item?.icon}></Icon>
                                    <span>{item?.tabName}</span>
                                </a>
                            </li>
                        </>
                    ))}
            </ul>
        </>
    );
};

export default CardTabs;