import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from 'reactstrap';
import { Button, Col, Icon, Row, RSelect } from '../../../../../../components/Component';
import { convertPackagingUnits, retreive_rSelectVariables } from '../../../../../../utils/functionalUtils';
import { messageToast, rSelectMapFun } from '../../../../../../utils/Utils';
import TextInput from '../../../../../components/inputs/TextInput';
import { useDispatch } from 'react-redux';
import { ADD_PRODUCT_TO_LOADING_ORDER_API, RETRIEVE_ACTIVE_AVAILABLE_PRODUCTS_API, RETRIEVE_ACTIVE_EMPLOYEE_BRANCH_LOCATION_LIST_API, RETRIEVE_USER_ACCOUNT_PROPERTIES_BY_UNIQUE_ID_API } from '../../../../../../api/api_data';
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { fail_product_list, loading_product_list, reset_product_list, success_product_list } from '../../../../../../reducers/dashboard_reducers/products/product_list_Slice';
import { ADD_LOADING_ORDER_PRODUCT_ACCESS_DENIED_MSG, ADD_LOADING_ORDER_PRODUCT_ERR_MSG, ADD_LOADING_ORDER_PRODUCT_SUCCESS_MSG, RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG, RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG, RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG, RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG, RETRIEVE_PRODUCTS_LIST_ERR_MSG, RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG, RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_ACCESS_DENIED_MSG, RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_ERR_MSG, RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import * as app_consts from "../../../../../../constants/react_app_consts";
import { useSelector } from 'react-redux';
import { fail_branch_location_list, loading_branch_location_list, reset_branch_location_list, success_branch_location_list } from '../../../../../../reducers/dashboard_reducers/branch_locations/branch_location_list_Slice';
import ListText from '../../../text/ListText';
import { fail_retrieve_user_account_property_list, loading_retrieve_user_account_property_list, reset_retrieve_user_account_property_list, success_retrieve_user_account_property_list } from '../../../../../../reducers/dashboard_reducers/user_account_property/retrieve_user_account_properties_Slice';
import { fail_add_product_to_loading_order, loading_add_product_to_loading_order, reset_add_product_to_loading_order, success_add_product_to_loading_order } from '../../../../../../reducers/dashboard_reducers/loading_orders/add_product_to_loading_order_Slice';

const initialState = { product_id: "", product_type: "", product: "", warehouse_storage_id: "", warehouse_storage: "", total_capacity_selected_measurement: "", packaging_total_number: "", total_capacity_in_mt: "", uom: "", uom_label: "", warehouse_storage_label: "", branch_location_id: "", branch_location_label: "", available_capacity: "", delivery_location_id: "", delivery_location_label: "", loading_order_ref_no: "" };

const AddLoadingOrderProduct = ({ page_data, openModal, successFunction, wizard_data }) => {

 const [step, setStep] = useState(1);
 const [formData, setFormData] = useState(initialState);
 const [errorVal, setError] = useState("");
 const [productTypeList, setProductTypeList] = useState([]);
 const [productListLoading, setProductListLoading] = useState(true);
 const [selectedProducts, setSelectedProducts] = useState();
 const [product_data, setProductData] = useState([]);
 const [productsList, setProductsList] = useState([]);
 const [uom, setUom] = useState([]);
 const dispatch = useDispatch();
 const [branches, setBranches] = useState([]);
 const [warehousestorageslist, setWarehouseStoragesList] = useState([]);
 const [delivery_location_list, setDeliveryLoactionList] = useState([]);
 const [delivery_location_loading, setDeliveryLocationLoading] = useState(true);
 const [delivery_location_data, setDeliveryLocationData] = useState([]);
 const [addLoading, setAddLoading] = useState(false);

 // Code that Runs When Page Opens
 useEffect(() => {
  // setRetrievedData(page_data);
  if (openModal && page_data)
  {
   setFormData({ ...formData, ["loading_order_ref_no"]: page_data?.loading_order_ref_no });
   if (page_data?.owner === "UAG")
   {
    dispatch(
     employee_dashboard_actions_helper(
      loading_branch_location_list,
      success_branch_location_list,
      fail_branch_location_list,
      RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG,
      RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG,
      app_consts.GET,
      RETRIEVE_ACTIVE_EMPLOYEE_BRANCH_LOCATION_LIST_API,
      null,
      RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG
     )
    );
   }
   else
   {
    dispatch(
     employee_dashboard_actions_helper(
      loading_retrieve_user_account_property_list,
      success_retrieve_user_account_property_list,
      fail_retrieve_user_account_property_list,
      RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_ERR_MSG,
      RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_SUCCESS_MSG,
      app_consts.POST,
      RETRIEVE_USER_ACCOUNT_PROPERTIES_BY_UNIQUE_ID_API,
      { unique_identification_number: page_data?.unique_identification_number },
      RETRIEVE_USER_ACCOUNT_PROPERTY_LIST_ACCESS_DENIED_MSG
     )
    );
   }

   // Retrieve Product List
   dispatch(
    employee_dashboard_actions_helper(
     loading_product_list,
     success_product_list,
     fail_product_list,
     RETRIEVE_PRODUCTS_LIST_ERR_MSG,
     RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG,
     app_consts.GET,
     RETRIEVE_ACTIVE_AVAILABLE_PRODUCTS_API,
     null,
     RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG
    )
   );
  }
 }, [openModal, page_data]);

 //List to State of Branch Location List
 const branch_location_list = useSelector((state) => state.branch_location_list.branch_location_list);

 useEffect(() => {
  if (branch_location_list.status !== app_consts.IDLE)
  {
   if (branch_location_list.status === app_consts.LOADING)
   {
    setDeliveryLocationLoading(true);
   } else
   {
    setDeliveryLocationLoading(false);
    if (branch_location_list.status === app_consts.FAIL)
    {
     messageToast(branch_location_list.message, app_consts.FAIL);
    } else if (branch_location_list.status === app_consts.SUCCESS)
    {
     if (branch_location_list.data)
     {
      setDeliveryLocationData(branch_location_list.data);
     }
    }
    dispatch(reset_branch_location_list());
   }
  }
 }, [branch_location_list]);

 //List to State of Branch Location List
 const user_property_list = useSelector((state) => state.retrieve_user_account_property_list.retrieve_user_account_property_list);

 useEffect(() => {
  if (user_property_list.status !== app_consts.IDLE)
  {
   if (user_property_list.status === app_consts.LOADING)
   {
    setDeliveryLocationLoading(true);
   } else
   {
    setDeliveryLocationLoading(false);
    if (user_property_list.status === app_consts.FAIL)
    {
     messageToast(user_property_list.message, app_consts.FAIL);
    } else if (user_property_list.status === app_consts.SUCCESS)
    {
     if (user_property_list.data)
     {
      setDeliveryLocationData(user_property_list.data);
     }
    }
    dispatch(reset_retrieve_user_account_property_list());
   }
  }
 }, [user_property_list]);

 useEffect(() => {
  if (delivery_location_data)
  {
   handleDeliveryLocationData();
  }
 }
  , [delivery_location_data]);

 const handleDeliveryLocationData = async () => {
  let mappedData = [];

  if (page_data?.owner === "UAG")
  {
   await Promise.all(
    delivery_location_data?.map(async (data_to_map) => {
     // Map Branch List
     const { label, value } = retreive_rSelectVariables(app_consts.BRANCH_LOCATION_MAP, data_to_map);
     const result = await rSelectMapFun(label, value);
     mappedData.push(result);
    })
   );
  }
  else
  {
   await Promise.all(
    delivery_location_data?.map(async (data_to_map) => {
     // Map Branch List
     const { label, value } = retreive_rSelectVariables(app_consts.USER_ACCOUNT_PROPERTY_MAP, data_to_map);
     const result = await rSelectMapFun(label, value);
     mappedData.push(result);
    })
   );
  }


  setDeliveryLoactionList(mappedData);
 };

 //Listen to State of Product List
 const product_list = useSelector((state) => state.product_list.product_list);

 useEffect(() => {
  if (product_list.status !== app_consts.IDLE)
  {
   if (product_list.status === app_consts.LOADING)
   {
    setProductListLoading(true);
   } else
   {
    setProductListLoading(false);
    if (product_list.status === app_consts.FAIL)
    {
     messageToast(product_list.message, app_consts.FAIL);
    } else if (product_list.status === app_consts.SUCCESS)
    {
     setProductData(product_list.data);
    }
    dispatch(reset_product_list());
   }
  }
 }, [product_list]);

 // Product Data listener
 useEffect(() => {
  if (product_data?.product_types) 
  {
   handleProductType();
  }
 }, [product_data]);

 const handleProductType = async () => {
  let mappedData = [];

  await Promise.all(
   product_data?.product_types?.map(async (data_to_map) => {
    const { label, value } = retreive_rSelectVariables(app_consts.PRODUCT_TYPE_MAP, data_to_map);
    const result = await rSelectMapFun(label, value);
    mappedData.push(result);
   })
  );

  setProductTypeList(mappedData);
 };

 // Handle Product Type Change
 const handleProductTypeChange = async (value, label) => {
  if (value && label) 
  {
   setFormData({ ...formData, ["product_type"]: label, ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
   let mappedData = [];
   const uniqueSet = new Set();

   const filtereredData = product_data?.products?.filter((data) => data.product_type_id === value);

   setSelectedProducts(filtereredData);

   await Promise.all(
    filtereredData?.map(async (data_to_map) => {
     const { label, value } = retreive_rSelectVariables(app_consts.PRODUCTS_MAP, data_to_map);
     const result = await rSelectMapFun(label, value);

     const uniqueKey = JSON.stringify(result);

     if (!uniqueSet.has(uniqueKey))
     {
      uniqueSet.add(uniqueKey);
      mappedData.push(result);
     }
    })
   );

   setProductsList(mappedData);
  }
  else
  {
   setFormData({ ...formData, ["product_type"]: "", ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
  }
 };

 // Handle Branch Location Change
 const handleBranchLocationChange = async (value, label) => {
  if (value && label) 
  {
   setFormData({ ...formData, ["branch_location_label"]: label, ["branch_location_id"]: value, ["warehouse_storage_id"]: "", ["warehouse_storage_label"]: "", ["available_capacity"]: "" });
   let mappedData = [];
   const uniqueSet = new Set();

   const filtereredData = product_data?.products?.filter((data) => data.branch_location_id === value && data.product_id === formData.product_id);

   setSelectedProducts(filtereredData);

   await Promise.all(
    filtereredData?.map(async (data_to_map) => {
     const { label, value } = retreive_rSelectVariables(app_consts.WAREHOUSE_STORAGE_TYPES_MAP, data_to_map);
     const result = await rSelectMapFun(label, value);

     const uniqueKey = JSON.stringify(result);

     if (!uniqueSet.has(uniqueKey))
     {
      uniqueSet.add(uniqueKey);
      mappedData.push(result);
     }
    })
   );
   setWarehouseStoragesList(mappedData);
  }
  else
  {
   setFormData({ ...formData, ["warehouse_storage_id"]: "", ["warehouse_storage_label"]: "", ["available_capacity"]: "", ["branch_location_label"]: "", ["branch_location_id"]: "" });
  }
 };

 // Handle Product Change
 const handleProductChange = async (value, label) => {
  if (value && label) 
  {
   setFormData({ ...formData, ["product_id"]: value, ["product"]: label, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "", ["branch_location_label"]: "", ["branch_location_id"]: "" });
   let mappedData = [];
   const filtereredData = selectedProducts?.filter((data) => data.product_id === value);

   await Promise.all(
    filtereredData?.map(async (data_to_map) => {
     // Map Pum List
     data_to_map?.pum_list?.map(async (map) => {
      map?.uom_description?.map(async (last_data) => {
       const { label, value } = retreive_rSelectVariables(app_consts.UOM_MAP, last_data);
       const result = await rSelectMapFun(label, value);

       // Check if the result is already in mappedData
       if (!mappedData.some(item => JSON.stringify(item) === JSON.stringify(result)))
       {
        mappedData.push(result);
       }
      });
     });
    })
   );

   setUom(mappedData);
  }
  else
  {
   setFormData({ ...formData, ["product_id"]: "", ["product"]: "", ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "", ["branch_location_label"]: "", ["branch_location_id"]: "" });
  }
 };

 // Handle Unit of Measurem Chanage
 const handleUomChange = async (value, label) => {
  if (value && label) 
  {
   let branchDataMap = [];

   setFormData({ ...formData, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: value, ["uom_label"]: label });


   const filtereredData = selectedProducts?.filter((data) => {
    // Check if the product_id matches
    if (data.product_id === formData.product_id)
    {
     // Check if any of the uom_description values match the desired value
     return data.pum_list?.some(pum => pum.uom_description.includes(value));
    }
    return false;
   });

   const uniqueSet = new Set();

   await Promise.all(
    filtereredData?.map(async (data_to_map) => {
     // Map Branch List
     const { label, value } = retreive_rSelectVariables(app_consts.BRANCH_LOCATION_MAP, data_to_map);
     const result = await rSelectMapFun(label, value);

     const uniqueKey = JSON.stringify(result);

     if (!uniqueSet.has(uniqueKey))
     {
      uniqueSet.add(uniqueKey);
      branchDataMap.push(result);
     }
    })
   );

   setBranches(branchDataMap);
  }
  else
  {
   setFormData({ ...formData, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "", ["uom"]: "", ["uom_label"]: "" });
  }
 };

 // Handle Change
 const handleChange = (e) => {
  if (e.target.name === "packaging_total_number") 
  {
   let converted_data = { total_capacity_in_mt: "", total_capacity_selected_measurement: "" };
   const result = convertPackagingUnits(e.target.value, formData.uom_label);
   if (result?.original)
   {
    converted_data.total_capacity_selected_measurement = result?.original;
   }
   if (result?.metricTonnes)
   {
    converted_data.total_capacity_in_mt = result?.metricTonnes;
   }

   setFormData({ ...formData, ["packaging_total_number"]: e.target.value, ["total_capacity_in_mt"]: converted_data.total_capacity_in_mt, ["total_capacity_selected_measurement"]: converted_data.total_capacity_selected_measurement });
  }
 };

 const handleOnblur = (e) => {
  if (e.target.name === "total_capacity_in_mt")
  {
   if (e.target.value)
   {
    setFormData({ ...formData, ["total_capacity_in_mt"]: parseFloat(e.target.value).toFixed(2) });
   }
   else
   {
    setFormData({ ...formData, ["total_capacity_in_mt"]: "" });
   }
  }
  else
  {
   if (e.target.value)
   {
    setFormData({ ...formData, [e.target.name]: parseFloat(e.target.value).toFixed(2) });
   }
   else
   {
    setFormData({ ...formData, [e.target.name]: "" });
   }
  }
 };

 // Handle Storage Type Change
 const handleStorageTypeChange = async (value, label) => {
  if (value && label) 
  {
   const filtereredData = selectedProducts?.filter((data) => data.warehouse_storage_type_id === value && data.product_id === formData.product_id);

   setFormData({ ...formData, ["warehouse_storage_id"]: value, ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: label, ["available_capacity"]: filtereredData[0]?.available_capacity || 0.00 });
  }
  else
  {
   setFormData({ ...formData, ["warehouse_storage_id"]: "", ["total_capacity_selected_measurement"]: "", ["packaging_total_number"]: "", ["total_capacity_in_mt"]: "", ["warehouse_storage_label"]: "" });
  }
 };

 // Handle Preview
 const handlePreview = () => {
  setError("");
  setStep(2);
 };

 //     // Handle Submit
 const handleSubmit = () => {
  setError("");
  dispatch(
   employee_dashboard_actions_helper(
    loading_add_product_to_loading_order,
    success_add_product_to_loading_order,
    fail_add_product_to_loading_order,
    ADD_LOADING_ORDER_PRODUCT_ERR_MSG,
    ADD_LOADING_ORDER_PRODUCT_SUCCESS_MSG,
    app_consts.POST,
    ADD_PRODUCT_TO_LOADING_ORDER_API,
    { ...formData, ["loading_order_ref_no"]: page_data?.loading_order_ref_no },
    ADD_LOADING_ORDER_PRODUCT_ACCESS_DENIED_MSG
   )
  );
 };

 // Listen to the Changes
 const product_add_listener = useSelector((state) => state.add_product_to_loading_order.add_product_to_loading_order);

 useEffect(() => {
  if (product_add_listener.status !== app_consts.IDLE)
  {
   if (product_add_listener.status === app_consts.LOADING)
   {
    setAddLoading(true);
   } else
   {
    setAddLoading(false);
    if (product_add_listener.status === app_consts.FAIL)
    {
     messageToast(product_add_listener.message, app_consts.FAIL);
     setError(product_add_listener.message);
    } else if (product_add_listener.status === app_consts.SUCCESS)
    {
     if (product_add_listener.data)
     {
      successFunction(product_add_listener.data);
     }
    }
    dispatch(reset_add_product_to_loading_order());
   }
  }
 }, [product_add_listener]);

 return (
  <>
   <div className="d-flex justify-content-between mb-2">
    {step === 2 && (
     <div
      className="nk-block-head-sub mb-2"
      style={{ cursor: "pointer" }}
      onClick={(e) => {
       e.preventDefault();
       setStep(1);
       setFormData(initialState);
      }}
     >
      <Icon name="arrow-left" />
      <span>back</span>
     </div>
    )}
   </div>
   {errorVal && (
    <div className="mb-3 w-100">
     <Alert color="danger" className="alert-icon">
      <Icon name="alert-circle" /> {errorVal}{" "}
     </Alert>
    </div>
   )}
   {step === 1 ? (
    <>
     <form
      onSubmit={(e) => {
       e.preventDefault();
      }}
     >
      <Row className="g-4">
       <div className="form-label-group">
        <label className="form-label text-soft fs-22px">Product Details</label>
       </div>
       <Col size={6} className="my-2">
        <div className="form-group">
         <label className="form-label" htmlFor="">
          Product Type
         </label>
         <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
          <div className="w-100">
           <RSelect
            options={productTypeList}
            className="w-100"
            placeholder="Search"
            id="product_type"
            isLoading={productListLoading}
            onChange={(e) => { handleProductTypeChange(e.value, e.label); }}
            isDisabled={productListLoading}
           />
          </div>
         </div>
        </div>
       </Col>
       <Col size={6} className="my-2">
        <div className="form-group">
         <label className="form-label" htmlFor="">
          Product
         </label>
         <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
          <div className="w-100">
           <RSelect
            options={productsList}
            className="w-100"
            placeholder="Search"
            id="product_id"
            onChange={(e) => { handleProductChange(e.value, e.label); }}
            isDisabled={!formData.product_type}
           />
          </div>
         </div>
        </div>
       </Col>
       <Col size={6} className="my-2">
        <div className="form-group">
         <label className="form-label" htmlFor="uom">
          Unit of Measure
         </label>
         <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
          <div className="w-100">
           <RSelect
            options={uom}
            className="w-100"
            placeholder="Search"
            id="uom"
            onChange={(e) => { handleUomChange(e.value, e.label); }}
            isDisabled={!formData.product_id}
           />
          </div>
         </div>
        </div>
       </Col>
       <Col size={6} className="my-2">
        <div className="form-group">
         <label className="form-label" htmlFor="uom">
          Delivery Location
         </label>
         <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
          <div className="w-100">
           <RSelect
            options={delivery_location_list}
            className="w-100"
            placeholder="Search"
            id="delivery_location_id"
            onChange={(e) => { setFormData({ ...formData, ["delivery_location_id"]: e.value, ["delivery_location_label"]: e.label }); }}
            isDisabled={!delivery_location_list}
           />
          </div>
         </div>
        </div>
       </Col>
       <div className="form-label-group">
        <label className="form-label text-soft fs-22px">Warehouse Details</label>
       </div>
       <Col size={6} className="my-2">
        <div className="form-group">
         <label className="form-label" htmlFor="">
          Branch Location
         </label>
         <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
          <div className="w-100">
           <RSelect
            options={branches}
            className="w-100"
            placeholder="Search"
            id="product_type"
            isLoading={productListLoading}
            onChange={(e) => { handleBranchLocationChange(e.value, e.label); }}
            isDisabled={!formData.uom}
           />
          </div>
         </div>
        </div>
       </Col>
       <Col size={6} className="my-2">
        <div className="form-group">
         <label className="form-label" htmlFor="">
          Storage Type
         </label>
         <div className="w-100 form-control-wrap d-flex flex-row justify-content-between align-items-center">
          <div className="w-100">
           <RSelect
            options={warehousestorageslist}
            className="w-100"
            placeholder="Search"
            id="warehouse_storage_type_id"
            isLoading={productListLoading}
            onChange={(e) => { handleStorageTypeChange(e.value, e.label); }}
            isDisabled={!formData.branch_location_id}
           />
          </div>
         </div>
        </div>
       </Col>
       <TextInput
        parentClassName="mt-2"
        label="Quantity Available"
        id_val="available_capacity"
        type="number"
        value={formData?.available_capacity}
        colSize="6"
        name="available_capacity"
        disabled={true}
        handleChange={handleChange}
       />
       <div className="form-label-group">
        <label className="form-label text-soft fs-22px">Measurement Details</label>
       </div>

       <TextInput
        parentClassName="mt-2"
        label="Packaging Total Number"
        id_val="packaging_total_number"
        type="number"
        value={formData?.packaging_total_number}
        colSize="6"
        name="packaging_total_number"
        disabled={!formData.warehouse_storage_id}
        handleChange={handleChange}
       />
       <TextInput
        parentClassName="mt-2"
        label={`Total Capacity ${formData.unit_measurement ? `(${formData.unit_measurement})` : ""}`}
        id_val="total_capacity_selected_measurement"
        type="number"
        value={formData?.total_capacity_selected_measurement}
        colSize="6"
        name="total_capacity_selected_measurement"
        disabled={true}
        handleChange={handleChange}
        onBlur={handleOnblur}
       />
       <TextInput
        parentClassName="mt-2"
        label="Quantity in (MT)"
        id_val="total_capacity_in_mt"
        maxlength="160"
        type="number"
        value={formData?.total_capacity_in_mt}
        colSize="6"
        name="total_capacity_in_mt"
        disabled={true}
        handleChange={handleChange}
        onBlur={handleOnblur}
       />
       <Col xl="12" className="d-flex justify-content-center">
        <Button
         onClick={handlePreview}
         size="lg"
         type="submit"
         color="primary"
         disabled={!(formData.product_id && formData.warehouse_storage_id && formData.total_capacity_selected_measurement && formData.packaging_total_number && formData.total_capacity_in_mt && formData.uom && formData.branch_location_id)}
        >
         Preview Product and Add
        </Button>
       </Col>
      </Row>
     </form>
    </>
   )
    :
    <>
     <ListText dataItem="data-item mySmall" label="Product Name" value={formData.product} />
     <ListText dataItem="data-item mySmall" label="Product Type" value={formData.product_type} />
     <ListText dataItem="data-item mySmall" label="Warehouse Storage Type" value={formData.warehouse_storage_label} />
     <ListText dataItem="data-item mySmall" label="Unit of Measurement" value={formData.uom_label} />
     <ListText dataItem="data-item mySmall" label="Branch Location" value={formData.branch_location_label} />
     <ListText dataItem="data-item mySmall" label="Delivery Location" value={formData.delivery_location_label} />
     <ListText dataItem="data-item mySmall" label="Packaging Total Number" value={formData.packaging_total_number} />
     <ListText dataItem="data-item mySmall" label={`Total Capacity ${formData.unit_measurement ? `(${formData.unit_measurement})` : ""}`} value={formData.total_capacity_selected_measurement} />
     <ListText dataItem="data-item mySmall" label="Total Capacity (MT)" value={formData.total_capacity_in_mt} />
     <Col xl="12" className="d-flex justify-content-end mt-4 mr-2">
      <Button onClick={handleSubmit} disabled={addLoading} color="primary">
       {addLoading ? <Spinner size="sm" color="light" /> : "Add Product"}
      </Button>
     </Col>
    </>
   }
  </>
 );
};

export default AddLoadingOrderProduct;