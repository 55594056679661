import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import TablePageHeading from "../../components/page_heading/TablePageHeading";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../constants/react_app_consts";
import { Block } from "../../../../components/Component";
import TableList from "../../components/table/TableList";
import { employee_dashboard_actions_helper } from "../../../../actions/actions_helper";
import { fail_retrieve_employee_list, loading_retrieve_employee_list, reset_retrieve_employee_list, success_retrieve_employee_list } from "../../../../reducers/dashboard_reducers/user/retrieve_employee_list_Slice";
import { RETRIEVE_EMPLOYEE_LIST_API } from "../../../../api/api_data";
import { RETRIEVE_EMPLOYEE_LIST_ACCESS_DENIED_MSG, RETRIEVE_EMPLOYEE_LIST_ERR_MSG, RETRIEVE_EMPLOYEE_LIST_SUCCESS_MSG } from "../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { BiErrorCircle } from "react-icons/bi";

const type = app_consts.EMPLOYEE_LIST_TYPE;
const initial_actions_data = { onboard_employee: false, view_employees: false, amend_employee_status: false };

const EmployeeList = () => {
  const dispatch = useDispatch();
  const [page_data, setPageData] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch Employee List
  useEffect(() => {
    retrieve_employees_list_func();
    setLoading(true);
  }, []);

  // Retrieve Users
  const retrieve_employees_list_func = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_retrieve_employee_list,
        success_retrieve_employee_list,
        fail_retrieve_employee_list,
        RETRIEVE_EMPLOYEE_LIST_ERR_MSG,
        RETRIEVE_EMPLOYEE_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_EMPLOYEE_LIST_API,
        null,
        RETRIEVE_EMPLOYEE_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Refresh List Function
  const refreshList = () => {
    setError("");
    // Retrieve User list
    retrieve_employees_list_func();
    setLoading(true);
    setPageData([]);
  };

  // Read Employee List State
  const employee_list = useSelector((state) => state.retrieve_employee_list.retrieve_employee_list);

  useEffect(() => {
    if (employee_list.status !== app_consts.IDLE)
    {
      if (employee_list.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (employee_list.status === app_consts.FAIL)
        {
          // setError(employee_list.message)
        } else if (employee_list.status === app_consts.SUCCESS)
        {
          setPageData(employee_list.data);
        }
        dispatch(reset_retrieve_employee_list());
      }
    }
  }, [employee_list]);

  return (
    <>
      <Head title="Employee List" />
      <Content>
        <TablePageHeading
          title="Employee List"
          description={`Total Employees: ${page_data.length}`}
          refresh={!loading}
          refreshFunc={refreshList}
          csv_export={!loading && !error}
          //   add_to_list={componentData.onboard_user}
          page_type={type}
          pageData={page_data}
        />
        {/* Table */}
        <Block>
          {
            loading ?
              <>
                <div className='w-full p-5 d-flex justify-center items-center'>
                  <Spinner />
                </div>
              </>
              :
              error && error.length > 0 ?
                <>
                  <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                    <BiErrorCircle className="text-danger fs-3" />
                    <p>{error}</p>
                  </div>
                </>
                :
                <>
                  <TableList
                    left_head_select
                    type={type}
                    data={page_data}
                    rowOptionsData={componentData}
                  // amendFunc={amend_Account_Status_modal}
                  // setOpendModalDetails={setOpendModalDetails}
                  // setSelectedData={setSelectedUser}
                  />
                </>
          }
        </Block>
      </Content>
    </>
  );
};

export default EmployeeList;
