import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
    create_warehouse_storage_type: { status: "idle" },
};

export const create_warehouse_storage_type_Slice = createSlice({
    name: "create_warehouse_storage_type",
    initialState,
    reducers: {
        success_create_warehouse_storage_type: (state, action) => {
            state.create_warehouse_storage_type = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_warehouse_storage_type: (state, action) => {
            state.create_warehouse_storage_type = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_warehouse_storage_type: (state) => {
            state.create_warehouse_storage_type = { status: app_consts.IDLE };
        },
        loading_create_warehouse_storage_type: (state) => {
            state.create_warehouse_storage_type = { status: app_consts.LOADING };
        },
    },
});

export const {
    success_create_warehouse_storage_type,
    fail_create_warehouse_storage_type,
    reset_create_warehouse_storage_type,
    loading_create_warehouse_storage_type,
} = create_warehouse_storage_type_Slice.actions;

export default create_warehouse_storage_type_Slice.reducer;
